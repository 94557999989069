import {
  FulfillmentGroupAddressesSchema,
  FulfillmentOrderDataSchema,
  type IFulfillmentOrderData,
} from "@redotech/redo-model/fulfillments/fulfillment-group";
import { z } from "zod";

export const mergeFulfillmentGroups = {
  input: z.object({
    groupIds: z.array(z.string()),
    destinationAddresses: FulfillmentGroupAddressesSchema,
  }),
  output: z.object({
    group: (FulfillmentOrderDataSchema as IFulfillmentOrderData).nullish(),
    success: z.boolean(),
  }),
};
