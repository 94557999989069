// extracted by mini-css-extract-plugin
export var container = "_82-0";
export var content = "_82-3";
export var customerTitle = "_82-4";
export var empty = "_82-5";
export var gray = "_82-6";
export var header = "_82-7";
export var info = "_82-8";
export var loading = "_82-2";
export var loadingCard = "_82-9";
export var oneLine = "_82-a";
export var personName = "_82-e";
export var pillContainer = "_82-b";
export var selected = "_82-1";
export var text = "_82-c";
export var twoLines = "_82-d";
export var unread = "_82-f";
export var unreadIndicator = "_82-g";