// extracted by mini-css-extract-plugin
export var active = "_1j-d";
export var blink = "_1j-e";
export var container = "_1j-5";
export var controls = "_1j-0";
export var controlsRight = "_1j-1";
export var fakeCursor = "_1j-c";
export var hidden = "_1j-b";
export var quillContainerSmall = "_1j-7";
export var quillEditor = "_1j-6";
export var quillEditorSmall = "_1j-8";
export var quillFormatButtons = "_1j-a";
export var quillToolbar = "_1j-9";
export var removePadding = "_1j-2";
export var state = "_1j-3";
export var stateTitle = "_1j-4";