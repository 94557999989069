import { ReturnStatus } from "@redotech/redo-model/return-status";
import {
  Trackable,
  TrackableFulfillment,
  TrackableType,
} from "@redotech/redo-model/trackable";
import { memo } from "react";
import { prettyTrackerStatus } from "../../settings-elements/order-tracking/delivery-estimation";
import { Header, Text } from "../../text";
import { PickupConfig } from "./pick-up-config-util";

export const TrackableStatus = memo(function TrackableStatus({
  status,
  trackable,
  fulfillment,
  storeUrl,
  pickupConfig,
  asHeader,
  source,
}: {
  status: string;
  trackable: Trackable;
  fulfillment?: TrackableFulfillment;
  storeUrl: string;
  pickupConfig: PickupConfig;
  asHeader?: boolean;
  source: string;
}) {
  const customStatusMessages = getCustomStatusMessage({
    storeUrl,
    trackableType: trackable.type,
    fulfillment,
  });
  if ("some" in customStatusMessages) {
    return (
      <Text fontSize="xl" fontWeight="bold">
        {customStatusMessages.some}
      </Text>
    );
  }

  const { isPickUpOrder, readyForPickUp, hasBeenPickedUp } = pickupConfig;
  const isGreenReturn =
    trackable.type === TrackableType.RETURN &&
    trackable.returnProducts.every((product) => product.green_return);

  const hasBeenCancelled =
    trackable.originalOrder.shopify.cancelReason ?? undefined;

  if (hasBeenCancelled) {
    return (
      <Text fontSize="xl" fontWeight="bold">
        Your order has been cancelled, please contact customer support for
        inquiries
      </Text>
    );
  }

  let statusMessage = "";
  switch (status) {
    case "unknown": {
      if (fulfillment?.status === "success") {
        if (fulfillment?.shipment_status === "delivered") {
          statusMessage = "Delivered";
        } else if (isPickUpOrder && readyForPickUp) {
          statusMessage = "Ready for pickup";
        } else if (isPickUpOrder && hasBeenPickedUp) {
          statusMessage = "Picked up";
        } else if (source === "pos") {
          statusMessage = "Purchased in Store";
        } else {
          statusMessage = "Pre-transit";
        }
      } else {
        if (trackable.type === TrackableType.ORDER) {
          if (isPickUpOrder && readyForPickUp) {
            statusMessage = "Ready for pickup";
          } else if (isPickUpOrder && hasBeenPickedUp) {
            statusMessage = "Picked up";
          } else if (source === "pos") {
            statusMessage = "Purchased in Store";
          } else {
            statusMessage = "Your order is being prepared";
          }
        } else {
          // Returns statuses
          if (trackable.status === ReturnStatus.REJECTED) {
            statusMessage = "Return rejected";
          } else if (trackable.status === ReturnStatus.DELETED) {
            statusMessage = "Return deleted";
          } else if (trackable.status === ReturnStatus.NEEDS_REVIEW) {
            statusMessage = "Awaiting approval";
          } else if (isGreenReturn) {
            if (trackable.status === ReturnStatus.COMPLETE) {
              statusMessage = "Return complete";
            } else {
              statusMessage = "Return pending";
            }
          } else {
            statusMessage = "Awaiting return";
          }
        }
      }
      break;
    }
    default:
      statusMessage =
        (prettyTrackerStatus[status as keyof typeof prettyTrackerStatus] ||
          status) + (trackable.type === "return" ? " to merchant" : "");
  }
  return asHeader ? (
    <Header
      fontSize="sm"
      fontWeight="medium"
      mb="md"
      mt="md"
      textColor="secondary"
    >
      {statusMessage}
    </Header>
  ) : (
    <Text fontSize="xl" fontWeight="bold">
      {statusMessage}
    </Text>
  );
});

function getCustomStatusMessage({
  storeUrl,
  trackableType,
  fulfillment,
}: {
  storeUrl: string;
  trackableType: TrackableType;
  fulfillment?: TrackableFulfillment;
}): { some: string } | { none: null } {
  // Put custom status messages here. Define the storeUrl first, then the status message for order or return tracking
  const statusMapping: Record<
    string,
    Partial<Record<"order" | "return", () => string | undefined>>
  > = {
    "andar-wallets.myshopify.com": {
      order() {
        if (fulfillment && isAndarFulfillmentInStorePickup(fulfillment)) {
          return "Ready for Pickup";
        }
        return undefined;
      },
    },
    "waterfield-designs.myshopify.com": {
      order() {
        if (!fulfillment) {
          return "In sewing queue";
        }
        return undefined;
      },
    },
    "lindsey-leigh-jewelry.myshopify.com": {
      order() {
        if (!fulfillment) {
          return "Your order is being prepared";
        }
        return undefined;
      },
    },
  };
  const customStatusOrUndefined = statusMapping[storeUrl]?.[trackableType]?.();
  return customStatusOrUndefined
    ? { some: customStatusOrUndefined }
    : { none: null };
}

export function isAndarFulfillmentInStorePickup(
  fulfillment?: TrackableFulfillment,
): boolean {
  return (
    !!fulfillment &&
    fulfillment["status"] === "success" &&
    fulfillment["tracking_company"] === "Self-Pickup (by PDQ)"
  );
}
