// extracted by mini-css-extract-plugin
export var buttons = "_5y-0";
export var buttonsActive = "_5y-h";
export var cancelConfirm = "_5y-1";
export var closeButton = "_5y-2";
export var column = "_5y-3";
export var container = "_5y-4";
export var detailHeader = "_5y-6";
export var detailTitle = "_5y-7";
export var detailsContent = "_5y-5";
export var header = "_5y-8";
export var headerWithClose = "_5y-9";
export var icon = "_5y-a";
export var infoIcon = "_5y-c";
export var informationContainer = "_5y-b";
export var sideDrawer = "_5y-d";
export var sideDrawerOpen = "_5y-e";
export var stickyFooter = "_5y-f";
export var typeOption = "_5y-g";