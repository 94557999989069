// extracted by mini-css-extract-plugin
export var centered = "_2v-6";
export var flexible = "_2v-5";
export var footer = "_2v-k";
export var footerBorder = "_2v-l";
export var header = "_2v-c";
export var headerBorder = "_2v-d";
export var headerDefault = "_2v-g";
export var headerMedium = "_2v-h";
export var headerPadding = "_2v-e";
export var iconAndTitle = "_2v-f";
export var large = "_2v-4";
export var largePadding = "_2v-a";
export var medium = "_2v-3";
export var mediumPadding = "_2v-9";
export var modal = "_2v-0";
export var modalContainer = "_2v-b";
export var noPadding = "_2v-7";
export var skinny = "_2v-1";
export var small = "_2v-2";
export var smallPadding = "_2v-8";
export var subtitle = "_2v-j";
export var title = "_2v-i";