import { Currency, CurrencyMinorUnits } from "@redotech/money/currencies";
import { useHandler } from "@redotech/react-util/hook";
import { DEFAULT_CURRENCY } from "@redotech/redo-model/money";
import { ArrowLeft, ArrowRight, BackspaceKey } from "@redotech/web-util/key";
import * as classNames from "classnames";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { SpacingValue } from "../../theme/box";
import { TextSizeValue } from "../../theme/typography";
import { BaseRedoInputContainer } from "./base-redo-input-container";
import {
  BaseRedoInput,
  RedoInputSize,
  RedoInputState,
} from "./base-redo-text-input";
import * as redoCurrencyInputCss from "./redo-currency-input.module.css";

export type RedoCurrencyInputValue =
  | { amount: number; currency: Currency }
  | undefined;

export interface RedoCurrencyInputProps {
  value?: RedoCurrencyInputValue;
  setValue(value?: RedoCurrencyInputValue): void;
  size?: RedoInputSize;
  state?: RedoInputState;
  label?: string;
  description?: string;
  className?: string;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  required?: boolean;
  name?: string;
  placeholder?: number;
}

export const RedoCurrencyInput = memo(function RedoCurrencyInput({
  value,
  setValue,
  size = RedoInputSize.SMALL,
  state = RedoInputState.DEFAULT,
  label,
  description,
  className,
  onFocus,
  onBlur,
  required,
  name,
  placeholder = 0,
}: RedoCurrencyInputProps) {
  const ref = useRef<HTMLInputElement>(null);
  const [activeCurrency, _] = useState(DEFAULT_CURRENCY);

  const minorUnits = useMemo(() => {
    return CurrencyMinorUnits[activeCurrency] ?? 2;
  }, [activeCurrency]);

  const currencyFormat = useMemo(() => {
    return new Intl.NumberFormat(undefined, {
      style: "currency",
      currency: activeCurrency,
      minimumFractionDigits: minorUnits,
    });
  }, [activeCurrency, minorUnits]);

  const formattedValue = useMemo(() => {
    if (!value) {
      return undefined;
    }
    return currencyFormat.formatToParts(value.amount);
  }, [value, currencyFormat]);

  const currencySymbol = useMemo(() => {
    let valueToParse = formattedValue;
    if (!valueToParse) {
      valueToParse = currencyFormat.formatToParts(0);
    }
    return formattedCurrencySymbol(valueToParse);
  }, [currencyFormat, formattedValue]);

  const displayValue = useMemo(() => {
    if (!formattedValue) {
      return "";
    }
    return formattedCurrencyNoSymbol(formattedValue);
  }, [formattedValue]);

  const handleSetValue = useCallback(
    (value: string) => {
      const onlyNumbers = value.replace(/[^0-9]/g, "");
      if (onlyNumbers === "") {
        setValue(undefined);
        return;
      }
      const minorUnitsRatio = Math.pow(10, Math.max(0, minorUnits));
      const parsedValue = parseFloat(onlyNumbers) / minorUnitsRatio;
      setValue({ amount: parsedValue, currency: activeCurrency });
    },
    [activeCurrency, setValue, minorUnits],
  );

  const handleKeydown = useHandler(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === ArrowRight || e.key === ArrowLeft) {
        e.preventDefault();
      } else if (e.key === BackspaceKey) {
        if (value?.amount === 0) {
          setValue(undefined);
        }
      }
    },
  );

  const handleClick = useHandler((e: React.MouseEvent<HTMLInputElement>) => {
    ref.current?.setSelectionRange(
      ref.current?.value.length,
      ref.current?.value.length,
    );
  });

  return (
    <BaseRedoInputContainer
      className={classNames(className, redoCurrencyInputCss.currencyInput)}
      description={description}
      label={label}
      labelProps={{ htmlFor: "currency-input" }}
      size={size}
      state={state}
    >
      <Flex gap={sizeToSpacing[size]}>
        <Text
          className={redoCurrencyInputCss.currencySymbol}
          fontSize={sizeToTextSize[size]}
        >
          {currencySymbol}
        </Text>
        <BaseRedoInput
          htmlId="currency-input"
          name={name}
          onBlur={onBlur}
          onClick={handleClick}
          onFocus={onFocus}
          onKeyDown={handleKeydown}
          placeholder={formattedCurrencyNoSymbol(
            currencyFormat.formatToParts(placeholder),
          )}
          ref={ref}
          required={required}
          setValue={handleSetValue}
          size={size}
          state={state}
          value={displayValue}
        />
      </Flex>
    </BaseRedoInputContainer>
  );
});

function formattedCurrencyNoSymbol(formattedParts: Intl.NumberFormatPart[]) {
  return formattedParts
    .filter((part) => part.type !== "currency" && part.type !== "literal")
    .map((part) => part.value)
    .join("");
}

function formattedCurrencySymbol(formattedParts: Intl.NumberFormatPart[]) {
  return formattedParts.find((part) => part.type === "currency")?.value;
}

const sizeToTextSize: Record<RedoInputSize, TextSizeValue> = {
  [RedoInputSize.SMALL]: "xs",
  [RedoInputSize.MEDIUM]: "md",
  [RedoInputSize.LARGE]: "md",
};

const sizeToSpacing: Record<RedoInputSize, SpacingValue> = {
  [RedoInputSize.SMALL]: "xxs",
  [RedoInputSize.MEDIUM]: "md",
  [RedoInputSize.LARGE]: "md",
};
