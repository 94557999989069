import type { InferRpcDefinition } from "@redotech/rpc/definition";
import { createCheckoutExperience } from "./schema/create-checkout-experience";
import { createConversionSplit } from "./schema/create-conversion-split";
import { getCheckoutExperienceById } from "./schema/get-checkout-experience-by-id";
import { getCheckoutExperiences } from "./schema/get-checkout-experiences";
import { getConversionSplits } from "./schema/get-conversion-splits";
import { setCheckoutExperienceActive } from "./schema/set-checkout-experience-active";

export const rpcDefinition = {
  createConversionSplit,
  getConversionSplits,
  setCheckoutExperienceActive,
  createCheckoutExperience,
  getCheckoutExperiences,
  getCheckoutExperienceById,
};

export type RedoConversionRpcDefinition = InferRpcDefinition<
  typeof rpcDefinition
>;
