// extracted by mini-css-extract-plugin
export var animationContainer = "_ca-0";
export var footer = "_ca-1";
export var gray = "_ca-2";
export var lineItem = "_ca-3";
export var lineItemImage = "_ca-4";
export var lineItemInfo = "_ca-5";
export var lineItemText = "_ca-6";
export var lineItems = "_ca-7";
export var modalContent = "_ca-8";
export var options = "_ca-9";
export var orderName = "_ca-a";
export var refundAmount = "_ca-b";