// extracted by mini-css-extract-plugin
export var accent = "_2w-4";
export var add = "_2w-j";
export var addIcon = "_2w-k";
export var deleteIcon = "_2w-i";
export var disabled = "_2w-9";
export var ellipsis = "_2w-f";
export var error = "_2w-3";
export var form = "_2w-5";
export var hideFocus = "_2w-2";
export var icon = "_2w-7";
export var input = "_2w-e";
export var listItem = "_2w-h";
export var mediumHeight = "_2w-b";
export var noBorder = "_2w-8";
export var noRadius = "_2w-1";
export var shortHeight = "_2w-a";
export var tallHeight = "_2w-c";
export var textInput = "_2w-0";
export var vertical = "_2w-g";
export var veryTallHeight = "_2w-d";
export var widget = "_2w-6";