import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { LoadState, useLoad } from "@redotech/react-util/load";
import { RedoClient } from "@redotech/redo-api-client";
import { WidgetClient, createAuth } from "@redotech/redo-api-client/widget";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { trackerStatusColors } from "@redotech/redo-merchant-app-common/order";
import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import {
  shopifyAdminOrderUrl,
  shopifyStoreName,
} from "@redotech/redo-merchant-app-common/shopify";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import {
  ParcelForm,
  ParcelModal,
  getDimensionString,
  parcelInput,
} from "@redotech/redo-merchant-app-fulfillment/setting/parcels";
import { REDO_RETURNS_APP_URL_LOCAL } from "@redotech/redo-model/customer-portal";
import { Order as IOrder } from "@redotech/redo-model/order";
import { Parcel } from "@redotech/redo-model/outbound-labels/parcel";
import { WeightUnit } from "@redotech/redo-model/outbound-labels/util";
import { ReturnTypeEnum } from "@redotech/redo-model/return";
import { Team } from "@redotech/redo-model/team";
import { orderToTrackable } from "@redotech/redo-model/trackable";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
  RedoBadgeType,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import DotIcon from "@redotech/redo-web/arbiter-icon/dot-01.svg";
import { BreadcrumbSlot, useBreadcrumb } from "@redotech/redo-web/breadcrumb";
import { ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { ButtonDropdown } from "@redotech/redo-web/button-dropdown";
import { Card } from "@redotech/redo-web/card";
import { RedoClientContext } from "@redotech/redo-web/client";
import { getDateString } from "@redotech/redo-web/date-utils";
import { DropdownOption } from "@redotech/redo-web/dropdown";
import { Flex } from "@redotech/redo-web/flex";
import ChevronDownIcon from "@redotech/redo-web/icon-old/chevron-down.svg";
import { ExternalLink } from "@redotech/redo-web/link";
import { ActionPortalContext } from "@redotech/redo-web/page";
import { prettyTrackerStatus } from "@redotech/redo-web/settings-elements/order-tracking/delivery-estimation";
import { OrderOrFulfillments } from "@redotech/redo-web/settings-elements/order/order-details";
import { TotalsCard } from "@redotech/redo-web/settings-elements/order/order-totals";
import {
  FulfillmentOrder,
  pickUpConfig,
} from "@redotech/redo-web/settings-elements/order/pick-up-config-util";
import { ShippingInfoCard } from "@redotech/redo-web/settings-elements/order/shipping-info";
import { Text } from "@redotech/redo-web/text";
import { orderExceptRedo } from "@redotech/redo-web/utils/general-utils";
import {
  InputProvider,
  groupInput,
  input,
  nonEmptyValidator,
  numberValidator,
} from "@redotech/ui/form";
import { newWindow } from "@redotech/web-util/window";
import { memo, useContext, useState } from "react";
import { createPortal } from "react-dom";
import { useNavigate, useParams } from "react-router-dom";
import { getFulfillmentOrdersByOrder } from "../api";
import { getOrder } from "../client/order";
import { MerchantCreateReturnModal } from "../return/claim-return-shared";
import { YofiFraudDetails } from "../return/return-page-right-panel/customer-tabs/yofi-fraud-details";
import { ActivityTimeline } from "./activity-timeline";
import * as orderCss from "./order.module.css";
export const ParcelDimensions = memo(function ParcelDimensions({
  parcel,
}: {
  parcel: Parcel | null;
}) {
  if (!parcel) {
    return null;
  }
  if ("carrier" in parcel) {
    return <>{getDimensionString(parcel)}</>;
  }
  return (
    <>
      {parcel.length ?? ""} &times; {parcel.width ?? ""} &times;{" "}
      {parcel.height ?? ""}
      {parcel.lengthUnit ?? "in"}, {parcel.weight ?? ""}
      {parcel.weightUnit ?? "oz"}
    </>
  );
});

export const CustomPackageModal = memo(function CustomPackageModal({
  customPackageModalOpen,
  onClose,
  onSave,
  saveLoad,
  selectedParcel,
  addToSaved,
  setAddToSaved,
}: {
  customPackageModalOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  saveLoad: LoadState<void | undefined>;
  selectedParcel: ParcelForm;
  addToSaved: boolean;
  setAddToSaved: (add: boolean) => void;
}) {
  return (
    <ParcelModal
      addToSaved={addToSaved}
      input={selectedParcel}
      onSave={() => onSave()}
      open={customPackageModalOpen}
      saveLoad={saveLoad}
      setAddToSaved={setAddToSaved}
      setOpen={() => onClose()}
      showSingleUse
    />
  );
});

export const Order = memo(function Order({
  breadcrumb,
  hideActionPortal,
  order,
}: {
  breadcrumb: BreadcrumbSlot;
  hideActionPortal?: boolean;
  order?: IOrder | null;
}) {
  const params = useParams<{ orderId: string }>();
  const { orderId } = params;

  const merchantClient = useRequiredContext(RedoMerchantClientContext);
  const rpcClient = useRequiredContext(RedoMerchantRpcClientContext);
  const redoClient = useRequiredContext(RedoClientContext);
  const team = useContext(TeamContext);

  const orderLoad = useLoad(
    async (signal) => {
      if (!orderId || !team) {
        return null;
      }
      const [order] = await Promise.allSettled([
        getOrder(merchantClient, orderId, signal),
      ]);
      if (order.status === "rejected") {
        return null;
      }
      return order.value;
    },
    [orderId, team],
  );

  const emailTemplateMapLoad = useLoad(
    async (signal) => {
      if (!team || !rpcClient) {
        return undefined;
      }
      const [emailTemplateIdToNameMap] = await Promise.allSettled([
        rpcClient.getEmailTemplateNames({ teamId: team._id }, { signal }),
      ]);
      return emailTemplateIdToNameMap.status === "fulfilled"
        ? emailTemplateIdToNameMap.value?.emailTemplateIdToNameMap
        : undefined;
    },
    [team?._id, rpcClient],
  );

  const orderWithRedo = order ?? orderLoad.value;
  const orderWithoutRedo = orderWithRedo
    ? orderExceptRedo(orderWithRedo)
    : null;
  const actionsPortal = useContext(ActionPortalContext);

  const fulfillmentOrders = useLoad<FulfillmentOrder[]>(async () => {
    if (team?.widget_slug && orderWithRedo?.shopify?.id) {
      return (
        await getFulfillmentOrdersByOrder(
          team?.widget_slug,
          orderWithRedo?.shopify?.id?.toString(),
        )
      ).data;
    }
    return [];
  }, [team?.widget_slug, orderWithRedo?.shopify?.id]);

  const pickupConfig = pickUpConfig(fulfillmentOrders.value ?? []);

  useBreadcrumb(breadcrumb, `${orderWithRedo?.shopify?.name ?? ""}`);

  const openCustomerTrackingPageCallbackGenerator = useHandler(
    (fulfillmentIndex: number | null) =>
      openCustomerPortalTrackingPage.bind({
        team,
        redoClient,
        order: orderWithRedo ?? undefined,
        fulfillmentIndex,
      }),
  );

  if (!orderWithRedo || !orderWithoutRedo || !team) {
    return <div>Loading...</div>;
  }

  const hasFulfilledItems = orderWithoutRedo.lineItemsFulfillment?.some(
    (item) => item.fulfillment_status !== null,
  );
  const hasUnfulfilledItems = orderWithoutRedo.lineItemsFulfillment?.some(
    (item) => item.fulfillment_status === null,
  );
  const fulfillmentStatus =
    hasFulfilledItems && hasUnfulfilledItems
      ? "Partial"
      : hasFulfilledItems
        ? "Fulfilled"
        : "Unfulfilled";
  const fulfillmentColor =
    fulfillmentStatus === "Fulfilled"
      ? RedoBadgeColor.SUCCESS
      : fulfillmentStatus === "Partial"
        ? RedoBadgeColor.WARNING
        : RedoBadgeColor.ERROR;

  const statuses = [];
  for (const tracker of orderWithoutRedo.trackers) {
    statuses.push(
      <dd>
        <RedoBadge
          color={trackerStatusColors[tracker._tracker.status]}
          segmentLeading={{ type: "icon", Icon: DotIcon }}
          size={RedoBadgeSize.SMALL}
          text={prettyTrackerStatus[tracker._tracker.status]}
          type={RedoBadgeType.PRIMARY}
        />
      </dd>,
    );
  }

  return (
    <>
      {actionsPortal &&
        !hideActionPortal &&
        createPortal(
          <ActionsMenu order={orderWithRedo} team={team} />,
          actionsPortal,
        )}
      <Flex className={orderCss.container} dir="column">
        <Flex
          as="dl"
          className={orderCss.descriptionDetails}
          textColor="secondary"
          wrap="wrap"
        >
          <div>
            <dt>Order date</dt>
            <dd>
              {getDateString(new Date(orderWithRedo.shopify?.created_at))}
            </dd>
          </div>
          <div>
            <dt>Total # of items</dt>
            <dd>
              {orderWithoutRedo?.shopify?.line_items?.reduce(
                (acc, item) => acc + item.quantity,
                0,
              ) ?? 0}
            </dd>
          </div>
          <div>
            <dt>Fulfillment status</dt>
            <Flex>
              <dd>
                <RedoBadge
                  color={fulfillmentColor}
                  segmentLeading={{ type: "icon", Icon: DotIcon }}
                  size={RedoBadgeSize.SMALL}
                  text={fulfillmentStatus}
                  type={RedoBadgeType.PRIMARY}
                />
              </dd>
            </Flex>
          </div>
          <div>
            <dt>Delivery status</dt>
            <Flex>{statuses}</Flex>
          </div>
        </Flex>
        <Flex className={orderCss.main} dir="row">
          <Flex dir="column" grow={1}>
            <OrderOrFulfillments
              handleTrackingPageButtonClickGenerator={
                openCustomerTrackingPageCallbackGenerator
              }
              largeTitle
              merchantApp
              order={orderWithoutRedo}
              pickupConfig={pickupConfig}
              showTracking={false}
              storeUrl={team.storeUrl}
            />
            <ActivityTimeline
              emailTemplateIdToNameMap={emailTemplateMapLoad.value}
              trackable={orderToTrackable(orderWithoutRedo)}
            />
          </Flex>
          <Flex dir="column" grow={0.15}>
            <TotalsCard order={orderWithRedo} showHeader />
            <ShippingInfoCard order={orderWithoutRedo} />
            <Card>
              <Text fontSize="md" fontWeight="semibold">
                Additional information
              </Text>
              <YofiFraudDetails order={orderWithRedo} />
            </Card>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
});

function openCustomerPortalTrackingPage(
  this: {
    team?: {
      widget_slug: string;
      portal: { domain?: string; pathPrefix: string };
      storeUrl: string;
    };
    redoClient: RedoClient;
    order?: IOrder;
    fulfillmentIndex: number | null;
  },
  event: React.MouseEvent,
) {
  event.stopPropagation();
  const { team, order, redoClient } = this;
  if (!team || !order) {
    return;
  }
  const widgetClient = new WidgetClient(redoClient, team.widget_slug);
  newWindow(async () => {
    const auth = await createAuth(widgetClient, {
      orderNumber: order.shopify.name,
      email: order.shopify.email,
      type: "order",
    });

    const domain = team.portal.domain || team.storeUrl;
    const path = team.portal.pathPrefix || "/apps/redo";

    const url =
      process.env.NODE_ENV === "production"
        ? new URL(`https://${domain}${path}/returns-portal`)
        : new URL(
            `${REDO_RETURNS_APP_URL_LOCAL}/widget_id/${team.widget_slug}/returns-portal`,
          );

    url.searchParams.set("customer", JSON.stringify(auth.customer));
    url.searchParams.set("token", auth.token);
    url.searchParams.set(
      "next",
      `/returns-portal/orders/${order._id}/track${this.fulfillmentIndex === null ? "" : `/${this.fulfillmentIndex}`}`,
    );
    return String(url);
  });
}

const ActionsMenu = memo(function ActionsMenu({
  team,
  order,
}: {
  team: { _id: string; storeUrl: string; settings: Team["settings"] };
  order: IOrder;
}) {
  const navigate = useNavigate();
  const options: { label: string; action: () => void }[] = [];

  const [createReturnType, setCreateReturnType] =
    useState<ReturnTypeEnum | null>(null);
  const [returnCreateOpen, setReturnCreateOpen] = useState(false);

  const closeReturnCreate = () => {
    setCreateReturnType(null);
    setReturnCreateOpen(false);
  };

  if (
    team.settings?.returns?.enabled &&
    team.settings?.returns?.enableCustomerPortal
  ) {
    options.push({
      label: "Create return",
      action: () => {
        navigate(`/stores/${team._id}/returns/create/${order._id}`);
      },
    });
  }
  if (team.settings?.packageProtection?.enabled) {
    options.push({
      label: "Create claim",
      action: () => {
        setCreateReturnType(ReturnTypeEnum.CLAIM);
        setReturnCreateOpen(true);
      },
    });
  }
  if (team.settings?.warranties?.enabled) {
    options.push({
      label: "Create warranty claim",
      action: () => {
        setCreateReturnType(ReturnTypeEnum.WARRANTY);
        setReturnCreateOpen(true);
      },
    });
  }

  return (
    <>
      <Flex dir="row">
        {order.provider === "shopify" && (
          <ExternalLink
            url={shopifyAdminOrderUrl(
              shopifyStoreName(team.storeUrl),
              order.shopify_id,
            ).toString()}
          >
            Go to Shopify Order
          </ExternalLink>
        )}
        <ButtonDropdown
          disabled={options.length === 0}
          dropdown={
            <>
              {options.map((option, index) => (
                <DropdownOption action={option.action} key={index}>
                  {option.label}
                </DropdownOption>
              ))}
            </>
          }
          restrictDropdownSizeToParent={false}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          <div className={orderCss.buttonContent}>
            Actions
            <ChevronDownIcon className={orderCss.buttonIcon} />
          </div>
        </ButtonDropdown>
      </Flex>
      {returnCreateOpen && createReturnType ? (
        <MerchantCreateReturnModal
          open={returnCreateOpen}
          relocationInfo={{ preselectedOrder: order }}
          returnType={createReturnType}
          setOpen={closeReturnCreate}
        />
      ) : null}
    </>
  );
});

export const labelInput = groupInput({
  totalWeight: input<string>({ validator: numberValidator({ min: 0 }) }),
  weightUnit: input<WeightUnit>({ validator: nonEmptyValidator }),
  selectedParcel: parcelInput,
});

export type LabelValue = InputProvider.Value<typeof labelInput>;

export type LabelForm = InputProvider.Form<typeof labelInput>;
