import {
  RedoDiscount,
  RedoDiscountConfiguration,
} from "@redotech/merchant-sdk/marketing-rpc/schema/discounts/discount-schemas";
import { DiscountGroupType } from "@redotech/redo-model/discount";
import { EmailFormat, ScreenSize } from "@redotech/redo-model/email-builder";
import { EmailTemplate } from "@redotech/redo-model/email-template";
import { EmailBuilderMetafield } from "@redotech/redo-model/metafield";
import {
  ProductInfo,
  ShopifyGraphQLProduct,
} from "@redotech/redo-model/product";
import { Team } from "@redotech/redo-model/team";
import { createContext } from "react";

export interface EmailBuilderContext {
  team: Team;
  template: EmailTemplate;
  setTemplate: (template: EmailTemplate) => void;
  getCollections: () => Promise<{ id: string; name: string }[]>;
  getMetafields: () => Promise<EmailBuilderMetafield[]>;
  getProduct: (productId: string) => Promise<ShopifyGraphQLProduct | undefined>;
  searchProducts: ({
    search,
    signal,
  }: {
    search: string;
    signal: AbortSignal;
  }) => Promise<ProductInfo[] | undefined>;
  sendTestEmail: (
    email: string,
    template: EmailTemplate,
    orderNumber?: string,
  ) => Promise<boolean>;
  getDiscounts: (groupType?: DiscountGroupType) => Promise<RedoDiscount[]>;
  getDiscount: (id: string) => Promise<RedoDiscount | undefined>;
  createDiscount: ({
    discountConfiguration,
  }: {
    discountConfiguration: RedoDiscountConfiguration;
  }) => Promise<string>;
  updateDiscount: ({
    discountId,
    discountConfiguration,
  }: {
    discountId: string;
    discountConfiguration: RedoDiscountConfiguration;
  }) => Promise<void>;
  deleteDiscount: (id: string) => Promise<void>;
}

export const EmailBuilderContext = createContext<
  EmailBuilderContext | undefined
>(undefined);

type EmailRenderContextProps = { screenSize: ScreenSize } & (
  | { format: Exclude<EmailFormat, EmailFormat.STATIC> }
  | { format: EmailFormat.STATIC; forceFallback: boolean }
);

export const EmailRenderContext = createContext<
  EmailRenderContextProps | undefined
>(undefined);
