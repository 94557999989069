export interface PickupConfig {
  isPickUpOrder: boolean;
  readyForPickUp: boolean;
  hasBeenPickedUp: boolean;
}

export interface FulfillmentOrder {
  status:
    | "open"
    | "in-progress"
    | "in_progress"
    | "scheduled"
    | "cancelled"
    | "on-hold"
    | "incomplete"
    | "closed";
  delivery_method: {
    method_type: "local" | "none" | "pick-up" | "retail" | "shipping";
  };
}

export const pickUpConfig = (
  fulfillmentOrders: FulfillmentOrder[],
): PickupConfig => {
  const isPickUpOrder: boolean = fulfillmentOrders.some(
    (fulfillmentOrder) =>
      fulfillmentOrder.delivery_method?.method_type === "pick-up",
  );

  const readyForPickUp: boolean = fulfillmentOrders.some(
    (fulfillmentOrder) =>
      fulfillmentOrder.status === "in_progress" &&
      fulfillmentOrder.delivery_method?.method_type === "pick-up",
  );

  const hasBeenPickedUp: boolean = fulfillmentOrders.some(
    (fulfillmentOrder) =>
      fulfillmentOrder.status === "closed" &&
      fulfillmentOrder.delivery_method?.method_type === "pick-up",
  );

  return { isPickUpOrder, readyForPickUp, hasBeenPickedUp };
};
