import { zExt } from "@redotech/rpc/ext";
import { isDef } from "@redotech/util/checks";
import { z } from "zod";
import { DeliveryZone } from "../shopify/delivery-profile";
import {
  ShippingRateLocation,
  ShippingRateTableArraySchema,
} from "./shipping-rate-table";

export const DELIVERY_DATE_VARIABLE = "{delivery_date}";

export enum DeliveryFilterType {
  // Filter out all redo options except the cheapest
  ONLY_CHEAPEST_REDO = "only-cheapest-redo",
  // Filter out all non-redo options
  ONLY_REDO = "only-redo",
  // Filter out all redo options (only useful for tests)
  NO_REDO = "no-redo",
  // Filter out all non-redo options except the cheapest one
  ONLY_CHEAPEST_NON_REDO = "only-cheapest-non-redo",
}

export enum DeliverySortType {
  // Cheapest Redo option, then sort all others by price
  REDO_CHEAPEST_FIRST = "redo-cheapest-first",
  // Show all Redo options before any other shipping options
  REDO_FIRST = "redo-first",
  // Sort all shipping options by price, cheapest first
  // Is essentially "toggle off"
  CHEAPEST_FIRST = "cheapest-first",
}

export enum CoverageProductEnum {
  SHIPPING = "Shipping",
  CHECKOUT = "Checkout",
}

export const maxValue = 1000000000;

const DeliveryFilterTypeEnum = z.nativeEnum(DeliveryFilterType);

const DeliverySortTypeEnum = z.nativeEnum(DeliverySortType);

const FilterSort = z.object({
  filterType: DeliveryFilterTypeEnum.optional(),
  sortType: DeliverySortTypeEnum.optional(),
});

export const CheckoutExperienceZodSchema = z.object({
  _id: zExt.objectId(),
  name: z.string(),
  rateTables: ShippingRateTableArraySchema,
});
export type CheckoutExperience = z.infer<typeof CheckoutExperienceZodSchema>;

export const CheckoutExperienceZodArraySchema = z.array(
  CheckoutExperienceZodSchema,
);

export const ShippingCoverageZodSchema = z.object({
  coverage: z.nativeEnum(CoverageProductEnum).nullish(), //I don't think coverage product should live here, but I need this out quickly
  enabled: z.boolean(),
  checkoutOutboundRatesEnabled: z.boolean().optional(),
  requestShippingDeliveryPermissions: z.boolean().optional(),
  checkoutExperiences: CheckoutExperienceZodArraySchema.optional(),
  rateTables: ShippingRateTableArraySchema,
  deliverySortType: DeliverySortTypeEnum.optional(),
  deliveryFilterType: DeliveryFilterTypeEnum.optional(),
  optIn: FilterSort.optional(),
  optOut: FilterSort.optional(),
});

export type ShippingCoverage = z.infer<typeof ShippingCoverageZodSchema>;

export function deliveryZoneToShippingRateLocations(
  deliveryZone: DeliveryZone,
): ShippingRateLocation[] {
  return deliveryZone.countries.map((country) => ({
    country: country.code.countryCode ?? country.name,
    provinces: country.provinces.map((province) => province.code),
  }));
}

export function deliveryZoneToOriginLocations(
  deliveryZone: DeliveryZone,
): ShippingRateLocation[] {
  return deliveryZone.countries.map((country) => ({
    country: country.code.countryCode ?? country.name,
    provinces: country.provinces.map((province) => province.code),
  }));
}

export function safeParseCheckoutExperienceArray(
  checkoutExperiences: unknown[],
): CheckoutExperience[] {
  return checkoutExperiences
    .map((checkoutExperience) => {
      const result = CheckoutExperienceZodSchema.safeParse(checkoutExperience);
      if (!result.success) {
        return undefined;
      }
      return result.data;
    })
    .filter(isDef);
}
