// extracted by mini-css-extract-plugin
export var actionButton = "_cl-1";
export var actions = "_cl-0";
export var copyableField = "_cl-2";
export var detailColumn = "_cl-3";
export var detailRow = "_cl-4";
export var dropdownTitle = "_cl-5";
export var fulfillmentDetails = "_cl-7";
export var fulfillments = "_cl-6";
export var icon = "_cl-8";
export var imgContainer = "_cl-9";
export var lineItems = "_cl-a";
export var link = "_cl-p";
export var orderCard = "_cl-k";
export var orderCollapseButton = "_cl-l";
export var orderCollapseButtonIcon = "_cl-m";
export var orderDetails = "_cl-b";
export var orderPillsContainer = "_cl-c";
export var pillWrapper = "_cl-d";
export var product = "_cl-e";
export var productDetails = "_cl-g";
export var productTopDetails = "_cl-h";
export var products = "_cl-f";
export var strong = "_cl-i";
export var tightText = "_cl-o";
export var tooltipHidden = "_cl-q";
export var variantQuantityRow = "_cl-j";
export var wrappingText = "_cl-n";