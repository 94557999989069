import { ProductInfo } from "@redotech/redo-model/customer-event/customer-event-definition";
import { Order } from "@redotech/redo-model/order";
import { ProductInfo as ShopifyProductInfo } from "@redotech/shopify-storefront";
import { CURRENCY_FORMAT } from "../currency";
import { getDateString } from "../date-utils";

// Removes all redo line items and fulfillments from an order
export const orderExceptRedo = <T extends Order>(order: T): T => {
  if (!order?.shopify) {
    return order;
  }
  return {
    ...order,
    shopify: {
      ...order.shopify,
      line_items: order.shopify.line_items.filter(
        (item: any) => item.vendor !== "re:do",
      ),
      fulfillments: order.shopify.fulfillments.filter(
        (fulfillment: any) =>
          !fulfillment.line_items.every((item: any) => item.vendor === "re:do"),
      ),
    },
  };
};

export const transformShopifyGraphQLProductInfoToRedoProductInfo = (
  products: ShopifyProductInfo[],
): ProductInfo[] => {
  return products.map((product: ShopifyProductInfo) => {
    const productInfo: ProductInfo = {
      productId: product.id,
      productTitle: product.title,
      price: {
        amount: Number(product.priceRange.minVariantPrice.amount),
        currencyCode: product.priceRange.minVariantPrice.currencyCode,
      },
      priceRange: product.priceRange,
      imageUrl: product.featuredImage?.url || product.images.nodes[0]?.url,
      handle: product.handle,
      url: product.onlineStoreUrl,
    };
    return productInfo;
  });
};

export const getProductUrlFromProductHandle = (
  storeUrl: string,
  handle: string | undefined,
) => {
  const formattedStoreUrl = storeUrl.endsWith("/") ? storeUrl : `${storeUrl}/`;
  return handle
    ? `https://${formattedStoreUrl}products/${handle}`
    : `https://${formattedStoreUrl}products`;
};

export const getProductUrl = (
  product: ProductInfo,
  storeUrl: string,
): string => {
  return product.url
    ? product.url
    : getProductUrlFromProductHandle(storeUrl, product.handle);
};

export const addProductUrlToProductInfo = (
  product: ProductInfo,
  storeUrl: string,
): ProductInfo => {
  const productUrl = getProductUrl(product, storeUrl);

  return { ...product, url: productUrl };
};

export const getOrderDate = (order: Order) => {
  const dateCreated = new Date(order.shopifyCreatedAt || order.createdAt);
  return getDateString(dateCreated);
};

export const formatProductInfoPriceRange = (product: ProductInfo) => {
  if (product.priceRange) {
    const minPrice = product.priceRange.minVariantPrice.amount;
    const maxPrice = product.priceRange.maxVariantPrice.amount;

    const formattedMin = CURRENCY_FORMAT(
      product.priceRange.minVariantPrice.currencyCode,
    ).format(minPrice);
    const formattedMax = CURRENCY_FORMAT(
      product.priceRange.maxVariantPrice.currencyCode,
    ).format(maxPrice);

    if (minPrice === maxPrice) {
      return formattedMin;
    }

    return `${formattedMin} - ${formattedMax}`;
  } else {
    return CURRENCY_FORMAT(product.price.currencyCode).format(
      product.price.amount,
    );
  }
};
