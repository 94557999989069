import { memo, ReactNode } from "react";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
  RedoButtonTheme,
} from "../arbiter-components/buttons/redo-button";
import { Flex } from "../flex";
import { InputSize } from "../input";
import { Actions } from "../page";
import { Text } from "../text";
import { TextInput } from "../text-input";

export interface FormBuilderHeaderButton {
  text: string;
  onClick(): void | Promise<void>;
  hierarchy: RedoButtonHierarchy;
  theme?: RedoButtonTheme;
  disabled?: boolean;
  pending?: boolean;
  size?: RedoButtonSize;
}

export const FormBuilderHeader = memo(function FormBuilderHeader({
  title,
  onTitleChange,
  description,
  descriptionRender,
  buttons,
  additionalContent,
}: {
  title: string;
  onTitleChange?: (title: string) => void;
  description?: string;
  descriptionRender?: ReactNode;
  buttons: FormBuilderHeaderButton[];
  additionalContent?: ReactNode;
}) {
  return (
    <Flex
      bgColor="primary"
      borderBottomWidth="1px"
      borderColor="primary"
      borderStyle="solid"
      dir="column"
    >
      <Flex
        gap="2xl"
        justify="space-between"
        pl="5xl"
        pr="3xl"
        py="3xl"
        w="full"
      >
        <Flex dir="column" gap="md">
          <Text fontSize="lg" fontWeight="semibold">
            {onTitleChange ? (
              <TextInput
                color="primary"
                ellipsis
                fullwidth
                hideFocus
                onChange={onTitleChange}
                readonly={false}
                size={InputSize.LARGE}
                value={title}
              />
            ) : (
              title
            )}
          </Text>
          {description && <Text>{description}</Text>}
          {descriptionRender && descriptionRender}
        </Flex>
        <Flex align="center">
          <Actions noBorder show>
            <Flex>
              {buttons.map((button, idx) => {
                return (
                  <RedoButton
                    {...button}
                    key={idx}
                    size={button.size ?? RedoButtonSize.LARGE}
                    theme={button.theme}
                  />
                );
              })}
            </Flex>
          </Actions>
        </Flex>
      </Flex>
      {additionalContent}
    </Flex>
  );
});
