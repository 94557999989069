export const BackspaceKey = "Backspace";
export const TabKey = "Tab";
export const ShiftKey = "Shift";
export const ControlKey = "Control";
export const AltKey = "Alt";
export const MetaKey = "Meta";
export const EnterKey = "Enter";

export const ArrowRight = "ArrowRight";
export const ArrowLeft = "ArrowLeft";
export const ArrowUp = "ArrowUp";
export const ArrowDown = "ArrowDown";
