import { ClickAwayListener } from "@mui/base";
import { MessageVisibility } from "@redotech/redo-model/conversation";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import ChevronDownSvg from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import CornerUpLeftSvg from "@redotech/redo-web/arbiter-icon/corner-up-left_filled.svg";
import { Dropdown, DropdownOption } from "@redotech/redo-web/dropdown";
import { Flex } from "@redotech/redo-web/flex";
import * as classNames from "classnames";
import { memo, useContext, useState } from "react";
import * as messageInputCss from "./message-input.module.css";

import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { Permission, permitted } from "@redotech/redo-model/user";
import ChatBubbleIcon from "@redotech/redo-web/icon-old/chat-bubble.svg";
import CheckIcon from "@redotech/redo-web/icon-old/check.svg";
import NoteIcon from "@redotech/redo-web/icon-old/note.svg";

export const MessageInputHeader = memo(function MessageInputHeader({
  visibility,
  setVisibility,
  writingInternalNoteOnEmailOrVoicePlatform,
}: {
  visibility: MessageVisibility;
  setVisibility: (visibility: MessageVisibility) => void;
  writingInternalNoteOnEmailOrVoicePlatform: boolean;
}) {
  const [pickingReplyType, setPickingReplyType] = useState(false);

  const [pickReplyButtonRef, setPickReplyButtonRef] =
    useState<HTMLButtonElement | null>(null);

  const handlePickReplyType = (mode: MessageVisibility) => {
    setVisibility(mode);
    setPickingReplyType(false);
  };

  const user = useContext(UserContext);

  const canReply =
    !!user && permitted(user.permissions, Permission.CREATE_REPLY);

  return (
    <Flex
      align="center"
      className={classNames(messageInputCss.wrappingText)}
      pt="md"
      px="md"
    >
      {!writingInternalNoteOnEmailOrVoicePlatform ? (
        <ClickAwayListener onClickAway={() => setPickingReplyType(false)}>
          <div>
            <RedoButton
              hierarchy={RedoButtonHierarchy.TERTIARY}
              IconLeading={CornerUpLeftSvg}
              IconTrailing={ChevronDownSvg}
              onClick={(e) => setPickingReplyType((v) => !v)}
              ref={setPickReplyButtonRef}
              size={RedoButtonSize.REGULAR}
              text={
                visibility === MessageVisibility.PUBLIC
                  ? "Reply"
                  : "Internal note"
              }
            />
            <Dropdown
              anchor={pickReplyButtonRef}
              fitToAnchor={false}
              open={pickingReplyType}
            >
              {canReply && (
                <DropdownOption
                  action={() => handlePickReplyType(MessageVisibility.PUBLIC)}
                >
                  <div className={messageInputCss.actionButtonContainer}>
                    <div
                      className={classNames(
                        messageInputCss.actionButton,
                        visibility === MessageVisibility.PUBLIC &&
                          messageInputCss.bold,
                      )}
                    >
                      <ChatBubbleIcon className={messageInputCss.icon} />
                      Reply
                    </div>
                    {visibility === MessageVisibility.PUBLIC && (
                      <CheckIcon className={messageInputCss.checkIcon} />
                    )}
                  </div>
                </DropdownOption>
              )}
              <DropdownOption
                action={() => handlePickReplyType(MessageVisibility.INTERNAL)}
              >
                <div className={messageInputCss.actionButtonContainer}>
                  <div
                    className={classNames(
                      messageInputCss.actionButton,
                      visibility === MessageVisibility.INTERNAL &&
                        messageInputCss.bold,
                    )}
                  >
                    <NoteIcon className={messageInputCss.icon} />
                    Internal note
                  </div>
                  {visibility === MessageVisibility.INTERNAL && (
                    <CheckIcon className={messageInputCss.checkIcon} />
                  )}
                </div>
              </DropdownOption>
            </Dropdown>
          </div>
        </ClickAwayListener>
      ) : (
        <Flex
          align="center"
          className={messageInputCss.wrappingText}
          gap="2xl"
          justify="space-between"
        >
          {/* For spacing the button correctly like non-email platforms which use "space-between" */}
          <Flex> </Flex>

          <RedoButton
            hierarchy={RedoButtonHierarchy.TERTIARY}
            onClick={() => setVisibility(MessageVisibility.PUBLIC)}
            size={RedoButtonSize.REGULAR}
            text="Close note"
          />
        </Flex>
      )}
    </Flex>
  );
});
