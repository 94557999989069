import { Currency } from "@redotech/money/currencies";
import {
  Cart,
  CartLine,
  PixelEventsCheckoutCompleted,
  PixelEventsCheckoutStarted,
  PixelEventsCollectionViewed,
  ProductVariant,
  Customer as ShopifyCustomer,
} from "@shopify/web-pixels-extension/build/ts/types/PixelEvents";
import { z } from "zod";
import { dateOrStringAsDate, zExt } from "../common/zod-util";
import { ConversationStatus } from "../conversation";
import { MarketingChannel } from "../marketing";
import { SesEventSchema } from "../order";
import { returnStatusJsonFormat } from "../return-new";
import { CompleteOrderFulfillmentStatus } from "../shopify-order";
import { signupFormSchema } from "../signup-form-schema";
import {
  CUSTOMER_EVENT_DISCRIMINATOR_KEY,
  CustomerEventType,
  ExternalCustomerPlatform,
  MarketingConfirmSource,
} from "./customer-event-definition";

const _CustomerEventSchema = z.object({
  _id: zExt.objectId().transform((id) => id.toString()),
  team: zExt.objectId().transform((id) => id.toString()),
  email: z.string().optional(),
  customer: zExt
    .objectId()
    .optional()
    .transform((id) => id?.toString()),
  timestamp: dateOrStringAsDate,
  eventType: z.nativeEnum(CustomerEventType),
});

const OrderLineItemSchema = z.object({
  productId: z.string(),
  variantId: z.string(),
});

const shopifyAddressSchema = z.object({
  country: z.string().optional(),
  country_code: z.string().optional(),
  province_code: z.string().optional(),
  province: z.string().optional(),
  address1: z.string().optional(),
  address2: z.string().optional(),
  city: z.string().optional(),
  zip: z.string().optional(),
});

const OrderCreatedCustomerEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.ORDER_CREATED),
  orderId: zExt.objectId(),
  shopifyId: z.string(),
  itemCount: z.number(),
  totalPrice: z.string(),
  orderName: z.string(),
  currency: z.nativeEnum(Currency).optional(),
  lineItems: z.array(OrderLineItemSchema).optional(),
  billingAddress: shopifyAddressSchema.optional(),
  usedRedoCoverage: z.boolean().optional(),
});

const OrderUpdatedCustomerEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.ORDER_UPDATED),
  orderId: zExt.objectId(),
  shopifyId: z.string(),
});

const OrderStatusUpdatedCustomerEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.ORDER_STATUS_UPDATED),
  orderId: zExt.objectId(),
  shopifyId: z.string(),
  newStatus: CompleteOrderFulfillmentStatus.zodParser,
});

const ReturnCreatedCustomerEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.RETURN_CREATED),
  returnId: zExt.objectId(),
});

const ReturnStatusUpdatedCustomerEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.RETURN_STATUS_UPDATED),
  returnId: zExt.objectId(),
  newStatus: z
    .string()
    .nullable()
    .transform((str) => {
      return returnStatusJsonFormat.read(str);
    }),
});

const ReturnCommentCreatedCustomerEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.RETURN_COMMENT_CREATED),
  returnId: zExt.objectId(),
  messageId: zExt.objectId(),
});

const ReturnProcessedCustomerEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.RETURN_PROCESSED),
  returnId: zExt.objectId(),
});

const ClaimCreatedCustomerEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.CLAIM_CREATED),
  claimId: zExt.objectId(),
});

const ClaimStatusUpdatedCustomerEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.CLAIM_STATUS_UPDATED),
  claimId: zExt.objectId(),
  newStatus: z
    .string()
    .nullable()
    .transform((str) => {
      return returnStatusJsonFormat.read(str);
    }),
});

const ClaimCommentCreatedCustomerEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.CLAIM_COMMENT_CREATED),
  claimId: zExt.objectId(),
  messageId: zExt.objectId(),
});

const ConversationCreatedCustomerEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.CONVERSATION_CREATED),
  conversationId: zExt.objectId(),
});

const ConversationStatusUpdatedCustomerEventSchema =
  _CustomerEventSchema.extend({
    eventType: z.literal(CustomerEventType.CONVERSATION_STATUS_UPDATED),
    conversationId: zExt.objectId(),
    newStatus: z.nativeEnum(ConversationStatus),
  });

const ConversationMessageCreatedCustomerEventSchema =
  _CustomerEventSchema.extend({
    eventType: z.literal(CustomerEventType.CONVERSATION_MESSAGE_CREATED),
    conversationId: zExt.objectId(),
    messageId: zExt.objectId(),
  });

const ExternalPlatformCustomerCreatedCustomerEventSchema =
  _CustomerEventSchema.extend({
    eventType: z.literal(CustomerEventType.EXTERNAL_PLATFORM_CUSTOMER_CREATED),
    externalPlatformCustomerId: z.string(),
    externalPlatform: z.nativeEnum(ExternalCustomerPlatform),
  });

const StorefrontLoginCustomerEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.STOREFRONT_LOGIN),
  multipassLogin: z.boolean(),
});

const MarketingSubscribeEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.MARKETING_SUBSCRIBED),
  email: z.string().optional(),
  phoneNumber: z.string().optional(),
  entryPoint: z.string(),
  formId: z
    .string()
    .or(zExt.objectId())
    .optional()
    .transform((id) => id?.toString()),
  formSnapshot: z.string().or(signupFormSchema),
  customerIpAddress: z.string().optional(),
});

const MarketingUnsubscribeEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.MARKETING_UNSUBSCRIBED),
  subscriptionType: z.nativeEnum(MarketingChannel),
});

const MarketingConfirmEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.MARKETING_CONFIRMED),
  subscriptionType: z.nativeEnum(MarketingChannel),
  source: z.nativeEnum(MarketingConfirmSource).optional(),
});

type PartialShopifyCustomer = Partial<ShopifyCustomer> | null;
type PartialCart = Partial<Cart> | null;

const _ShoppingEventSchema = _CustomerEventSchema.extend({
  externalEventId: z.string(),
  externalCustomer: z
    .any()
    .optional()
    .transform((val: any) => val as PartialShopifyCustomer),
});

const ProductViewedShoppingEventSchema = _ShoppingEventSchema.extend({
  eventType: z.literal(CustomerEventType.PRODUCT_VIEWED),
  // TECH-DEBT -- we should really parse these fields using parsers instead
  // of using `z.any()` followed by casting. Feel free to clean up.
  productVariant: z.any().transform((val: any) => val as ProductVariant),
  cart: z.any().transform((val: any) => val as PartialCart),
});

const ProductAddedToCartShoppingEventSchema = _ShoppingEventSchema.extend({
  eventType: z.literal(CustomerEventType.PRODUCT_ADDED_TO_CART),
  // TECH-DEBT -- we should really parse these fields using parsers instead
  // of using `z.any()` followed by casting. Feel free to clean up.
  cartLine: z.any().transform((val: any) => val as CartLine | null),
  cart: z
    .any()
    .optional()
    .transform((val: any) => val as PartialCart | null | undefined),
});

const ProductRemovedFromCartShoppingEventSchema = _ShoppingEventSchema.extend({
  eventType: z.literal(CustomerEventType.PRODUCT_REMOVED_FROM_CART),
  cartLine: z.any().transform((val: any) => val as CartLine | null),
  cart: z.any().transform((val: any) => val as PartialCart | null),
});

const CheckoutContactInfoSubmittedShoppingEventSchema =
  _ShoppingEventSchema.extend({
    eventType: z.literal(CustomerEventType.CHECKOUT_CONTACT_INFO_SUBMITTED),
    checkout: z
      .any()
      .transform(
        (val: any) => val as PixelEventsCheckoutStarted["data"]["checkout"],
      ),
  });

const CheckoutStartedShoppingEventSchema = _ShoppingEventSchema.extend({
  eventType: z.literal(CustomerEventType.CHECKOUT_STARTED),
  // TECH-DEBT -- we should really parse these fields using parsers instead
  // of using `z.any()` followed by casting. Feel free to clean up.
  checkout: z
    .any()
    .transform(
      (val: any) => val as PixelEventsCheckoutStarted["data"]["checkout"],
    ),
  cart: z
    .any()
    .optional()
    .transform((val: any) => val as PartialCart | null | undefined),
});

const CheckoutCompletedShoppingEventSchema = _ShoppingEventSchema.extend({
  eventType: z.literal(CustomerEventType.CHECKOUT_COMPLETED),
  // TECH-DEBT -- we should really parse these fields using parsers instead
  // of using `z.any()` followed by casting. Feel free to clean up.
  checkout: z
    .any()
    .transform(
      (val: any) => val as PixelEventsCheckoutCompleted["data"]["checkout"],
    ),
});

const StorePageViewedShoppingEventSchema = _ShoppingEventSchema.extend({
  eventType: z.literal(CustomerEventType.STORE_PAGE_VIEWED),
  urlWithoutParams: z.string(),
  urlWithParams: z.string(),
});

const CollectionViewedShoppingEventSchema = _ShoppingEventSchema.extend({
  eventType: z.literal(CustomerEventType.COLLECTION_VIEWED),
  // TECH-DEBT -- we should really parse these fields using parsers instead
  // of using `z.any()` followed by casting. Feel free to clean up.
  collection: z
    .any()
    .transform(
      (val: any) => val as PixelEventsCollectionViewed["data"]["collection"],
    ),
});

const _OutreachAnalyticsEventBaseSchema = _CustomerEventSchema.extend({
  template: z.string().optional(),
  automation: z.string().optional(),
  campaign: z.string().optional(),
  category: z.string().optional(),
  channel: z.nativeEnum(MarketingChannel),
  channelId: z.string(),
  rawEvent: SesEventSchema.optional(),
});

const OutreachSendEventSchema = _OutreachAnalyticsEventBaseSchema.extend({
  eventType: z.literal(CustomerEventType.OUTREACH_SEND),
});

const OutreachDeliveryEventSchema = _OutreachAnalyticsEventBaseSchema.extend({
  eventType: z.literal(CustomerEventType.OUTREACH_DELIVERY),
});

const OutreachOpenEventSchema = _OutreachAnalyticsEventBaseSchema.extend({
  eventType: z.literal(CustomerEventType.OUTREACH_OPEN),
});

const OutreachClickEventSchema = _OutreachAnalyticsEventBaseSchema.extend({
  eventType: z.literal(CustomerEventType.OUTREACH_CLICK),
});

const OutreachBounceEventSchema = _OutreachAnalyticsEventBaseSchema.extend({
  eventType: z.literal(CustomerEventType.OUTREACH_BOUNCE),
});

const OutreachComplaintEventSchema = _OutreachAnalyticsEventBaseSchema.extend({
  eventType: z.literal(CustomerEventType.OUTREACH_COMPLAINT),
});

const CustomerInformationUpdatedEventSchema = _CustomerEventSchema.extend({
  eventType: z.literal(CustomerEventType.CUSTOMER_INFORMATION_UPDATE),
  newFirstName: z.string().optional(),
  newLastName: z.string().optional(),
  newPhoneNumber: z.string().optional(),
});

export const CustomerEventSchema = z.discriminatedUnion(
  CUSTOMER_EVENT_DISCRIMINATOR_KEY,
  [
    OrderCreatedCustomerEventSchema,
    OrderUpdatedCustomerEventSchema,
    OrderStatusUpdatedCustomerEventSchema,
    ReturnCreatedCustomerEventSchema,
    ReturnStatusUpdatedCustomerEventSchema,
    ReturnCommentCreatedCustomerEventSchema,
    ClaimCreatedCustomerEventSchema,
    ClaimStatusUpdatedCustomerEventSchema,
    ClaimCommentCreatedCustomerEventSchema,
    ConversationCreatedCustomerEventSchema,
    ConversationStatusUpdatedCustomerEventSchema,
    ConversationMessageCreatedCustomerEventSchema,
    ExternalPlatformCustomerCreatedCustomerEventSchema,
    StorefrontLoginCustomerEventSchema,
    MarketingSubscribeEventSchema,
    MarketingUnsubscribeEventSchema,
    MarketingConfirmEventSchema,
    ProductViewedShoppingEventSchema,
    ProductAddedToCartShoppingEventSchema,
    CheckoutStartedShoppingEventSchema,
    CheckoutCompletedShoppingEventSchema,
    StorePageViewedShoppingEventSchema,
    CollectionViewedShoppingEventSchema,
    OutreachSendEventSchema,
    OutreachDeliveryEventSchema,
    OutreachOpenEventSchema,
    OutreachClickEventSchema,
    OutreachBounceEventSchema,
    OutreachComplaintEventSchema,
    CheckoutContactInfoSubmittedShoppingEventSchema,
    ProductRemovedFromCartShoppingEventSchema,
    CustomerInformationUpdatedEventSchema,
    ReturnProcessedCustomerEventSchema,
  ],
);

// Make sure the discriminated union is exhaustive
// An error here means that a new event type was added but not to the CustomerEventSchema
const _allEventTypes = null as unknown as CustomerEventType;
export const _supportedEventTypes: z.infer<
  typeof CustomerEventSchema
>["eventType"] = _allEventTypes;
