import { z } from "zod";

export const printLabels = {
  input: z.intersection(
    z.object({ templateId: z.string().optional() }),
    z.union([
      z.object({ fulfillmentGroupIds: z.array(z.string()) }),
      z.object({ batchId: z.string() }),
    ]),
  ),
  output: z.object({ url: z.string(), successIds: z.array(z.string()) }),
};
