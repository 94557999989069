import { Order, YofiRiskLevels } from "@redotech/redo-model/order";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import { Flex } from "@redotech/redo-web/flex";
import NeutralFaceIcon from "@redotech/redo-web/icon-old/face-neutral.svg";
import SadFaceIcon from "@redotech/redo-web/icon-old/face-sad.svg";
import SmileFaceIcon from "@redotech/redo-web/icon-old/face-smile.svg";
import { ExternalLink } from "@redotech/redo-web/link";
import { PillTheme } from "@redotech/redo-web/pill";
import { BadgePillThemeToColorMapping } from "@redotech/redo-web/tags/tag-pill";
import { Text } from "@redotech/redo-web/text";
import { memo, useState } from "react";
import { YofiDetailsModal } from "../../return-modals/yofi-details-modal";
import * as yofiFraudDetailsCss from "./yofi-fraud-details.module.css";

export const YofiFraudDetails = memo(function YofiFraudDetails({
  order,
  returnTypeName,
}: {
  order?: Order | null;
  returnTypeName?: string;
}) {
  const yofiFraudScore = getFraudScore(order?.yofiScores);
  const [yofiOpen, setYofiOpen] = useState(false);

  const fraudRiskText = returnTypeName
    ? `${returnTypeName} fraud risk`
    : "Fraud risk";

  if (!order) {
    return null;
  }

  return (
    <Flex dir="column" gap="xs">
      {yofiFraudScore && (
        <Flex justify="space-between">
          <Flex align="center" gap="lg">
            <Text fontSize="xs" fontWeight="medium" textColor="primary">
              {fraudRiskText}
            </Text>
            <YofiRiskBadge yofiFraudScore={yofiFraudScore} />
          </Flex>
          <ExternalLink
            className={yofiFraudDetailsCss.link}
            onClick={() => {
              setYofiOpen(true);
            }}
            showIcon={false}
          >
            View report
          </ExternalLink>
        </Flex>
      )}
      {yofiOpen && (
        <YofiDetailsModal
          customerId={order.shopify.customer.id}
          onClose={() => {
            setYofiOpen(false);
          }}
          open={yofiOpen}
        />
      )}
    </Flex>
  );
});

export function getYofiBadgeColor(yofiFraudScore: YofiRiskLevels | null) {
  switch (yofiFraudScore) {
    case YofiRiskLevels.LOW:
      return RedoBadgeColor.SUCCESS;
    case YofiRiskLevels.MEDIUM:
      return RedoBadgeColor.WARNING;
    case YofiRiskLevels.HIGH:
      return RedoBadgeColor.ERROR;
    default:
      return undefined;
  }
}

export function getFraudScore(
  yofiScores:
    | {
        return_abuse_score?: {
          prediction_name: string;
          prediction_value: YofiRiskLevels;
          justification: string;
          indicators: [{ name: string; is_risk: boolean }];
        };
        bot_abuse_score?: {
          prediction_name: string;
          prediction_value: YofiRiskLevels;
          justification: string;
          indicators: [{ name: string; is_risk: boolean }];
        };
        discount_abuse_score?: {
          prediction_name: string;
          prediction_value: YofiRiskLevels;
          justification: string;
          indicators: [{ name: string; is_risk: boolean }];
        };
        resell_abuse_score?: {
          prediction_name: string;
          prediction_value: YofiRiskLevels;
          justification: string;
          indicators: [{ name: string; is_risk: boolean }];
        };
      }
    | undefined,
) {
  const scores: (YofiRiskLevels | null)[] = [
    yofiScores?.return_abuse_score?.prediction_value || null,
    yofiScores?.resell_abuse_score?.prediction_value || null,
    yofiScores?.bot_abuse_score?.prediction_value || null,
    yofiScores?.discount_abuse_score?.prediction_value || null,
  ];
  const yofiFraudScore = scores.reduce((acc, score) => {
    if (score === YofiRiskLevels.HIGH) {
      return YofiRiskLevels.HIGH;
    }
    if (acc === YofiRiskLevels.HIGH || acc === YofiRiskLevels.MEDIUM) {
      return acc;
    }
    if (score === YofiRiskLevels.MEDIUM) {
      return YofiRiskLevels.MEDIUM;
    }
    if (score === YofiRiskLevels.LOW) {
      return YofiRiskLevels.LOW;
    }
    return acc;
  }, null);
  return yofiFraudScore;
}

export function getPillThemeAndIcon(yofiFraudScore: YofiRiskLevels | null) {
  let yofiPillTheme: PillTheme | undefined = undefined;
  let yofiIcon = undefined;
  switch (yofiFraudScore) {
    case YofiRiskLevels.LOW:
      yofiPillTheme = PillTheme.SUCCESS;
      yofiIcon = <SmileFaceIcon className={yofiFraudDetailsCss.pillIcon} />;
      break;
    case YofiRiskLevels.MEDIUM:
      yofiPillTheme = PillTheme.NEUTRAL_YELLOW;
      yofiIcon = <NeutralFaceIcon className={yofiFraudDetailsCss.pillIcon} />;
      break;
    case YofiRiskLevels.HIGH:
      yofiPillTheme = PillTheme.DANGER;
      yofiIcon = <SadFaceIcon className={yofiFraudDetailsCss.pillIcon} />;
      break;
  }
  return { yofiPillTheme, yofiIcon };
}

export const YofiRiskBadge = memo(function YofiRiskBadge({
  yofiFraudScore,
}: {
  yofiFraudScore?: YofiRiskLevels | null;
}) {
  const { yofiPillTheme, yofiIcon } = getPillThemeAndIcon(
    yofiFraudScore || null,
  );

  return (
    <>
      {yofiFraudScore ? (
        <RedoBadge
          color={
            BadgePillThemeToColorMapping[yofiPillTheme || PillTheme.NORMAL]
          }
          segmentLeading={{ type: "icon", Icon: () => yofiIcon || null }}
          size={RedoBadgeSize.SMALL}
          text={
            yofiFraudScore.charAt(0).toUpperCase() + yofiFraudScore.slice(1)
          }
        />
      ) : (
        <RedoBadge
          color={RedoBadgeColor.GRAY}
          size={RedoBadgeSize.SMALL}
          text="N/A"
        />
      )}
    </>
  );
});
