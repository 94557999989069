import { z } from "zod";

export enum PrintableDocument {
  PICK_LIST = "pickList",
  PACKING_SLIP = "packingSlip",
  LABEL = "label",
  COMMERCIAL_INVOICE = "commercialInvoice",
}

// TODO: Migrate this to PrintableDocument
export enum PrintKind {
  PICK_LIST = "pickListPrinted",
  PACKING_SLIP = "packingSlipPrinted",
  LABEL = "labelPrinted",
  COMMERCIAL_INVOICE = "commercialInvoicePrinted",
}

export const PrintKindForDocument = {
  [PrintableDocument.PICK_LIST]: PrintKind.PICK_LIST,
  [PrintableDocument.PACKING_SLIP]: PrintKind.PACKING_SLIP,
  [PrintableDocument.LABEL]: PrintKind.LABEL,
  [PrintableDocument.COMMERCIAL_INVOICE]: PrintKind.COMMERCIAL_INVOICE,
};

export const PrintStatusSchema = z.object({
  [PrintKind.PICK_LIST]: z.boolean().nullish(),
  [PrintKind.PACKING_SLIP]: z.boolean().nullish(),
  [PrintKind.LABEL]: z.boolean().nullish(),
  [PrintKind.COMMERCIAL_INVOICE]: z.boolean().nullish(),
});

export type PrintStatus = z.infer<typeof PrintStatusSchema>;
