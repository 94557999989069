import { camelToSnakeCase } from "@redotech/util/string";
import { Schema, SchemaInstance } from "../type-system/schema";
import {
  baseMarketingBrowseAbandomentSchema,
  baseMarketingCartAbandomentSchema,
  baseMarketingCheckoutAbandonmentSchema,
  baseMarketingEmailSchema,
  baseMarketingSchema,
  emailMarketingSignupSchema,
  exampleBaseMarketingBrowseAbandomentSchemaInstance,
  exampleBaseMarketingCartAbandomentSchemaInstance,
  exampleBaseMarketingCheckoutAbandonmentSchemaInstance,
  exampleBaseMarketingEmailSchemaInstance,
  exampleEmailMarketingSignupSchema,
  exampleSmsMarketingConfirmedSchema,
  exampleSmsMarketingSignupSchema,
  smsMarketingConfirmedSchema,
  smsMarketingSignupSchema,
} from "./marketing/marketing";
import {
  baseNewFulfillmentGroupSchema,
  examplebaseNewFulfillmentGroupInstance,
} from "./oms";
import {
  baseReviewsSchema,
  exampleBaseReviewsSchemaInstance,
} from "./reviews/reviews";
import {
  exampleOrderTrackingSchemaInstance,
  orderTrackingSchema,
} from "./tracking/order-tracking";
import {
  exampleReturnTrackingSchemaInstance,
  returnTrackingSchema,
} from "./tracking/return-tracking";

export enum SchemaType {
  ORDER_TRACKING = "order_tracking",
  RETURN_TRACKING = "return_tracking",
  EMAIL_MARKETING_SIGNUP = "email_marketing_signup",
  SMS_MARKETING_SIGNUP = "sms_marketing_signup",
  SMS_MARKETING_CONFIRMED = "sms_marketing_confirmed",
  MARKETING_CAMPAIGN = "marketing_campaign",
  REVIEWS = "reviews",
  MARKETING_EMAIL = "marketing_email",
  MARKETING_CART_ABANDONMENT = "marketing_cart_abandonment",
  MARKETING_BROWSE_ABANDONMENT = "marketing_browse_abandonment",
  MARKETING_CHECKOUT_ABANDONMENT = "marketing_checkout_abandonment",
  OMS_NEW_FULFILLMENT_GROUP = "oms_new_fulfillment_group",
}

export const schemas: Record<SchemaType, Schema> = {
  [SchemaType.ORDER_TRACKING]: orderTrackingSchema,
  [SchemaType.RETURN_TRACKING]: returnTrackingSchema,
  [SchemaType.EMAIL_MARKETING_SIGNUP]: emailMarketingSignupSchema,
  [SchemaType.SMS_MARKETING_SIGNUP]: smsMarketingSignupSchema,
  [SchemaType.SMS_MARKETING_CONFIRMED]: smsMarketingConfirmedSchema,
  [SchemaType.MARKETING_CAMPAIGN]: baseMarketingSchema,
  [SchemaType.REVIEWS]: baseReviewsSchema,
  [SchemaType.MARKETING_EMAIL]: baseMarketingEmailSchema,
  [SchemaType.MARKETING_CART_ABANDONMENT]: baseMarketingCartAbandomentSchema,
  [SchemaType.MARKETING_BROWSE_ABANDONMENT]:
    baseMarketingBrowseAbandomentSchema,
  [SchemaType.MARKETING_CHECKOUT_ABANDONMENT]:
    baseMarketingCheckoutAbandonmentSchema,
  [SchemaType.OMS_NEW_FULFILLMENT_GROUP]: baseNewFulfillmentGroupSchema,
};

export const schemaInstanceExamples: Record<
  SchemaType,
  SchemaInstance<Schema>
> = {
  [SchemaType.ORDER_TRACKING]: exampleOrderTrackingSchemaInstance,
  [SchemaType.RETURN_TRACKING]: exampleReturnTrackingSchemaInstance,
  [SchemaType.EMAIL_MARKETING_SIGNUP]: exampleEmailMarketingSignupSchema,
  [SchemaType.SMS_MARKETING_SIGNUP]: exampleSmsMarketingSignupSchema,
  [SchemaType.SMS_MARKETING_CONFIRMED]: exampleSmsMarketingConfirmedSchema,
  [SchemaType.MARKETING_CAMPAIGN]: exampleBaseMarketingEmailSchemaInstance, // intentionally the same as email
  [SchemaType.REVIEWS]: exampleBaseReviewsSchemaInstance,
  [SchemaType.MARKETING_EMAIL]: exampleBaseMarketingEmailSchemaInstance,
  [SchemaType.MARKETING_CART_ABANDONMENT]:
    exampleBaseMarketingCartAbandomentSchemaInstance,
  [SchemaType.MARKETING_BROWSE_ABANDONMENT]:
    exampleBaseMarketingBrowseAbandomentSchemaInstance,
  [SchemaType.MARKETING_CHECKOUT_ABANDONMENT]:
    exampleBaseMarketingCheckoutAbandonmentSchemaInstance,
  [SchemaType.OMS_NEW_FULFILLMENT_GROUP]:
    examplebaseNewFulfillmentGroupInstance,
};

export interface DynamicVariable {
  key: string;
  default?: string;
}

export const applyDynamicVariableSyntax = (
  dynamicVariable: DynamicVariable,
) => {
  return [
    `{{ `,
    camelToSnakeCase(dynamicVariable.key),
    `${dynamicVariable.default ? ` | \`${dynamicVariable.default}\`` : ""}`,
    ` }}`,
  ].join("");
};

/**
 * Returns the regex to find a template variable, optionally with its default value
 *
 * @capture-groups
 *  1. variable name
 *  2. variable default, if present
 */
export function getTemplateVariableRegex(
  args: {
    variable?: string;
    flags?: string;
    allowAngleBrackets?: boolean;
  } = {},
) {
  const { variable, flags } = args;
  const allowAngleBrackets = args.allowAngleBrackets ?? true;

  const variableStartRegex = allowAngleBrackets ? "(?:<|{{)" : "{{";
  const variableEndRegex = allowAngleBrackets ? "(?:>|}})" : "}}";
  const whiteSpaceRegex = "\\s*";
  const defaultValueRegex = [
    "\\|",
    whiteSpaceRegex,
    "`([^`>}]+)`",
    whiteSpaceRegex,
  ].join("");
  return new RegExp(
    [
      variableStartRegex,
      whiteSpaceRegex,
      `(${variable ? camelToSnakeCase(variable) : "\\S+"})`,
      whiteSpaceRegex,
      `(?:${defaultValueRegex})?`,
      variableEndRegex,
    ].join(""),
    flags,
  );
}

export const populateVariables = (
  text: string,
  schemaInstance: SchemaInstance<Schema>,
  html = true,
) => {
  const regex = getTemplateVariableRegex({
    flags: "g",
    allowAngleBrackets: false,
  });
  text = text.replace(
    regex,
    (match, variableName: string, variableDefault?: string) => {
      if (variableName.includes(".")) {
        const [key, value] = variableName.split(".");
        const obj = schemaInstance[snakeToCamelCase(key)];
        if (typeof obj === "object" && obj !== null) {
          const variableValue = (obj as Record<string, unknown>)[value];
          return renderVariable({ variableValue, html, variableDefault });
        }
        return variableDefault ?? "";
      }
      const variableValue = schemaInstance[snakeToCamelCase(variableName)];
      return renderVariable({ variableValue, html, variableDefault });
    },
  );
  return text.trim();
};

function snakeToCamelCase(str: string) {
  return str.replace(/(_\w)/g, (match) => match[1].toUpperCase());
}

function renderVariable({
  variableValue,
  variableDefault,
  html = true,
}: {
  variableValue: unknown;
  variableDefault?: string;
  html: boolean;
}): string {
  if (!html) {
    return prettyVariable(variableValue);
  }

  if (variableValue === undefined || variableValue === null) {
    return variableDefault ?? "";
  }

  if (variableValue instanceof Temporal.PlainDate) {
    return variableValue.toLocaleString();
  }

  if (typeof variableValue === "object") {
    return Object.entries(variableValue)
      .map(([key, value]) => `<br />${key}: ${prettyVariable(value)}`)
      .join("");
  }

  if (Array.isArray(variableValue)) {
    return variableValue
      .map((item) => `<br />${prettyVariable(item)}`)
      .join("");
  }

  return prettyVariable(variableValue, variableDefault);
}

function prettyVariable(variable: unknown, defaultValue?: string): string {
  if (variable === undefined || variable === null) {
    return defaultValue ?? "";
  }
  if (variable instanceof Date) {
    return variable.toDateString();
  }
  if (typeof variable === "number") {
    return variable.toString();
  }
  if (Array.isArray(variable)) {
    return variable.join(", ");
  }
  if (variable instanceof Temporal.PlainDate) {
    return variable.toLocaleString();
  }
  return String(variable);
}
