// extracted by mini-css-extract-plugin
export var active = "_bz-b";
export var arrow = "_bz-8";
export var arrowContainer = "_bz-4";
export var container = "_bz-0";
export var disabled = "_bz-5";
export var dot = "_bz-a";
export var dotContainer = "_bz-9";
export var leftArrowContainer = "_bz-6 _bz-4";
export var rightArrowContainer = "_bz-7 _bz-4";
export var slide = "_bz-3";
export var sliderContainer = "_bz-1";
export var sliderOverflow = "_bz-2";