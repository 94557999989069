import { useCallback, useEffect, useState } from "react";

export function useMaxAvailableHeight({
  areaRef,
  contentBelowRef,
}: {
  areaRef: React.RefObject<HTMLDivElement>;
  contentBelowRef: React.RefObject<HTMLDivElement> | undefined;
}) {
  const [availableHeight, setAvailableHeight] = useState(0);

  const calculateAvailableHeight = useCallback(() => {
    const distFromTop = areaRef.current?.getBoundingClientRect().top || 0;
    const contentBelowHeight = contentBelowRef?.current?.offsetHeight || 0;
    const newAvailableHeight =
      window.innerHeight - distFromTop - contentBelowHeight;
    setAvailableHeight(newAvailableHeight);
  }, [areaRef, contentBelowRef]);

  useEffect(() => {
    calculateAvailableHeight();

    const handleResize = () => {
      calculateAvailableHeight();
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [areaRef, contentBelowRef, calculateAvailableHeight]);

  return availableHeight;
}
