// extracted by mini-css-extract-plugin
export var chevron = "_4f-a";
export var disabled = "_4f-9";
export var icon = "_4f-b";
export var input = "_4f-0";
export var open = "_4f-7";
export var placeholder = "_4f-8";
export var regular = "_4f-3";
export var singleSelectInput = "_4f-1 _4f-0";
export var small = "_4f-2";
export var spinnerContainer = "_4f-c";
export var tag = "_4f-5";
export var tagsContainer = "_4f-6";
export var tagsInput = "_4f-4 _4f-0";