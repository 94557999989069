import { z } from "zod";

/**
 * Based on Shopify's "Inventory Policy"
 * https://shopify.dev/docs/api/admin-graphql/2024-10/enums/ProductVariantInventoryPolicy
 *
 * This enum is intentionally separate from Shopify's enum to keep the Product
 * collection platform agnostic.
 */
export enum InventoryPolicy {
  /**
   * Customers can buy this product after it's out of stock.
   */
  CONTINUE = "continue",
  /**
   * Customers can't buy this product after it's out of stock.
   */
  DENY = "deny",
}

/**
 * Shopify inventory policies are uppercase
 */
export function shopifyInventoryPolicyToInventoryPolicy(
  shopifyInventoryPolicy: string,
): InventoryPolicy {
  switch (shopifyInventoryPolicy) {
    case "CONTINUE":
      return InventoryPolicy.CONTINUE;
    case "DENY":
      return InventoryPolicy.DENY;
    default:
      throw new Error(`Unknown inventory policy: ${shopifyInventoryPolicy}`);
  }
}

export const ShopifyProductImage = z.object({
  id: z.string().optional().nullable(),
  url: z.string().optional().nullable(),
});

export const ProductVariant = z.object({
  id: z.string(),
  legacyResourceId: z.string(),
  title: z.string(),
  price: z.string(),
  inventoryQuantity: z.number().optional().nullable(),
  compareAtPrice: z.string().optional().nullable(),
  availableForSale: z.boolean(),
  image: z.object({ url: z.string() }).optional().nullable(),
  selectedOptions: z.array(z.object({ name: z.string(), value: z.string() })),
  contextualPricing: z.object({
    price: z.object({ amount: z.string(), currencyCode: z.string() }),
  }),
});

export const ShopifyGraphQLProduct = z.object({
  id: z.string(),
  legacyResourceId: z.string(),
  vendor: z.string().optional(),
  tags: z.array(z.string()),
  publishedAt: z.string().optional().nullable(),
  productType: z.string(),
  onlineStoreUrl: z.string().optional().nullable(),
  handle: z.string(),
  title: z.string(),
  description: z.string(),
  options: z.array(
    z.object({
      name: z.string(),
      optionValues: z.array(z.object({ name: z.string() })),
    }),
  ),
  featuredMedia: z
    .object({
      preview: z
        .object({ image: z.object({ url: z.string() }).optional().nullable() })
        .optional()
        .nullable(),
    })
    .optional()
    .nullable(),
  variants: z.object({ nodes: z.array(ProductVariant) }),
  images: z.object({ nodes: z.array(ShopifyProductImage) }),
  priceRange: z.object({
    minVariantPrice: z.object({ amount: z.string(), currencyCode: z.string() }),
    maxVariantPrice: z.object({ amount: z.string(), currencyCode: z.string() }),
  }),
});

export type ShopifyGraphQLProduct = z.infer<typeof ShopifyGraphQLProduct>;
export type ProductVariant = z.infer<typeof ProductVariant>;

export const ProductImage = z.object({ url: z.string(), alt: z.string() });

export const VariantInfo = z.object({
  id: z.string(),
  price: z.string(),
  inventoryPolicy: z.nativeEnum(InventoryPolicy),
  inventoryQuantity: z.number(),
  inventoryTracked: z.boolean(),
  sku: z.string(),
  name: z.string(),
  quantityAvailable: z.number(),
  image: ProductImage,
  productId: z.string(),
  productName: z.string(),
});

export type VariantInfo = z.infer<typeof VariantInfo>;

export const ProductInfo = z.object({
  id: z.string(),
  name: z.string(),
  image: ProductImage,
  description: z.string(),
  tags: z.array(z.string()),
  variants: z.array(VariantInfo),
});

export type ProductInfo = z.infer<typeof ProductInfo>;

export enum ECommercePlatform {
  SHOPIFY = "shopify",
  COMMERCE_CLOUD = "commerceCloud",
  COMMENT_SOLD = "commentsold",
}

/**
 * Converts `123` to `gid://shopify/Product/123`
 */
export function productIdToShopifyGlobalProductId(productId: string): string {
  return `gid://shopify/Product/${productId}`;
}

/**
 * Converts `gid://shopify/Product/123` to `123`
 */
export function shopifyGlobalProductIdToProductId(
  globalProductId: string,
): string {
  const productId = globalProductId.split("/").pop();
  if (!productId) {
    throw new Error(`Invalid global product ID: ${globalProductId}`);
  }
  return productId;
}

export const getShopifyProductDetailPageUrl = (
  storeUrl: string,
  productHandle: string,
): string => {
  return `https://${storeUrl}/products/${productHandle}`;
};
