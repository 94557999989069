import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { getConversationsCounts } from "@redotech/redo-merchant-app-common/client/team";
import { MerchantAppTopic } from "@redotech/redo-merchant-app-common/events/merchant-app-event-server";
import { MerchantAppEventServerContext } from "@redotech/redo-merchant-app-common/events/merchant-app-event-server-provider";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import {
  ConversationFiltersV3,
  FiltersStatus,
} from "@redotech/redo-model/conversation-filters/conversation-filters";
import { FrontendTeamNoticeEvent } from "@redotech/redo-model/team-notifications/team-notifications";
import { deepIntersectionEquals } from "@redotech/util/equal";
import {
  ReactNode,
  createContext,
  memo,
  useContext,
  useEffect,
  useState,
} from "react";
import { maybeRunConversationsRefresh } from "../handle-notice-event";
import { ActiveViewContext } from "./active-view-context";
import { FinalizedFiltersContext } from "./filters-context";

export const ActiveViewConversationCountsContext = createContext<
  Record<string, number> | undefined
>({});

export const ActiveViewConversationCountsProvider = memo(
  function ConversationCountsProvider({
    children,
    blockCountRefreshes,
  }: {
    blockCountRefreshes: boolean;
    children: ReactNode | ReactNode[];
  }) {
    const client = useRequiredContext(RedoMerchantClientContext);
    const eventServer = useRequiredContext(MerchantAppEventServerContext);
    const activeFilters = useContext(FinalizedFiltersContext);
    const activeView = useContext(ActiveViewContext);
    const activeViewName = activeView.name;
    const team = useRequiredContext(TeamContext);

    useEffect(() => {
      const unlistenCallback = eventServer.subscribe({
        topic: MerchantAppTopic.TEAM,
        callback: async (message: FrontendTeamNoticeEvent) => {
          if (blockCountRefreshes) {
            return;
          }

          maybeRunConversationsRefresh(message, doConversationCountsLoad);
        },
      });
      return () => unlistenCallback();
    }, [blockCountRefreshes]);

    useEffect(() => {
      doConversationCountsLoad();
    }, [activeFilters]);

    const [conversationCounts, setConversationCounts] = useState<
      Record<string, number>
    >({});

    const [_, doConversationCountsLoad] = useTriggerLoad(async (signal) => {
      const closedFilter = { ...activeFilters, status: FiltersStatus.CLOSED };
      const openFilter = { ...activeFilters, status: FiltersStatus.OPEN };
      const snoozedFilter = { ...activeFilters, status: FiltersStatus.SNOOZED };
      const inProgressFilter = {
        ...activeFilters,
        status: FiltersStatus.IN_PROGRESS,
      };

      const currentViewFilters: {
        name: string;
        filters: ConversationFiltersV3;
      }[] = [
        { name: `${activeViewName}-closed`, filters: closedFilter },
        { name: `${activeViewName}-open`, filters: openFilter },
        { name: `${activeViewName}-snoozed`, filters: snoozedFilter },
        ...(team.settings.support?.useInProgressStatus
          ? [
              {
                name: `${activeViewName}-in_progress`,
                filters: inProgressFilter,
              },
            ]
          : []),
      ];

      const rawConversationCounts = await getConversationsCounts(client, {
        views: currentViewFilters,
      });

      const counts = {
        ...rawConversationCounts,
        [`${activeViewName}-all`]: Object.values(rawConversationCounts).reduce(
          (acc, val) => acc + val,
          0,
        ),
      };

      if (!deepIntersectionEquals(counts, conversationCounts)) {
        setConversationCounts(counts);
      }

      return counts;
    });

    return (
      <ActiveViewConversationCountsContext.Provider value={conversationCounts}>
        {children}
      </ActiveViewConversationCountsContext.Provider>
    );
  },
);
