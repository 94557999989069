import { useRequiredContext } from "@redotech/react-util/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import {
  AssigneesFilterType,
  FilterGroupFilterOption,
  AssigneesFilter as ModelAssigneesFilter,
  PendingFilter,
} from "@redotech/redo-model/conversation-filters/conversation-filters";
import { GetUser } from "@redotech/redo-model/user";
import {
  RedoFilterDropdownAnchor,
  RedoFilterGroup,
} from "@redotech/redo-web/arbiter-components/filter-group/redo-filter-group";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoListItemSize } from "@redotech/redo-web/arbiter-components/list/redo-list-item";
import { RedoMultiSelectDropdown } from "@redotech/redo-web/arbiter-components/select-dropdown/redo-multi-select-dropdown";
import { RedoSingleSelectDropdown } from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown";
import {
  ConversationFilterOptionToIcon,
  ConversationFilterOptionToName,
} from "@redotech/redo-web/conversation-filters/conversation-filter-icons";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useEffect, useMemo, useState } from "react";

type Assignee = { id: string | null; name: string };

export const AssigneesFilter = memo(function AssigneesFilter({
  filter,
  setFilter,
  removeFilter,
  openOnRender,
}: {
  filter: ModelAssigneesFilter | PendingFilter<ModelAssigneesFilter>;
  setFilter(
    filter: ModelAssigneesFilter | PendingFilter<ModelAssigneesFilter>,
  ): void;
  removeFilter(): void;
  openOnRender: boolean;
}) {
  const [queryRef, setQueryRef] = useState<HTMLButtonElement | null>(null);
  const [valueRef, setValueRef] = useState<HTMLButtonElement | null>(null);

  const team = useRequiredContext(TeamContext);

  const { value: assignees, query: assigneeFilterType } = filter;

  const teamUsers = team.users.map((user) => user.user as GetUser);

  const assigneesOptions: Assignee[] = useMemo(
    () => [
      { id: null, name: "Unassigned" },
      ...teamUsers
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((user) => ({ id: user._id, name: user.name })),
      { id: process.env.CHATBOT_USER_ID || "", name: "Chatbot" },
    ],
    [team],
  );

  useEffect(() => {
    if (openOnRender && valueRef) {
      valueRef.click();
    }
  }, [openOnRender, valueRef]);

  const query = (
    <RedoFilterDropdownAnchor
      color="secondary"
      ref={setQueryRef}
      text={assigneeFilterTypeToText[assigneeFilterType]}
    />
  );

  const queryDropdown = (
    <RedoSingleSelectDropdown
      dropdownButtonRef={queryRef}
      options={assigneeQueryDropdownItems}
      optionSelected={(query: RedoListItem<AssigneesFilterType>) => {
        setFilter({ ...filter, query: query.value });
      }}
      selectedItem={assigneeQueryDropdownItems.find(
        (item) => item.value === assigneeFilterType,
      )}
      size={RedoListItemSize.SMALL}
    >
      {(item) => (
        <Text
          fontSize="sm"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {assigneeFilterTypeToText[item.value]}
        </Text>
      )}
    </RedoSingleSelectDropdown>
  );

  const valueText = assignees
    ? assignees.length === 1
      ? "1 Assignee"
      : ` ${assignees.length} Assignees`
    : "...";

  const value = (
    <RedoFilterDropdownAnchor
      color="secondary"
      ref={setValueRef}
      text={valueText}
      tooltip={
        assignees && (
          <Flex dir="column">
            {assignees.map((assignee) => (
              <Text key={assignee}>
                {assigneeToFriendlyAssignee(assignee, teamUsers)}
              </Text>
            ))}
          </Flex>
        )
      }
    />
  );

  const assigneeListOptions: RedoListItem<Assignee>[] = assigneesOptions.map(
    (assignee) => {
      return { value: assignee };
    },
  );

  const selectedAssignees: RedoListItem<Assignee>[] =
    assigneeListOptions.filter((assignee) =>
      assignees?.includes(assignee.value.id),
    );

  const valueDropdown = (
    <RedoMultiSelectDropdown
      dropdownButtonRef={valueRef}
      options={assigneeListOptions}
      selectedOptions={selectedAssignees}
      setSelectedOptions={(assignees) => {
        if (assignees.length === 0) {
          setFilter({ ...filter, value: null });
        } else {
          const ids = assignees.map((item) => item.value.id);
          setFilter({ ...filter, value: ids });
        }
      }}
      size={RedoListItemSize.SMALL}
    >
      {(item) => (
        <Text
          fontSize="sm"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {item.value.name}
        </Text>
      )}
    </RedoMultiSelectDropdown>
  );

  return (
    <>
      {queryDropdown}
      {valueDropdown}
      <RedoFilterGroup
        Icon={ConversationFilterOptionToIcon[FilterGroupFilterOption.ASSIGNEES]}
        propertyName={
          ConversationFilterOptionToName[FilterGroupFilterOption.ASSIGNEES]
        }
        query={query}
        removeFilter={removeFilter}
        value={value}
      />
    </>
  );
});

const assigneeFilterTypeToText: Record<AssigneesFilterType, string> = {
  [AssigneesFilterType.INCLUDES]: "include",
  [AssigneesFilterType.EXCLUDES]: "do not include",
};

const assigneeQueryDropdownItems: RedoListItem<AssigneesFilterType>[] = [
  { value: AssigneesFilterType.INCLUDES },
  { value: AssigneesFilterType.EXCLUDES },
];

function assigneeToFriendlyAssignee(
  assignee: string | null,
  teamUsers: GetUser[],
): string {
  if (assignee === null) {
    return "Unassigned";
  } else if (assignee === process.env.CHATBOT_USER_ID) {
    return "Chatbot";
  }
  return (
    teamUsers.find((user) => user._id === assignee)?.name || "Unknown user"
  );
}
