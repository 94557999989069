import {
  Mjml,
  MjmlAll,
  MjmlAttributes,
  MjmlBody,
  MjmlHead,
  MjmlPreview,
  MjmlRaw,
} from "@faire/mjml-react";
import { EmailFormat } from "@redotech/redo-model/email-builder";
import { MOBILE_WIDTH_PX } from "@redotech/redo-model/email-builder/email-sizing";

export enum GlobalEmailClass {
  MJML_BODY = "mjml-body",
  MJML_PREVIEW_BODY = "mjml-preview-body",
  SPINNER = "spinner",
  LINE_ITEM_LOADER = "line-item-loader",
  APPLE_MAIL_ONLY = "apple-mail-show",
  APPLE_MAIL_HIDE = "apple-mail-hide",
  FORCE_FALLBACK = "force-fallback",
  OBJECT_FIT_CONTAIN = "contain",
  OBJECT_FIT_COVER = "cover",
}

export const MerchantTemplatedEmailBoilerplate = ({
  customCss,
  format,
  emailBody,
  children,
  preview,
  backgroundColor,
  forceFallback,
}: {
  customCss?: string;
  format: EmailFormat;
  emailBody: string;
  children: React.ReactNode;
  forceFallback?: boolean;
  preview: string | null;
  backgroundColor: string;
}) => {
  // TODO: add attributes to root element post-render (MJML doesn't support adding attributes to the <html> tag)
  // amp4email=""
  // data-css-strict=""
  // This intentionally avoids using MjmlStyle component because it doesn't play nice with AMP
  // (it doesn't support the amp-custom or amp4email-boilerplate attributes)

  const bodyClasses = [GlobalEmailClass.MJML_BODY];
  if (forceFallback) {
    bodyClasses.push(GlobalEmailClass.FORCE_FALLBACK);
  }

  return (
    <Mjml>
      <MjmlHead>
        <MjmlRaw>
          <meta charSet="utf-8" />
          {format === EmailFormat.AMP && (
            <>
              {necessaryAmpScripts(emailBody).map((script) => (
                <script
                  async
                  custom-element={script.customElement}
                  custom-template={script.customTemplate}
                  key={script.src}
                  src={script.src}
                />
              ))}

              <style amp4email-boilerplate="">{`body{visibility:hidden}`}</style>
            </>
          )}
          {customCss && (
            <style
              amp-custom={format === EmailFormat.AMP ? "" : undefined}
              dangerouslySetInnerHTML={{ __html: customCss }}
            />
          )}
        </MjmlRaw>
        <MjmlAttributes>
          <MjmlAll padding="0" />
        </MjmlAttributes>
        {preview && <MjmlPreview>{preview}</MjmlPreview>}
      </MjmlHead>
      <MjmlBody
        backgroundColor={backgroundColor}
        cssClass={bodyClasses.join(" ")}
        width={600}
      >
        {children}
      </MjmlBody>
    </Mjml>
  );
};

export interface AmpScript {
  src: string;
  customElement?: string;
  customTemplate?: string;
  includeIfStringPresent?: string;
}

export const ampScripts: AmpScript[] = [
  { src: "https://cdn.ampproject.org/v0.js", customElement: undefined },
  {
    src: "https://cdn.ampproject.org/v0/amp-form-0.1.js",
    customElement: "amp-form",
    includeIfStringPresent: "<form",
  },
  {
    src: "https://cdn.ampproject.org/v0/amp-list-0.1.js",
    customElement: "amp-list",
  },
  {
    src: "https://cdn.ampproject.org/v0/amp-mustache-0.2.js",
    customTemplate: "amp-mustache",
    includeIfStringPresent: "<template",
  },
  {
    src: "https://cdn.ampproject.org/v0/amp-bind-0.1.js",
    customElement: "amp-bind",
  },
];

function necessaryAmpScripts(emailBody: string) {
  return ampScripts.filter((script) => {
    if (script.src === "https://cdn.ampproject.org/v0.js") {
      return true;
    }
    if (
      script.customElement &&
      emailBody.includes("<" + script.customElement)
    ) {
      return true;
    }
    if (script.customTemplate && emailBody.includes(script.customTemplate)) {
      return true;
    }
    if (
      script.includeIfStringPresent &&
      emailBody.includes(script.includeIfStringPresent)
    ) {
      return true;
    }
    return false;
  });
}

export const MERCHANT_TEMPLATED_EMAIL_GLOBAL_STYLES = `
    * {
      margin: 0;
    }
    html, body {
      width: 100%;
      margin: 0;
      padding: 0;
    }
    p {
      margin: 0;
    }
    body {
      padding-top: 76px;
      padding-bottom: 76px;
    }
    @media screen and (max-width: 600px) {
      body {
        padding: 0;
      }
      .${GlobalEmailClass.MJML_BODY} {
        width: 100%;
      }
    }
    .ql-align-right {
      text-align: right;
    }
    .ql-align-center {
      text-align: center;
    }
    .ql-size-small {
      font-size: 0.75em
    }
    .ql-size-large {
      font-size: 1.5em
    }
    .ql-size-huge {
      font-size: 2.5em
    }
    .ql-blank {
      padding: unset !important;
    }
    .ql-blank::before {
      left: unset !important;
      right: unset !important;
    }
    amp-img.${GlobalEmailClass.OBJECT_FIT_CONTAIN} img {
      object-fit: contain;
    }
    amp-img.${GlobalEmailClass.OBJECT_FIT_COVER} img {
      object-fit: cover;
    }
    .left img {
      object-position: left;
    }
    .right img {
      object-position: right;
    }
    .center img {
      object-position: center;
    }
    select {
      background-color: transparent;
      border: 1px solid #d6d6d6;
      border-radius: 6px;
      padding: 6px 8px 6px 8px;
      display: block;
      margin-top: 6px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-image: url(https://assets.getredo.com/chevron.png);
      background-repeat: no-repeat;
      background-position: right 10px top 50%;
      background-size: 12px;
    }
    select:focus {
      outline: 1px solid #999;
    }
    .line-item-loader {
      z-index: 99; /* 99 is max value in AMP */
      visibility: hidden;
    }
    .amp-form-submitting .line-item-loader, .amp-form-submit-success .${GlobalEmailClass.LINE_ITEM_LOADER} {
      visibility: visible;
    }
    .${GlobalEmailClass.SPINNER} {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: 2px solid transparent;
      border-left-color: #000;
      transform: rotate(0deg);
    }
    /* These animations cannot be put on the spinner at page load for some strange reason */
    .amp-form-submitting .${GlobalEmailClass.SPINNER}, .amp-form-submit-success .${GlobalEmailClass.SPINNER} {
      /* animations and keyframes are not supported in AMP */
      transition: transform 10000s linear;
      transform: rotate(3600000deg);
    }
    ${[2, 3, 4]
      .map(
        (i) => `
      amp-list.col-${i} div[role="list"] {
        display: grid;
        grid-template-columns: repeat(${i}, 1fr);
        gap: 24px;
    }`,
      )
      .join("\n")}
    /* Collapse > 2 columns on mobile */
    @media screen and (max-width: ${MOBILE_WIDTH_PX}px) {
      amp-list.col-3 div[role="list"], amp-list.col-4 div[role="list"] {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    .${GlobalEmailClass.APPLE_MAIL_ONLY} {
      display: none;
    }
    /** CSS counter support is a good proxy for whether we're in a modern email client */
    @supports (counter-reset: x) {
      .${GlobalEmailClass.MJML_BODY}:not(.${GlobalEmailClass.FORCE_FALLBACK}) .${GlobalEmailClass.APPLE_MAIL_HIDE} {
        display: none;
      }
      .${GlobalEmailClass.MJML_BODY}:not(.${GlobalEmailClass.FORCE_FALLBACK}) .${GlobalEmailClass.APPLE_MAIL_ONLY} {
        display: block;
      }
    }
  `;
