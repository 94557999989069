import { useRequiredContext } from "@redotech/react-util/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { ConversationTimelineEvent } from "@redotech/redo-model/conversation-timeline";
import { GetUser } from "@redotech/redo-model/user";
import { Avatar } from "@redotech/redo-web/arbiter-components/avatars/avatar";
import MessageTextSquareIcon from "@redotech/redo-web/arbiter-icon/message-text-square-02.svg";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { memo, ReactNode, useEffect, useMemo, useState } from "react";
import * as activityCardCss from "../../../common/activity-card.module.css";
import * as customerActivityCardCss from "../../../common/activity-card.module.css";
import { getUserAvatarUrl } from "../../../common/get-avatar-url";
import { BaseConversationActivityCard } from "./base-conversation-activity-card";

export const ConversationActivityCard = memo(
  function ConversationConversationActivityCard({
    conversationActivity,
  }: {
    conversationActivity: ConversationTimelineEvent;
  }) {
    const [user, setUser] = useState<GetUser | undefined>();

    const team = useRequiredContext(TeamContext);

    useEffect(() => {
      const curUser = team.users.find((u) => {
        // Handle both populated and unpopulated user objects
        const userId =
          typeof u.user === "object" && u.user !== null ? u.user._id : u.user;
        return userId === conversationActivity.userId;
      });
      setUser((curUser?.user as GetUser) || undefined);
    }, []);

    const senderImage = useMemo(() => {
      if (!user) {
        return null;
      }
      return getUserAvatarUrl({
        email: user?.email,
        size: Math.ceil(devicePixelRatio * 32),
        userId: conversationActivity.userId,
      });
    }, [devicePixelRatio, user]);

    const iconElement = (
      <Flex
        align="center"
        className={
          activityCardCss.conversationActivityDataConversationIconContainer
        }
        justify="center"
      >
        {user ? (
          <Avatar alt="avatar image" imageUrl={senderImage} name={user?.name} />
        ) : (
          <MessageTextSquareIcon
            className={
              customerActivityCardCss.customerActivityDataConversationIcon
            }
          />
        )}
      </Flex>
    );

    const getExtraDetailsElement = (): ReactNode | undefined => {
      return (
        <Tooltip
          arrow
          placement="bottom"
          title={conversationActivity.longDescription}
        >
          <Flex mt="sm">
            <Text fontSize="xs">
              {conversationActivity.shortDescription ||
                conversationActivity.message}
            </Text>
          </Flex>
        </Tooltip>
      );
    };

    return (
      <BaseConversationActivityCard
        extraDetailsElement={getExtraDetailsElement()}
        timestamp={conversationActivity.timestamp}
        topLeftElement={iconElement}
      />
    );
  },
);
