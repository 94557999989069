import { z, ZodNumber, ZodString } from "zod";
import { timeframeSchema } from "./segment-timeframe-zod-schema";
import {
  ComparisonType,
  ListCompareOperators,
  NumericCompareOperator,
  TokenCompareOperators,
  WhereConditionDataType,
} from "./segment-where-condition";

const listCompareSchema = (zType: ZodNumber | ZodString) =>
  z.object({
    type: z.literal(ComparisonType.LIST),
    operator: z.nativeEnum(ListCompareOperators),
    values: z.array(zType),
  });

const tokenCompareSchema = z.object({
  type: z.literal(ComparisonType.TOKEN),
  operator: z.nativeEnum(TokenCompareOperators),
  values: z.array(z.string()),
});

export type TokenCondition = z.infer<typeof tokenConditionSchema>;
const tokenConditionSchema = z.object({
  type: z.literal(WhereConditionDataType.TOKEN),
  dimension: z.string(),
  comparison: tokenCompareSchema,
});

const numericCompareSchema = z.object({
  type: z.literal(ComparisonType.NUMERIC),
  operator: z.nativeEnum(NumericCompareOperator),
  value: z.number(),
});

export type NumericCondition = z.infer<typeof numericConditionSchema>;
const numericConditionSchema = z.object({
  type: z.literal(WhereConditionDataType.NUMERIC),
  dimension: z.string(),
  comparison: numericCompareSchema,
});

const booleanCompareSchema = z.object({
  type: z.literal(ComparisonType.BOOLEAN),
  value: z.boolean(),
});

export type BooleanCondition = z.infer<typeof booleanConditionSchema>;
const booleanConditionSchema = z.object({
  type: z.literal(WhereConditionDataType.BOOLEAN),
  dimension: z.string(),
  comparison: booleanCompareSchema,
});

export type DateCondition = z.infer<typeof dateConditionSchema>;
const dateConditionSchema = z.object({
  type: z.literal(WhereConditionDataType.DATE),
  dimension: z.string(),
  comparison: timeframeSchema,
});

export type TokenListCondition = z.infer<typeof tokenListConditionSchema>;
const tokenListConditionSchema = z.object({
  type: z.literal(WhereConditionDataType.TOKEN_LIST),
  dimension: z.string(),
  comparison: listCompareSchema(z.string()),
});

const tokenHierarchyCompareSchema = z.object({
  type: z.literal(ComparisonType.TOKEN),
  operator: z.nativeEnum(TokenCompareOperators),
  prerequisiteValues: z.array(z.string()),
  values: z.array(z.string()),
});
export type TokenHierarchyCondition = z.infer<
  typeof tokenHierarchyConditionSchema
>;
export const tokenHierarchyConditionSchema = z.object({
  type: z.literal(WhereConditionDataType.TOKEN_HIERARCHY),
  dimension: z.string(),
  comparison: tokenHierarchyCompareSchema,
});

export type WhereCondition = z.infer<typeof whereConditionSchema>;
export const whereConditionSchema = z.discriminatedUnion("type", [
  tokenConditionSchema,
  numericConditionSchema,
  booleanConditionSchema,
  dateConditionSchema,
  tokenListConditionSchema,
  tokenHierarchyConditionSchema,
]);
