import { ShopifyAddress } from "@redotech/redo-model/order";
import { AxiosResponse } from "axios";
import { memo, useContext, useState } from "react";
import { Button, ButtonSize, ButtonTheme } from "../../button";
import { Card } from "../../card";
import { CurrencyContext } from "../../currency";
import { EditOrderModal } from "../../edit-order-modal";
import { Flex } from "../../flex";
import { Text } from "../../text";
export const ShippingInfoCard = memo(function ShippingInfoCard({
  order,
  orderEditingEnabled,
  reloadOrder,
  updateOrder,
  getLineItemInventory,
}: {
  order: any;
  orderEditingEnabled?: boolean;
  reloadOrder?: () => void;
  updateOrder?: (data: any) => Promise<AxiosResponse<any, any>>;
  getLineItemInventory?: (id: string) => Promise<number>;
}) {
  const { formatCurrency } = useContext(CurrencyContext);
  const saleTransactions =
    order.transactions?.filter((transaction: any) =>
      ["sale", "capture"].includes(transaction.kind),
    ) ?? [];

  // Example: str = "•••• •••• •••• 1234" returns "1234"
  const extractNumbers = (str: string) => {
    const numbers = str.match(/\d+$/);
    return numbers ? numbers[0] : null;
  };

  const [editModalOpen, setEditModalOpen] = useState(false);

  const isFulfilled = order.shopify.fulfillments?.length > 0;

  return (
    <Card gap="none">
      <Flex dir="column" gap="4xl">
        <Flex dir="column" gap="md">
          <Flex align="flex-end" dir="row" gap="md" justify="space-between">
            <Text fontSize="md" fontWeight="semibold">
              Contact information
            </Text>
            {!isFulfilled &&
              orderEditingEnabled &&
              updateOrder &&
              reloadOrder && (
                <Button
                  onClick={() => setEditModalOpen(true)}
                  size={ButtonSize.MICRO}
                  theme={ButtonTheme.PRIMARY}
                >
                  Edit
                </Button>
              )}
          </Flex>
          <Text fontSize="sm">{order.shopify.email}</Text>
        </Flex>
        {order.shopify.shipping_address && (
          <Address
            address={order.shopify.shipping_address}
            addressName="Shipping address"
          />
        )}
        <Flex dir="column" gap="md">
          <Text fontSize="md" fontWeight="semibold">
            Shipping method
          </Text>
          <Text fontSize="sm">
            {/* TODO: multiple shipping lines? Probably related to multiple fulfillments */}
            {order.shopify.shipping_lines[0]?.title}
          </Text>
        </Flex>
        <Flex dir="column" gap="md">
          <Text fontSize="md" fontWeight="bold">
            Payment method
          </Text>
          {saleTransactions.map((transaction: any, i: number) => (
            <Text fontSize="sm" key={i}>
              {transaction?.payment_method &&
              transaction?.payment_details?.credit_card_company &&
              transaction?.payment_details?.credit_card_number ? (
                <>
                  <span>
                    {transaction?.payment_details?.credit_card_company ??
                      "Unknown card"}{" "}
                    ending in{" "}
                    {extractNumbers(
                      transaction?.payment_details?.credit_card_number ?? "",
                    ) || "unknown numbers"}{" "}
                    -{" "}
                  </span>
                  <Text as="span" fontWeight="bold">
                    {formatCurrency(transaction.amount)}
                  </Text>
                </>
              ) : transaction?.gateway ? (
                <>
                  <span>
                    {`Made through ${transaction.gateway.replace(/(^|_)./g, (s: string) => s.replace("_", " ").toUpperCase())} - `}
                  </span>
                  <Text as="span" fontWeight="semibold">
                    {formatCurrency(transaction.amount)}
                  </Text>
                </>
              ) : (
                <Text fontWeight="semibold">
                  {formatCurrency(transaction.amount)}
                </Text>
              )}
            </Text>
          ))}
        </Flex>
        {order.shopify.billing_address && (
          <Address
            address={order.shopify.billing_address}
            addressName="Billing address"
          />
        )}
      </Flex>
      {!isFulfilled && updateOrder && reloadOrder && (
        <EditOrderModal
          getLineItemInventory={getLineItemInventory}
          onOrdersChange={reloadOrder}
          open={editModalOpen}
          order={order}
          setOpen={setEditModalOpen}
          updateOrder={updateOrder}
        />
      )}
    </Card>
  );
});

export const Address = memo(function Address({
  address,
  addressName,
}: {
  address: ShopifyAddress;
  addressName: string;
}) {
  return (
    <Flex dir="column" gap="md">
      <Text fontSize="md" fontWeight="semibold">
        {addressName}
      </Text>
      <Flex dir="column" fontSize="sm" gap="none">
        {address?.company && <div>{address.company}</div>}
        {address?.name && <div>{address.name}</div>}
        {address?.address1 && <div>{address.address1}</div>}
        {address?.address2 && <div>{address.address2}</div>}
        {(address?.city || address?.province || address?.zip) && (
          <div>
            {address.city}
            {address.province && `, ${address.province}`} {address.zip}
          </div>
        )}
        {address?.country && <div>{address.country}</div>}
        {address?.phone && <div>{address.phone}</div>}
      </Flex>
    </Flex>
  );
});
