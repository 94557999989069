// extracted by mini-css-extract-plugin
export var attachment = "_c3-a";
export var emailContainer = "_c3-4";
export var emailContentPaddedContainer = "_c3-b";
export var emailHeader = "_c3-6";
export var emailInfoIcon = "_c3-9";
export var emailShortenedSummary = "_c3-e";
export var infoDropdown = "_c3-0";
export var infoRowBoundedContent = "_c3-d";
export var internal = "_c3-5";
export var merchant = "_c3-8";
export var messageInputContainer = "_c3-h";
export var normal = "_c3-7";
export var replyCard = "_c3-f";
export var shadowDomWrapper = "_c3-3";
export var subtleButton = "_c3-1";
export var subtleButtonIconContainer = "_c3-2";
export var toggleViewRepliesButton = "_c3-c";
export var wrappingText = "_c3-g";