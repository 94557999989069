// extracted by mini-css-extract-plugin
export var actionButton = "_x-0";
export var actionButtons = "_x-1";
export var addIcon = "_x-1o";
export var addItem = "_x-21";
export var addressSpace = "_x-3a";
export var adjustment = "_x-r";
export var adjustmentBonus = "_x-24";
export var adjustmentFee = "_x-25";
export var animationContainer = "_x-2";
export var buttonBar = "_x-k";
export var buttonContent = "_x-4";
export var buttonContentPrintBarcodes = "_x-5";
export var buttonIcon = "_x-3";
export var card = "_x-6";
export var cardTitleContainer = "_x-7";
export var centerButtons = "_x-g";
export var checkbox = "_x-q";
export var chevron = "_x-11";
export var chevronDisabled = "_x-12";
export var clickAwayListener = "_x-2f";
export var clickable = "_x-p";
export var code = "_x-9";
export var column1 = "_x-a";
export var column2 = "_x-b";
export var container = "_x-c";
export var coverage = "_x-2p";
export var createLabelsImage = "_x-33";
export var deleteIconContainer = "_x-38";
export var editIcon = "_x-j";
export var editItem = "_x-h";
export var editNewItemsLink = "_x-1n";
export var emptyExchangeOrder = "_x-27";
export var error = "_x-w";
export var external = "_x-2o";
export var fraudRiskContainer = "_x-2z";
export var fullHeight = "_x-32";
export var fullWidth = "_x-31";
export var greenReturnAdjustment = "_x-28";
export var greenReturnImage = "_x-2c";
export var greenReturnSelectContainer = "_x-29";
export var greenReturnSelectTitle = "_x-2a";
export var greenReturnText = "_x-2b";
export var header = "_x-10";
export var headerSubtitle = "_x-m";
export var helpText = "_x-i";
export var icon = "_x-8";
export var item = "_x-15";
export var itemCard = "_x-34";
export var itemExtra = "_x-16";
export var itemHeader = "_x-1d";
export var itemImage = "_x-17";
export var itemIndexText = "_x-14";
export var itemMain = "_x-18";
export var itemOption = "_x-1j";
export var itemOptions = "_x-1g";
export var itemOptionsText = "_x-1h";
export var itemPrice = "_x-1m";
export var itemPriceContainer = "_x-1t";
export var itemPriceEdit = "_x-1l";
export var itemProperties = "_x-19";
export var itemProperty = "_x-1a";
export var itemPropertyName = "_x-1b";
export var itemPropertyValue = "_x-1c";
export var itemSearch = "_x-22";
export var itemSubtitle = "_x-1f";
export var itemSummaryLine = "_x-1v";
export var itemSummaryValue = "_x-1w";
export var itemTitle = "_x-1e";
export var itemTitleIcon = "_x-1i";
export var itemType = "_x-1k";
export var itemValue = "_x-1u";
export var menuTitle = "_x-1y";
export var modalButton = "_x-2d";
export var modalContent = "_x-2q";
export var modalFooterRight = "_x-2e";
export var modalImage = "_x-z";
export var modalProduct = "_x-o";
export var modalProductInfo = "_x-v";
export var modalProductInfoRight = "_x-x";
export var modalProductInfoRightBottom = "_x-y";
export var newAddress = "_x-13";
export var newItemHeader = "_x-26";
export var newItemsSection = "_x-39";
export var noConversations = "_x-d";
export var noTextWrap = "_x-35";
export var noTransition = "_x-u";
export var optionContent = "_x-1z";
export var optionTitle = "_x-1x";
export var orderLink = "_x-2l";
export var originalPrice = "_x-1p";
export var paddedModalContent = "_x-2r";
export var priceSummaryTitle = "_x-20";
export var processButtons = "_x-2s";
export var processItemSubheader = "_x-n";
export var product = "_x-2m";
export var restock = "_x-s";
export var restockSwitch = "_x-t";
export var returnTypeModal = "_x-2g";
export var returnTypeModalButton = "_x-2j";
export var returnTypeModalHeader = "_x-2i";
export var right = "_x-1q";
export var rightAligned = "_x-2h";
export var riskReportIframe = "_x-30";
export var selectItemsModalButtons = "_x-e";
export var selectItemsModalRightButtons = "_x-f";
export var status = "_x-2n";
export var subsection = "_x-2x";
export var summary = "_x-l";
export var summaryText = "_x-1r";
export var summaryTextLight = "_x-1s";
export var table = "_x-2k";
export var tableCell = "_x-2w";
export var tableDescription = "_x-2v";
export var tableHeader = "_x-2u";
export var tableHeaderGroup = "_x-2t";
export var tooltipLink = "_x-37";
export var trackingNumbers = "_x-2y";
export var trash = "_x-36";
export var voided = "_x-23";