import {
  AuthorizationField,
  credentialsFieldValueFormat,
} from "@redotech/http/semantics";
import { Currency } from "@redotech/money/currencies";
import type { ExchangeRates } from "@redotech/money/exchange-rates";
import { bearerCredentialsFormat } from "@redotech/oauth2/request";
import { TOKEN_KEY } from "@redotech/redo-merchant-app-common/auth";
import { REDO_API_URL } from "@redotech/redo-merchant-app-common/config";
import { ReturnAddress } from "@redotech/redo-model/return";
import type { Cart } from "@redotech/shopify-client/storefront.graphql";
import type { AxiosInstance, AxiosResponse } from "axios";
import axios, { AxiosHeaders } from "axios";

function widgetClient(widgetId: string): AxiosInstance {
  const baseURL = `${REDO_API_URL}/widgets/${widgetId}`;
  const headers = new AxiosHeaders();
  return axios.create({ baseURL, headers });
}

function client(): AxiosInstance {
  const headers = new AxiosHeaders();
  return axios.create({ baseURL: REDO_API_URL, headers });
}

const authentication = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (!token) {
    return {};
  }
  return {
    [String(AuthorizationField.name)]: credentialsFieldValueFormat.write(
      bearerCredentialsFormat.write(token),
    ),
  };
};

export async function getExchangeRates(): Promise<ExchangeRates> {
  const response = await client().get<ExchangeRates>("money/exchange-rates", {
    headers: authentication(),
  });
  return response.data;
}

function throwShopifyErrors<T extends {}>(
  response: AxiosResponse<T>,
): AxiosResponse<T> {
  if ("errors" in response.data) {
    if (
      typeof response.data.errors === "string" &&
      response.data.errors.length > 0
    ) {
      throw new Error(
        `Shopify API returned errors:\n- ${response.data.errors}`,
      );
    } else if (
      Array.isArray(response.data.errors) &&
      response.data.errors.length > 0
    ) {
      throw new Error(
        `Shopify API returned errors:\n- ${response.data.errors.map((e) => e.message).join("\n- ")}`,
      );
    }
  }
  return response;
}

export async function createCart({
  widgetId,
  cartParams,
}: {
  widgetId: string;
  cartParams: {
    storeUrl: string;
    deliveryAddress?: {
      province_code: string;
      country_code: string;
      city: string;
    };
    email?: string;
  };
}): Promise<{ id: string }> {
  return widgetClient(widgetId)
    .post<{
      data: { cartCreate: { cart: { id: string; checkoutUrl: string } } };
    }>("cart/create", { headers: authentication(), params: cartParams })
    .then(throwShopifyErrors)
    .then((res) => res.data.data.cartCreate.cart);
}

export async function getCart({
  widgetId,
  cartParams,
}: {
  widgetId: string;
  cartParams: { storeUrl: string; cartId: string };
}): Promise<Partial<Cart>> {
  return widgetClient(widgetId)
    .post<{ data: { cart: Partial<Cart> } }>("cart/get", {
      headers: authentication(),
      params: cartParams,
    })
    .then(throwShopifyErrors)
    .then((res) => res.data.data.cart);
}

export async function addItemsToCart({
  widgetId,
  params,
}: {
  widgetId: string;
  params: {
    storeUrl: string;
    cartId: string;
    lines: {
      variantId: number | string;
      properties?: Record<string, any>;
      quantity?: number;
    }[];
  };
}): Promise<{ id: string }> {
  return widgetClient(widgetId)
    .post<{ cartLinesAdd: { cart: { id: string } } }>("cart/add", {
      headers: authentication(),
      params,
    })
    .then(throwShopifyErrors)
    .then((res) => res.data.cartLinesAdd.cart);
}

export async function removeLinesFromCart({
  widgetId,
  params,
}: {
  widgetId: string;
  params: { storeUrl: string; cartId: string; lineIds: string[] };
}): Promise<{ id: string }> {
  return widgetClient(widgetId)
    .post<{ data: { cartLinesRemove: { cart: { id: string } } } }>(
      "cart/remove",
      { headers: authentication(), params },
    )
    .then(throwShopifyErrors)
    .then((res) => res.data.data.cartLinesRemove.cart);
}

export const getFulfillmentOrdersByOrder = (
  widgetId: string,
  shopifyOrderId: string,
) => {
  return widgetClient(widgetId).get(`fulfillment-orders/${shopifyOrderId}`, {
    headers: authentication(),
  });
};

/** Replaces the current discount codes */
export async function setDiscountCodesOfCart({
  widgetId,
  cartParams,
}: {
  widgetId: string;
  cartParams: {
    storeUrl: string;
    cartId: string;
    discountCodes: string[];
    automaticDiscounts: string[];
  };
}): Promise<{ id: string }> {
  return widgetClient(widgetId)
    .post<{ data: { cartDiscountCodesUpdate: { cart: { id: string } } } }>(
      "cart/discounts",
      { headers: authentication(), params: cartParams },
    )
    .then(throwShopifyErrors)
    .then((res) => res.data.data.cartDiscountCodesUpdate.cart);
}

export async function updateCartLineQuantity({
  widgetId,
  cartParams,
}: {
  widgetId: string;
  cartParams: {
    storeUrl: string;
    cartId: string;
    quantity: number;
    lineId: string;
  };
}): Promise<{ id: string }> {
  return widgetClient(widgetId)
    .post<{ cart: { id: string } }>("cart/updateQuantity", {
      headers: authentication(),
      params: { ...cartParams, itemId: cartParams.lineId },
    })
    .then(throwShopifyErrors)
    .then((res) => res.data.cart);
}

export const getProductTaxes = (
  widgetId: string,
  params: {
    products: { variantId: string; itemValue?: string }[];
    shippingAddress?: ReturnAddress;
    orderId: string;
  },
): Promise<{ data: { variantId: string; tax: number }[] }> => {
  return widgetClient(widgetId).post("products/taxes", {
    headers: authentication(),
    params,
  });
};

export async function getCurrencyExchangeRatesMerchantAPI(
  widgetId: string,
  baseCurrency?: string,
): Promise<AxiosResponse<Record<Currency, number>>> {
  return widgetClient(widgetId).get<Record<Currency, number>>(
    "currency-conversion-rates",
    { params: { baseCurrency }, headers: authentication() },
  );
}
