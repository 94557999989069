// extracted by mini-css-extract-plugin
export var alternate = "_42-h";
export var black = "_42-3";
export var blue = "_42-d";
export var blueLight = "_42-c";
export var brand = "_42-7";
export var error = "_42-9";
export var gray = "_42-6";
export var grayBlue = "_42-b";
export var iconWrapper = "_42-4";
export var indigo = "_42-e";
export var orange = "_42-g";
export var pink = "_42-f";
export var primary = "_42-0";
export var purple = "_42-8";
export var success = "_42-5";
export var warning = "_42-a";
export var white = "_42-1";
export var whiteSuccess = "_42-2";