import { ClickAwayListener } from "@mui/material";
import { genericMemo } from "@redotech/react-util/component";
import { useSearch } from "@redotech/react-util/search";
import Fuse from "fuse.js";
import { ReactNode, useMemo, useState } from "react";
import { RedoList, RedoListItem } from "./arbiter-components/list/redo-list";
import { RedoListItemSize } from "./arbiter-components/list/redo-list-item";
import { Divider } from "./divider";
import { Dropdown } from "./dropdown";
import { Flex } from "./flex";
import * as tagInputCss from "./item-dropdown-multi-select.module.css";

export type ItemWithDisplayString<T> = { item: T; displayString: string };

export const ItemDropdownMultiSelect = genericMemo(
  function ItemDropdownMultiSelect<T>({
    itemLabel,
    itemLabelPlural,
    openOnRender,
    dropdownAnchor,
    items,
    isItemSelected,
    onItemClick,
    children,
    onCreateItem,
    onMenuSelect,
    setClosedOnRender,
    setSearch,
    dbSearch,
  }: {
    itemLabel: string;
    itemLabelPlural?: string;
    openOnRender: boolean;
    dropdownAnchor: HTMLElement | null;
    items: ItemWithDisplayString<T>[];
    isItemSelected: (item: T) => boolean;
    onItemClick: (item: T) => void;
    children: (item: T) => ReactNode;
    onCreateItem?: () => void;
    onMenuSelect?: (item: T) => void;
    setClosedOnRender: () => void;
    setSearch?: (search: string) => void;
    dbSearch?: boolean;
  }) {
    const [searchText, setSearchText] = useState("");

    const searcher = useMemo(
      () =>
        new Fuse<ItemWithDisplayString<T>>([], {
          keys: ["displayString"],
          threshold: 0.3,
        }),
      [],
    );
    const filteredItems = useSearch(searcher, items, searchText);

    const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
    const [focusedIndex, setFocusedIndex] = useState<number | undefined>();

    const dropdownItems: RedoListItem<ItemWithDisplayString<T | "create">>[] =
      filteredItems.map((item) => ({
        value: item,
        ...(onMenuSelect && {
          menu: {
            onClick: () => {
              setClosedOnRender();
              onMenuSelect(item.item);
            },
          },
        }),
      }));

    const isDropdownItemSelected = (
      item: RedoListItem<ItemWithDisplayString<T>>,
    ) => isItemSelected(item.value.item);

    if (onCreateItem) {
      dropdownItems.push({
        value: { item: "create", displayString: `Create new ${itemLabel}` },
      });
    }

    const onDropdownItemClick = (
      item: RedoListItem<ItemWithDisplayString<T | "create">>,
    ) => {
      if (item.value.item === "create") {
        setClosedOnRender();
        onCreateItem?.();
      } else {
        onItemClick(item.value.item);
      }
    };

    const dropdownItemRenderer = (
      item: RedoListItem<ItemWithDisplayString<T | "create">>,
    ) => {
      if (item.value.item === "create") {
        return <div>Create new {itemLabel}</div>;
      }
      return children(item.value.item);
    };

    return (
      <ClickAwayListener
        onClickAway={() => {
          setClosedOnRender();
        }}
      >
        <Dropdown
          anchor={dropdownAnchor}
          fitToAnchor={false}
          open={openOnRender}
        >
          <Flex
            className={tagInputCss.dropdown}
            dir="column"
            gap="sm"
            onClick={(e) => e.stopPropagation()}
          >
            <input
              autoFocus
              className={tagInputCss.input}
              disabled={false}
              onChange={(event) => {
                setSearch?.(event.target.value);
                setSearchText(event.target.value);
              }}
              placeholder={
                itemLabelPlural
                  ? `Search ${itemLabelPlural}...`
                  : `Search ${itemLabel}s...`
              }
              ref={setInputRef}
              value={searchText}
            />
            <Divider />
            <div className={tagInputCss.list}>
              <RedoList
                focusedIndex={focusedIndex}
                isItemSelected={isDropdownItemSelected}
                items={dropdownItems}
                itemSelected={onDropdownItemClick}
                refToListenTo={inputRef}
                setFocusedIndex={setFocusedIndex}
                size={RedoListItemSize.SMALL}
              >
                {dropdownItemRenderer}
              </RedoList>
            </div>
          </Flex>
        </Dropdown>
      </ClickAwayListener>
    );
  },
);
