// extracted by mini-css-extract-plugin
export var animationContainer = "_9g-0";
export var conversationContainer = "_9g-1";
export var detailsContent = "_9g-2";
export var message = "_9g-3";
export var modalContent = "_9g-4";
export var modalFooter = "_9g-6";
export var modalHeader = "_9g-5";
export var name = "_9g-7";
export var optionContainer = "_9g-8";
export var optionTitle = "_9g-9";
export var pillContainer = "_9g-a";
export var selectedConversationsContainer = "_9g-b";
export var selectedOptionContainer = "_9g-c";