import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import { Provider } from "@redotech/redo-model/order";
import { Product, Return } from "@redotech/redo-model/return";
import { Team } from "@redotech/redo-model/team";
import {
  ButtonPlacement,
  RedoModal,
  RedoModalSize,
} from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import { assertNever } from "@redotech/util/type";
import { Fragment, memo, useMemo, useState } from "react";
import {
  ProductItemSummaryRow,
  ProductItemSummaryRowRestockSwitch,
} from "./common/product-item-summary-row";
import {
  getDefaultItemsToRestock,
  ItemRestockState,
  ItemRestockUpdateParams,
  setItemRestockUpdated,
} from "./common/util";
import * as restockItemsModalCss from "./restock-items-modal.module.css";

type HandleItemRestockUpdatedParams = { itemId: string; value: boolean };

const RestockItem = memo(function RestockItem({
  item,
  itemPrice,
  return_,
  itemRestock,
  handleItemRestockUpdated,
}: {
  item: Product;
  itemPrice: number;
  return_: Return;
  itemRestock: ItemRestockUpdateParams;
  handleItemRestockUpdated: (input: HandleItemRestockUpdatedParams) => void;
}) {
  const productItemSummaryRowRestockSwitch: ProductItemSummaryRowRestockSwitch =
    useMemo(() => {
      switch (itemRestock.itemRestockState) {
        case ItemRestockState.ABLE_TO_RESTOCK:
          return {
            itemRestockState: ItemRestockState.ABLE_TO_RESTOCK,
            value: itemRestock.value,
            setValue: (value: boolean) =>
              handleItemRestockUpdated({ itemId: item._id, value }),
          };
        case ItemRestockState.ALREADY_RESTOCKED:
          return { itemRestockState: ItemRestockState.ALREADY_RESTOCKED };
        case ItemRestockState.NOT_RESTOCKABLE:
          return { itemRestockState: ItemRestockState.NOT_RESTOCKABLE };
        default:
          assertNever(itemRestock);
      }
    }, [itemRestock, handleItemRestockUpdated, item._id]);

  return (
    <ProductItemSummaryRow
      item={item}
      refundAmount={itemPrice}
      restockSwitch={productItemSummaryRowRestockSwitch}
      return_={return_}
    />
  );
});

export const RestockItemsModal = memo(function RestockItemsModal({
  open,
  closeModal,
  return_,
  orderProvider,
  returnItemsToPrice,
  team,
}: {
  open: boolean;
  closeModal: (_reload?: boolean) => void;
  return_: Return;
  orderProvider: Provider;
  returnItemsToPrice: Record<string, number>;
  team: Team;
}) {
  const client = useRequiredContext(RedoMerchantRpcClientContext);

  const [itemsToRestock, setItemsToRestock] = useState<
    Record<string, ItemRestockUpdateParams>
  >(() => {
    return getDefaultItemsToRestock(return_, team, orderProvider);
  });

  const handleItemRestockUpdated = useHandler(
    ({ itemId, value }: HandleItemRestockUpdatedParams) => {
      setItemsToRestock((prevItemsToRestock) =>
        setItemRestockUpdated(prevItemsToRestock, itemId, value),
      );
    },
  );

  const handleRestockItemsClicked = useHandler(async () => {
    const _itemsToRestock = Object.entries(itemsToRestock).reduce<
      Record<string, boolean>
    >((acc, [itemId, itemRestockUpdateParams]) => {
      if (
        itemRestockUpdateParams.itemRestockState ===
        ItemRestockState.ABLE_TO_RESTOCK
      ) {
        acc[itemId] = itemRestockUpdateParams.value;
      }
      return acc;
    }, {});
    await client.restockItemsForReturn({
      returnId: return_._id,
      itemsToRestock: _itemsToRestock,
    });
    closeModal(true);
  });

  return (
    <RedoModal
      buttonPlacement={ButtonPlacement.TIGHT}
      contentScrollable
      footerBorder
      headerBorder
      isOpen={open}
      modalSize={RedoModalSize.SMALL}
      onModalCloseRequested={() => closeModal()}
      primaryButton={{ text: "Restock", onClick: handleRestockItemsClicked }}
      secondaryButton={{ text: "Cancel", onClick: () => closeModal() }}
      title="Restock items"
    >
      <Flex dir="column" pb="xs" pt="xs">
        {return_.products.map((item, index) => {
          return (
            <Fragment key={item._id}>
              {index !== 0 && (
                <div className={restockItemsModalCss.divider}>
                  <Divider />
                </div>
              )}
              <RestockItem
                handleItemRestockUpdated={handleItemRestockUpdated}
                item={item}
                itemPrice={returnItemsToPrice[item._id]}
                itemRestock={itemsToRestock[item._id]}
                return_={return_}
              />
            </Fragment>
          );
        })}
      </Flex>
    </RedoModal>
  );
});
