import { useRequiredContext } from "@redotech/react-util/context";
import { useTriggerLoad } from "@redotech/react-util/load";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { Condition as ModelCondition } from "@redotech/redo-model/return-flow/condition";
import { Autocomplete } from "@redotech/redo-web/autocomplete";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { useLayoutEffect, useState } from "react";

import { getShopifyCollections } from "@redotech/redo-merchant-app-common/client/shopify";
import { getCommentsoldCollections } from "../../../client/commensold";
import { ConditionType } from "../condition";

export const COLLECTION: ConditionType<string[], ModelCondition.Collection> = {
  name: "Collections",
  description(state) {
    return `Collections: ${state.join(", ")}`;
  },
  Details({ state, setState, disabled }) {
    const client = useRequiredContext(RedoMerchantClientContext);
    const [collectionsSearchString, setCollectionsSearchString] =
      useState<string>();
    const [collectionsLoading, setCollectionsLoading] = useState(false);
    const [collectionOptionsLoad, doCollectionsOptionsLoad] = useTriggerLoad(
      async (signal) => {
        let shopifyCollections: { id: string; name: string }[] = [];
        let csCollections: { id: number; name: string }[] = [];
        try {
          shopifyCollections = await getShopifyCollections(client, {
            searchString: collectionsSearchString,
            signal,
          });
        } catch (e) {
          console.error("failed to get shopify Collections");
        }

        try {
          csCollections = await getCommentsoldCollections(client, {
            searchString: collectionsSearchString,
            signal,
          });
        } catch (e) {
          console.error("failed to get commentsold collections");
        }
        setCollectionsLoading(false);
        const collections = [...shopifyCollections, ...csCollections];

        let collectionNames = collections.map(
          (collection: { id: string | number; name: string }) => {
            return collection.name;
          },
        );

        collectionNames = Array.from(new Set(collectionNames));

        return { collections: collectionNames };
      },
    );
    useLayoutEffect(() => {
      doCollectionsOptionsLoad();
    }, []);
    const onCollectionsInputChange = (event: any, value: string) => {
      setCollectionsSearchString(value);
      setCollectionsLoading(true);
      doCollectionsOptionsLoad();
    };
    return (
      <LabeledInput
        description="The first 100 collections are shown by default. To access more, search by title."
        label="Collections"
      >
        <Autocomplete
          disabled={disabled}
          filterOptions={(x) => x}
          getLabel={(collection) => collection}
          keyFn={(collection) => collection}
          multiple
          noOptionsText={collectionsLoading ? "Loading..." : "No options"}
          onInputChange={onCollectionsInputChange}
          options={collectionOptionsLoad.value?.collections || []}
          value={state}
          valueChange={setState}
        >
          {(collection) => collection}
        </Autocomplete>
      </LabeledInput>
    );
  },
  empty: [],
  fromModel(model) {
    return model.collections;
  },
  toModel(state) {
    return { type: ModelCondition.COLLECTION, collections: state };
  },
  valid() {
    return true;
  },
};
