import * as amplitude from "@amplitude/analytics-browser";
import { Currency } from "@redotech/money/currencies";
import { useRequiredContext } from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useTriggerLoad } from "@redotech/react-util/load";
import { RedoMerchantClient } from "@redotech/redo-merchant-app-common/client";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import {
  cancelOrder,
  getCalculatedRefundValue,
} from "@redotech/redo-merchant-app-common/client/shopify";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { Order } from "@redotech/redo-model/order";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { FormCheckbox } from "@redotech/redo-web/checkbox";
import { Divider } from "@redotech/redo-web/divider";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { Modal, ModalSize, PaddingAmount } from "@redotech/redo-web/modal";
import { FormRadioGroup, RadioGroupLayout } from "@redotech/redo-web/radio";
import { FormSelectDropdown } from "@redotech/redo-web/select-dropdown";
import { InputProvider, groupInput, input } from "@redotech/ui/form";
import { memo, useContext, useEffect, useState } from "react";
import * as cancelOrderModalCss from "./cancel-order-modal.module.css";

export const cancelShopifyOrder = async (
  client: RedoMerchantClient,
  order: Order,
  currency: string,
  refund: boolean,
  notify: boolean,
  restockLocationId?: number,
  reason?: "customer" | "inventory" | "fraud" | "declined" | "other",
) => {
  const data: {
    email: boolean;
    reason?: "customer" | "inventory" | "fraud" | "declined" | "other";
  } = { email: notify };
  if (reason) {
    data["reason"] = reason;
  }
  await cancelOrder(client, {
    shopifyOrderId: order.shopify.id,
    refund,
    currency,
    restockLocationId,
    data,
  });
};

export const CancelOrderModal = memo(function CancelOrderModal({
  open,
  setOpen,
  order,
  onOrdersChange,
}: {
  open: boolean;
  setOpen(value: boolean): void;
  order: Order;
  onOrdersChange: () => void;
}) {
  const team = useContext(TeamContext);
  const client = useRequiredContext(RedoMerchantClientContext);
  const [pending, setPending] = useState(false);
  const [locationId, setLocationId] = useState<number | undefined>(undefined);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [refundAmountLoad, doRefundAmountLoad] = useTriggerLoad(
    async (signal) => {
      const response = await getCalculatedRefundValue(client, {
        shopifyOrderId: order.shopify.id,
        currency:
          order.shopify.total_price_set?.presentment_money.currency_code,
        signal,
      });
      return response.refundAmount;
    },
  );

  const cancelForm = groupInput({
    refundMethod: input<string>(),
    reason: input<string>(),
    restock: input<boolean>(),
    notify: input<boolean>(),
  });

  type CancelValue = InputProvider.Value<typeof cancelForm>;

  const cancelFormDefault: CancelValue = {
    refundMethod: "now",
    reason: "other",
    restock: true,
    notify: true,
  };

  const cancelInput = useInput(cancelForm, cancelFormDefault);

  useEffect(() => {
    if (order) {
      doRefundAmountLoad();
    }
  }, [order]);

  useEffect(() => {
    if (team) {
      setLocationId(Number(team.returnLocationId) || undefined);
    }
  }, [team]);

  const handleCancelOrder = async () => {
    setPending(true);
    await cancelShopifyOrder(
      client,
      order,
      order.shopify.total_price_set?.presentment_money.currency_code,
      cancelInput.inputs.refundMethod.value === "now",
      cancelInput.inputs.notify.value,
      locationId,
      cancelInput.inputs.reason.value as
        | "customer"
        | "inventory"
        | "fraud"
        | "declined"
        | "other",
    );
    amplitude.logEvent("cancel-order", { shopifyOrderId: order.shopify_id });

    setPending(false);
    setOpen(false);
    onOrdersChange();
  };

  const footer = (
    <div className={cancelOrderModalCss.footer}>
      <Button onClick={() => setOpen(false)} theme={ButtonTheme.OUTLINED}>
        Keep order
      </Button>
      <Button
        onClick={() => setConfirmationModalOpen(true)}
        theme={ButtonTheme.DANGER}
      >
        Cancel order
      </Button>
    </div>
  );

  return (
    <>
      <Modal
        footer={pending || refundAmountLoad.pending ? undefined : footer}
        onClose={() => setOpen(false)}
        open={open}
        paddingAmount={PaddingAmount.NONE}
        showFooterBorder
        title="Cancel order"
      >
        {pending || refundAmountLoad.pending ? (
          <div className={cancelOrderModalCss.animationContainer}>
            <LoadingRedoAnimation />
          </div>
        ) : refundAmountLoad.value ? (
          <div className={cancelOrderModalCss.modalContent}>
            <div className={cancelOrderModalCss.orderName}>
              Order {order.shopify.name}
            </div>
            <div className={cancelOrderModalCss.lineItems}>
              {order.shopify.line_items.map((lineItem, index) => (
                <>
                  <div className={cancelOrderModalCss.lineItem} key={index}>
                    <div className={cancelOrderModalCss.lineItemInfo}>
                      <img
                        alt={lineItem.title}
                        className={cancelOrderModalCss.lineItemImage}
                        src={lineItem.image?.src}
                      />
                      <div className={cancelOrderModalCss.lineItemText}>
                        <div>{lineItem.title}</div>
                        <div className={cancelOrderModalCss.gray}>
                          {lineItem.variant_title
                            ? lineItem.variant_title + "  |  "
                            : ""}
                          {lineItem.quantity}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Divider />
                </>
              ))}
            </div>
            {Number(refundAmountLoad.value) > 0 && (
              <FormRadioGroup
                input={cancelInput.inputs.refundMethod}
                label="Refund payments"
                layout={RadioGroupLayout.VERTICAL}
                optionLabel={(option) =>
                  ({
                    now: (
                      <div className={cancelOrderModalCss.options}>
                        <div>Now</div>
                        <div className={cancelOrderModalCss.gray}>
                          Refund{" "}
                          {order.shopify.total_price_set?.presentment_money
                            .currency_code === Currency.USD
                            ? "$"
                            : ""}
                          {refundAmountLoad.value}{" "}
                          {
                            order.shopify.total_price_set?.presentment_money
                              .currency_code
                          }
                        </div>
                      </div>
                    ),
                    later: (
                      <div className={cancelOrderModalCss.options}>
                        <div>Later</div>
                        <div className={cancelOrderModalCss.gray}>
                          Your customer won't be refunded
                        </div>
                      </div>
                    ),
                  })[option]
                }
                options={["now", "later"]}
              />
            )}
            <FormSelectDropdown
              input={cancelInput.inputs.reason}
              label="Reason for cancellation"
              options={["customer", "inventory", "fraud", "declined", "other"]}
            >
              {(option) => {
                switch (option) {
                  case "customer":
                    return "Customer changed or canceled order";
                  case "inventory":
                    return "Item(s) unavailable";
                  case "fraud":
                    return "Fraudulent order";
                  case "declined":
                    return "Payment declined";
                  case "other":
                    return "Other";
                }
                return null;
              }}
            </FormSelectDropdown>
            <FormCheckbox input={cancelInput.inputs.restock}>
              Restock inventory
            </FormCheckbox>
            <FormCheckbox input={cancelInput.inputs.notify}>
              Send a notification to the customer
            </FormCheckbox>
          </div>
        ) : null}
      </Modal>
      <CancelOrderConfirmationModal
        handleCancelClick={handleCancelOrder}
        open={confirmationModalOpen}
        setOpen={setConfirmationModalOpen}
      />
    </>
  );
});

const CancelOrderConfirmationModal = memo(
  function CancelOrderConfirmationModal({
    open,
    setOpen,
    handleCancelClick,
  }: {
    open: boolean;
    setOpen(value: boolean): void;
    handleCancelClick: () => void;
  }) {
    return (
      <Modal
        onClose={() => setOpen(false)}
        open={open}
        showHeaderBorder={false}
        size={ModalSize.SMALL}
        title="Are you sure you want to cancel this order?"
      >
        <Button
          onClick={() => {
            handleCancelClick();
            setOpen(false);
          }}
          theme={ButtonTheme.DANGER}
        >
          Yes, cancel order
        </Button>
        <Button onClick={() => setOpen(false)} theme={ButtonTheme.OUTLINED}>
          No, don't cancel order
        </Button>
      </Modal>
    );
  },
);
