// extracted by mini-css-extract-plugin
export var container = "_2t-0";
export var error = "_2t-9";
export var label = "_2t-5";
export var light = "_2t-6";
export var md = "_2t-3";
export var sm = "_2t-2";
export var thin = "_2t-8";
export var thinBold = "_2t-7";
export var vertical = "_2t-4";
export var xs = "_2t-1";