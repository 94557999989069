export function camelToTitleCase(str: string) {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2") // camelCase to camel Case
    .replace(/^./, (s) => s.toUpperCase())
    .replace("-", " ")
    .trim();
}

// more generic version of camelToTitleCase
export function titleCase(str: string) {
  return str
    .split(/[-_]/)
    .map((s) => camelToTitleCase(s))
    .join(" ");
}
