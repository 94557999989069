// extracted by mini-css-extract-plugin
export var aboveInput = "_b7-0";
export var actionButton = "_b7-5";
export var actionButtonContainer = "_b7-1";
export var actionIcon = "_b7-6";
export var aiInfoContainer = "_b7-2";
export var attachmentWrapper = "_b7-7";
export var bold = "_b7-3";
export var checkIcon = "_b7-8";
export var clickingRecipientBar = "_b7-11";
export var commentThreadSwitch = "_b7-a";
export var draftImage = "_b7-b";
export var draftImageContainer = "_b7-c";
export var draftImages = "_b7-e";
export var editButton = "_b7-g";
export var editor = "_b7-f";
export var editorContainer = "_b7-y";
export var editorErrorTooltipWrapper = "_b7-9";
export var headerDivider = "_b7-h";
export var icon = "_b7-i";
export var info = "_b7-j";
export var internal = "_b7-n";
export var internalDivider = "_b7-k";
export var internalMessage = "_b7-l";
export var messageInputCard = "_b7-m";
export var messageInputForm = "_b7-o";
export var messageInputFormInternal = "_b7-p";
export var options = "_b7-q";
export var publicMessageCustomer = "_b7-s";
export var publicMessageMerchant = "_b7-r";
export var removeImageButton = "_b7-d";
export var replyButtons = "_b7-t";
export var showSignatureButton = "_b7-u";
export var spinner = "_b7-v";
export var verticalButtonDivider = "_b7-4";
export var visibilitySelector = "_b7-w";
export var warning = "_b7-x";
export var wrappingText = "_b7-z";
export var writingInternalNoteTopBorder = "_b7-10";