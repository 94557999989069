// extracted by mini-css-extract-plugin
export var addButton = "_5a-0";
export var border = "_5a-8";
export var check = "_5a-h";
export var danger = "_5a-6";
export var dropdownContainer = "_5a-1";
export var editModalBody = "_5a-j";
export var footerContainer = "_5a-i";
export var fullWidth = "_5a-a";
export var hide = "_5a-9";
export var icon = "_5a-3";
export var input = "_5a-7";
export var inputFlex = "_5a-b";
export var inputWrapper = "_5a-c";
export var manualFocus = "_5a-5";
export var optionButton = "_5a-4";
export var pillWrapper = "_5a-e";
export var pointer = "_5a-2";
export var showOverflow = "_5a-f";
export var tagsFlex = "_5a-d";
export var xButton = "_5a-g";