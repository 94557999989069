import {
  useLazyContext,
  useRequiredContext,
} from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useHandler } from "@redotech/react-util/hook";
import { LoadState, useLoad, useTriggerLoad } from "@redotech/react-util/load";
import type { Parcel } from "@redotech/redo-model/outbound-labels/parcel";
import {
  CarrierParcel,
  CustomParcelType,
  ParcelType,
} from "@redotech/redo-model/outbound-labels/parcel";
import {
  LengthUnit,
  WeightUnit,
} from "@redotech/redo-model/outbound-labels/util";
import { PillTheme } from "@redotech/redo-model/pill-theme";
import { ActionMenu, ActionMenuItem } from "@redotech/redo-web/action-menu";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
  RedoButtonTheme,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import Plus from "@redotech/redo-web/arbiter-icon/plus.svg";
import {
  Button,
  ButtonBorder,
  ButtonSize,
  ButtonTheme,
} from "@redotech/redo-web/button";
import { Card } from "@redotech/redo-web/card";
import { getCarrierLogoUrl } from "@redotech/redo-web/carrier/logo";
import {
  Checkbox,
  CheckboxTheme,
  FormCheckbox,
} from "@redotech/redo-web/checkbox";
import {
  ExpandableDropdown,
  ExpandableDropdownGroup,
} from "@redotech/redo-web/expandable-dropdown";
import * as gridCss from "@redotech/redo-web/grid.module.css";
import EditPencil from "@redotech/redo-web/icon-old/edit-pencil.svg";
import Star from "@redotech/redo-web/icon-old/star-thick.svg";
import ThreeDots from "@redotech/redo-web/icon-old/three-dots-vertical.svg";
import Trash from "@redotech/redo-web/icon-old/trash2.svg";
import { LabelTheme } from "@redotech/redo-web/labeled-input";
import { HeaderStyle, Modal } from "@redotech/redo-web/modal";
import { Pill, PillSize } from "@redotech/redo-web/pill";
import { FormRadioGroup } from "@redotech/redo-web/radio";
import { FormSelectDropdown } from "@redotech/redo-web/select-dropdown";
import { SkeletonText } from "@redotech/redo-web/skeleton";
import { Tabs } from "@redotech/redo-web/tab";
import { FormTextInput } from "@redotech/redo-web/text-input";
import {
  InputProvider,
  groupInput,
  input,
  listInput,
  nonEmptyValidator,
} from "@redotech/ui/form";
import {
  arrayEqual,
  nullableEqual,
  numberEqual,
  objectEqual,
  optionalEqual,
  stringEqual,
} from "@redotech/util/equal";
import * as classNames from "classnames";
import { memo, useEffect, useState } from "react";
import { RedoOutboundLabelsRpcClientContext } from "../outbound-labels-rpc-client";
import { ParcelContext } from "../services/parcel-service";
import * as parcelsCss from "./parcels-and-carriers.module.css";

const DEFAULT_PACKAGE_IMAGE = "https://assets.getredo.com/default-package.png";

namespace Carrier {
  export const USPS = "usps";
  export const UPS = "ups";
  export const FEDEX = "fedex";
  export const DHL = "dhlexpress";
}

type Carrier =
  | typeof Carrier.USPS
  | typeof Carrier.UPS
  | typeof Carrier.FEDEX
  | typeof Carrier.DHL;

const getCarrierDisplayName = (carrier: Carrier): string => {
  switch (carrier) {
    case Carrier.USPS:
      return "USPS";
    case Carrier.UPS:
      return "UPS";
    case Carrier.FEDEX:
      return "FedEx";
    case Carrier.DHL:
      return "DHL";
  }
};

namespace Actions {
  export const SET_AS_DEFAULT = "Set as default";
  export const EDIT = "Edit";
  export const DELETE = "Delete";
}

// Formatting "CamelCase" into "Camel Case"
export const formatParcelName = (input: string) => {
  return (
    input
      // insert a space between letter and number
      .replace(/([A-z])([0-9])/g, "$1 $2")

      // insert a space between lower & upper
      .replace(/([a-z])([A-Z])/g, "$1 $2")

      // space before last upper in a sequence followed by lower
      .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")

      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })

      // format FedEx correctly
      .replace("Fed Ex", "FedEx")
  );
};

export const formatDimensionString = (dimensionString: string) => {
  return dimensionString.includes("in")
    ? `${dimensionString.replaceAll("in", "")} in`
    : dimensionString;
};

export const getDimensionString = (parcel: CarrierParcel) => {
  return (parcel.dimensions || []).slice(1).reduce(
    (acc, dimension) => {
      return acc + `, ${formatDimensionString(dimension)}`;
    },
    formatDimensionString(parcel.dimensions?.[0] || ""),
  );
};

const enum ParcelTab {
  CUSTOM_PARCEL = "custom-parcel",
  CARRIER_PARCEL = "carrier-parcel",
}

const parcelTabs = [ParcelTab.CUSTOM_PARCEL, ParcelTab.CARRIER_PARCEL];

const ParcelTabLabel = memo(function ParcelTabLabel({
  tab,
}: {
  tab: ParcelTab;
}) {
  switch (tab) {
    case ParcelTab.CUSTOM_PARCEL:
      return <>Custom package</>;
    case ParcelTab.CARRIER_PARCEL:
      return <>Carrier package</>;
  }
});

export const parcelInput = groupInput({
  name: input<string>({ validator: nonEmptyValidator }),
  type: input<string>({ validator: nonEmptyValidator }),
  length: input<string>({ validator: nonEmptyValidator }),
  width: input<string>({ validator: nonEmptyValidator }),
  height: input<string>({ validator: nonEmptyValidator }),
  lengthUnit: input<string>({ validator: nonEmptyValidator }),
  weight: input<string>({ validator: nonEmptyValidator }),
  weightUnit: input<string>({ validator: nonEmptyValidator }),
  isDefault: input<boolean>(),
  carrierParcel: input<CarrierParcel | undefined>({
    equal: optionalEqual(
      objectEqual({
        parcelType: stringEqual,
        carrier: stringEqual,
        description: nullableEqual(stringEqual),
        dimensions: arrayEqual(stringEqual),
        human_readable: nullableEqual(stringEqual),
        max_weight: nullableEqual(numberEqual),
        name: stringEqual,
      }),
    ),
  }),
});

export type ParcelValue = InputProvider.Value<typeof parcelInput>;

export type ParcelForm = InputProvider.Form<typeof parcelInput>;

const firstParcelDefault = (): ParcelValue => ({
  name: "",
  type: CustomParcelType.BOX,
  length: "",
  width: "",
  height: "",
  lengthUnit: "in",
  weight: "0",
  weightUnit: "oz",
  isDefault: true,
  carrierParcel: undefined,
});

export const parcelDefault = (): ParcelValue => ({
  name: "",
  type: CustomParcelType.BOX,
  length: "",
  width: "",
  height: "",
  lengthUnit: "in",
  weight: "0",
  weightUnit: "oz",
  isDefault: false,
  carrierParcel: undefined,
});

const parcelsForm = listInput(
  () => parcelInput,
  parcelDefault,
  (value) => value.name,
);

export const parcelToParcelValue = (parcel: Parcel): ParcelValue => {
  if (!("carrier" in parcel)) {
    return {
      type: parcel.type,
      length: parcel.length.toString(),
      width: parcel.width.toString(),
      height: parcel.height.toString(),
      lengthUnit: parcel.lengthUnit,
      weight: parcel.weight.toString(),
      weightUnit: parcel.weightUnit,
      name: parcel.name,
      isDefault: false,
      carrierParcel: undefined,
    };
  } else {
    return {
      name: parcel.name,
      type: CustomParcelType.BOX,
      length: "0",
      width: "0",
      height: "0",
      lengthUnit: LengthUnit.INCH,
      weight: "0",
      weightUnit: WeightUnit.OUNCE,
      isDefault: false,
      carrierParcel: {
        carrier: parcel.carrier,
        description: parcel.description || "",
        dimensions: parcel.dimensions || [],
        human_readable: parcel.human_readable || "",
        max_weight: parcel.max_weight,
        name: parcel.name,
      } as CarrierParcel,
    };
  }
};

export const parcelValueToParcel = (value: ParcelValue): Parcel => {
  if (value.carrierParcel) {
    return {
      parcelType: ParcelType.CARRIER,
      carrier: value.carrierParcel.carrier,
      description: value.carrierParcel.description,
      dimensions: value.carrierParcel.dimensions,
      human_readable: value.carrierParcel.human_readable,
      max_weight: value.carrierParcel.max_weight,
      name: value.carrierParcel.name,
    };
  } else {
    return {
      parcelType: ParcelType.CUSTOM,
      name: value.name,
      type: value.type as CustomParcelType,
      length: +value.length,
      width: +value.width,
      height: +value.height,
      lengthUnit: value.lengthUnit as LengthUnit,
      weight: +value.weight,
      weightUnit: value.weightUnit as WeightUnit,
    };
  }
};

const getButtonText = (
  showSingleUse: boolean,
  existingInput: boolean,
  addToSaved: boolean,
): string => {
  if (showSingleUse) {
    if (addToSaved) {
      return "Add package and save";
    } else {
      return "Save";
    }
  } else {
    if (existingInput) {
      return "Update parcel";
    } else {
      return "Add package";
    }
  }
};

export const ParcelModal = memo(function ParcelModal({
  open,
  onSave,
  saveLoad,
  setOpen,
  input,
  showSingleUse = false,
  addToSaved = true,
  setAddToSaved,
  numParcels,
  carrierParcels,
}: {
  open: boolean;
  onSave: (parcel: ParcelValue) => void;
  saveLoad?: LoadState<void | undefined>;
  setOpen: (open: boolean) => void;
  input?: ParcelForm;
  showSingleUse?: boolean;
  addToSaved?: boolean;
  setAddToSaved?: (addToSaved: boolean) => void;
  numParcels?: number;
  carrierParcels?: CarrierParcel[];
}) {
  let carriers = new Set();
  if (carrierParcels) {
    carriers = carrierParcels.reduce((acc, parcel) => {
      acc.add(parcel.carrier);
      return acc;
    }, carriers);
  }
  const [carrierParcel, setCarrierParcel] = useState<
    CarrierParcel | undefined
  >();

  const getExpandableDropdown = (carrier: Carrier) => {
    const options = carrierParcels?.filter(
      (parcel) => parcel.carrier === carrier,
    );
    const logoUrl = getCarrierLogoUrl(carrier);
    const header = (
      <div className={parcelsCss.carrierHeader}>
        <img
          alt="carrier logo"
          className={parcelsCss.carrierLogo}
          src={logoUrl}
        />
        <div>{getCarrierDisplayName(carrier)} Package</div>
      </div>
    );

    return function ParcelModalDropdown(props: any) {
      return (
        <ExpandableDropdown
          header={header}
          options={options}
          value={carrierParcel}
          valueChange={setCarrierParcel}
          {...props}
        >
          {(option: CarrierParcel) => (
            <div>
              <div className={parcelsCss.carrierParcelName}>
                {formatParcelName(option.name)}
              </div>
              <div className={parcelsCss.carrierParcelDimensions}>
                {getDimensionString(option)}
              </div>
            </div>
          )}
        </ExpandableDropdown>
      );
    };
  };
  const newParcel = useInput(
    parcelInput,
    numParcels ? parcelDefault() : firstParcelDefault(),
  );
  const [buttonText, setButtonText] = useState<string>("Add package");
  const [currentTab, setCurrentTab] = useState<ParcelTab>(
    ParcelTab.CUSTOM_PARCEL,
  );
  const {
    name,
    type,
    length,
    width,
    height,
    lengthUnit,
    weight,
    weightUnit,
    isDefault,
  } = input?.inputs || newParcel.inputs;
  const allErrors = input?.allErrors || newParcel.allErrors;

  useEffect(() => {
    setButtonText(getButtonText(showSingleUse, !!input, addToSaved));
  }, [addToSaved, showSingleUse]);

  useEffect(() => {
    if (!addToSaved) {
      name.setValue("Single use package");
    }
  }, [addToSaved]);
  // Used for resetting if the customer clicks cancel
  const [originalParcelValue, setOriginalParcelValue] = useState(input?.value);
  useEffect(() => {
    if (!open) {
      return;
    }
    if (input) {
      setOriginalParcelValue(input?.value);
    }
    if (!numParcels) {
      newParcel.setValue(firstParcelDefault());
    } else {
      newParcel.setValue(parcelDefault());
    }
  }, [open]);

  const closeModal = () => {
    if (input && originalParcelValue) {
      input.setValue(originalParcelValue);
    }
    setCurrentTab(ParcelTab.CUSTOM_PARCEL);
    setCarrierParcel(undefined);
    setOpen(false);
  };

  const canSubmit =
    (currentTab === ParcelTab.CUSTOM_PARCEL && !allErrors.length) ||
    (currentTab === ParcelTab.CARRIER_PARCEL && !!carrierParcel);

  return (
    <Modal
      className={parcelsCss.modal}
      headerStyle={HeaderStyle.MEDIUM}
      onClose={closeModal}
      open={open}
      showHeaderBorder={false}
      title={input ? "Edit parcel" : "Add package"}
    >
      <Tabs
        // Hide tabs if editing existing parcel
        customTabCss={{
          width: "284px",
          visibility: input ? "hidden" : "visible",
          marginTop: input ? "-20px" : "0px",
        }}
        options={input ? [] : parcelTabs}
        tab={(option) => <ParcelTabLabel tab={option} />}
        value={currentTab as ParcelTab}
        valueChange={(tab) => setCurrentTab(tab)}
      >
        {currentTab === ParcelTab.CUSTOM_PARCEL && (
          <div className={parcelsCss.modalForm}>
            {addToSaved && (
              <FormTextInput
                input={name}
                label="Parcel name"
                labelTheme={LabelTheme.THIN}
                placeholder="Add package name"
                type="text"
              />
            )}
            <FormRadioGroup
              input={type}
              label="Parcel type"
              labelTheme={LabelTheme.THIN}
              optionLabel={(item) =>
                ({
                  box: "Box",
                  envelope: "Envelope",
                  soft_pack: "Soft package or satchel",
                })[item]
              }
              options={["box", "envelope", "soft_pack"]}
            />
            <div className={parcelsCss.editDimensions}>
              <FormTextInput
                input={length}
                label="Length"
                labelTheme={LabelTheme.THIN}
                placeholder=""
                type="number"
              />
              <FormTextInput
                input={width}
                label="Width"
                labelTheme={LabelTheme.THIN}
                placeholder=""
                type="number"
              />
              <FormTextInput
                input={height}
                label="Height"
                labelTheme={LabelTheme.THIN}
                placeholder=""
                type="number"
              />
              <div className={parcelsCss.editUnit}>
                <FormSelectDropdown
                  input={lengthUnit}
                  label=""
                  options={["in", "cm"]}
                >
                  {(option) => option}
                </FormSelectDropdown>
              </div>
            </div>
            <div className={parcelsCss.editWeight}>
              <FormTextInput
                input={weight}
                label="Weight when empty"
                labelTheme={LabelTheme.THIN}
                placeholder=""
                type="number"
              />
              <div className={parcelsCss.editUnit}>
                <FormSelectDropdown
                  input={weightUnit}
                  label=""
                  options={["oz", "lb", "g", "kg"]}
                >
                  {(option) => option}
                </FormSelectDropdown>
              </div>
            </div>
            {showSingleUse && setAddToSaved && (
              <div className={parcelsCss.checkboxContainer}>
                <Checkbox
                  onChange={() => {
                    setAddToSaved(!addToSaved);
                  }}
                  theme={CheckboxTheme.NORMAL}
                  value={addToSaved}
                />
                <div>
                  <div className={parcelsCss.checkboxText}>
                    Add to saved parcels
                  </div>
                  <div className={parcelsCss.checkboxSubtext}>
                    This will be added to your list of saved parcels for future
                    use
                  </div>
                </div>
              </div>
            )}
            {!input && !!numParcels && addToSaved && (
              <div>
                <FormCheckbox input={isDefault}>
                  <div>
                    <div className={parcelsCss.checkboxText}>
                      Use as default package
                    </div>
                  </div>
                </FormCheckbox>
                <div className={parcelsCss.checkboxSubtext}>
                  Used to calculate rates at checkout and pre-selected when
                  buying labels
                </div>
              </div>
            )}
          </div>
        )}

        {currentTab === ParcelTab.CARRIER_PARCEL && (
          <>
            {carrierParcels ? (
              <div className={parcelsCss.carrierParcelsForm}>
                <ExpandableDropdownGroup>
                  {getExpandableDropdown(Carrier.USPS)}
                  {getExpandableDropdown(Carrier.UPS)}
                  {getExpandableDropdown(Carrier.FEDEX)}
                  {getExpandableDropdown(Carrier.DHL)}
                </ExpandableDropdownGroup>
                <div>
                  <FormCheckbox input={isDefault}>
                    <div>
                      <div className={parcelsCss.checkboxText}>
                        Use as default package
                      </div>
                    </div>
                  </FormCheckbox>
                  <div className={parcelsCss.checkboxSubtext}>
                    Used to calculate rates at checkout and pre-selected when
                    buying labels
                  </div>
                </div>
              </div>
            ) : (
              <>Loading...</>
            )}
          </>
        )}
      </Tabs>

      <div className={parcelsCss.modalActions}>
        <Button
          border={ButtonBorder.LIGHT}
          className={parcelsCss.modalButton}
          onClick={closeModal}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          Cancel
        </Button>
        <Button
          className={parcelsCss.modalButton}
          disabled={!canSubmit}
          onClick={() => {
            let saveValue;
            if (currentTab === ParcelTab.CUSTOM_PARCEL) {
              saveValue = input ? input.value : newParcel.value;
            } else {
              saveValue = {
                name: carrierParcel?.name || "",
                type: CustomParcelType.BOX,
                length: "0",
                width: "0",
                height: "0",
                lengthUnit: "in",
                weight: "0",
                weightUnit: "oz",
                isDefault: isDefault.value ?? false,
                carrierParcel: carrierParcel,
              };
            }
            onSave(saveValue);
            newParcel.setValue(parcelDefault());
          }}
          pending={saveLoad?.pending}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.PRIMARY}
        >
          {buttonText}
        </Button>
      </div>
    </Modal>
  );
});

export const ParcelsPage = memo(function ParcelsPage() {
  const outboundLabelRpcClient = useRequiredContext(
    RedoOutboundLabelsRpcClientContext,
  );
  const [value, setValue] = useState<ParcelValue[] | undefined>();

  const [parcelsLoad, invalidateParcels] = useLazyContext(ParcelContext);

  const [saveLoad, doSave] = useTriggerLoad(async (signal) => {
    const parcels = (value || []).map((parcel) => {
      return parcel.carrierParcel
        ? {
            carrier: parcel.carrierParcel.carrier,
            description: parcel.carrierParcel.description,
            dimensions: parcel.carrierParcel.dimensions,
            human_readable: parcel.carrierParcel.human_readable,
            max_weight: parcel.carrierParcel.max_weight,
            name: parcel.name,
            parcelType: ParcelType.CARRIER as any,
            default: parcel.isDefault,
          }
        : {
            name: parcel.name,
            type: parcel.type as CustomParcelType,
            parcelType: ParcelType.CUSTOM as any,
            length: parseFloat(parcel.length),
            width: parseFloat(parcel.width),
            height: parseFloat(parcel.height),
            lengthUnit: parcel.lengthUnit as LengthUnit,
            weight: parseFloat(parcel.weight),
            weightUnit: parcel.weightUnit as WeightUnit,
            default: parcel.isDefault,
          };
    });

    await outboundLabelRpcClient.upsertParcels({ parcels }, { signal });
    invalidateParcels();
  });

  const handleSave = useHandler((value: ParcelValue[]) => {
    setValue(value);
    doSave();
  });

  useEffect(() => {
    if (!parcelsLoad.value) {
      return;
    }
    const value = [
      ...(parcelsLoad.value || []).map((parcel) => {
        if (parcel.parcelType === ParcelType.CUSTOM) {
          return {
            type: parcel.type,
            length: parcel.length.toString(),
            width: parcel.width.toString(),
            height: parcel.height.toString(),
            lengthUnit: parcel.lengthUnit,
            weight: parcel.weight.toString(),
            weightUnit: parcel.weightUnit,
            name: parcel.name,
            isDefault: parcel.default ?? false,
            carrierParcel: undefined,
          };
        } else {
          return {
            name: parcel.name,
            type: CustomParcelType.BOX,
            length: "0",
            width: "0",
            height: "0",
            lengthUnit: LengthUnit.INCH,
            weight: "0".toString(),
            weightUnit: WeightUnit.OUNCE,
            isDefault: parcel.default ?? false,
            carrierParcel: {
              carrier: parcel.carrier,
              description: parcel.description || "",
              dimensions: parcel.dimensions || [],
              human_readable: parcel.human_readable || "",
              max_weight: parcel.max_weight,
              name: parcel.name,
              parcelType: ParcelType.CARRIER,
            } as CarrierParcel,
          };
        }
      }),
    ];
    setValue(value);
  }, [parcelsLoad]);

  return (
    <div className={classNames(gridCss.grid, parcelsCss.container)}>
      <div className={classNames(gridCss.span12, gridCss.span6M)}>
        <Card className={parcelsCss.mainCard} title="Saved packages">
          {value ? (
            <ParcelsList
              onSave={handleSave}
              saveLoad={saveLoad}
              value={value}
            />
          ) : (
            <>
              <SkeletonText length={30} />
              <SkeletonText length={30} />
            </>
          )}
        </Card>
      </div>
    </div>
  );
});

const Parcel = memo(function Parcel({
  parcel,
  setDefaultParcel,
  deleteParcel,
  numParcels,
}: {
  parcel: ParcelForm;
  setDefaultParcel: (parcel: ParcelValue) => void;
  deleteParcel: (parcel: ParcelValue) => void;
  numParcels: number;
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [actionMenuOpen, setActionMenuOpen] = useState(false);
  const actionMenuItems: ActionMenuItem[] = [
    {
      text: Actions.SET_AS_DEFAULT,
      id: Actions.SET_AS_DEFAULT,
      Icon: (props) => <Star stroke="#737373" {...props} />,
      onClick: () => {
        setDefaultParcel(parcel.value);
        setActionMenuOpen(false);
      },
    },
    {
      text: Actions.EDIT,
      id: Actions.EDIT,
      Icon: (props) => <EditPencil stroke="#737373" {...props} />,
      onClick: () => {
        setModalOpen(true);
        setActionMenuOpen(false);
      },
    },
    {
      text: Actions.DELETE,
      id: Actions.DELETE,
      textColor: "#D92D20",
      Icon: (props) => <Trash {...props} />,
      onClick: () => {
        deleteParcel(parcel.value);
        setActionMenuOpen(false);
      },
    },
  ];
  const availableActions = actionMenuItems.filter((item) => {
    switch (item.id) {
      case Actions.SET_AS_DEFAULT:
        return !parcel.value.isDefault;
      case Actions.EDIT:
        return !parcel.value.carrierParcel;
      default:
        return true;
    }
  });

  const imageUrl = parcel.value.carrierParcel
    ? getCarrierLogoUrl(parcel.value.carrierParcel.carrier)
    : DEFAULT_PACKAGE_IMAGE;
  const displayName = parcel.value.carrierParcel
    ? formatParcelName(parcel.value.carrierParcel.name)
    : parcel.value.name;
  const displayDimensions = parcel.value.carrierParcel
    ? getDimensionString(parcel.value.carrierParcel)
    : `${parcel.value.length} × ${parcel.value.width} × ${parcel.value.height} ${parcel.value.lengthUnit}, ${parcel.value.weight} ${parcel.value.weightUnit}`;
  return (
    <div className={parcelsCss.parcel}>
      <div className={parcelsCss.mainInfo}>
        <img alt="package" className={parcelsCss.image} src={imageUrl} />
        <div className={parcelsCss.text}>
          <div className={parcelsCss.name}>{displayName}</div>
          <div className={parcelsCss.dimensions}>{displayDimensions}</div>
        </div>
      </div>
      {parcel.value.isDefault && (
        <div className={parcelsCss.pillContainer}>
          <Pill size={PillSize.SMALL} theme={PillTheme.PRIMARY_LIGHT}>
            Default
          </Pill>
        </div>
      )}
      <div className={parcelsCss.actions}>
        <ActionMenu
          items={availableActions}
          open={actionMenuOpen}
          right={52}
          setOpen={setActionMenuOpen}
          top={32}
          width={187}
        />
        <div
          className={parcelsCss.actionsButton}
          onClick={() => setActionMenuOpen(true)}
        >
          <ThreeDots
            className={parcelsCss.actionsIcon}
            stroke="#A3A3A3"
            strokeWidth="1.66667"
          />
        </div>
      </div>
      <ParcelModal
        input={parcel}
        numParcels={numParcels}
        onSave={(updatedParcel) => {
          parcel?.setValue(updatedParcel);
          setModalOpen(false);
        }}
        open={modalOpen}
        setOpen={setModalOpen}
      />
    </div>
  );
});

const ParcelsList = memo(function ParcelsList({
  onSave,
  saveLoad,
  value,
}: {
  onSave: (value: ParcelValue[]) => void;
  saveLoad: LoadState<void>;
  value: ParcelValue[];
}) {
  const outboundLabelRpcClient = useRequiredContext(
    RedoOutboundLabelsRpcClientContext,
  );

  const carrierParcelsLoad = useLoad(
    async (signal) => {
      const { carrierParcels } = await outboundLabelRpcClient.getCarrierParcels(
        null,
        { signal },
      );
      return carrierParcels;
    },
    [outboundLabelRpcClient],
  );

  const input = useInput(parcelsForm, value);
  const [modalOpen, setModalOpen] = useState(false);
  const deleteParcel = useHandler((parcel: ParcelValue) => {
    const reassignDefault = parcel.isDefault;
    if (reassignDefault) {
      input.setValue(
        input.value
          .filter((p) => p !== parcel)
          .map((p, index) => ({ ...p, isDefault: index === 0 })),
      );
    } else {
      input.setValue(input.value.filter((p) => p !== parcel));
    }
  });
  const setDefaultParcel = useHandler((parcel: ParcelValue) => {
    input.setValue(input.value.map((p) => ({ ...p, isDefault: p === parcel })));
  });

  const saveDisabled = input.allErrors.length > 0 || !input.changed;

  return (
    <>
      <div className={parcelsCss.parcels}>
        {input.inputs
          .sort((a, b) => (a.value.isDefault ? -1 : 1))
          .map((parcel, index) => (
            <Parcel
              deleteParcel={deleteParcel}
              key={index}
              numParcels={input.value.length}
              parcel={parcel}
              setDefaultParcel={setDefaultParcel}
            />
          ))}
      </div>
      <div className={parcelsCss.buttons}>
        <RedoButton
          hierarchy={RedoButtonHierarchy.SECONDARY}
          IconLeading={Plus}
          onClick={() => {
            setModalOpen(true);
          }}
          size={RedoButtonSize.LARGE}
          text="Add package"
          theme={RedoButtonTheme.NORMAL}
        />
        <RedoButton
          disabled={saveDisabled}
          hierarchy={RedoButtonHierarchy.PRIMARY}
          onClick={() => {
            const { value } = input;
            onSave(value);
          }}
          pending={saveLoad.pending}
          size={RedoButtonSize.LARGE}
          text="Save"
          theme={RedoButtonTheme.NORMAL}
        />
      </div>
      <ParcelModal
        carrierParcels={carrierParcelsLoad.value as CarrierParcel[]}
        numParcels={input.value.length}
        onSave={(parcel) => {
          const setNewDefault = parcel.isDefault;
          // If the new parcel is being added as the default, ensure none of the other parcels are default
          input.setValue([
            ...input.value.map((existingParcel) => {
              return {
                ...existingParcel,
                isDefault: setNewDefault ? false : existingParcel.isDefault,
              };
            }),
            parcel,
          ]);
          setModalOpen(false);
        }}
        open={modalOpen}
        setOpen={setModalOpen}
      />
    </>
  );
});
