import { z } from "zod";
import { Coverage } from "./coverage";
import { Parcel, ShipmentRatesOld } from "./outbound-labels";
import {
  InputAnswer,
  MultipleChoiceAnswer,
  ReturnableItem,
} from "./return-flow";
import { Label, Rate } from "./shipment";
import { OrderFulfillmentStatusEnum, OriginAddress } from "./shopify-order";
import { Team } from "./team";
import { TimelineEvent } from "./timeline";
import { ExternalShipmentTracker, ITrackable } from "./trackable";

export interface DiscountCode {
  code: string;
  amount: string;
  type: string;
}

export interface Order extends ITrackable {
  coverage: Coverage;
  protected: boolean;
  redoPrice?: string;
  packageProtected: boolean;
  finalSaleReturnsProtected: boolean;
  credits: number;
  isExchangeOrder: boolean;
  coverageEnabled: boolean;
  _id: string;
  shopify_id: string;
  team: string;
  createdAt: string;
  customer_name: string;
  lineItemsFulfillment: any[];
  lineItems?: any[];
  stalledInFullfillment?: boolean;
  discount?: {
    id: string;
    description: string;
    code: string;
    expirationDateTime: string;
  };
  shopify: {
    id: number;
    line_items: LineItem[];
    fulfillments: Fulfillment[];
    [key: string]: any;
    total_price: string;
    total_tax: string;
    financial_status: string;
    discount_codes: DiscountCode[];
    taxes_included: boolean;
    fulfillment_status:
      | z.infer<typeof OrderFulfillmentStatusEnum>
      | null
      | undefined;
    cancelled_at: string | null | undefined;
    total_shipping_price_set: {
      shop_money: { amount: string };
      presentment_money: { amount: string };
    };
  };
  shopifyCreatedAt: string;
  shopifyUpdatedAt: string;
  timeline: TimelineEvent[];
  updatedAt: string;
  originItems?: OriginItem[];
  currentEmailFlows?: {
    emailFlowId: string;
    currentStep: number;
    continueDate: string;
    fulfillmentId?: string;
  }[];
  trackers: { _tracker: ExternalShipmentTracker; fulfillmentID: string }[];
  trackingAnalytics: {
    email: SesEvent[];
    page: {
      url: string;
      eventType: "ad" | "upsell";
      image?: string;
      createdAt: string;
    }[];
  };
  trackingTextsSent?: { sid: string; mms: boolean; sentAt: string }[];
  trackingEmailsSent?: {
    emailId: string;
    status?: string;
    sentAt: string;
    s3URL?: string;
    trigger?: string;
    trackingCode?: string;
  }[];
  trackingBillingStatus?: "billed" | "free";
  yofiScores?: {
    return_abuse_score?: {
      prediction_name: string;
      prediction_value: YofiRiskLevels;
      justification: string;
      indicators: [{ name: string; is_risk: boolean }];
    };
    bot_abuse_score?: {
      prediction_name: string;
      prediction_value: YofiRiskLevels;
      justification: string;
      indicators: [{ name: string; is_risk: boolean }];
    };
    discount_abuse_score?: {
      prediction_name: string;
      prediction_value: YofiRiskLevels;
      justification: string;
      indicators: [{ name: string; is_risk: boolean }];
    };
    resell_abuse_score?: {
      prediction_name: string;
      prediction_value: YofiRiskLevels;
      justification: string;
      indicators: [{ name: string; is_risk: boolean }];
    };
  };
  customerYofiScores?: {
    return_abuse_score?: {
      prediction_name: string;
      prediction_value: YofiRiskLevels;
      justification: string;
      indicators: [{ name: string; is_risk: boolean }];
    };
  };
  shipmentRates?: ShipmentRatesOld;
  parcels?: Parcel[];
  labels?: { label: Label; rate: Rate; printed: boolean }[];
  provider?: Provider;
}

export interface OrderWithReturnableItems extends Order {
  returnableItems: ReturnableItem[];
  line_items?: LineItem[];
}

export enum Provider {
  COMMENTSOLD = "commentsold",
  SHOPIFY = "shopify",
  COMMERCE_CLOUD = "commerce-cloud",
  BIGCOMMERCE = "bigcommerce",
  MANUAL = "manual",
}

export enum YofiRiskLevels {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
}

export interface OrdersFacets {
  total: number;
}

export interface TaxLine {
  price: string;
  rate: number;
  title: string;
}

export interface LineItem {
  id: number;
  product_id: number | null;
  sku: string;
  properties: { name: string; value: string }[];
  title: string;
  variant_title: string;
  variant_id: number;
  [key: string]: any;
  tax_lines: TaxLine[];
  discount_price: string | null;
  greenReturn?: boolean;
  inputAnswers?: InputAnswer[];
  merchantAddress?: any;
  multipleChoiceAnswers?: MultipleChoiceAnswer[];
  reason: string | null;
  selectedReturnType?: string;
  tags?: string[];
}

export interface OriginItem {
  unitPrice: string;
  preDiscountPrice?: string;
  currency: string;
  tax: string;
  originVariantId: number | string;
  newVariantId: number | string;
  applicableShopifyOrder?: number;
}

export interface Tracker {
  tracking_code: string;
  carrier: string;
  status: string;
  est_delivery_date: string;
  public_url?: string;
  created_at: string;
  updated_at: string;
  tracking_details: { message: string; status: string; datetime: string }[];
}

export interface Fulfillment {
  id: number;
  name: string;
  status: string;
  tracking_company: string | null;
  tracking_url: string | null;
  shipment_status: string | null;
  // If multiple tracking numbers are set on this fulfillment, only the first one will be returned in the tracking_number field.
  tracking_number: string;
  tracking_numbers: string[];
  tracking_urls: string[];
  line_items: LineItem[];
  created_at: string;
  updated_at?: string;
  location_id?: string;
  origin_address?: OriginAddress;
}

export interface ShopifyAddress {
  name?: string;
  first_name?: string;
  last_name?: string;
  address1?: string;
  address2?: string;
  city?: string;
  province?: string;
  province_code?: string;
  country?: string;
  country_code?: string;
  zip?: string;
  phone?: string;
  company?: string;
  latitude?: number;
  longitude?: number;
}

export class ExchangeOrderFormat {
  constructor(private readonly settings: Team["settings"]["exchanges"]) {}

  getName(original: string) {
    if (this.settings.orderName.useShopifyGeneratedName) {
      return null;
    }

    // Strip # from original attribute
    // EX: Manly Bands Mercahnt
    if (this.settings.shouldStripHash) {
      original = original.replace("#", "");
    }

    if (this.settings.suffix !== null) {
      return this.settings.suffix
        ? `${original}-${this.settings.suffix}`
        : original;
    }

    return (
      this.settings.orderName.prefix + original + this.settings.orderName.suffix
    );
  }

  isName(name: string) {
    if (this.settings.suffix !== null) {
      return (
        !!this.settings.suffix && name.endsWith(`-${this.settings.suffix}`)
      );
    }
    return (
      (this.settings.orderName.prefix || this.settings.orderName.suffix) &&
      name.startsWith(this.settings.orderName.prefix) &&
      name.endsWith(this.settings.orderName.suffix)
    );
  }
}

export const isRedoLineItem = (lineItem: LineItem | undefined) => {
  if (
    /^redo$|^re:do$/i.test(lineItem?.vendor) || // milwaukeemotorcycleclothing changed the vendor :/
    /route-/i.test(lineItem?.sku || "") ||
    /routeins/i.test(lineItem?.sku || "") ||
    /insurance|package\s+protection|order\s+protection/i.test(lineItem?.name)
  ) {
    return true;
  }
  return false;
};

export const getNumNonRedoItemsInOrders = (orders: Order[]) => {
  // Exclude Redo items
  return orders.reduce((acc, order) => {
    return (
      acc +
      order.shopify.line_items
        ?.filter((lineItem) => !isRedoLineItem(lineItem))
        .reduce((acc, lineItem) => acc + lineItem.quantity, 0)
    );
  }, 0);
};

export enum SesEventType {
  Bounce = "Bounce",
  Complaint = "Complaint",
  Delivery = "Delivery",
  Send = "Send",
  Open = "Open",
  Click = "Click",
  Subscription = "Subscription",
}

const SesMailSchema = z.object({
  triggerType: z.string().nullish(),
  timestamp: z.string(),
  source: z.string().optional(),
  messageId: z.string(),
  destination: z.array(z.string()).optional(),
  tags: z.record(z.array(z.string())).optional(),
});

const SesBounceEventSchema = z.object({
  eventType: z.literal(SesEventType.Bounce),
  mail: SesMailSchema,
  bounce: z.object({
    bounceType: z.string(),
    bounceSubType: z.string(),
    bouncedRecipients: z.array(
      z.object({
        emailAddress: z.string(),
        status: z.string().optional(),
        action: z.string().optional(),
        diagnosticCode: z.string().optional(),
      }),
    ),
    timestamp: z.string(),
    feedbackId: z.string(),
  }),
});

const SesComplaintEventSchema = z.object({
  eventType: z.literal(SesEventType.Complaint),
  mail: SesMailSchema,
  complaint: z.object({
    complainedRecipients: z.array(z.object({ emailAddress: z.string() })),
    timestamp: z.string(),
    feedbackId: z.string(),
    userAgent: z.string().optional(),
    complaintFeedbackType: z.string().optional(),
  }),
});

const SesDeliveryEventSchema = z.object({
  eventType: z.literal(SesEventType.Delivery),
  mail: SesMailSchema,
  delivery: z.object({
    timestamp: z.string(),
    recipients: z.array(z.string()),
  }),
});

const SesSendEventSchema = z.object({
  eventType: z.literal(SesEventType.Send),
  mail: SesMailSchema,
});

const SesOpenEventSchema = z.object({
  eventType: z.literal(SesEventType.Open),
  mail: SesMailSchema,
  open: z.object({
    timestamp: z.string(),
    ipAddress: z.string(),
    userAgent: z.string(),
  }),
});

const SesClickEventSchema = z.object({
  eventType: z.literal(SesEventType.Click),
  mail: SesMailSchema,
  click: z.object({
    timestamp: z.string(),
    link: z.string(),
    userAgent: z.string(),
    ipAddress: z.string(),
  }),
});

const SesSubscriptionEventSchema = z.object({
  eventType: z.literal(SesEventType.Subscription),
  mail: SesMailSchema,
  subscription: z.object({
    contactList: z.string(),
    timestamp: z.string(),
    source: z.string(),
    newTopicPreferences: z.object({
      unsubscribeAll: z.boolean(),
      topicSubscriptionStatus: z.array(
        z.object({ topicName: z.string(), subscriptionStatus: z.string() }),
      ),
    }),
    oldTopicPreferences: z.object({
      unsubscribeAll: z.boolean(),
      topicSubscriptionStatus: z.array(
        z.object({ topicName: z.string(), subscriptionStatus: z.string() }),
      ),
    }),
  }),
});

export const SesEventSchema = z.discriminatedUnion("eventType", [
  SesBounceEventSchema,
  SesComplaintEventSchema,
  SesDeliveryEventSchema,
  SesSendEventSchema,
  SesOpenEventSchema,
  SesClickEventSchema,
  SesSubscriptionEventSchema,
]);

export type SesEvent = z.infer<typeof SesEventSchema>;
