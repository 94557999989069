// extracted by mini-css-extract-plugin
export var chip = "_2p-9";
export var chipLabel = "_2p-a";
export var close = "_2p-7";
export var closeIcon = "_2p-8";
export var error = "_2p-6";
export var infoTooltip = "_2p-2";
export var innerContainer = "_2p-1";
export var input = "_2p-b";
export var medium = "_2p-5";
export var outerContainer = "_2p-0";
export var small = "_2p-4";
export var xSmall = "_2p-3";