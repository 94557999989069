// extracted by mini-css-extract-plugin
export var defaultWrapper = "_ab-3";
export var downloadButton = "_ab-4";
export var fileContainer = "_ab-7";
export var fileInfo = "_ab-5";
export var fileInfoWidthRestricted = "_ab-6";
export var fileType = "_ab-1";
export var fileTypeName = "_ab-2";
export var image = "_ab-0";
export var link = "_ab-9";
export var overflowEllipsis = "_ab-8";