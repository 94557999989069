// extracted by mini-css-extract-plugin
export var grid = "_g-0";
export var span1 = "_g-2";
export var span10 = "_g-b";
export var span10L = "_g-z";
export var span10M = "_g-n";
export var span10Xl = "_g-1b";
export var span11 = "_g-c";
export var span11L = "_g-10";
export var span11M = "_g-o";
export var span11Xl = "_g-1c";
export var span12 = "_g-d";
export var span12L = "_g-11";
export var span12M = "_g-p";
export var span12Xl = "_g-1d";
export var span1L = "_g-q";
export var span1M = "_g-e";
export var span1Xl = "_g-12";
export var span2 = "_g-3";
export var span2L = "_g-r";
export var span2M = "_g-f";
export var span2Xl = "_g-13";
export var span3 = "_g-4";
export var span3L = "_g-s";
export var span3M = "_g-g";
export var span3Xl = "_g-14";
export var span4 = "_g-5";
export var span4L = "_g-t";
export var span4M = "_g-h";
export var span4Xl = "_g-15";
export var span5 = "_g-6";
export var span5L = "_g-u";
export var span5M = "_g-i";
export var span5Xl = "_g-16";
export var span6 = "_g-7";
export var span6L = "_g-v";
export var span6M = "_g-j";
export var span6Xl = "_g-17";
export var span7 = "_g-8";
export var span7L = "_g-w";
export var span7M = "_g-k";
export var span7Xl = "_g-18";
export var span8 = "_g-9";
export var span8L = "_g-x";
export var span8M = "_g-l";
export var span8Xl = "_g-19";
export var span9 = "_g-a";
export var span9L = "_g-y";
export var span9M = "_g-m";
export var span9Xl = "_g-1a";
export var stretch = "_g-1";