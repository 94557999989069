import { genericMemo } from "@redotech/react-util/component";
import { useHandler } from "@redotech/react-util/hook";
import { ArrowDown, ArrowUp, EnterKey } from "@redotech/web-util/key";
import * as React from "react";
import { Dispatch, ReactNode, SetStateAction, useEffect, useMemo } from "react";
import { Flex } from "../../flex";
import { Spinner } from "../../spinner";
import { Text } from "../../text";
import { PaddingProps, paddingPropsKeys, SpacingValue } from "../../theme/box";
import * as redoDropdownInputCss from "../select-dropdown/redo-dropdown-input.module.css";
import {
  RedoListItem,
  RedoListItemMenu,
  RedoListItemSize,
  RedoListItemVariant,
} from "./redo-list-item";
import * as redoListCss from "./redo-list.module.css";

export interface RedoListItem<T> {
  label?: ReactNode;
  id?: string;
  value: T;
  menu?: RedoListItemMenu;
  suffix?: ReactNode;
}

export enum RedoListItemSelectedSource {
  Keyboard = "keyboard",
  Mouse = "mouse",
}

/**
 * @param refToListenTo -- this can be an item like a button or an input.
 * After the button is clicked or the input receives focus,
 * keyboard navigation will be enabled for the list.
 */
export interface RedoListProps<T> {
  items: RedoListItem<T>[];
  itemSelected(
    item: RedoListItem<T>,
    source: RedoListItemSelectedSource,
    index: number,
  ): void;
  refToListenTo: HTMLElement | null;
  focusedIndex: number | undefined;
  setFocusedIndex: Dispatch<SetStateAction<number | undefined>>;
  selectionVariant?: RedoListItemVariant;
  children(item: RedoListItem<T>): ReactNode;
  isItemSelected?(item: RedoListItem<T>): boolean;
  keyFn?: (item: RedoListItem<T>, index: number) => string | number;
  size?: RedoListItemSize;
  containerClassName?: string;
  gap?: SpacingValue;
  isItemDisabled?(item: RedoListItem<T>): boolean;
  itemsLoading?: boolean;
  emptyListMessage?: string;
}

export const RedoList = genericMemo(function RedoList<T>({
  size = RedoListItemSize.MEDIUM,
  items,
  itemsLoading = false,
  itemSelected,
  isItemSelected = () => false,
  focusedIndex,
  setFocusedIndex,
  refToListenTo,
  children,
  selectionVariant = RedoListItemVariant.CHECKMARK,
  keyFn = (_, idx) => idx,
  containerClassName,
  gap,
  isItemDisabled = () => false,
  emptyListMessage = "No items",
  ...otherProps
}: RedoListProps<T> & PaddingProps) {
  const paddingProps = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(otherProps).filter(([key]) =>
          paddingPropsKeys.includes(key as any),
        ),
      ) as PaddingProps,
    [otherProps],
  );

  const itemSelectedStable = useHandler(itemSelected);
  const setFocusedIndexStable = useHandler(setFocusedIndex);
  const childrenStable = useHandler(children);

  const handleKeyPress = useHandler(
    (event: KeyboardEvent | React.KeyboardEvent) => {
      if (event.key === EnterKey) {
        event.preventDefault();
        if (focusedIndex === undefined) {
          return;
        }
        event.stopPropagation();
        const selectedItem = items[focusedIndex];
        if (!isItemDisabled(selectedItem)) {
          itemSelected(
            selectedItem,
            RedoListItemSelectedSource.Keyboard,
            focusedIndex,
          );
        }
      } else if (event.key === ArrowDown) {
        event.preventDefault();
        const oldIndex = focusedIndex ?? -1;
        let newIndex = Math.min(oldIndex + 1, items.length - 1);
        while (newIndex < items.length && isItemDisabled(items[newIndex])) {
          newIndex++;
        }
        if (newIndex < items.length) {
          setFocusedIndex(newIndex);
        }
      } else if (event.key === ArrowUp) {
        event.preventDefault();
        const oldIndex = focusedIndex ?? items.length;
        let newIndex = Math.min(oldIndex - 1, items.length - 1);
        while (newIndex >= 0 && isItemDisabled(items[newIndex])) {
          newIndex--;
        }
        if (newIndex >= 0) {
          setFocusedIndex(newIndex);
        } else {
          setFocusedIndex(undefined);
        }
      }
    },
  );

  useEffect(() => {
    refToListenTo?.addEventListener("keydown", handleKeyPress);
    return () => {
      refToListenTo?.removeEventListener("keydown", handleKeyPress);
    };
  }, [refToListenTo, handleKeyPress]);

  return (
    <div className={containerClassName}>
      {itemsLoading ? (
        <Flex justify="center">
          <Flex className={redoDropdownInputCss.spinnerContainer}>
            <Spinner />
          </Flex>
        </Flex>
      ) : (
        <Flex
          className={redoListCss.childrenContainer}
          dir="column"
          gap={gap}
          onKeyDown={handleKeyPress}
          {...paddingProps}
          tabIndex={0}
        >
          {items.length === 0 && (
            <RedoListItem
              disabled
              focused={false}
              itemClicked={() => {}}
              menu={undefined}
              onItemHovered={() => {}}
              selected={false}
              selectionVariant={selectionVariant}
              size={size}
            >
              <Text>{emptyListMessage}</Text>
            </RedoListItem>
          )}
          {items.map((item, idx) => (
            <ListItemRender
              disabled={isItemDisabled(item)}
              focused={focusedIndex === idx}
              idx={idx}
              item={item}
              itemSelected={itemSelectedStable}
              key={keyFn(item, idx)}
              selected={isItemSelected(item)}
              selectionVariant={selectionVariant}
              setFocusedIndex={setFocusedIndexStable}
              size={size}
            >
              {childrenStable}
            </ListItemRender>
          ))}
        </Flex>
      )}
    </div>
  );
});

const ListItemRender = genericMemo(function ListItemRender<T>({
  item,
  idx,
  setFocusedIndex,
  focused,
  itemSelected,
  selectionVariant,
  size,
  children,
  disabled,
  selected,
}: {
  item: RedoListItem<T>;
  idx: number;
  focused: boolean;
  disabled: boolean;
  selected: boolean;
  setFocusedIndex: Dispatch<SetStateAction<number | undefined>>;
  itemSelected(
    item: RedoListItem<T>,
    source: RedoListItemSelectedSource,
    index: number,
  ): void;
  selectionVariant: RedoListItemVariant;
  size: RedoListItemSize;
  children(item: RedoListItem<T>): ReactNode;
}) {
  const onItemHovered = useHandler((hovered: boolean) => {
    if (hovered && !disabled) {
      setFocusedIndex(idx);
    } else {
      setFocusedIndex((currentFocusedIndex) => {
        const thisItemIsFocused = currentFocusedIndex === idx;
        return thisItemIsFocused ? undefined : currentFocusedIndex;
      });
    }
  });

  const itemClicked = useHandler(() => {
    if (!disabled) {
      itemSelected(item, RedoListItemSelectedSource.Mouse, idx);
    }
  });

  return (
    <RedoListItem
      disabled={disabled}
      focused={focused}
      itemClicked={itemClicked}
      menu={item.menu}
      onItemHovered={onItemHovered}
      selected={selected}
      selectionVariant={selectionVariant}
      size={size}
      suffix={item.suffix}
    >
      {children(item)}
    </RedoListItem>
  );
});
