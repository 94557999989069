import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { RedoClient } from "@redotech/redo-api-client";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { REDO_MERCHANT_SERVER_URL } from "@redotech/redo-merchant-app-common/config";
import { listen } from "@redotech/redo-merchant-app-common/events/utils";
import {
  AUTO_REPLY_USER_ID,
  ExpandedConversation,
  ExpandedConversationMessage,
  isSupportOrEmailConversionMessage,
  MessageVisibility,
} from "@redotech/redo-model/conversation";
import { getPrimaryCustomerEmail } from "@redotech/redo-model/customer";
import {
  EmailEnvelopeInfo,
  EmailReplyType,
  filterAndPreprocessEmailInfo,
  filterAndPreprocessOneEmail,
  hasExactlyOneRecipientEmail,
} from "@redotech/redo-model/support/conversations/email-info";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { RedoClientContext } from "@redotech/redo-web/client";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { formatDateTime } from "@redotech/redo-web/time";
import { emailRegex } from "@redotech/util/email";
import {
  Dispatch,
  Fragment,
  memo,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { getProfilePictures } from "../../client/conversations";
import { getPublicDraftReplyingToMessage } from "../message-input-utils";
import { SystemMessage } from "../system-message";
import { VoiceTranscriptMessage } from "../voice-transcript-message";
import * as conversationEmailMessagesViewCss from "./conversation-email-messages-view.module.css";
import { EmailMessage, MessageContent } from "./email-message";

const getConversationStream = (
  client: RedoClient,
  { conversationId, signal }: { conversationId: string; signal: AbortSignal },
) => {
  const url = `${REDO_MERCHANT_SERVER_URL}/conversations/${conversationId}/live`;
  return fetch(url, { signal, headers: client.authorization() });
};

const getEnvelopeInfoOfThisMessage = (
  conversation: ExpandedConversation,
  message: ExpandedConversationMessage,
): Required<Omit<EmailEnvelopeInfo, "inReplyTo" | "inReplyToMongoId">> => {
  if (message.type === "merchant" && message.user?._id === AUTO_REPLY_USER_ID) {
    // We don't want to reply to auto-replies, so we should have it reply to the previous customer message.
    const previousCustomerMessage = conversation.messages
      .slice()
      .reverse()
      .find((msg) => msg.type === "customer" && msg.sentAt < message.sentAt);
    if (previousCustomerMessage) {
      return getEnvelopeInfoOfThisMessage(
        conversation,
        previousCustomerMessage,
      );
    }
  }
  // if the message contains a non-undefined value for each of
  // to, cc, bcc, from, return an object with those values
  const envelopeInfo =
    /**
     * Should we fall back to old behavior of using conversation-set emails?
     */
    message.email?.to !== undefined &&
    message.email?.cc !== undefined &&
    message.email?.bcc !== undefined &&
    message.email?.from !== undefined
      ? {
          to: filterAndPreprocessEmailInfo(message.email.to),
          cc: filterAndPreprocessEmailInfo(message.email.cc),
          bcc: filterAndPreprocessEmailInfo(message.email.bcc),
          from: filterAndPreprocessEmailInfo([message.email.from])?.[0],
        }
      : {
          to: filterAndPreprocessEmailInfo(
            fallbackRecipientsFromConversation(conversation, message),
          ),
          cc: filterAndPreprocessEmailInfo(
            conversation.ccEmails?.map((ccEmail) => ({ email: ccEmail })) ?? [],
          ),
          bcc: filterAndPreprocessEmailInfo(
            conversation.bccEmails?.map((bccEmail) => ({ email: bccEmail })) ??
              [],
          ),
          from: filterAndPreprocessEmailInfo([
            fallbackSenderFromConversation(conversation, message),
          ])?.[0],
        };

  /**
   * We really really need a "from" email at the very least.
   * So, we try fallbacks here, and even if validating fails for some strange reason,
   * just use the unvalidated email.
   */
  const primaryCustomerEmail = getPrimaryCustomerEmail(conversation.customer);

  if (!envelopeInfo.from) {
    const unvalidatedFallbackFromEmail = {
      email:
        primaryCustomerEmail ??
        fallbackFromEmailWhenCustomerHasntLoaded(conversation) ??
        "",
    };
    envelopeInfo.from = {
      email:
        filterAndPreprocessOneEmail(unvalidatedFallbackFromEmail.email) ??
        unvalidatedFallbackFromEmail.email,
    };
  }

  return envelopeInfo;
};

/**
 * If the customer hasn't loaded yet, we need to try to grab the email from a customer message.
 */
const fallbackFromEmailWhenCustomerHasntLoaded = (
  conversation: ExpandedConversation,
) =>
  conversation.messages
    .filter((m) => m.type === "customer")
    .reverse()
    .find((m) => emailRegex.test(m.content))
    ?.content.match(emailRegex)?.[0];

const fallbackSenderFromConversation = (
  conversation: ExpandedConversation,
  message: ExpandedConversationMessage,
) => {
  const primaryCustomerEmail = getPrimaryCustomerEmail(message.customer);

  if (message.email?.from?.email) {
    return message.email.from;
  }
  if (message.type === "customer") {
    return {
      email: primaryCustomerEmail || "",
      name: message.customer?.name || "",
    };
  }
  return { email: conversation.email?.email };
};

const fallbackRecipientsFromConversation = (
  conversation: ExpandedConversation,
  message: ExpandedConversationMessage,
) => {
  if (message.email?.to?.length) {
    return message.email.to;
  }
  const primaryCustomerEmail = getPrimaryCustomerEmail(message.customer);

  const sender = fallbackSenderFromConversation(conversation, message);
  const mainRecipient =
    message.type === "customer"
      ? { email: conversation.email?.email }
      : {
          email: primaryCustomerEmail || "",
          name: message.customer?.name || "",
        };
  const filteredToRecipients = (conversation.toEmails || []).filter(
    (toEmail) =>
      toEmail.email !== sender.email && toEmail.email !== mainRecipient.email,
  );
  return [mainRecipient, ...filteredToRecipients];
};

export const ConversationEmailMessagesView = memo(
  function ConversationEmailMessagesView({
    conversation,
    typing,
    removeConversationFromProximity,
    conversationJustMerged,
    setErrorMessage,
    setShowErrorMessage,
    setShowFullCommentThread,
    setActiveConversation,
    setTyping,
    showFullCommentThread,
    nextConversationInList,
    prevConversationInList,
  }: {
    conversation: ExpandedConversation;
    typing: Record<string, Date>;
    removeConversationFromProximity: (
      conversationToExclude: ExpandedConversation,
    ) => void;
    setErrorMessage: Dispatch<SetStateAction<string>>;
    conversationJustMerged?: boolean;
    setShowErrorMessage: Dispatch<SetStateAction<boolean>>;
    setShowFullCommentThread: Dispatch<SetStateAction<boolean>>;
    setActiveConversation: (
      conversation: ExpandedConversation | undefined,
    ) => void;
    setTyping: Dispatch<SetStateAction<Record<string, Date>>>;
    showFullCommentThread: boolean;
    nextConversationInList?: ExpandedConversation;
    prevConversationInList?: ExpandedConversation;
  }) {
    const [retryCount, setRetryCount] = useState(0);
    const client = useRequiredContext(RedoClientContext);
    const merchantClient = useRequiredContext(RedoMerchantClientContext);
    const [indexOfChatConversionMessage, setIndexOfChatConversionMessage] =
      useState<number | null>(null);

    const profilePicturesLoad = useLoad(async (signal) => {
      const profilePictures = await getProfilePictures(merchantClient, {
        conversationId: conversation._id,
        signal,
      });

      return profilePictures;
    }, []);

    const messagesSortedByDateWithoutDrafts: ExpandedConversationMessage[] =
      conversation.messages
        .sort((a, b) => {
          return new Date(a.sentAt).getTime() - new Date(b.sentAt).getTime();
        })
        .filter((message) => !message.draftInfo?.isDraft);

    useEffect(() => {
      if (
        conversation.originalPlatform &&
        conversation.originalPlatform !== conversation.platform
      ) {
        const indexesOfConversionMessages: number[] = [];
        messagesSortedByDateWithoutDrafts.forEach((message, index) => {
          if (isSupportOrEmailConversionMessage(message)) {
            indexesOfConversionMessages.push(index);
          }
        });
        if (indexesOfConversionMessages.length > 0) {
          const chatConversionMessageIndex =
            indexesOfConversionMessages[indexesOfConversionMessages.length - 1];
          setIndexOfChatConversionMessage(chatConversionMessageIndex);
        }
      } else {
        setIndexOfChatConversionMessage(null);
      }
    }, [messagesSortedByDateWithoutDrafts, conversation._id]);

    useEffect(() => {
      const abortController = new AbortController();
      (async () => {
        try {
          for await (const updatedConversation of listen({
            query: async () => {
              return await getConversationStream(client, {
                conversationId: conversation._id,
                signal: abortController.signal,
              });
            },
            loopCondition: !!conversation?._id,
            setErrorMessage,
            setShowErrorMessage,
          })) {
            setActiveConversation(
              updatedConversation as unknown as ExpandedConversation,
            );
          }
        } catch (e) {
          // Wait 5 seconds before trying again
          setTimeout(() => {
            // Continue polling for changes
            setRetryCount(retryCount + 1);
          }, 5000);
          if (abortController.signal.aborted) {
            return;
          }
          throw e;
        }
      })();
      return () =>
        abortController.abort("Conversation changed or component unmounted");
    }, [conversation._id, retryCount]);

    type MessagesChunk = ExpandedConversationMessage[];

    const [lengthOfInitialMessages, setLengthOfInitialMessages] = useState(
      messagesSortedByDateWithoutDrafts.length,
    );
    useLayoutEffect(() => {
      setLengthOfInitialMessages(messagesSortedByDateWithoutDrafts.length);
    }, [conversation._id]);

    /**  Group and filter messages.
     *
     * Some caveats here:
     *  - if it's after a conversion to email, we don't want to collapse into chunks since it'll be really information-sparse.
     *  - if it's a new message that came through after we loaded initially, we don't want to expand it.
     *  - if it's a draft, we don't want to include it at all.
     */
    const messageChunks: MessagesChunk[] = [[]];
    for (const [
      index,
      message,
    ] of messagesSortedByDateWithoutDrafts.entries()) {
      // system messages are their own chunks
      if (message.type === "system") {
        // And if it's after a conversion to email, break the previous chunk into one-message chunks
        // so merchants don't have to expand the boilerplate automated messages.
        if (isSupportOrEmailConversionMessage(message)) {
          messageChunks.splice(
            0,
            messageChunks.length,
            ...messageChunks.flat().map((message) => [message]),
          );
        }

        // add system messages as a new chunk, with a new chunk for new messages that come in
        messageChunks.push([message], []);
      } else if (index >= lengthOfInitialMessages) {
        // add new messages that come in as we go a new chunk
        messageChunks.push([message]);
      } else {
        // non-system, non-new messages are chunked together
        messageChunks[messageChunks.length - 1].push(message);
      }
    }

    // remove tailing empty chunk if needed
    if (messageChunks[messageChunks.length - 1].length === 0) {
      messageChunks.pop();
    }

    const shouldStartAllExpandedBecauseClosedConversation =
      conversation.status === "closed";

    type ChunksToCollapse = boolean[];
    const [chunksUserHasCollapsed, setChunksUserHasCollapsed] =
      useState<ChunksToCollapse>(
        messageChunks.map(
          () => !shouldStartAllExpandedBecauseClosedConversation,
        ),
      );

    // Upon first render of conversation, set the messages to hide initially unless the message has a draft reply
    const N_MESSAGES_BEFORE_HIDING = 5;
    useLayoutEffect(() => {
      if (shouldStartAllExpandedBecauseClosedConversation) {
        return;
      }
      setChunksUserHasCollapsed(
        messageChunks.map((chunk) => {
          const shouldCollapse = chunk.length > N_MESSAGES_BEFORE_HIDING;
          const hasDraft = chunk.some((msg) =>
            getPublicDraftReplyingToMessage(conversation, msg),
          );
          return hasDraft ? false : shouldCollapse;
        }),
      );
    }, [conversation._id, lengthOfInitialMessages]);

    // At the beginning of viewing the conversation, scroll to the bottom.
    const conversationMessagesScrollRef = useRef<HTMLDivElement>();
    useEffect(() => {
      setTimeout(() => {
        const scrollRef = conversationMessagesScrollRef.current;
        if (scrollRef) {
          scrollRef.scrollTop = scrollRef.scrollHeight;
        }
      }, 300);
    }, [conversation._id]);

    const [drawerOpen, setDrawerOpen] = useState(false);
    const [transcriptionMessage, setTranscriptionMessage] =
      useState<ExpandedConversationMessage>();

    const handleDrawerState = (message: ExpandedConversationMessage) => {
      if (transcriptionMessage && transcriptionMessage._id === message._id) {
        setTranscriptionMessage(undefined);
        setDrawerOpen(false);
      } else {
        setTranscriptionMessage(message);
        setDrawerOpen(true);
      }
    };

    return (
      <div className={conversationEmailMessagesViewCss.messagesCard}>
        <Flex
          align="stretch"
          className={conversationEmailMessagesViewCss.messagesContainer}
          dir="column"
          grow={1}
          ref={conversationMessagesScrollRef}
        >
          <>
            {messageChunks.map((messageChunk, chunk_index) => (
              <Fragment key={`chunk${conversation._id}${chunk_index}`}>
                {messageChunk.map((message, message_in_chunk_index) => {
                  const envelopeInfo = getEnvelopeInfoOfThisMessage(
                    conversation,
                    message,
                  );
                  const messageContent: MessageContent = message.email?.htmlBody
                    ? {
                        type: "messageHtml",
                        messageHtml: message.email.htmlBody.replaceAll(
                          "<p></p>",
                          "<br/>",
                        ),
                      }
                    : {
                        type: "messageText",
                        messageText:
                          message.email?.textBody ||
                          message.content ||
                          "(empty message)",
                      };

                  /** Show the last message as expanded */
                  const shouldStartExpandedBecauseItsTheLastMessage =
                    chunk_index === messageChunks.length - 1 &&
                    message_in_chunk_index ===
                      messageChunks[chunk_index].length - 1;

                  // If we have a public draft saved, start the message expanded
                  const savedDraft = getPublicDraftReplyingToMessage(
                    conversation,
                    message,
                  );
                  const shouldStartExpandedBecauseOfDraft =
                    !!savedDraft &&
                    savedDraft.visibility === MessageVisibility.PUBLIC;

                  /** When new messages come in, show them (e.g. if the merchants write a reply, don't collapse it.) */
                  const shouldStartExpandedBecauseOfNewMessages =
                    messageChunks.slice(0, chunk_index).flat().length +
                      message_in_chunk_index +
                      1 >=
                    lengthOfInitialMessages;

                  /** Under any of these circumstances, show the message */
                  const shouldStartExpanded =
                    (shouldStartExpandedBecauseItsTheLastMessage ||
                      (shouldStartExpandedBecauseOfNewMessages &&
                        !conversationJustMerged) ||
                      shouldStartExpandedBecauseOfDraft) &&
                    (indexOfChatConversionMessage === null ||
                      chunk_index >= indexOfChatConversionMessage);

                  /** We want a reply box to be open when it's time to respond to a customer. */
                  const whichDraftModeToStartWith: EmailReplyType | undefined =
                    shouldStartExpanded && message.type === "customer"
                      ? /** if there's just one recipient in senderInfo's "to", use "Reply", otherwise use "Reply All" */
                        hasExactlyOneRecipientEmail(envelopeInfo) &&
                        envelopeInfo.to?.length
                        ? EmailReplyType.REPLY
                        : EmailReplyType.REPLY_ALL
                      : undefined;

                  return (
                    <Fragment
                      key={`message${conversation._id}${message._id}${message_in_chunk_index}`}
                    >
                      {chunksUserHasCollapsed[chunk_index] &&
                        messageChunk.length > N_MESSAGES_BEFORE_HIDING &&
                        message_in_chunk_index === messageChunk.length - 1 && (
                          <Flex
                            className={
                              conversationEmailMessagesViewCss.showMoreButtonContainer
                            }
                          >
                            {/* Special gmail-esque "show more" button that lives on top of collapsed messages */}
                            <Button
                              className={
                                conversationEmailMessagesViewCss.showMoreButton
                              }
                              key={`showmore${chunk_index}`}
                              onClick={() => {
                                setChunksUserHasCollapsed(
                                  chunksUserHasCollapsed.map((val, index) =>
                                    index === chunk_index ? !val : val,
                                  ),
                                );
                              }}
                              size={ButtonSize.MEDIUM}
                              theme={ButtonTheme.OUTLINED}
                            >
                              <Flex align="center" justify="center">
                                <Text fontSize="sm">
                                  {/** The first and last aren't collapsed, so -2 */}
                                  {messageChunk.length - 2}
                                </Text>
                              </Flex>
                            </Button>
                          </Flex>
                        )}
                      {(!chunksUserHasCollapsed[chunk_index] ||
                        [0, messageChunk.length - 1].includes(
                          message_in_chunk_index,
                        )) && (
                        <>
                          {message.type === "system" ? (
                            <Flex
                              className={
                                conversationEmailMessagesViewCss.systemMessageCardWrapper
                              }
                              p="md"
                              pb="3xl"
                              pt="2xl"
                            >
                              <SystemMessage message={message.content} />
                            </Flex>
                          ) : message.type === "call" ? (
                            <VoiceTranscriptMessage
                              conversation={conversation}
                              handleDrawerState={handleDrawerState}
                              isEmail
                              message={message}
                              open={drawerOpen}
                              transcriptionMessage={transcriptionMessage}
                            />
                          ) : (
                            <EmailMessage
                              // If the message is hidden by the bubble, start it collapsed.
                              bcc={envelopeInfo.bcc}
                              cc={envelopeInfo.cc}
                              conversation={conversation}
                              conversationJustMerged={conversationJustMerged}
                              date={formatDateTime(
                                Temporal.Instant.from(message.sentAt),
                              )}
                              files={message.files}
                              from={envelopeInfo.from}
                              indexOfChatConversionMessage={
                                indexOfChatConversionMessage
                              }
                              isUser={message.type === "merchant"}
                              message={message}
                              messageContent={messageContent}
                              messageIndex={chunk_index}
                              nextConversationInList={nextConversationInList}
                              prevConversationInList={prevConversationInList}
                              profilePictures={
                                !profilePicturesLoad.pending &&
                                !profilePicturesLoad.error
                                  ? profilePicturesLoad.value
                                  : {}
                              }
                              removeConversationFromProximity={
                                removeConversationFromProximity
                              }
                              setActiveConversation={setActiveConversation}
                              setErrorMessage={setErrorMessage}
                              setShowErrorMessage={setShowErrorMessage}
                              setShowFullCommentThread={
                                setShowFullCommentThread
                              }
                              setTyping={setTyping}
                              showFullCommentThread={showFullCommentThread}
                              startCollapsed={!shouldStartExpanded}
                              subject={conversation.subject}
                              to={envelopeInfo.to}
                              typing={typing}
                              userAvatarImageUrl={
                                profilePicturesLoad.value?.[
                                  message?.customer?._id || ""
                                ] || ""
                              }
                              visibility={message.visibility}
                              whichDraftModeToStartWith={
                                whichDraftModeToStartWith
                              }
                            />
                          )}
                        </>
                      )}
                    </Fragment>
                  );
                })}
              </Fragment>
            ))}
          </>
        </Flex>
      </div>
    );
  },
);
