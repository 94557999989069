// extracted by mini-css-extract-plugin
export var border = "_4z-5";
export var button = "_4z-g";
export var center = "_4z-7";
export var centered = "_4z-3";
export var closeButton = "_4z-9";
export var disabledWrapper = "_4z-h";
export var error = "_4z-k";
export var fill = "_4z-d";
export var footer = "_4z-c";
export var footerAction = "_4z-e";
export var footerButtons = "_4z-f";
export var header = "_4z-4";
export var icon = "_4z-j";
export var info = "_4z-n";
export var inline = "_4z-6";
export var large = "_4z-2";
export var main = "_4z-a";
export var modal = "_4z-0";
export var neutral = "_4z-o";
export var none = "_4z-p";
export var scrollable = "_4z-b";
export var small = "_4z-1";
export var success = "_4z-m";
export var tight = "_4z-i";
export var titleIcon = "_4z-8";
export var warn = "_4z-l";