import { EmailFormat } from "@redotech/redo-model/email-builder";
import {
  AmpScript,
  MERCHANT_TEMPLATED_EMAIL_GLOBAL_STYLES,
} from "@redotech/redo-web/email-builder/email-wrapper";
import { memo, useContext, useLayoutEffect, useRef } from "react";
import { GlobalEmailClass } from "../email-wrapper";
import { EmailRenderContext } from "./email-builder-context";

/**
 * Outer component responsible for rendering children to a string (rawHtml)
 * and passing it to the inner iframe component.
 */
export const EmailBlockIsolator = memo(function EmailBlockIsolator({
  rawHtml,
  scriptUrls,
  format,
  includeWrapperPadding = false,
}: {
  rawHtml: string;
  scriptUrls: AmpScript[];
  format: EmailFormat;
  includeWrapperPadding?: boolean;
}) {
  return (
    <div
      style={{
        lineHeight: 0, // not sure why this is needed, but otherwise parent becomes 6px too tall
        pointerEvents: "none",
        width: "100%",
      }}
    >
      <EmailBlockIsolatorHelper
        format={format}
        includeWrapperPadding={includeWrapperPadding}
        rawHtml={rawHtml}
        scriptUrls={scriptUrls}
      />
    </div>
  );
});

/**
 * Child component responsible for rendering the iframe.
 * Only rerenders when rawHtml string changes.
 * @param includeWrapperPadding - whether to include root-level email padding in the iframe's height. (the padding that appears on desktop-size emails, wrapping it in a gray bg)
 */
const EmailBlockIsolatorHelper = memo(function AmpIframeIsolator({
  format,
  rawHtml,
  scriptUrls,
  includeWrapperPadding,
}: {
  format: EmailFormat;
  rawHtml: string;
  scriptUrls: AmpScript[];
  includeWrapperPadding?: boolean;
}) {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const emailRenderContext = useContext(EmailRenderContext);
  const screenSize = emailRenderContext?.screenSize;

  useLayoutEffect(() => {
    const iframe = iframeRef.current;
    if (!iframe || !iframe.contentDocument) {
      return;
    }
    const iframeDocument = iframe.contentDocument;

    // Render the email block in its own isolated iframe
    // Can't do more obvious things like iframeDocument.documentElement.innerHTML = rawHtml
    // Because the <!DOCTYPE html> needs to be set, otherwise browsers render in quirks mode,
    // which messes up the styles.
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Quirks_Mode_and_Standards_Mode
    iframeDocument.open();
    iframeDocument.write(rawHtml);
    iframeDocument.close();

    const { head, body } = iframeDocument;
    body.style.overflow = "hidden"; // weirdly, an empty scrollbar appears occasionally without this
    iframe.width = "100%";

    if (format === EmailFormat.AMP) {
      const styleElement = iframeDocument.createElement("style");
      styleElement.setAttribute("amp4email-boilerplate", "");
      styleElement.textContent = "body{visibility:hidden}";
      head.appendChild(styleElement);

      // AMP plays dirty with height, we have to play dirtier
      const style = iframeDocument.createElement("style");
      const moreSpecificPseudoClassesThanAmp = ":first-of-type:not([amp4ads])";
      style.textContent = `html${moreSpecificPseudoClassesThanAmp},body${moreSpecificPseudoClassesThanAmp}{
      height: fit-content !important;
      margin: 0;
      overflow: hidden;
      pointer-events: none !important;
    }`;
      head.appendChild(style);

      const customStyles = iframeDocument.createElement("style");
      customStyles.textContent = MERCHANT_TEMPLATED_EMAIL_GLOBAL_STYLES;
      head.appendChild(customStyles);
    }

    const adjustHeight = () => {
      const mainElement = includeWrapperPadding
        ? body
        : body.querySelector(`.${GlobalEmailClass.MJML_BODY}`);
      const height = mainElement?.clientHeight;
      iframe.height = (height ?? 0) + "px";
    };

    // Initial adjustment
    adjustHeight();

    // Check height repeatedly until it stabilizes
    let retries = 5;
    const interval = setInterval(() => {
      if (retries-- <= 0) {
        clearInterval(interval);
      }
      adjustHeight();
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, [rawHtml, scriptUrls, format, screenSize, includeWrapperPadding]);

  return <iframe key={rawHtml} ref={iframeRef} />;
}, ampIframePropsAreEqual);

/**
 * without this, clicking on the iframe will cause AMP to crash and become blank
 * (this is the reason for the Helper component)
 */
function ampIframePropsAreEqual(prevProps: any, nextProps: any) {
  return (
    prevProps.rawHtml === nextProps.rawHtml &&
    prevProps.scriptUrls === nextProps.scriptUrls
  );
}
