import { ExternalShipmentTracker } from "@redotech/redo-model/trackable";
import { RedoBadgeColor } from "@redotech/redo-web/arbiter-components/badge/redo-badge";

export const trackerStatusColors: {
  [key in ExternalShipmentTracker["status"]]: NonNullable<RedoBadgeColor>;
} = {
  unknown: RedoBadgeColor.ERROR,
  pre_transit: RedoBadgeColor.WARNING,
  in_transit: RedoBadgeColor.WARNING,
  out_for_delivery: RedoBadgeColor.WARNING,
  delivered: RedoBadgeColor.SUCCESS,
  available_for_pickup: RedoBadgeColor.SUCCESS,
  return_to_sender: RedoBadgeColor.ERROR,
  failure: RedoBadgeColor.ERROR,
  cancelled: RedoBadgeColor.ERROR,
  error: RedoBadgeColor.ERROR,
} as const;
