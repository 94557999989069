import { zExt } from "@redotech/redo-model/common/zod-util";
import {
  DiscountGroupType,
  DiscountProvider,
  DiscountValueType,
} from "@redotech/redo-model/discount";
import { z } from "zod";

const combinesWithSchema = z.object({
  orderDiscounts: z.boolean().optional(),
  productDiscounts: z.boolean().optional(),
  shippingDiscount: z.boolean().optional(),
});

export enum DiscountSettingsType {
  SHOPIFY_BASIC = "SHOPIFY_BASIC",
}

export type BasicShopifyDiscountConfiguration = z.infer<
  typeof basicShopifyDiscountSchema
>;
const basicShopifyDiscountSchema = z.object({
  settingsType: z.literal(DiscountSettingsType.SHOPIFY_BASIC),
  discountValueType: z.nativeEnum(DiscountValueType),
  discountValueAmount: z.number(),
  combinesWith: combinesWithSchema,
  excludeItemsOnSale: z.boolean().optional(),
  appliesToCollections: z.array(z.string()).optional(),
  endsAt: z.date().optional(),
});

export type DiscountCodeGenerationStrategy = z.infer<
  typeof codeGenerationStrategy
>;
const codeGenerationStrategy = z.object({
  strategy: z.nativeEnum(DiscountGroupType),
  code: z.string(),
});

export type RedoDiscountConfiguration = z.infer<typeof discountConfiguration>;
/**
 * Shipping discounts are not implemented yet.
 * When that happens, this will need to be a discriminated union of basic + shipping shopify types
 */
export const discountConfiguration = z.object({
  name: z.string(),
  provider: z.literal(DiscountProvider.SHOPIFY),
  codeGenerationStrategy: codeGenerationStrategy,
  discountSettings: z.discriminatedUnion("settingsType", [
    basicShopifyDiscountSchema,
  ]),
});

export type RedoDiscount = z.infer<typeof redoDiscountSchema>;
export const redoDiscountSchema = z.intersection(
  discountConfiguration,
  z.object({
    _id: zExt.objectId(),
    teamId: zExt.objectId(),
    providerIdentifier: z.string(),
  }),
);
