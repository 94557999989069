// extracted by mini-css-extract-plugin
export var acitonButtons = "_83-0";
export var block = "_83-1";
export var blockContent = "_83-7";
export var blockDescription = "_83-8";
export var blockIcon = "_83-9";
export var blockTitle = "_83-6";
export var border = "_83-a";
export var center = "_83-k";
export var edge = "_83-b";
export var edgeLabel = "_83-c";
export var edgePath = "_83-d";
export var error = "_83-e";
export var flowchart = "_83-f";
export var flowchartContent = "_83-g";
export var highlighted = "_83-3";
export var hover = "_83-4";
export var icon = "_83-h";
export var left = "_83-l";
export var navButton = "_83-i";
export var navButtons = "_83-j";
export var right = "_83-m";
export var selected = "_83-2";
export var small = "_83-5";