import { TooltipProps } from "@mui/material/Tooltip";
import { memo } from "react";
import { OptionalTooltip } from "./optional-tooltip";

interface DisabledTooltipProps {
  disabledMessage: string | undefined | false | true;
  children: React.ReactElement;
  otherTooltipProps?: Omit<TooltipProps, "title">;
  wrapperClass?: string;
}

export const DisabledTooltip = memo(function DisabledTooltip({
  disabledMessage,
  children,
  otherTooltipProps,
  wrapperClass,
}: DisabledTooltipProps): React.ReactElement {
  const shouldShow = typeof disabledMessage === "string" && !!disabledMessage;

  return (
    <OptionalTooltip
      meetsCondition={shouldShow}
      tooltipProps={{ ...otherTooltipProps, title: disabledMessage }}
      wrapperClass={wrapperClass}
    >
      {children}
    </OptionalTooltip>
  );
});
