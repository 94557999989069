import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";

export const getConversionSplits = {
  input: z.null(),
  output: z.array(
    z.object({ _id: zExt.objectId(), name: z.string(), active: z.boolean() }),
  ),
};
export type GetConversionSplitsOutput = z.infer<
  typeof getConversionSplits.output
>;
