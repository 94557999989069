// extracted by mini-css-extract-plugin
export var checkbox = "_55-0";
export var circle = "_55-3";
export var horizontalContainer = "_55-7";
export var sm = "_55-5";
export var switch0 = "_55-2";
export var switchDisabled = "_55-8";
export var switchEnabled = "_55-1";
export var textSwitchContainer = "_55-9";
export var textSwitchOption = "_55-a";
export var textSwitchOptionSelected = "_55-b";
export var verticalContainer = "_55-6";
export var xs = "_55-4";