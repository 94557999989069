// extracted by mini-css-extract-plugin
export var actionButton = "_cm-1";
export var actions = "_cm-0";
export var copyableField = "_cm-2";
export var icon = "_cm-3";
export var overflowVisible = "_cm-4";
export var pillWrapper = "_cm-5";
export var productDetails = "_cm-7";
export var productPillsContainer = "_cm-8";
export var productTopDetails = "_cm-9";
export var products = "_cm-6";
export var returnDetails = "_cm-a";
export var strong = "_cm-b";
export var variantQuantityRow = "_cm-c";