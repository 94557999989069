import { z } from "zod";

export const printPackingSlip = {
  input: z.intersection(
    z.union([
      z.object({ fulfillmentGroupIds: z.array(z.string()) }),
      z.object({ batchId: z.string() }),
    ]),
    z.object({ templateId: z.string().optional() }),
  ),
  output: z.object({ url: z.string() }),
};
