import { viewTypes } from "@redotech/redo-model/views/views";
import {
  ReactNode,
  createContext,
  memo,
  useContext,
  useEffect,
  useState,
} from "react";
import { RedoOutboundLabelsRpcClientContext } from "./outbound-labels-rpc-client";

export const OutboundViewsContext = createContext<
  { teamViews?: viewTypes["fulfillment-groups"][] } | undefined
>(undefined);

export const ReloadOutboundViewsContext = createContext<
  (() => Promise<void>) | undefined
>(undefined);

export const OutboundViewsProvider = memo(function OutboundViewsProvider({
  children,
}: {
  children: ReactNode | ReactNode[];
}) {
  const client = useContext(RedoOutboundLabelsRpcClientContext);

  const [views, setViews] = useState<viewTypes["fulfillment-groups"][]>([]);

  const reloadViews = async () => {
    if (!client) {
      return;
    }
    const abortController = new AbortController();
    const views = await client.getFulfillmentGroupViews(null, {
      signal: abortController.signal,
    });
    setViews(views);
  };

  useEffect(() => {
    void reloadViews();
  }, [client]);

  return (
    <OutboundViewsContext.Provider value={{ teamViews: views }}>
      <ReloadOutboundViewsContext.Provider value={reloadViews}>
        {children}
      </ReloadOutboundViewsContext.Provider>
    </OutboundViewsContext.Provider>
  );
});
