import { FulfillmentGroup, FulfillmentOrderData } from "./fulfillment-group";

export enum FulfillmentGroupStatus {
  Cancelled = "CANCELLED",
  Closed = "CLOSED",
  Incomplete = "INCOMPLETE",
  Open = "OPEN",
  OnHold = "ON_HOLD",
  Scheduled = "SCHEDULED",
  Pending = "PENDING",
  Voided = "VOIDED",
  PaymentPending = "PAYMENT_PENDING",
  Merged = "MERGED",
  Invalid = "INVALID",
}

export const OpenLikeStatuses = [
  FulfillmentGroupStatus.Open,
  FulfillmentGroupStatus.OnHold,
  FulfillmentGroupStatus.PaymentPending,
  FulfillmentGroupStatus.Scheduled,
  FulfillmentGroupStatus.Pending,
  FulfillmentGroupStatus.Incomplete,
];

export function canPurchaseLabelStatus(
  status: FulfillmentGroupStatus | undefined | null,
): boolean {
  if (!status) {
    return false;
  }
  return [FulfillmentGroupStatus.Open].includes(status);
}

export function canSplitStatus(
  status: FulfillmentGroupStatus | undefined | null,
): boolean {
  if (!status) {
    return false;
  }
  return [
    FulfillmentGroupStatus.Open,
    FulfillmentGroupStatus.OnHold,
    FulfillmentGroupStatus.PaymentPending,
    FulfillmentGroupStatus.Scheduled,
  ].includes(status);
}

export function canMergeFulfillmentGroups(
  fulfillmentGroups: FulfillmentGroup[],
): boolean {
  return fulfillmentGroups
    .map((group) => group.status)
    .every((status) => status === FulfillmentGroupStatus.Open);
}

export function canMergeFulfillmentOrderData(
  groupData: readonly FulfillmentOrderData[],
): boolean {
  return groupData
    .map((group) => group.status)
    .every((status) => status === FulfillmentGroupStatus.Open);
}
