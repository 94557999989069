import { Order } from "@redotech/redo-model/order";
import type { Return } from "@redotech/redo-model/return";
import { Channel, SalesChannels } from "@redotech/redo-model/sales-channels";
import { Card, CardSize } from "@redotech/redo-web/card";
import * as cardCss from "@redotech/redo-web/card.module.css";
import { PreviewImage } from "@redotech/redo-web/image";
import { Pill, PillSize } from "@redotech/redo-web/pill";
import { memo } from "react";
import { YofiFraudDetails } from "../return-page-right-panel/customer-tabs/yofi-fraud-details";
import * as returnCss from "../return.module.css";
import { returnTypeName } from "../util";
import * as customerResponseCss from "./customer-response-card.module.css";

export const CustomerResponseCard = memo(function CustomerResponseCard({
  return: return_,
  order,
}: {
  return?: Return;
  order?: Order;
}) {
  const shopifySourceName =
    return_?.products[0]?.order?.shopify?.source_name || null;
  const salesChannelName =
    SalesChannels.find((channel: Channel) => {
      let found = false;
      channel.sourceNames.forEach((source: string) => {
        // Using starts with because some shopify source names include an order # that we want to leave out.
        if (shopifySourceName?.startsWith(source)) {
          found = true;
        }
      });
      return found;
    })?.displayName || null;

  const customerComments =
    return_?.timeline.filter((event) => event.customer && event.message) || [];
  const customerImages =
    return_?.timeline.filter((event) => event.customer && event.image) || [];

  return (
    <Card size={CardSize.MEDIUM} title="Additional information">
      {salesChannelName && (
        <div className={customerResponseCss.salesChannelWrapper}>
          <h3 className={cardCss.title}>Sales channel</h3>
          <Pill size={PillSize.SMALL}>{salesChannelName}</Pill>
        </div>
      )}
      <section className={customerResponseCss.section}>
        <h3 className={cardCss.title}>Customer images and videos</h3>
        <div className={customerResponseCss.images}>
          {customerImages.map((event, index) => (
            <PreviewImage key={index} src={event.image!} />
          ))}
          {!customerImages.length && <>-</>}
        </div>
      </section>
      <section className={customerResponseCss.section}>
        <h3 className={cardCss.title}>Customer comments</h3>
        {customerComments.map((event) => (
          <div className={returnCss.comment} key={event._id}>
            {event.message}
          </div>
        ))}
        {!customerComments.length && <>-</>}
      </section>
      <YofiFraudDetails
        order={order}
        returnTypeName={returnTypeName(return_?.type, true)}
      />
    </Card>
  );
});
