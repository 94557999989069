import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { CampaignWithCustomerGroups } from "@redotech/redo-model/campaigns/campaign-definition";
import { EmailFormat } from "@redotech/redo-model/email-builder";
import { DESKTOP_WIDTH_PX } from "@redotech/redo-model/email-builder/email-sizing";
import { MarketingChannel } from "@redotech/redo-model/marketing";
import { getFromName, getFullFromEmail } from "@redotech/redo-model/team";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import {
  RedoInputSize,
  RedoInputState,
} from "@redotech/redo-web/arbiter-components/input/base-redo-text-input";
import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import { EmailBlockIsolator } from "@redotech/redo-web/email-builder/builder/amp-isolator";
import { ampScripts } from "@redotech/redo-web/email-builder/email-wrapper";
import { Flex } from "@redotech/redo-web/flex";
import { LoadingRedoAnimation } from "@redotech/redo-web/loading-redo-animation";
import { Text } from "@redotech/redo-web/text";
import { memo } from "react";
import { InfoTooltip } from "../../../../web/src/info-tooltip";
import { RedoMarketingRpcClientContext } from "../../app/redo-marketing-rpc-client-provider";

interface EmailSummaryState {
  subject: string;
  emailPreview: string;
}

function useEmailCostEstimate(campaign?: CampaignWithCustomerGroups | null) {
  const marketingRpcClient = useRequiredContext(RedoMarketingRpcClientContext);
  const costOfEmail = useLoad(
    async (signal) =>
      marketingRpcClient.getEstimatedEmailCost(null, { signal }),
    [marketingRpcClient],
  );

  const recipientCount = useLoad(
    async (signal) => {
      const customerGroups = campaign?.customerGroups || [];

      if (!customerGroups.length) {
        return 0;
      }

      const { count } =
        await marketingRpcClient.getEstimatedCampaignRecipientCount(
          {
            groups: customerGroups.map((group) => group._id.toString()),
            subscriptionType: MarketingChannel.EMAIL,
          },
          { signal },
        );

      return count;
    },
    [campaign],
  );

  const costEstimate =
    (costOfEmail.value?.costPerThousand || 0) *
    ((recipientCount.value || 0) / 1000);

  const costIsNull = costOfEmail.value === null;

  return {
    costEstimate: costIsNull ? null : costEstimate,
    costPerThousand: costOfEmail.value?.costPerThousand || 0,
    recipientCount: recipientCount.value || 0,
  };
}

export const EmailSummaryCard = memo(function EmailSummaryCard({
  campaign,
  editor,
}: {
  campaign?: CampaignWithCustomerGroups | null;
  editor?: {
    state: EmailSummaryState;
    setState: (state: EmailSummaryState) => void;
  };
}) {
  const team = useRequiredContext(TeamContext);

  const marketingRpcClient = useRequiredContext(RedoMarketingRpcClientContext);

  const emailPreviewLoad = useLoad(
    async (signal) => {
      if (!campaign?._id) {
        return null;
      }
      return await marketingRpcClient.previewEmailTemplate(
        { campaignId: campaign._id },
        { signal },
      );
    },
    [marketingRpcClient, campaign?._id],
  );

  const { costEstimate, costPerThousand, recipientCount } =
    useEmailCostEstimate(campaign);

  const emailPreview = emailPreviewLoad.value;

  return (
    <Flex
      bgColor="primary"
      borderColor="primary"
      borderStyle="solid"
      borderWidth="1px"
      dir="column"
      height="fit-content"
      p="3xl"
      radius="xl"
    >
      <Text fontSize="sm" fontWeight="semibold">
        {campaign?.finishedAt ? "Email content" : "Email preview"}
      </Text>
      <Flex gap="3xl">
        <Flex
          basis="0"
          bgColor="secondary"
          borderColor="primary"
          borderStyle="solid"
          borderWidth="1px"
          dir="column"
          grow={1}
          height="half-screen"
          p="3xl"
          radius="sm"
          style={{ minWidth: DESKTOP_WIDTH_PX /* padding */ }}
        >
          <Flex overflow="auto" w="full">
            {emailPreview ? (
              <EmailBlockIsolator
                format={EmailFormat.STATIC}
                includeWrapperPadding
                rawHtml={emailPreview}
                scriptUrls={ampScripts}
              />
            ) : (
              <Flex align="center" grow={1} justify="center">
                <LoadingRedoAnimation />
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex
          basis="0"
          bgColor="primary"
          dir="column"
          grow={1}
          my="auto"
          px="6xl"
        >
          <Flex dir="column" gap="xl" justify="center">
            <Flex dir="column" gap="xxs">
              <Text fontSize="sm" fontWeight="semibold" textColor="secondary">
                Subject
              </Text>
              <RedoTextInput
                setValue={(value) =>
                  editor?.setState({ ...editor.state, subject: value })
                }
                size={RedoInputSize.SMALL}
                state={editor ? undefined : RedoInputState.READONLY}
                value={
                  editor?.state.subject ||
                  campaign?.emailTemplate?.subject ||
                  ""
                }
              />
            </Flex>
            <Flex dir="column" gap="xxs">
              <Text fontSize="sm" fontWeight="semibold" textColor="secondary">
                Preview text
              </Text>
              <RedoTextInput
                setValue={(value) =>
                  editor?.setState({ ...editor.state, emailPreview: value })
                }
                size={RedoInputSize.SMALL}
                state={editor ? undefined : RedoInputState.READONLY}
                value={
                  editor?.state.emailPreview ||
                  campaign?.emailTemplate?.emailPreview ||
                  ""
                }
              />
            </Flex>
            <Flex dir="column" gap="xxs">
              <Text fontSize="sm" fontWeight="semibold" textColor="secondary">
                From/Reply-to
              </Text>
              <RedoTextInput
                setValue={() => {}}
                size={RedoInputSize.SMALL}
                state={RedoInputState.READONLY}
                value={`${getFromName(team)} <${getFullFromEmail(team)}>`}
              />
            </Flex>
            <Flex dir="column" gap="xxs">
              <Text fontSize="sm" fontWeight="semibold" textColor="secondary">
                Included segments
              </Text>
              <Flex gap="xs" wrap="wrap">
                {campaign?.customerGroups?.map((cg) => (
                  <RedoBadge
                    color={RedoBadgeColor.GRAY}
                    key={cg._id}
                    size={RedoBadgeSize.X_SMALL}
                    text={cg.name}
                  />
                )) || null}
              </Flex>
            </Flex>
            <Flex dir="column" gap="xxs">
              <Text fontSize="sm" fontWeight="semibold" textColor="secondary">
                {campaign?.finishedAt ? "Recipients" : "Audience"}
              </Text>
              <RedoTextInput
                setValue={() => {}}
                size={RedoInputSize.SMALL}
                state={RedoInputState.READONLY}
                value={recipientCount.toLocaleString()}
              />
            </Flex>
            {costEstimate !== null && (
              <Flex dir="column" gap="xxs">
                <Flex align="center">
                  <Text fontSize="sm" fontWeight="semibold">
                    Cost estimate
                  </Text>
                  <InfoTooltip
                    boxSize={12}
                    tooltip={{
                      title: `Cost per thousand emails: $${costPerThousand.toFixed(2)}`,
                    }}
                  />
                </Flex>
                <Text fontSize="xs" textColor="primary">
                  {`$${costEstimate.toFixed(2)}`}
                </Text>
              </Flex>
            )}
            {campaign?.finishedAt && (
              <Flex dir="column" gap="xxs">
                <Text fontSize="sm" fontWeight="semibold" textColor="secondary">
                  Sent
                </Text>
                <RedoTextInput
                  setValue={() => {}}
                  size={RedoInputSize.SMALL}
                  state={RedoInputState.READONLY}
                  value={campaign.finishedAt
                    .toTemporalInstant()
                    .toLocaleString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      hour: "numeric",
                      minute: "2-digit",
                      hour12: true,
                    })}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
});
