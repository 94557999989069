import { RouteObject } from "react-router-dom";
import { inFaqBuilder, inFaqHomeView } from "../faq/route";

const hideHeader = (url: string) => {
  return /one-click-upsell\/about/.test(url);
};

const inForge = (url: string) => {
  return (
    (url.includes("upsell-page/") && !url.endsWith("upsell-page/")) ||
    inFaqBuilder(url)
  );
};

const HIDE_PADDING_ROUTES: string[] = ["/one-click-upsell/upsell-page"];

function hidePadding(url: string) {
  return HIDE_PADDING_ROUTES.some((route) => url.includes(route));
}

export const aboutOneClickUpsellRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "One-click upsell", breadcrumbNavigate: false },
    async lazy() {
      const { Page } = await import("../../app/page");
      return {
        element: (
          <Page
            hideHeader={(url) => hideHeader(url) || inForge(url)}
            hideHeaderBorder={inFaqHomeView}
            hideNavbar={(url) => inForge(url)}
            hidePadding={hidePadding}
          />
        ),
      };
    },
    children: [
      {
        handle: { breadcrumb: "Upsell Page" },
        path: "upsell-page",
        children: [
          {
            index: true,
            lazy: async () => {
              const { UpsellPage } = await import(
                "../order-tracking-settings/one-click-upsell/upsell-page"
              );
              return { Component: UpsellPage };
            },
          },
          {
            handle: { breadcrumb: "Upsell Flow Builder" },
            path: ":upsellFlowId/:conditionId",
            children: [
              {
                index: true,
                lazy: async () => {
                  const { UpsellFlowBuilder } = await import(
                    "../order-tracking-settings/one-click-upsell/flow-builder/upsell-flow-builder"
                  );
                  return { Component: UpsellFlowBuilder };
                },
              },
              {
                handle: { breadcrumb: "Upsell Builder" },
                path: "upsell-page-builder/:upsellId",
                lazy: async () => {
                  const { UpsellPageBuilder } = await import(
                    "../order-tracking-settings/one-click-upsell/upsell-page-builder"
                  );
                  return { element: <UpsellPageBuilder /> };
                },
              },
            ],
          },
        ],
      },
      {
        path: "about",
        async lazy() {
          const { AboutOneClickUpsell } = await import(
            "./about-one-click-upsell"
          );
          return { element: <AboutOneClickUpsell /> };
        },
      },
    ],
  },
];
