import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { OverflowDirection } from "@redotech/react-util/overflow";
import { useSearch } from "@redotech/react-util/search";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { RenderedMessageContent } from "@redotech/redo-merchant-app/support/rendered-message-content";
import {
  isAtLeastOneMacroAutomationActive,
  Macro,
  MacroAutomation,
  SnoozeDuration,
} from "@redotech/redo-model/macro";
import { PutMacroBody } from "@redotech/redo-model/put-macro-body";
import { Permission, permitted } from "@redotech/redo-model/user";
import { toast } from "@redotech/redo-web/alert";
import { RedoCommandMenuItem } from "@redotech/redo-web/arbiter-components/command-menu/redo-command-menu";
import {
  RedoList,
  RedoListItem,
  RedoListItemSelectedSource,
} from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoListItemSize } from "@redotech/redo-web/arbiter-components/list/redo-list-item";
import { RedoBaseModal } from "@redotech/redo-web/arbiter-components/modal/redo-base-modal";
import {
  RedoModal,
  RedoModalHeader,
  RedoModalTheme,
} from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import EditPencilIcon from "@redotech/redo-web/icon-old/edit-pencil.svg";
import PlusIcon from "@redotech/redo-web/icon-old/plus.svg";
import TrashIcon from "@redotech/redo-web/icon-old/trash.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { QuillAttachmentCarousel } from "@redotech/redo-web/quill/quill-attachment-carousel";
import { Text } from "@redotech/redo-web/text";
import { TextInput } from "@redotech/redo-web/text-input";
import { OverflowTooltip } from "@redotech/redo-web/tooltip/overflow-tooltip";
import * as classNames from "classnames";
import Fuse from "fuse.js";
import "quill/dist/quill.snow.css";
import {
  Fragment,
  memo,
  ReactElement,
  useContext,
  useEffect,
  useState,
} from "react";
import { createMacro, deleteMacro, editMacro } from "../../client/macro";
import {
  EmailSubjectMacroAutomationPill,
  StatusMacroAutomationPill,
  TagMacroAutomationPill,
} from "./macro-automation-pill";
import { MacroEditorModal } from "./macro-editor-modal";
import * as macroModalCss from "./macro-modal.module.css";

import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { AddInternalNoteAutomation } from "./macro-automations/add-internal-note";
import { ForwardMessageAutomationPreviewMode } from "./macro-automations/forward-message";

const searcher = new Fuse<Macro>([], { keys: ["name"], threshold: 0.5 });

export const MacroModal = memo(function MacroModal({
  open,
  setOpen,
  setMacro,
  macros: externalMacros,
  refreshMacros,
}: {
  open: boolean;
  setOpen(value: boolean): void;
  setMacro(macro: Macro): Promise<void>;
  refreshMacros(): void;
  macros: Macro[] | undefined;
}) {
  const [macros, setMacros] = useState<Macro[]>([]);

  const client = useRequiredContext(RedoMerchantClientContext);
  const team = useRequiredContext(TeamContext);
  const user = useContext(UserContext);

  const [searchValue, setSearchValue] = useState<string>("");

  const [focusedMacro, setFocusedMacro] = useState<Macro | undefined>();

  const [macroPending, setMacroPending] = useState(false);

  const [macroEditorOpen, setMacroEditorOpen] = useState(false);
  const [macroBeingEdited, setMacroBeingEdited] = useState<Macro | undefined>();

  const [macroBeingDeleted, setMacroBeingDeleted] = useState<
    Macro | undefined
  >();

  useEffect(() => {
    setMacros(externalMacros || []);
  }, [externalMacros]);

  const canCreateMacro =
    !!user && permitted(user.permissions, Permission.CREATE_MACRO);

  const openMacroEditor = (withMacro?: Macro | undefined) => {
    setOpen(false);
    setMacroEditorOpen(true);
    setMacroBeingEdited(withMacro);
  };

  const closeMacroEditor = () => {
    setMacroEditorOpen(false);
    setOpen(true);
  };

  const closeAndResetSelf = () => {
    setOpen(false);
    setSearchValue("");
    setFocusedMacro(undefined);
  };

  const handleCancel = () => {
    closeAndResetSelf();
  };

  const handleApplyMacro = async (macro: Macro) => {
    setMacroPending(true);
    await setMacro(macro);
    setMacroPending(false);
    closeAndResetSelf();
  };

  const openDeleteConfirmationModal = async (macro: Macro) => {
    setMacroBeingDeleted(macro);
  };

  const handleDeleteMacro = async (macro: Macro) => {
    await deleteMacro(client, { id: macro._id });

    const updatedMacros = macros.filter((m) => m._id !== macro._id);
    setMacros(updatedMacros);

    amplitude.logEvent("delete-macro", { id: macro._id });
    refreshMacros();

    setMacroBeingDeleted(undefined);
    setOpen(true);
    setFocusedMacro(undefined);
  };

  async function handleMacroEditorSubmit(macro: PutMacroBody): Promise<void> {
    try {
      if (macroBeingEdited) {
        const updatedMacro = await editMacro(client, macroBeingEdited._id, {
          ...macro,
        });

        const updatedMacros: Macro[] = macros.map((m) => {
          if (m._id === macroBeingEdited._id) {
            return updatedMacro.data;
          }
          return m;
        });
        setMacros(updatedMacros);

        amplitude.logEvent("update-macro", { name: macro.name });
      } else {
        const newMacro = await createMacro(client, macro);
        const updatedMacros = [...macros, newMacro.data].sort((a, b) =>
          a.name.localeCompare(b.name),
        );
        setMacros(updatedMacros);

        amplitude.logEvent("create-macro", { name: macro.name });
      }
      refreshMacros();
      closeMacroEditor();
    } catch (err) {
      toast("Failed to save template. Template names must be unique.", {
        variant: "error",
      });
    }
  }

  const editorModal = macroEditorOpen && (
    <MacroEditorModal
      cancel={closeMacroEditor}
      client={client}
      initialState={macroBeingEdited}
      submitMacro={handleMacroEditorSubmit}
      team={team}
    />
  );

  const deleteModal = macroBeingDeleted && (
    <RedoModal
      onModalCloseRequested={() => {
        setMacroBeingDeleted(undefined);
        setOpen(true);
      }}
      primaryButton={{
        text: "Delete",
        onClick: () => handleDeleteMacro(macroBeingDeleted),
      }}
      secondaryButton={{
        text: "Cancel",
        onClick: () => {
          setMacroBeingDeleted(undefined);
          setOpen(true);
        },
      }}
      subtitle="Are you sure you want to delete this template?"
      theme={RedoModalTheme.ERROR}
      title="Delete template"
      TitleIcon={TrashIcon}
    />
  );
  return (
    <>
      <RedoBaseModal
        isOpen={open}
        onModalCloseRequested={handleCancel}
        otherClasses={[macroModalCss.modal]}
      >
        <RedoModalHeader
          cancelClicked={handleCancel}
          headerBorder
          title="Use template"
        />
        <section className={macroModalCss.wrapper}>
          <MacroSidebar
            canCreateMacro={canCreateMacro}
            macroFocused={setFocusedMacro}
            macros={macros}
            macroSelected={handleApplyMacro}
            open={open}
            openDeleteConfirmationModal={(macro) =>
              openDeleteConfirmationModal(macro)
            }
            openMacroEditor={openMacroEditor}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
          {focusedMacro ? (
            <MacroPreview
              applyMacro={(macro) => handleApplyMacro(macro)}
              macro={focusedMacro}
              macroPending={macroPending}
            />
          ) : (
            <MacroEmptyState
              canCreateMacro={canCreateMacro}
              openMacroEditor={openMacroEditor}
            />
          )}
        </section>
      </RedoBaseModal>
      {deleteModal}
      {editorModal}
    </>
  );
});

const snoozeDurationToLabel = (duration: SnoozeDuration) => {
  switch (duration) {
    case SnoozeDuration.SAME_DAY:
      return "Later in the day";
    case SnoozeDuration.ONE_DAY:
      return "The next day";
    case SnoozeDuration.TWO_DAYS:
      return "Two days later";
    case SnoozeDuration.SATURDAY:
      return "The next Saturday";
    case SnoozeDuration.MONDAY:
      return "The next Monday";
  }
};

const MacroEmptyState = memo(function MacroEmptyState({
  canCreateMacro,
  openMacroEditor,
}: {
  canCreateMacro: boolean;
  openMacroEditor(): void;
}) {
  return (
    <section className={macroModalCss.mainContent}>
      <div className={macroModalCss.details}>
        <div className={macroModalCss.preview2}>
          <p>You don't have any templates yet</p>
        </div>
      </div>
      {canCreateMacro && (
        <div className={macroModalCss.buttonSection}>
          <Divider />
          <div className={macroModalCss.buttonContainer2}>
            <Button
              onClick={() => openMacroEditor()}
              theme={ButtonTheme.PRIMARY}
            >
              Create a template
            </Button>
          </div>
        </div>
      )}
    </section>
  );
});

const MacroPreview = memo(function MacroPreview({
  macro,
  macroPending,
  applyMacro,
}: {
  macro: Macro;
  macroPending: boolean;
  applyMacro(macro: Macro): void;
}) {
  const hasAutomations = isAtLeastOneMacroAutomationActive(macro);

  const TemplatesForViewingPendingMacroAutomations: Record<
    MacroAutomation,
    ReactElement
  > = {
    [MacroAutomation.SET_STATUS]: (
      <>
        {!!macro.statusToSet && (
          <Flex align="center">
            <div className={macroModalCss.statusPillContainer}>
              <StatusMacroAutomationPill
                snoozeDuration={macro.snoozeDuration}
                statusToSet={macro.statusToSet}
              />
            </div>
            {macro.snoozeDuration && (
              <Text textColor="tertiary">{`- Until ${snoozeDurationToLabel(macro.snoozeDuration).charAt(0).toLowerCase()}${snoozeDurationToLabel(macro.snoozeDuration).slice(1)}`}</Text>
            )}
          </Flex>
        )}
      </>
    ),
    [MacroAutomation.ADD_INTERNAL_NOTE]: (
      <AddInternalNoteAutomation.PreviewTag
        noteToAddContent={macro.noteToAddContent ?? ""}
        shouldAddNote={macro.shouldAddNote ?? false}
      />
    ),
    [MacroAutomation.FORWARD_MESSAGE]: (
      <ForwardMessageAutomationPreviewMode
        content={macro.messageToForwardExtraContent ?? ""}
        email={macro.messageToForwardEmailAddress ?? ""}
        enabled={macro.shouldForwardMessage ?? false}
      />
    ),
    [MacroAutomation.CHANGE_EMAIL_SUBJECT]: (
      <>
        {!!macro.emailSubjectToSet && (
          <div className={macroModalCss.statusPillContainer}>
            <EmailSubjectMacroAutomationPill
              emailSubject={macro.emailSubjectToSet}
            />
          </div>
        )}
      </>
    ),
    [MacroAutomation.ADD_TAGS]: (
      <>
        {!!macro.tagsToAdd?.length && (
          <LabeledInput label="Add tags">
            <div className={macroModalCss.tagsContainer}>
              {macro.tagsToAdd?.map((tag) => {
                return <TagMacroAutomationPill key={tag} tag={tag} />;
              })}
            </div>
          </LabeledInput>
        )}
      </>
    ),
  };

  return (
    <section className={macroModalCss.mainContent}>
      <div className={macroModalCss.details}>
        <div className={macroModalCss.preview2}>
          <Flex dir="column">
            <Text fontSize="lg" fontWeight="semibold">
              Template name
            </Text>
            <Text>{macro.name}</Text>
          </Flex>
          <Text fontSize="md" fontWeight="semibold">
            Message preview
          </Text>
          <RenderedMessageContent
            fillSpace
            htmlContent={macro.htmlContent}
            rawText={macro.content}
          />
          {!!macro.attachments?.length && (
            <>
              <Text fontSize="md" fontWeight="semibold">
                Message attachments
              </Text>
              <QuillAttachmentCarousel attachments={macro.attachments} />
            </>
          )}
        </div>
        <Divider />
        <div className={classNames(macroModalCss.preview2)}>
          <div>
            <h3>
              <strong>Automations</strong>
            </h3>
          </div>
          <Flex dir="column">
            {Object.entries(TemplatesForViewingPendingMacroAutomations).map(
              ([automation, element]) => (
                <Fragment key={automation}>
                  <Flex>{element}</Flex>
                </Fragment>
              ),
            )}
          </Flex>

          {!hasAutomations && <p>No automations</p>}
        </div>
      </div>
      <div className={macroModalCss.buttonSection}>
        <Divider />
        <div className={macroModalCss.buttonContainer2}>
          <Button
            onClick={() => applyMacro(macro)}
            pending={macroPending}
            theme={ButtonTheme.PRIMARY}
          >
            Add to message
          </Button>
        </div>
      </div>
    </section>
  );
});

const MacroSidebar = memo(function MacroSidebar({
  macros,
  searchValue,
  setSearchValue,
  openMacroEditor,
  openDeleteConfirmationModal,
  canCreateMacro,
  macroFocused,
  macroSelected,
  open,
}: {
  macros: Macro[];
  searchValue: string;
  setSearchValue(value: string): void;
  openMacroEditor(macro?: Macro): void;
  openDeleteConfirmationModal(macro: Macro): void;
  canCreateMacro: boolean;
  macroFocused(macro: Macro): void;
  macroSelected(macro: Macro): void;
  open: boolean;
}) {
  const [focusedMacroIndex, setFocusedMacroIndex] = useState<number | null>(0);
  const [selectedMacroIndex, setSelectedMacroIndex] = useState<number | null>(
    0,
  );

  const filteredMacros = useSearch(searcher, macros, searchValue);

  const [templateSearchRef, setTemplateSearchRef] = useState<
    HTMLInputElement | HTMLTextAreaElement | null
  >(null);

  useEffect(() => {
    setFocusedMacroIndex(0);
    setSelectedMacroIndex(null);
  }, [searchValue]);

  useEffect(() => {
    if (open && templateSearchRef) {
      templateSearchRef.focus();
    }
  }, [open, templateSearchRef]);

  useEffect(() => {
    if (focusedMacroIndex === null) {
      return;
    }
    const macro = filteredMacros[focusedMacroIndex];
    if (macro) {
      macroFocused(macro);
    }
  }, [focusedMacroIndex, filteredMacros]);

  const macroActionsDropdown = (macro: Macro): RedoCommandMenuItem[] => {
    const edit: RedoCommandMenuItem = {
      Icon: EditPencilIcon,
      text: "Edit template",
      onClick: () => {
        openMacroEditor(macro);
      },
    };
    const remove: RedoCommandMenuItem = {
      Icon: TrashIcon,
      text: "Delete template",
      onClick: () => {
        openDeleteConfirmationModal(macro);
      },
    };

    return [edit, remove];
  };

  function handleMacroSelected(
    item: RedoListItem<{ macro: Macro; selected: boolean }>,
    source: RedoListItemSelectedSource,
  ) {
    const index = filteredMacros.findIndex(
      (macro) => macro._id === item.value.macro._id,
    );
    setSelectedMacroIndex(index);

    /** Keyboard enter key both selects and _selects_ as in performing this automation */
    if (source === RedoListItemSelectedSource.Keyboard) {
      macroSelected(item.value.macro);
    }
  }

  const templateListItems: RedoListItem<{ macro: Macro; selected: boolean }>[] =
    filteredMacros.map((macro, idx) => {
      return {
        key: macro._id,
        menu: canCreateMacro ? macroActionsDropdown(macro) : undefined,
        value: { macro, selected: idx === selectedMacroIndex },
      };
    });

  return (
    <div
      className={macroModalCss.sidebar}
      onMouseLeave={() => {
        if (selectedMacroIndex === null) {
          return;
        }
        macroFocused(filteredMacros[selectedMacroIndex]);
        setFocusedMacroIndex(null);
      }}
    >
      <div className={macroModalCss.sidebarHeader}>
        <Text fontWeight="semibold" textColor="secondary">
          Templates
        </Text>
        {canCreateMacro && (
          <Button onClick={() => openMacroEditor()} size={ButtonSize.MICRO}>
            <div className={macroModalCss.headerButton}>
              <PlusIcon />
            </div>
          </Button>
        )}
      </div>
      <div className={macroModalCss.sidebarSearch}>
        <TextInput
          onChange={setSearchValue}
          placeholder="Search"
          ref={setTemplateSearchRef}
          value={searchValue}
        />
      </div>
      <div className={macroModalCss.sidebarList}>
        <RedoList
          focusedIndex={focusedMacroIndex ?? undefined}
          isItemSelected={(item) => item.value.selected}
          items={templateListItems}
          itemSelected={handleMacroSelected}
          refToListenTo={templateSearchRef}
          setFocusedIndex={(index) => {
            const newValue =
              typeof index === "function"
                ? index(focusedMacroIndex || undefined)
                : index;

            if (newValue === undefined) {
              return;
            }
            setFocusedMacroIndex(newValue);
          }}
          size={RedoListItemSize.MEDIUM}
        >
          {(item) => <TemplateListItem macro={item.value.macro} />}
        </RedoList>
      </div>
    </div>
  );
});

const TemplateListItem = memo(function TemplateListItem({
  macro,
}: {
  macro: Macro;
}) {
  const [overflowRef, setOverflowRef] = useState<HTMLElement | null>(null);

  return (
    <OverflowTooltip
      direction={OverflowDirection.Horizontal}
      overflowRef={overflowRef}
      tooltipProps={{ title: macro.name }}
    >
      <Text
        overflow="hidden"
        ref={setOverflowRef}
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {macro.name}
      </Text>
    </OverflowTooltip>
  );
});
