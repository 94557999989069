import { useTriggerLoad } from "@redotech/react-util/load";
import { isString } from "@redotech/util/type";
import * as classNames from "classnames";
import { JSXElementConstructor, memo, ReactNode } from "react";
import CloseIcon from "../../arbiter-icon/x-close.svg";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { DisabledTooltip } from "../../tooltip/disabled-tooltip";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
  RedoButtonTheme,
} from "../buttons/redo-button";
import { RedoBaseModal } from "./redo-base-modal";
import * as redoModalCss from "./redo-modal.module.css";

export enum RedoModalSize {
  SMALL = "Small",
  MEDIUM = "Medium",
  LARGE = "Large",
}

export enum RedoModalTheme {
  ERROR = "Error",
  WARN = "Warn",
  SUCCESS = "Success",
  INFO = "Info",
  NONE = "None",
  NEUTRAL = "Neutral",
}

export enum RedoModalHeaderIconAlignment {
  ABOVE = "Above",
  INLINE = "Inline",
}

export enum ButtonPlacement {
  FULL = "Full",
  TIGHT = "Tight",
}

export interface RedoModalButtonProps {
  text: string;
  onClick(): void | Promise<void>;
  disabled?: boolean | string;
  theme?: RedoButtonTheme;
  IconLeading?: JSXElementConstructor<unknown>;
}

/**
 *
 * If you feel the need to add a prop here, consider using <RedoBaseModal> instead
 *
 * If you still feel strongly that a prop is missing that should be on the base modal,
 * please double check with someone on the Arbiter team.
 *
 * @param onModalCloseRequested - Called when x clicked, the user presses escape, or clicks away
 */
export interface RedoModalProps {
  title: string;
  subtitle?: string | ReactNode;
  primaryButton?: RedoModalButtonProps;
  secondaryButton?: RedoModalButtonProps;
  children?: ReactNode | undefined;
  onModalCloseRequested: () => void;
  TitleIcon?: JSXElementConstructor<unknown>;
  headerIconAlignment?: RedoModalHeaderIconAlignment;
  headerBorder?: boolean;
  theme?: RedoModalTheme;
  hideCloseButton?: boolean;
  modalSize?: RedoModalSize;
  footerBorder?: boolean;
  centerContent?: boolean;
  isOpen?: boolean;
  buttonPlacement?: ButtonPlacement;
  contentScrollable?: boolean;
}

export const RedoModal = memo(function RedoModal({
  title,
  subtitle,
  primaryButton,
  secondaryButton,
  children,
  onModalCloseRequested,
  TitleIcon,
  headerIconAlignment = RedoModalHeaderIconAlignment.ABOVE,
  headerBorder = false,
  theme = RedoModalTheme.NONE,
  hideCloseButton = false,
  modalSize = RedoModalSize.MEDIUM,
  footerBorder = false,
  centerContent = false,
  isOpen = true,
  buttonPlacement,
  contentScrollable = false,
}: RedoModalProps) {
  return (
    <RedoBaseModal
      isOpen={isOpen}
      onModalCloseRequested={onModalCloseRequested}
      otherClasses={[
        modalSizeClasses[modalSize],
        centerContent && redoModalCss.centered,
      ]}
    >
      <>
        <RedoModalHeader
          cancelClicked={onModalCloseRequested}
          centerContent={centerContent}
          headerBorder={headerBorder}
          headerIconAlignment={headerIconAlignment}
          hideCloseButton={hideCloseButton}
          subtitle={subtitle}
          theme={theme}
          title={title}
          TitleIcon={TitleIcon}
        />
        {children && (
          <section
            className={classNames(redoModalCss.main, {
              [redoModalCss.scrollable]: contentScrollable,
            })}
          >
            {children}
          </section>
        )}
        <RedoModalFooter
          buttonPlacement={buttonPlacement}
          footerBorder={footerBorder}
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
          theme={theme}
        />
      </>
    </RedoBaseModal>
  );
});

export const RedoModalHeader = memo(function RedoModalHeader({
  TitleIcon = undefined,
  theme = RedoModalTheme.NONE,
  title,
  subtitle,
  hideCloseButton = false,
  centerContent = false,
  headerIconAlignment = RedoModalHeaderIconAlignment.ABOVE,
  headerBorder = false,
  cancelClicked,
}: {
  TitleIcon?: JSXElementConstructor<unknown> | undefined;
  theme?: RedoModalTheme;
  title: string;
  subtitle?: string | ReactNode;
  headerIconAlignment?: RedoModalHeaderIconAlignment;
  hideCloseButton?: boolean;
  headerBorder?: boolean;
  centerContent?: boolean;
  cancelClicked(): void;
}) {
  return (
    <header
      className={classNames(
        headerBorder && redoModalCss.border,
        centerContent && redoModalCss.center,
        redoModalCss.header,
        headerIconAlignmentClasses[headerIconAlignment],
      )}
    >
      {TitleIcon && (
        <div
          className={classNames(
            headerIconAlignmentClasses[headerIconAlignment],
            redoModalCss.titleIcon,
          )}
        >
          <span
            className={classNames(redoModalCss.icon, iconThemeClasses[theme])}
          >
            <TitleIcon />
          </span>
        </div>
      )}
      <Flex dir="column" gap="xs">
        <Text fontSize="lg" fontWeight="semibold" textColor="primary">
          {title}
        </Text>
        {subtitle && (
          <Text fontSize="sm" textColor="tertiary">
            {subtitle}
          </Text>
        )}
      </Flex>

      {!hideCloseButton && (
        <RedoButton
          className={redoModalCss.closeButton}
          hierarchy={RedoButtonHierarchy.TERTIARY}
          IconLeading={CloseIcon}
          onClick={cancelClicked}
          size={RedoButtonSize.REGULAR}
        />
      )}
    </header>
  );
});

export const RedoModalFooter = memo(function RedoModalFooter({
  primaryButton,
  secondaryButton,
  theme = RedoModalTheme.NONE,
  footerBorder,
  buttonPlacement = ButtonPlacement.TIGHT,
}: {
  primaryButton?: RedoModalButtonProps;
  secondaryButton?: RedoModalButtonProps;
  theme?: RedoModalTheme;
  footerBorder?: boolean;
  buttonPlacement?: ButtonPlacement;
}) {
  const [isSubmitting, doSubmit] = useTriggerLoad(async () => {
    await primaryButton?.onClick();
  });

  const submitButton = primaryButton && (
    <DisabledTooltip
      disabledMessage={
        isString(primaryButton.disabled) && primaryButton.disabled
      }
      wrapperClass={redoModalCss.disabledWrapper}
    >
      <RedoButton
        className={redoModalCss.button}
        disabled={!!primaryButton.disabled}
        hierarchy={RedoButtonHierarchy.PRIMARY}
        IconLeading={primaryButton.IconLeading}
        onClick={() => doSubmit()}
        pending={isSubmitting.pending}
        size={RedoButtonSize.EXTRA_LARGE}
        text={primaryButton.text}
        theme={primaryButton.theme ?? redoModalThemeToButtonTheme(theme)}
      />
    </DisabledTooltip>
  );
  const cancelButton = secondaryButton && (
    <DisabledTooltip
      disabledMessage={
        isString(secondaryButton.disabled) && secondaryButton.disabled
      }
      wrapperClass={redoModalCss.disabledWrapper}
    >
      <RedoButton
        className={redoModalCss.button}
        disabled={isSubmitting.pending || !!secondaryButton.disabled}
        hierarchy={RedoButtonHierarchy.SECONDARY}
        IconLeading={secondaryButton.IconLeading}
        onClick={secondaryButton.onClick}
        size={RedoButtonSize.EXTRA_LARGE}
        text={secondaryButton.text}
        theme={secondaryButton.theme}
      />
    </DisabledTooltip>
  );

  return (
    <footer
      className={classNames(
        redoModalCss.footer,
        footerBorder && redoModalCss.border,
        buttonPlacementClasses[buttonPlacement],
      )}
    >
      <div
        className={classNames(
          redoModalCss.footerButtons,
          buttonPlacementClasses[buttonPlacement],
        )}
      >
        {cancelButton}
        {submitButton}
      </div>
    </footer>
  );
});

function redoModalThemeToButtonTheme(theme: RedoModalTheme): RedoButtonTheme {
  switch (theme) {
    case RedoModalTheme.ERROR:
      return RedoButtonTheme.DESTRUCTIVE;
    default:
      return RedoButtonTheme.NORMAL;
  }
}

const modalSizeClasses: Record<RedoModalSize, string> = {
  [RedoModalSize.SMALL]: redoModalCss.small,
  [RedoModalSize.MEDIUM]: "",
  [RedoModalSize.LARGE]: redoModalCss.large,
};

const iconThemeClasses: Record<RedoModalTheme, string> = {
  [RedoModalTheme.ERROR]: redoModalCss.error,
  [RedoModalTheme.WARN]: redoModalCss.warn,
  [RedoModalTheme.SUCCESS]: redoModalCss.success,
  [RedoModalTheme.INFO]: redoModalCss.info,
  [RedoModalTheme.NONE]: redoModalCss.none,
  [RedoModalTheme.NEUTRAL]: redoModalCss.neutral,
};

const headerIconAlignmentClasses = {
  [RedoModalHeaderIconAlignment.ABOVE]: undefined,
  [RedoModalHeaderIconAlignment.INLINE]: redoModalCss.inline,
};

const buttonPlacementClasses: Record<ButtonPlacement, string> = {
  [ButtonPlacement.FULL]: redoModalCss.fill,
  [ButtonPlacement.TIGHT]: redoModalCss.tight,
};
