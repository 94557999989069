import { IterableMap } from "@redotech/react-util/component";
import {
  orderToTrackable,
  Trackable,
  TrackableFulfillment,
  TrackableLineItem,
} from "@redotech/redo-model/trackable";
import { memo, useContext } from "react";
import { Button, ButtonSize, ButtonTheme } from "../../button";
import { Card } from "../../card";
import { CurrencyContext } from "../../currency";
import { Flex } from "../../flex";
import { Text } from "../../text";
import * as css from "./order.module.css";
import { PickupConfig } from "./pick-up-config-util";
import { TrackableStatus } from "./trackable-status";

export const OrderOrFulfillments = memo(function OrderOrFulfillments({
  order,
  merchantApp,
  largeTitle,
  showTracking = true,
  pickupConfig,
  handleTrackingPageButtonClickGenerator,
  storeUrl,
}: {
  order: any;
  merchantApp?: boolean;
  largeTitle?: boolean;
  showTracking?: boolean;
  pickupConfig: PickupConfig;
  handleTrackingPageButtonClickGenerator: (
    fulfillmentNumber: number | null,
  ) => (event: React.MouseEvent) => void;
  storeUrl: string;
}) {
  const trackable = orderToTrackable(order);

  return trackable.fulfillments.length > 0 &&
    !(order?.provider === "commentsold") ? (
    <>
      {trackable.fulfillments.map(
        (fulfillment: TrackableFulfillment, index: number) => (
          <FulfillmentDetailsCard
            fulfillment={fulfillment}
            handleTrackingPageButtonClick={handleTrackingPageButtonClickGenerator(
              index,
            )}
            key={fulfillment.id}
            largeTitle={largeTitle}
            pickupConfig={pickupConfig}
            showTracking={showTracking}
            storeUrl={storeUrl}
            trackable={trackable}
          />
        ),
      )}
    </>
  ) : (
    <OrderDetailsCard
      handleTrackingPageButtonClick={handleTrackingPageButtonClickGenerator(
        null,
      )}
      pickupConfig={pickupConfig}
      showTracking={showTracking}
      storeUrl={storeUrl}
      trackable={trackable}
    />
  );
});

export const OrderDetailsCard = memo(function OrderDetailsCard({
  trackable,
  handleTrackingPageButtonClick,
  pickupConfig,
  showTracking = true,
  storeUrl,
}: {
  trackable: Trackable;
  handleTrackingPageButtonClick: (event: React.MouseEvent) => void;
  pickupConfig: PickupConfig;
  storeUrl: string;
  showTracking?: boolean;
}) {
  return (
    <Card gap="none">
      <Flex align="center" justify="space-between">
        <Flex dir="column" gap="xs">
          <TrackableStatus
            fulfillment={undefined}
            pickupConfig={pickupConfig}
            source={trackable.originalOrder.shopify?.source ?? ""}
            status="unknown"
            storeUrl={storeUrl}
            trackable={trackable}
          />
        </Flex>
        {showTracking && (
          <Button
            onClick={handleTrackingPageButtonClick}
            size={ButtonSize.MICRO}
            theme={ButtonTheme.PRIMARY}
          >
            Track package
          </Button>
        )}
      </Flex>
      <Flex dir="column" gap="none">
        <IterableMap
          items={trackable.lineItems || []}
          keyFn={(item) => item.id}
        >
          {(item, i) => <LineItem item={item} />}
        </IterableMap>
      </Flex>
    </Card>
  );
});

export const FulfillmentDetailsCard = memo(function FulfillmentDetailsCard({
  fulfillment,
  trackable,
  largeTitle,
  showTracking = true,
  handleTrackingPageButtonClick,
  pickupConfig,
  storeUrl,
}: {
  fulfillment: TrackableFulfillment;
  trackable: Trackable;
  largeTitle?: boolean;
  showTracking?: boolean;
  handleTrackingPageButtonClick: (event: React.MouseEvent) => void;
  pickupConfig: PickupConfig;
  storeUrl: string;
}) {
  const fulfillmentIndex = trackable.fulfillments.indexOf(fulfillment);

  const tracker = trackable.fulfillments[fulfillmentIndex]?.trackers[0];

  const source: string = trackable.originalOrder.shopify?.sourceName ?? "";

  return (
    <Card gap="none">
      <Flex align="center" justify="space-between">
        <Flex dir="column" gap="xs">
          <Text fontSize={largeTitle ? "lg" : "md"} fontWeight="semibold">
            <TrackableStatus
              fulfillment={fulfillment}
              pickupConfig={pickupConfig}
              source={source}
              status={tracker?.status ?? "unknown"}
              storeUrl={storeUrl}
              trackable={trackable}
            />
          </Text>
          <Text fontSize="sm">
            Package {fulfillmentIndex + 1} of {trackable.fulfillments.length}
          </Text>
        </Flex>
        {showTracking && (
          <Button
            onClick={handleTrackingPageButtonClick}
            size={ButtonSize.MICRO}
            theme={ButtonTheme.PRIMARY}
          >
            Track package
          </Button>
        )}
      </Flex>
      <Flex dir="column" gap="none">
        <div>
          <IterableMap
            items={fulfillment.lineItems || []}
            keyFn={(item) => item.id}
          >
            {(item, i) => <LineItem item={item} />}
          </IterableMap>
        </div>
        {source !== "pos" && (
          <Flex className={css.fulfillmentFooter} mt="3xl" pt="3xl">
            <Flex dir="column" flex={1}>
              <Text fontSize="md" fontWeight="semibold">
                Delivery Method
              </Text>
              <Text fontSize="sm">
                {trackable.originalOrder.shopify.shippingLines[0]?.title ??
                  "Unknown"}
              </Text>
            </Flex>
            {tracker && (
              <Flex dir="column" flex={1}>
                <Text fontSize="md" fontWeight="semibold">
                  Carrier
                </Text>
                <Text fontSize="sm">
                  {replaceWordsByMerchantRequest(
                    tracker.carrier ?? "Unknown carrier",
                    storeUrl,
                  )}
                  :{" "}
                  {storeUrl === "shopconzuri.myshopify.com" ? (
                    <a href={tracker.public_url}>{tracker.tracking_code}</a>
                  ) : (
                    tracker.tracking_code
                  )}
                </Text>
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
    </Card>
  );
});

export const LineItem = memo(function LineItem({
  item,
}: {
  item: TrackableLineItem;
}) {
  const { formatCurrency: priceFormatter } = useContext(CurrencyContext);
  if (item.vendor === "re:do") {
    return null;
  }

  const image = item.image?.src;

  return (
    <Flex className={css.product} pt="3xl">
      <img className={css.productImage} src={image} />
      <Flex dir="column" flex={1} gap="xl" w="full">
        <Text as="div" fontSize="md" fontWeight="medium">
          {item.title}
        </Text>
        <Flex dir="row" fontSize="xs" justify="space-between">
          <Flex dir="column" flex={1}>
            <div>{item.variantTitle}</div>
            <div>{`Quantity: ${item.quantity}`}</div>
          </Flex>
          {priceFormatter && (
            <Flex flex={1} mt="auto" mx="auto">
              {priceFormatter(item.price)}
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
});

export function replaceWordsByMerchantRequest(
  statusMessage: string,
  storeUrl: string,
) {
  if (storeUrl === "shopconzuri.myshopify.com") {
    statusMessage = statusMessage.replaceAll("Yanwen", "Conzuri");
    statusMessage = statusMessage.replaceAll("pickup scan", "order received");
    statusMessage = statusMessage.replaceAll(
      "information input",
      "delivery information",
    );
    statusMessage = statusMessage.replaceAll("carrier", "local carrier");
    statusMessage = statusMessage.replaceAll("HongKong", "Conzuri");
    statusMessage = statusMessage.replaceAll("hong kong", "Conzuri");
    statusMessage = statusMessage.replaceAll("Guangdong Province", "Conzuri");
    statusMessage = statusMessage.replaceAll("Guangdong", "Conzuri");
    statusMessage = statusMessage.replaceAll("Shipper", "Conzuri");
    statusMessage = statusMessage.replaceAll("received", "boxed up");
    statusMessage = statusMessage.replaceAll(
      "China Post",
      "International Carrier",
    );
    statusMessage = statusMessage.replaceAll(
      "Handed over by Customers",
      "International Carrier",
    );
    statusMessage = statusMessage.replaceAll(
      "Item boxed up",
      "item received at local carrier",
    );
    statusMessage = statusMessage.replaceAll("Asia", "Conzuri");
    statusMessage = statusMessage.replaceAll("China", "Conzuri");
    statusMessage = statusMessage.replaceAll(
      "/安排投递/",
      "arrange for delivery",
    );
    // Remove any east asian unicode characters
    statusMessage = statusMessage.replace(/[\u4E00-\u9FFF\u3400-\u4DBF]/g, "");
  }
  return statusMessage;
}
