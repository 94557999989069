import { ExternalShipmentTracker } from "@redotech/redo-model/trackable";

export const prettyTrackerStatus: Record<
  ExternalShipmentTracker["status"],
  string
> = {
  unknown: "Unknown",
  pre_transit: "Pre-transit",
  in_transit: "In transit",
  out_for_delivery: "Out for delivery",
  delivered: "Delivered",
  available_for_pickup: "Available for pickup",
  return_to_sender: "Return to sender",
  failure: "Failure",
  cancelled: "Cancelled",
  error: "Error",
};

export const shipmentStatusList: Record<
  ExternalShipmentTracker["status"] | "stalled_in_transit",
  string
> = {
  unknown: "Unknown",
  pre_transit: "Pre-transit",
  in_transit: "In transit",
  out_for_delivery: "Out for delivery",
  delivered: "Delivered",
  available_for_pickup: "Available for pickup",
  return_to_sender: "Return to sender",
  failure: "Failure",
  cancelled: "Cancelled",
  error: "Error",
  stalled_in_transit: "Stalled",
};
