// extracted by mini-css-extract-plugin
export var actionButtonsContainer = "_cj-0";
export var footer = "_cj-2";
export var infoSection = "_cj-3";
export var infoSectionContainer = "_cj-4";
export var infoSectionTitleRow = "_cj-5";
export var itemDetails = "_cj-6";
export var line = "_cj-7";
export var lineItemImage = "_cj-1";
export var lineItems = "_cj-8";
export var modalContent = "_cj-9";
export var multiLineInfo = "_cj-a";
export var orderName = "_cj-b";
export var strong = "_cj-c";