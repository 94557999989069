// extracted by mini-css-extract-plugin
export var danger = "_3x-6";
export var lightPink = "_3x-a";
export var medium = "_3x-j";
export var nearSquare = "_3x-m";
export var neutralBlue = "_3x-8";
export var neutralYellow = "_3x-7";
export var noCapitalize = "_3x-1";
export var normal = "_3x-2";
export var pill = "_3x-0";
export var primary = "_3x-3";
export var primaryLight = "_3x-4";
export var shadow = "_3x-n";
export var small = "_3x-k";
export var solidBlack = "_3x-i";
export var solidGreen = "_3x-d";
export var solidLightBlue = "_3x-f";
export var solidNavy = "_3x-e";
export var solidOrange = "_3x-h";
export var solidPink = "_3x-g";
export var solidRed = "_3x-b";
export var solidWhite = "_3x-9";
export var solidYellow = "_3x-c";
export var success = "_3x-5";
export var xsmall = "_3x-l";