import { FulfillmentGroupForPackingSlip } from "../fulfillments/fulfillment-group";
import type { Team } from "../team";
import { UtmParameters } from "../utm";
import { PackingSlip, PackingSlipPageLayout } from "./packing-slip-definition";
export namespace PaddingSize {
  export const SMALL = 8;
  export const MEDIUM = 16;
  export const LARGE = 32;
}

export enum FontWeight {
  NORMAL = "normal",
  BOLD = "bold",
}

export type Padding = {
  top: number;
  right: number;
  bottom: number;
  left: number;
};

export enum Alignment {
  LEFT = "left",
  CENTER = "center",
  RIGHT = "right",
}

export enum FontFamily {
  ARIAL = "Arial",
  COURIER_NEW = "Courier New",
  GEORGIA = "Georgia",
  LUCIDA_SANS_UNICODE = "Lucida Sans Unicode",
  TAHOMA = "Tahoma",
  TIMES_NEW_ROMAN = "Times New Roman",
  TREBUCHET_MS = "Trebuchet MS",
  VERDANA = "Verdana",
}

export const FONT_WEIGHTS = [FontWeight.NORMAL, FontWeight.BOLD] as const;

export const FONT_FAMILIES = [
  FontFamily.ARIAL,
  FontFamily.COURIER_NEW,
  FontFamily.GEORGIA,
  FontFamily.LUCIDA_SANS_UNICODE,
  FontFamily.TAHOMA,
  FontFamily.TIMES_NEW_ROMAN,
  FontFamily.TREBUCHET_MS,
  FontFamily.VERDANA,
] as const;

export const RICH_TEXT_VARIABLES: (keyof FulfillmentGroupForPackingSlip)[] = [
  "recipientFirstName",
  "recipientFullName",
  "orderNumber",
  "orderDate",
  "orderTotal",
  "shippingCarrier",
  "shippingService",
];

export type RichTextVariable = (typeof RICH_TEXT_VARIABLES)[number];
export type RichTextVariables = { [K in RichTextVariable]?: string };

export enum OrderItemTableColumnType {
  BIN = "bin",
  ITEM_THUMBNAIL = "itemThumbnail",
  ITEM_NAME_AND_DESCRIPTION = "itemDescription",
  QUANTITY = "quantity",
  PRICE = "price",
  SKU = "sku",
  UPC = "upc",
  // VENDOR_ID = "vendor-id",
}

export enum OrderItemSort {
  ORDER_NUMBER = "order-number",
  ORDER_SKU = OrderItemTableColumnType.SKU,
  ORDER_UPC = OrderItemTableColumnType.UPC,
  ORDER_BIN = OrderItemTableColumnType.BIN,
  ORDER_ITEM_NAME = OrderItemTableColumnType.ITEM_NAME_AND_DESCRIPTION,
  ORDER_QUANTITY = OrderItemTableColumnType.QUANTITY,
}

export const OrderItemSortToColumnType: Record<
  OrderItemSort,
  keyof OrderItemTableColumnData
> = {
  [OrderItemSort.ORDER_NUMBER]: OrderItemTableColumnType.SKU,
  [OrderItemSort.ORDER_SKU]: OrderItemTableColumnType.SKU,
  [OrderItemSort.ORDER_UPC]: OrderItemTableColumnType.UPC,
  [OrderItemSort.ORDER_BIN]: OrderItemTableColumnType.BIN,
  [OrderItemSort.ORDER_ITEM_NAME]:
    OrderItemTableColumnType.ITEM_NAME_AND_DESCRIPTION,
  [OrderItemSort.ORDER_QUANTITY]: OrderItemTableColumnType.QUANTITY,
};

export type OrderItemTableColumn = {
  type: OrderItemTableColumnType;
  enabled: boolean;
  relativeFontSize?: number;
  fontWeight?: FontWeight;
};

export type OrderItemTableColumnData = {
  bin: string;
  itemThumbnail: string;
  itemName: string;
  itemDescription: string;
  quantity: number;
  price: string;
  sku: string;
  upc: string;
};

export const orderItemTableColumnTypeToLabel: Record<
  OrderItemTableColumnType,
  string
> = {
  [OrderItemTableColumnType.BIN]: "Bin",
  [OrderItemTableColumnType.ITEM_THUMBNAIL]: "Image",
  [OrderItemTableColumnType.ITEM_NAME_AND_DESCRIPTION]: "Product Name",
  [OrderItemTableColumnType.QUANTITY]: "Quantity",
  [OrderItemTableColumnType.PRICE]: "Price",
  [OrderItemTableColumnType.SKU]: "SKU",
  [OrderItemTableColumnType.UPC]: "UPC",
  // [OrderItemTableColumnType.VENDOR_ID]: "Vendor ID",
};

export enum PackingSlipBlockType {
  IMAGE = "image",
  LOGO = "logo",
  TEXT = "text",
  SPACER = "spacer",
  LINE = "line",
  COLUMN = "column",
  BILLING_ADDRESS = "billing-address",
  COMPANY_ADDRESS = "company-address",
  CONTACT_INFORMATION = "contact-information",
  NOTE_FROM_CUSTOMER = "note-from-customer",
  NOTE_TO_CUSTOMER = "note-to-customer",
  ORDER_BARCODE = "order-barcode",
  ORDER_DATE = "order-date",
  ORDER_ITEMS = "order-items",
  ORDER_DETAILS = "order-details",
  ORDER_NUMBER = "order-number",
  PACKAGE_INFORMATION = "package-information",
  //OUT_OF_STOCK_ITEMS = "out-of-stock-items", need more definition
  PAYMENT_BREAKDOWN = "payment-breakdown",
  PAYMENT_METHOD = "payment-method",
  //PURCHASE_ORDER_NUMBER = "purchase-order-number", need more definition
  PACKAGE_WEIGHT = "package-weight",
  //PROMOTIONAL_MESSAGE = "promotional-message", can this not be a text block?
  SHIPPING_ADDRESS = "shipping-address",
  SHIPPING_METHOD = "shipping-method",
  //SPECIAL_INSTRUCTIONS = "special-instructions", can this not be a text block?
  TRACKING_NUMBER = "tracking-number",
}

export enum PackingSlipBlockCategory {
  GENERAL = "general",
  ORDER = "order",
  MERCHANT = "merchant",
  CUSTOMER = "customer",
}

export namespace Section {
  export interface BaseSection {
    blockId: string;
    duplicate?: () => Section;
    sectionPadding: Padding;
  }

  export interface FontSection extends BaseSection {
    fontFamily?: FontFamily | null;
    fontSize?: number | null;
    textColor?: string | null;
    fontWeight?: FontWeight | null;
  }

  export interface AddressSection extends FontSection {
    header?: string;
    showHeader?: boolean;
    showName?: boolean;
    showAddress?: boolean;
    showPhone?: boolean;
    showEmail?: boolean;
  }

  export interface BillingAddress extends AddressSection {
    type: PackingSlipBlockType.BILLING_ADDRESS;
  }

  export interface Column extends BaseSection {
    type: PackingSlipBlockType.COLUMN;
    columns: SectionWithoutColumn[];
    columnCount: number;
    sectionColor: string;
    gap: number;
  }

  export interface CompanyAddress extends AddressSection {
    type: PackingSlipBlockType.COMPANY_ADDRESS;
  }

  export interface ContactInformation extends FontSection {
    type: PackingSlipBlockType.CONTACT_INFORMATION;
    showName: boolean;
    showAddress: boolean;
    showPhone: boolean;
    showEmail: boolean;
  }

  export interface NoteFromCustomer extends FontSection {
    type: PackingSlipBlockType.NOTE_FROM_CUSTOMER;
  }

  export interface NoteToCustomer extends FontSection {
    type: PackingSlipBlockType.NOTE_TO_CUSTOMER;
  }

  export interface OrderBarcode extends BaseSection {
    type: PackingSlipBlockType.ORDER_BARCODE;
    width?: number;
    height?: number;
  }

  export interface OrderDate extends FontSection {
    type: PackingSlipBlockType.ORDER_DATE;
  }

  export interface OrderItems extends FontSection {
    type: PackingSlipBlockType.ORDER_ITEMS;
    sort: OrderItemSort;
    tableColumns: OrderItemTableColumn[];
    emphasizeQuantity?: boolean | null;
    rowHeight?: number | null;
    showDescription?: boolean | null;
    includeHeader?: boolean | null;
    tableBorderColor?: string | null;
  }

  export interface OrderDetails extends FontSection {
    type: PackingSlipBlockType.ORDER_DETAILS;
    showRecipientInformation: boolean;
    showOrderNumber: boolean;
    showOrderDate: boolean;
    showShippingService: boolean;
    stacked?: boolean;
  }

  export interface OrderNumber extends FontSection {
    type: PackingSlipBlockType.ORDER_NUMBER;
  }

  export interface PackageInformation extends FontSection {
    type: PackingSlipBlockType.PACKAGE_INFORMATION;
  }

  export interface PaymentBreakdown extends FontSection {
    type: PackingSlipBlockType.PAYMENT_BREAKDOWN;
  }

  export interface PaymentMethod extends FontSection {
    type: PackingSlipBlockType.PAYMENT_METHOD;
    showSubtotal: boolean;
    showPaymentMethod: boolean;
    showPaymentDate: boolean;
  }

  export interface PackageWeight extends FontSection {
    type: PackingSlipBlockType.PACKAGE_WEIGHT;
  }

  export interface ShippingAddress extends AddressSection {
    type: PackingSlipBlockType.SHIPPING_ADDRESS;
  }

  export interface ShippingMethod extends FontSection {
    type: PackingSlipBlockType.SHIPPING_METHOD;
  }

  export interface TrackingNumber extends FontSection {
    type: PackingSlipBlockType.TRACKING_NUMBER;
  }

  export interface Text extends BaseSection {
    type: PackingSlipBlockType.TEXT;
    textColor: string;
    fontSize: number;
    fontFamily: FontFamily;
    sectionColor: string;
    linkColor: string;
    text: string;
  }

  export interface Line extends BaseSection {
    type: PackingSlipBlockType.LINE;
    color: string;
    sectionColor: string;
  }

  export interface Spacer extends BaseSection {
    type: PackingSlipBlockType.SPACER;
    sectionColor: string;
    height: number;
  }

  export interface Image extends BaseSection {
    type: PackingSlipBlockType.IMAGE;
    imageUrl: string;
    sectionColor: string;
    showCaption: boolean;
    maxHeight?: number | null;
    caption?: string;
    altText?: string;
    clickthroughUrl?: string;
    aspectRatio?: number;
  }

  export interface Logo extends BaseSection {
    type: PackingSlipBlockType.LOGO;
    imageUrl?: string;
    maxHeight?: number | null;
  }
}

export type SectionWithoutColumn =
  | Section.Text
  | Section.Line
  | Section.Spacer
  | Section.Image
  | Section.Logo
  | Section.BillingAddress
  | Section.CompanyAddress
  | Section.ContactInformation
  | Section.NoteFromCustomer
  | Section.NoteToCustomer
  | Section.OrderBarcode
  | Section.OrderDate
  | Section.OrderItems
  | Section.OrderDetails
  | Section.OrderNumber
  | Section.PackageInformation
  | Section.PaymentBreakdown
  | Section.PaymentMethod
  | Section.PackageWeight
  | Section.ShippingAddress
  | Section.ShippingMethod
  | Section.TrackingNumber;

export type Section = SectionWithoutColumn | Section.Column;

export interface SharedProps {
  team: Team;
  template: Omit<PackingSlip, "sections" | "team">;
  isBuilder: boolean;
  blockId?: string;
  utm: UtmParameters;
}

export class PackingSlipUtmParameters extends UtmParameters {
  constructor(campaign?: string, contact?: string) {
    super({ source: "redo", medium: "packing-slip", campaign, contact });
  }

  override getSource(): string {
    return super.getSource() ?? "";
  }

  override getMedium(): string {
    return super.getMedium() ?? "";
  }
}

export enum ScreenSize {
  DESKTOP = "desktop",
  MOBILE = "mobile",
}

export enum PageSize {
  FULL_SIZE = "full-size",
  LABEL = "label",
}

export enum PageOrientation {
  PORTRAIT = "portrait",
  LANDSCAPE = "landscape",
}

export function getPageFormatDimensions(pageLayout: PackingSlipPageLayout) {
  if (pageLayout.size === PageSize.FULL_SIZE) {
    return pageLayout.orientation === PageOrientation.PORTRAIT
      ? { width: 8.5, height: 11 }
      : { width: 11, height: 8.5 };
  } else if (pageLayout.size === PageSize.LABEL) {
    return pageLayout.orientation === PageOrientation.PORTRAIT
      ? { width: 4, height: 6 }
      : { width: 6, height: 4 };
  }
  throw new Error(`Invalid page size: ${pageLayout.size}`);
}
