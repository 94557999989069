// extracted by mini-css-extract-plugin
export var bubbleContent = "_86-0";
export var dark = "_86-2";
export var dot = "_86-7";
export var firstDot = "_86-9";
export var left = "_86-3";
export var light = "_86-1";
export var loadingBubble = "_86-5";
export var loadingDot = "_86-c";
export var loadingDotContainer = "_86-6";
export var moveUpDown = "_86-8";
export var right = "_86-4";
export var secondDot = "_86-a";
export var thirdDot = "_86-b";