import * as skeletonCss from "./skeleton.module.css";

export function SkeletonBlock() {
  return <div className={skeletonCss.skeleton} />;
}

export function SkeletonText({
  length,
}: {
  length: number;
  animated?: boolean;
}) {
  return <span className={skeletonCss.skeletonText}>{"_".repeat(length)}</span>;
}

export function AnimatedSkeletonBlock({
  height,
  width,
}: {
  height: string;
  width: string;
}) {
  return (
    <span className={skeletonCss.skeletonText} style={{ width, height }}>
      _
    </span>
  );
}
