import { z } from "zod";
import { UserInfo } from "./get-advanced-flows";

/**
 * Gets the most recently published flow for each version group id
 * If no flows are published with that version group id, returns the most
 * recently created flow for that version group id.
 */
export const getAdvancedFlowsByVersionGroupId = {
  input: z.object({
    versionGroupIds: z.array(z.string()),
    getUsers: z.boolean(),
  }),
  output: z.object({
    advancedFlows: z.array(z.any()),
    users: z.map(z.string(), UserInfo).nullish(),
  }),
};
