// extracted by mini-css-extract-plugin
export var brand = "_4e-7";
export var compact = "_4e-5";
export var extraSmall = "_4e-a";
export var icon = "_4e-0";
export var large = "_4e-3";
export var light = "_4e-1";
export var medium = "_4e-4";
export var outline = "_4e-9";
export var primaryDark = "_4e-8";
export var primaryLight = "_4e-6";
export var primaryModern = "_4e-b";
export var success = "_4e-2";