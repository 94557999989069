import { Equal, numberEqual, objectEqual } from "@redotech/util/equal";

/**
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15 RFC 9110 15 Status Codes}
 */

export class Status {
  constructor(
    /**
     * Numeric code
     */
    readonly code: number,
    readonly reason: string,
  ) {}

  get codeString() {
    return String(this.code);
  }

  class() {
    return new StatusClass(Math.floor(this.code / 100));
  }

  toString() {
    return `${this.code} ${this.reason}`;
  }

  static readonly equals: Equal<Status> = objectEqual({ code: numberEqual });
}

export class StatusClass {
  constructor(readonly digit: number) {}

  toString() {
    return `${this.digit}xx`;
  }

  static readonly equals: Equal<StatusClass> = objectEqual({
    digit: numberEqual,
  });
}

/**
 * Informational 1xx
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.2 RFC 9110 15.2 Informational 1xx}
 */
export const InformationalClass = new StatusClass(1);

/**
 * Successful 2xx
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.3 RFC 9110 15.3 Successful 2xx}
 */
export const SuccessfulClass = new StatusClass(2);

/**
 * Redirection 3xx
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.4 RFC 9110 15.4 Redirection 3xx}
 */
export const RedirectionClass = new StatusClass(3);

/**
 * Client Error 4xx
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.5 RFC 9110 15.5 Client Error 4xx}
 */
export const ClientErrorClass = new StatusClass(4);

/**
 * Server Error 5xx
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.6 RFC 9110 15.6 Client Error 4xx}
 */
export const ServerErrorClass = new StatusClass(5);

/**
 * 100 Continue
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.2.1 RFC 9110 15.2.1 100 Continue}
 */
export const ContinueStatus = new Status(100, "Continue");

/**
 * 101 Switching Protocols
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.2.2 RFC 9110 15.2.2 101 Switching Protocols}
 */
export const SwitchingProtocolsStatus = new Status(101, "Switching Protocols");

/**
 * 200 OK
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.3.1 RFC 9110 15.3.1 200 OK}
 */
export const OkStatus = new Status(200, "OK");

/**
 * 201 Created
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.3.2 RFC 9110 15.3.2 201 Created}
 */
export const CreatedStatus = new Status(201, "Created");

/**
 * 202 Accepted
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.3.3 RFC 9110 15.3.3 202 Accepted}
 */
export const AcceptedStatus = new Status(202, "Accepted");

/**
 * 203 Non-Authoritative Information
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.3.4 RFC 9110 15.3.4 203 Non-Authoritative Information}
 */
export const NonAuthoritativeInformationStatus = new Status(
  203,
  "Non-Authoritative Information",
);

/**
 * 204 No Content
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.3.5 RFC 9110 15.3.5 204 No Content}
 */
export const NoContentStatus = new Status(204, "No Content");

/**
 * 205 Reset Content
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.3.6 RFC 9110 15.3.6 205 Reset Content}
 */
export const ResetContentStatus = new Status(205, "Reset Content");

/**
 * 206 Partial Content
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.3.7 RFC 9110 15.3.7 206 Partial Content}
 */
export const PartialContentStatus = new Status(206, "Partial Content");

/**
 * 300 Multiple Choices
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.4.3 RFC 9110 15.4.1 300 Multiple Choices}
 */
export const MultipleChoicesStatus = new Status(300, "Multiple Choices");

/**
 * 301 Multiple Choices
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.4.3 RFC 9110 15.4.2 301 Multiple Choices}
 */
export const MovedPermanentlyStatus = new Status(301, "Moved Permanently");

/**
 * 302 Found
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.4.3 RFC 9110 15.4.3 302 Found}
 */
export const FoundStatus = new Status(302, "Found");

/**
 * 302 Found
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.4.4 RFC 9110 15.4.3 303 See Other}
 */
export const SeeOtherStatus = new Status(303, "See Other");

/**
 * 304 Not Modified
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.4.5 RFC 9110 15.4.3 304 Not Modified}
 */
export const NotModifiedStatus = new Status(304, "Not Modified");

/**
 * 307 Temporary Redirect
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.4.5 RFC 9110 15.4.8 307 Temporary Redirect}
 */
export const TemporaryRedirectStatus = new Status(307, "Temporary Redirect");

/**
 * 308 Permanent Redirect
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.4.5 RFC 9110 15.4.9 308 Permanent Redirect}
 */
export const PermanentRedirectStatus = new Status(308, "Permanent Redirect");

/**
 * 400 Bad Request
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.5.1 RFC 9110 15.5.1 400 Bad Request}
 */
export const BadRequestStatus = new Status(400, "Bad Request");

/**
 * 401 Unauthorized
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.5.2 RFC 9110 15.5.2 401 Unauthorized}
 */
export const UnauthorizedStatus = new Status(401, "Unauthorized");

/**
 * 402 Payment Required
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.5.3 RFC 9110 15.5.3 402 Payment Required}
 */
export const PaymentRequiredStatus = new Status(402, "Payment Required");

/**
 * 403 Forbidden
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.5.4 RFC 9110 15.5.4 403 Forbidden}
 */
export const ForbiddenStatus = new Status(403, "Forbidden");

/**
 * 404 Not FOund
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.5.4 RFC 9110 15.5.5 404 Not Found}
 */
export const NotFoundStatus = new Status(404, "Not Found");

/**
 * 405 Method Not Allowed
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.5.6 RFC 9110 15.5.6 405 Method Not Allowed}
 */
export const MethodNotAllowedStatus = new Status(405, "Method Not Allowed");

/**
 * 406 Not Acceptable
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.5.7 RFC 9110 15.5.7 406 Not Acceptable}
 */
export const NotAcceptableStatus = new Status(406, "Not Acceptable");

/**
 * 407 Proxy Authentication Required
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.5.8 RFC 9110 15.5.8 407 Proxy Authentication Required}
 */
export const ProxyAuthenticationRequiredStatus = new Status(
  407,
  "Proxy Authentication Required",
);

/**
 * 408 Request Timeout
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.5.9 RFC 9110 15.5.9 408 Request Timeout}
 */
export const RequestTimeoutStatus = new Status(408, "Request Timeout");

/**
 * 409 Conflict
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.5.10 RFC 9110 15.5.10 409 Conflict}
 */
export const ConflictStatus = new Status(409, "Conflict");

/**
 * 410 Gone
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.5.11 RFC 9110 15.5.11 410 Gone}
 */
export const GoneStatus = new Status(410, "Gone");

/**
 * 411 Length Required
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.5.12 RFC 9110 15.5.12 411 Length Required}
 */
export const LengthRequiredStatus = new Status(411, "Length Required");

/**
 * 412 Precondition Failed
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.5.13 RFC 9110 15.5.13 412 Precondition Failed}
 */
export const PreconditionFailedStatus = new Status(412, "Precondition Failed");

/**
 * 415 Unsupported Media Type
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.5.16 RFC 9110 15.5.16 415 Unsupported Media Type}
 */
export const UnsupportedMediaTypeStatus = new Status(
  415,
  "Unsupported Media Type",
);

/**
 * 422 Unprocessable Content
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.5.20 RFC 9110 15.5.20 422 Unprocessable Content}
 */
export const UnprocessableContentStatus = new Status(
  422,
  "Unprocessable Content",
);

/**
 * 426 Upgrade Required
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.5.21 RFC 9110 15.5.21 426 Upgrade Required}
 */
export const UpgradeRequiredStatus = new Status(426, "Upgrade Required");

/**
 * 500 Internal Server Error
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.6.1 RFC 9110 15.6.1 500 Internal Server}
 */
export const InternalServerErrorStatus = new Status(
  500,
  "Internal Server Error",
);

/**
 * 501 Not Implemented
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.6.2 RFC 9110 15.6.2 501 Not Implemented}
 */
export const NotImplementedStatus = new Status(501, "Not Implemented");

/**
 * 502 Bad Gateway
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.6.3 RFC 9110 15.6.3 502 Bad Gateway}
 */
export const BadGatewayStatus = new Status(502, "Bad Gateway");

/**
 * 503 Service Unavailable
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.6.4 RFC 9110 15.6.4 503 Service Unavailable}
 */
export const ServiceUnavailableStatus = new Status(503, "Service Unavailable");

/**
 * 504 Gateway Timeout
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.6.5 RFC 9110 15.6.5 504 Gateway Timeout}
 */
export const GatewayTimeoutStatus = new Status(504, "Gateway Timeout");

/**
 * 505 HTTP Version Not Supported
 * @see {@link https://www.rfc-editor.org/rfc/rfc9110#section-15.6.6 RFC 9110 15.6.6 505 HTTP Version Not Supported}
 */
export const HttpVersionNotSupportedStatus = new Status(
  505,
  "HTTP Version Not Supported",
);
