import { z } from "zod";

export const UserInfo = z.object({
  _id: z.string(),
  name: z.string().nullish(),
  email: z.string().nullish(),
});

export type UserInfo = z.infer<typeof UserInfo>;

export const getAdvancedFlows = {
  input: z.object({ getUsers: z.boolean() }),
  output: z.object({
    advancedFlows: z.any(),
    users: z.map(z.string(), UserInfo).nullish(),
  }),
};
