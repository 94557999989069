// extracted by mini-css-extract-plugin
export var colorSquare = "_ch-0";
export var danger = "_ch-5";
export var lightPink = "_ch-8";
export var neutralBlue = "_ch-7";
export var neutralYellow = "_ch-6";
export var normal = "_ch-1";
export var primary = "_ch-2";
export var primaryLight = "_ch-3";
export var solidBlack = "_ch-g";
export var solidGreen = "_ch-b";
export var solidLightBlue = "_ch-d";
export var solidNavy = "_ch-c";
export var solidOrange = "_ch-f";
export var solidPink = "_ch-e";
export var solidRed = "_ch-9";
export var solidYellow = "_ch-a";
export var success = "_ch-4";