import { memo } from "react";
import HelpIcon from "./arbiter-icon/help-circle.svg";
import { Flex } from "./flex";
import { RedoTooltipProps, Tooltip } from "./tooltip/tooltip";

export const InfoTooltip = memo(function InfoTooltip({
  tooltip,
  boxSize,
}: {
  tooltip: Omit<RedoTooltipProps, "children">;
  boxSize: number;
}) {
  return (
    <Tooltip {...tooltip}>
      <Flex>
        <HelpIcon
          style={{
            width: `${boxSize}px`,
            height: `${boxSize}px`,
            color: "var(--redo-colors-foreground-fg-quinary-400)",
          }}
        />
      </Flex>
    </Tooltip>
  );
});
