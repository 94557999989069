import { CarrierAndServiceSchema } from "@redotech/redo-model/fulfillments/fulfillment-carriers-and-services";
import {
  FulfillmentOrderDataSchema,
  OMSUserSchema,
} from "@redotech/redo-model/fulfillments/fulfillment-group";
import { PrintStatusSchema } from "@redotech/redo-model/fulfillments/fullfilment-print-status";
import { ParcelSchema } from "@redotech/redo-model/outbound-labels/parcel";
import { WeightSchema } from "@redotech/redo-model/outbound-labels/util";
import { TagSchema } from "@redotech/redo-model/tag";
import { z } from "zod";

const FulfillmentOrderUpdateSchema = z.object({
  fulfillmentGroupId: z.string(),
  selectedParcel: ParcelSchema.nullish(),
  overrideWeight: WeightSchema.nullish(),
  selectedShippingRate: CarrierAndServiceSchema.nullish(),
  displayWeightGrams: z.number().nullish(),
  assignedUser: OMSUserSchema.nullish(),
  tags: z.array(TagSchema).nullish(),
  noteFromBuyer: z.string().nullish(),
  noteToBuyer: z.string().nullish(),
  internalNote: z.string().nullish(),
  printStatus: PrintStatusSchema.nullish(),
  signatureRequired: z.boolean().nullish(),
  adultSignatureRequired: z.boolean().nullish(),
});

export type FulfillmentOrderUpdate = z.infer<
  typeof FulfillmentOrderUpdateSchema
>;

export const updateFulfillmentGroups = {
  input: z.object({ updates: z.array(FulfillmentOrderUpdateSchema) }),
  output: z.record(z.string(), FulfillmentOrderDataSchema),
};
