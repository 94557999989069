// extracted by mini-css-extract-plugin
export var arrows = "_6h-5";
export var decrementer = "_6h-9";
export var disabled = "_6h-3";
export var error = "_6h-1";
export var hiddenText = "_6h-a";
export var incrementer = "_6h-8";
export var input = "_6h-7";
export var inputWrapper = "_6h-0";
export var readonly = "_6h-2";
export var regular = "_6h-6";
export var small = "_6h-4";