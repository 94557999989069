// extracted by mini-css-extract-plugin
export var actionButton = "_7l-t";
export var buttonContainer = "_7l-0";
export var buttonContainer2 = "_7l-1";
export var buttonSection = "_7l-2";
export var details = "_7l-3";
export var dropdownItem = "_7l-5";
export var dropdownItemsContainer = "_7l-4";
export var fileInput = "_7l-r";
export var headerButton = "_7l-6";
export var icon = "_7l-7";
export var input = "_7l-8";
export var macroListItem = "_7l-9";
export var mainContent = "_7l-a";
export var microText = "_7l-b";
export var modal = "_7l-w";
export var optionsButton = "_7l-c";
export var paperclip = "_7l-s";
export var pillContent = "_7l-d";
export var preview = "_7l-e";
export var preview2 = "_7l-f";
export var quillEditor = "_7l-q";
export var selectedMacro = "_7l-g";
export var sidebar = "_7l-h";
export var sidebarHeader = "_7l-j";
export var sidebarList = "_7l-i";
export var sidebarListItem = "_7l-k";
export var sidebarSearch = "_7l-l";
export var snoozeDurationWrapper = "_7l-v";
export var statusPillContainer = "_7l-m";
export var tagsContainer = "_7l-n";
export var unselectedMacro = "_7l-o";
export var variable = "_7l-u";
export var wrapper = "_7l-p";