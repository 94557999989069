// extracted by mini-css-extract-plugin
export var copyableField = "_bs-4";
export var customerBadge = "_bs-0";
export var customerInfoTitle = "_bs-2";
export var details = "_bs-1";
export var dropdownContainer = "_bs-5";
export var dropdownImage = "_bs-6";
export var editAssigneeForm = "_bs-a";
export var editButton = "_bs-3";
export var email = "_bs-7";
export var hidden = "_bs-d";
export var input = "_bs-8";
export var saveButton = "_bs-b";
export var shopifyIcon = "_bs-c";
export var username = "_bs-9";