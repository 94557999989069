// extracted by mini-css-extract-plugin
export var bubble = "_3d-0";
export var container = "_3d-3";
export var contrastBorder = "_3d-4";
export var greyOut = "_3d-2";
export var initialsWrapper = "_3d-1";
export var large = "_3d-9";
export var medium = "_3d-8";
export var mediumSmall = "_3d-7";
export var micro = "_3d-5";
export var small = "_3d-6";
export var tiny = "_3d-b";
export var xTiny = "_3d-a";