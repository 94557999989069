import { breadcrumbSlot } from "@redotech/redo-web/breadcrumb";
import { RouteObject } from "react-router-dom";

const checkoutExperienceBreadcrumb = breadcrumbSlot();

export const conversionRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Conversion", breadcrumbNavigate: false },
    async lazy() {
      const { Page } = await import("../app/page");
      return { element: <Page hidePadding={hidePadding} /> };
    },
    children: [
      {
        path: "live-view",
        handle: { breadcrumb: "Live view" },
        async lazy() {
          const { LiveViewPage } = await import("./live-view/live-view-page");
          return { Component: LiveViewPage };
        },
      },
      {
        path: "ab-tests",
        handle: { breadcrumb: "A/B tests" },
        async lazy() {
          const { AbTestsPage } = await import("./ab-tests/ab-tests-page");
          return { Component: AbTestsPage };
        },
      },
      {
        path: "checkouts",
        handle: { breadcrumb: "Checkouts" },
        children: [
          {
            handle: { breadcrumb: checkoutExperienceBreadcrumb },
            path: ":checkoutExperienceId",
            lazy: async () => {
              const { ShippingMethodsPage } = await import(
                "./checkout-experiences/checkout-page/shipping-methods"
              );
              return {
                element: (
                  <ShippingMethodsPage
                    breadcrumb={checkoutExperienceBreadcrumb}
                  />
                ),
              };
            },
          },
          {
            path: "",
            async lazy() {
              const { CheckoutExperiencePage } = await import(
                "./checkout-experiences/checkout-experience-page"
              );
              return { Component: CheckoutExperiencePage };
            },
          },
        ],
      },
    ],
  },
];

function isAbTestsRoute(url: string): boolean {
  return /conversion\/ab-tests/.test(url);
}

function isCheckoutsRoute(url: string): boolean {
  return /conversion\/checkouts/.test(url);
}

function hidePadding(url: string): boolean {
  return isAbTestsRoute(url) || isCheckoutsRoute(url);
}
