import { ClickAwayListener } from "@mui/material";
import {
  ObjectParam,
  StringParam,
  useParam,
} from "@redotech/react-router-util/param";
import { IterableMap, genericMemo } from "@redotech/react-util/component";
import { useHandler } from "@redotech/react-util/hook";
import { LoadState, useLoad, useTriggerLoad } from "@redotech/react-util/load";
import { useScrolled } from "@redotech/react-util/scroll";
import { SortDirection, TableSort } from "@redotech/redo-model/tables/table";
import { AdvancedFilterType } from "@redotech/redo-model/views/advanced-filters/generic-advanced-filter-data";
import { filtersAreEqual } from "@redotech/redo-model/views/utils/util";
import {
  AdvancedFilterData,
  ViewColumn,
  ViewSort,
  viewTypes,
} from "@redotech/redo-model/views/views";
import Edit04Icon from "@redotech/redo-web/arbiter-icon/edit-04.svg";
import FilterLinesIcon from "@redotech/redo-web/arbiter-icon/filter-lines_filled.svg";
import { Flex } from "@redotech/redo-web/flex";
import TrashIcon from "@redotech/redo-web/icon-old/trash.svg";
import { objectEqual, stringEqual } from "@redotech/util/equal";
import { deepCopyObject } from "@redotech/util/object";
import { downloadBlob } from "@redotech/web-util/download";
import * as classnames from "classnames";
import { stringify } from "csv-stringify/browser/esm/sync";
import * as React from "react";
import {
  ForwardedRef,
  MouseEvent,
  MutableRefObject,
  ReactElement,
  ReactNode,
  RefObject,
  forwardRef,
  memo,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDebounce } from "usehooks-ts";
import {
  AdvancedTableFilter,
  decodeAdvancedFilters,
  encodeAdvancedFilters,
} from "./advanced-filters/advanced-filter";
import { ArrayStringFilterGroup } from "./advanced-filters/components/array-to-string-filter";
import { BooleanFilterGroup } from "./advanced-filters/components/boolean-filter";
import { DateFilterGroup } from "./advanced-filters/components/date-filter";
import { NumberFilterGroup } from "./advanced-filters/components/number-filter";
import { StringFilterGroup } from "./advanced-filters/components/string-filter";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
  RedoButtonTheme,
} from "./arbiter-components/buttons/redo-button";
import { RedoButtonDropdown } from "./arbiter-components/buttons/redo-dropdown-button";
import {
  RedoCommandMenu,
  RedoCommandMenuItem,
} from "./arbiter-components/command-menu/redo-command-menu";
import { RedoTextInput } from "./arbiter-components/input/redo-text-input";
import { RedoListItem } from "./arbiter-components/list/redo-list";
import { RedoListItemSize } from "./arbiter-components/list/redo-list-item";
import { RedoMultiSelectDropdown } from "./arbiter-components/select-dropdown/redo-multi-select-dropdown";
import { RedoSingleSelectDropdown } from "./arbiter-components/select-dropdown/redo-single-select-dropdown";
import {
  RedoTable,
  RedoTableColumn,
  RedoTableColumnWidthType,
  RedoTableSize,
} from "./arbiter-components/tables/redo-table";
import { RedoTableCellProps } from "./arbiter-components/tables/redo-table-cells";
import {
  StandardRedoTableHeaderCell,
  StandardRedoTableHeaderCellArrowMode,
} from "./arbiter-components/tables/redo-table-header-cells";
import * as redoTableHeaderCellsCss from "./arbiter-components/tables/redo-table-header-cells.module.css";
import { RedoVirtualTable } from "./arbiter-components/tables/redo-virtual-table";
import {
  RedoHorizontalTabMode,
  RedoHorizontalTabSize,
  RedoHorizontalTabs,
} from "./arbiter-components/tabs/redo-horizontal-tabs";
import LeftArrowIcon from "./arbiter-icon/arrow-left_filled.svg";
import RightArrowIcon from "./arbiter-icon/arrow-right_filled.svg";
import ColumnsIcon from "./arbiter-icon/columns-03.svg";
import DownloadIconSvg from "./arbiter-icon/download-01.svg";
import SearchIcon from "./arbiter-icon/search-sm.svg";
import XCloseIconSvg from "./arbiter-icon/x-close.svg";
import { Button, ButtonSize, ButtonTheme } from "./button";
import { ColumnSelector } from "./column-selector";
import { DeleteTableViewModal } from "./delete-table-view-modal";
import { Dropdown } from "./dropdown";
import ChevronDown from "./icon-old/chevron-down.svg";
import ArrowDown from "./icon-old/down-arrow.svg";
import { HeaderOverrideContext } from "./page";
import { SaveTableViewModal } from "./save-table-view-modal";
import { Spinner } from "./spinner";
import {
  Column,
  ColumnAlignment,
  CsvColumn,
  DEFAULT_PAGE_SIZE,
  Filter,
  FilterComponent,
  RowCounts,
  Search,
  TableContentProps,
  TableFetcher,
  TableProps,
  TableRef,
  TableTheme,
  sortEqual,
  sortParam,
  tableThemeClasses,
} from "./table";
import { TableFilterEditorHeader } from "./table-filter-editor-header";
import * as tableCss from "./table.module.css";
import * as table2Css from "./table2.module.css";
import { TabsPortal } from "./tabs-portal";
import { Text } from "./text";
import { variableToSemanticDisplay } from "./utils/display-code-variables";

type DisplayedColumn = { key: string; displayed: boolean };
const MINIMUM_COLUMN_WIDTH = 45;

function getAdvancedFilterArray(
  activeView: viewTypes[keyof viewTypes] | undefined,
  advancedFilters: AdvancedTableFilter[] | undefined,
) {
  return encodeAdvancedFilters(
    (activeView?.filters || []).reduce((acc, filter) => {
      if (!advancedFilters) {
        return acc;
      }
      const activeViewFilter = advancedFilters.find(
        (activeViewFilter) => activeViewFilter.data.name === filter.name,
      );
      if (activeViewFilter) {
        const deepClone = deepCopyObject(activeViewFilter);
        deepClone.data = filter;
        acc.push(deepClone);
      }
      return acc;
    }, [] as AdvancedTableFilter[]),
  );
}

function getColumnsFromDisplayedColumns<T>(
  columns: Column2<T>[],
  displayedColumns: DisplayedColumn[],
): Column2<T>[] {
  const returnCols: Column2<T>[] = [];
  for (const displayedColumn of displayedColumns) {
    const column = columns.find((column) => column.key === displayedColumn.key);
    if (column) {
      column.hidden = !displayedColumn.displayed;
      returnCols.push(column);
    }
  }

  return returnCols;
}

const AdvancedFilterComponent = memo(function AdvancedFilterComponent({
  filter,
  setValue,
  removeFilter,
  filters,
  isViewFilter,
}: {
  filter: AdvancedTableFilter;
  removeFilter: () => void;
  setValue: (filter: AdvancedTableFilter) => void;
  filters: AdvancedTableFilter[];
  isViewFilter: boolean;
}) {
  switch (filter.type) {
    case AdvancedFilterType.DATE:
      return (
        <DateFilterGroup
          filter={filter}
          openOnRender={
            filter.openOnRender ? filter.openOnRender : !isViewFilter
          }
          removeFilter={removeFilter}
          setFilter={setValue}
        />
      );
    case AdvancedFilterType.ARRAY_TO_STRING:
      return (
        <ArrayStringFilterGroup
          filter={filter}
          filters={filters}
          openOnRender={
            filter.openOnRender ? filter.openOnRender : !isViewFilter
          }
          removeFilter={removeFilter}
          setFilter={setValue}
        />
      );
    case AdvancedFilterType.NUMBER:
      return (
        <NumberFilterGroup
          filter={filter}
          removeFilter={removeFilter}
          setFilter={setValue}
        />
      );
    case AdvancedFilterType.STRING:
      return (
        <StringFilterGroup
          filter={filter}
          filters={filters}
          openOnRender={
            filter.openOnRender ? filter.openOnRender : !isViewFilter
          }
          removeFilter={removeFilter}
          setFilter={setValue}
        />
      );
    case AdvancedFilterType.BOOLEAN:
      return (
        <BooleanFilterGroup
          filter={filter}
          openOnRender={
            filter.openOnRender ? filter.openOnRender : !isViewFilter
          }
          removeFilter={removeFilter}
          setFilter={setValue}
        />
      );
    default:
      return null;
  }
});

function viewChanges<T>({
  activeView,
  displayedAdvancedFilters,
  columnWidths,
  displayedColumns,
  pageSize,
  sort,
  columns,
}: {
  activeView?: viewTypes[keyof viewTypes];
  displayedAdvancedFilters: AdvancedTableFilter[];
  columnWidths: Record<string, number>;
  displayedColumns: DisplayedColumn[];
  pageSize?: number;
  sort: TableSort<string>;
  columns: Column2<T>[];
}): {
  allFiltersMatch: boolean;
  columnsValuesMatch: boolean;
  pageSizesMatch: boolean;
  sortsMatch: boolean;
  columnOrderMatches: boolean;
} {
  const allFiltersMatch =
    displayedAdvancedFilters.length === activeView?.filters.length &&
    displayedAdvancedFilters.every((f) =>
      activeView?.filters.some((vf) => filtersAreEqual(f.data, vf)),
    );

  const columnsForDisplay = getColumnsFromDisplayedColumns<T>(
    columns,
    displayedColumns,
  );

  let columnsValuesMatch = true;
  for (const viewColumn of activeView?.columns || []) {
    const col = columnsForDisplay.find((col) => col.key === viewColumn.key);
    if (!col) {
      columnsValuesMatch = false;
      break;
    }
    if (
      viewColumn.hidden !== col.hidden ||
      viewColumn.width !== (columnWidths?.[col.key] ?? col.width)
    ) {
      columnsValuesMatch = false;
      break;
    }
  }

  const viewColKeys = (activeView?.columns || []).map((col) => col.key);
  const displayedColKeys = columnsForDisplay
    .map((col) => col.key)
    .filter((key) => viewColKeys.includes(key));

  let columnOrderMatches = true;
  for (let i = 0; i < viewColKeys.length; i++) {
    if (viewColKeys[i] !== displayedColKeys[i]) {
      columnOrderMatches = false;
      break;
    }
  }

  const pageSizesMatch = activeView?.pageSize === pageSize;
  const sortsMatch =
    activeView?.sort?.direction === sort.direction &&
    activeView?.sort?.key === sort.key;

  return {
    allFiltersMatch,
    columnsValuesMatch,
    pageSizesMatch,
    sortsMatch,
    columnOrderMatches,
  };
}

const alignClasses = new Map<ColumnAlignment, string>();
alignClasses.set(ColumnAlignment.CENTER, tableCss.center);
alignClasses.set(ColumnAlignment.LEFT, tableCss.left);
alignClasses.set(ColumnAlignment.RIGHT, tableCss.right);

/** Same as original columns, but using the new style of specifying headers and rows, _but_ it's always "fill" width mode
 * (because `redo/web/table.tsx` uses the widths to calculate certain things). */
export type Column2<DATA> = Omit<Column<DATA>, "Cell"> &
  Omit<RedoTableColumn<DATA>, "renderHeaderCell" | "alignment"> & {
    //"widthMode" |
    /** Determines if the column is hidden before the user does any column selection */
    hidden?: boolean;
    /** Determines if the column can be toggled between hidden and visible. If not set, column can be hidden */
    nonHidable?: boolean;
  };

/**
 *  Presentational layer of the header of the fetching table.
 * Lays out the filters, CSV button, action button, etc...
 */
export const Table2Header = genericMemo(function Table2Header<T>({
  headerTitle,
  headerSubtitle,
  primaryFilterOptions,
  dynamicFilters,
  countLoad,
  filters,
  setFilters,
  setPrimaryFilter,
  embeddedTable,
  filterComponents,
  primaryFilter,
  search,
  searchEnabled,
  setSearch,
  searchButton,
  csv,
  hideExportButton,
  actionButton,
  downloadTriggerRef,
  fetcher,
  filename,
  onDownloadStatusChange,
  passThroughValues,
  saveView,
  saveViewLoad,
  sort,
  setSort,
  columnSelection,
  columns,
  advancedFilters,
  advancedFilterParams,
  setAdvancedFilterParams,
  viewBeingEdited,
  setViewBeingEdited,
  activeView,
  onDeleteView,
  reservedViewNames,
  appliedAdvancedFilters,
  pageSize,
  displayedColumns,
  setDisplayedColumns,
  columnWidths,
  putTabsInHeader,
}: TableProps2<T> & {
  countLoad: LoadState<RowCounts>;
  advancedFilterParams: string;
  setAdvancedFilterParams: (filters: string) => void;
  advancedFilters: AdvancedTableFilter[];
  filters: { [name: string]: string };
  setFilters: (filters: { [name: string]: string }) => void;
  primaryFilter: string;
  setPrimaryFilter: (filter: string) => void;
  filterComponents: FilterComponent[];
  searchEnabled: boolean;
  searchButton: ReactNode;
  search: string;
  setSearch: (search: string) => void;
  sort: TableSort<string>;
  setSort: (sort: TableSort<string>) => void;
  viewBeingEdited?: boolean;
  setViewBeingEdited?: (viewBeingEdited: boolean) => void;
  activeView?: viewTypes[keyof viewTypes];
  onDeleteView?: (view: viewTypes[keyof viewTypes]) => void;
  reservedViewNames?: Set<string>;
  appliedAdvancedFilters?: AdvancedTableFilter[];
  setDisplayedColumns: (columns: DisplayedColumn[]) => void;
  displayedColumns: DisplayedColumn[];
  columnWidths: Record<string, number>;
}) {
  const [filtersDropdownButtonRef, setFiltersDropdownButtonRef] =
    useState<HTMLButtonElement | null>(null);

  const [
    columnSelectionDropdownButtonRef,
    setColumnSelectionDropdownButtonRef,
  ] = useState<HTMLButtonElement | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  const [
    advancedFiltersDropdownButtonRef,
    setAdvancedFiltersDropdownButtonRef,
  ] = useState<HTMLButtonElement | null>(null);

  const [displayedAdvancedFilters, setDisplayedAdvancedFilters] = useState<
    AdvancedTableFilter[]
  >(decodeAdvancedFilters(advancedFilterParams, advancedFilters));

  const [advancedFiltersDropdownOpen, setAdvancedFiltersDropdownOpen] =
    useState(false);

  useEffect(() => {
    setDisplayedAdvancedFilters(
      decodeAdvancedFilters(advancedFilterParams, advancedFilters),
    );
  }, [advancedFilterParams, advancedFilters, activeView]);

  const shouldShowAdvancedFilterButton = advancedFilters.length > 0;
  const shouldShowFilterButton =
    filterComponents.length > 0 && !shouldShowAdvancedFilterButton;
  const shouldShowFilterRow =
    selectedFilters.length > 0 || displayedAdvancedFilters.length > 0;

  const xPadding = embeddedTable ? "3xl" : "6xl";

  const [deleteViewModalOpen, setDeleteViewModalOpen] = useState(false);
  const [saveViewModalOpen, setSaveViewModalOpen] = useState(false);

  const deleteViewItem: RedoCommandMenuItem = useMemo(() => {
    return {
      Icon: TrashIcon,
      text: "Delete view",
      onClick: () => {
        setDeleteViewModalOpen(true);
      },
      theme: RedoButtonTheme.DESTRUCTIVE,
    };
  }, [setDeleteViewModalOpen]);

  const editViewItem: RedoCommandMenuItem = useMemo(() => {
    return {
      text: "Edit view",
      onClick: () => {
        setViewBeingEdited?.(true);
      },
      Icon: Edit04Icon,
    };
  }, [setViewBeingEdited]);

  const [editViewDropdownRef, setEditViewDropdownRef] =
    useState<HTMLElement | null>(null);
  const [editViewDropdownOpen, setEditViewDropdownOpen] = useState(false);

  const {
    allFiltersMatch,
    columnsValuesMatch,
    pageSizesMatch,
    sortsMatch,
    columnOrderMatches,
  } = useMemo(
    () =>
      viewChanges<T>({
        activeView,
        displayedAdvancedFilters,
        columnWidths,
        displayedColumns,
        sort,
        pageSize,
        columns,
      }),
    [
      activeView,
      displayedAdvancedFilters,
      columnWidths,
      displayedColumns,
      sort,
      pageSize,
      columns,
    ],
  );

  const viewHasChanged =
    !allFiltersMatch ||
    !columnsValuesMatch ||
    !pageSizesMatch ||
    !sortsMatch ||
    !columnOrderMatches;

  const showUpdateViewButton = viewHasChanged && !viewBeingEdited;

  const showEditViewButton = !showUpdateViewButton && !viewBeingEdited;

  const showViewActionButtons =
    !!saveView && activeView && !!onDeleteView && appliedAdvancedFilters;

  const showSaveNewViewButton = viewHasChanged && !viewBeingEdited;

  return (
    <Flex align="stretch" dir="column" gap="none" pb="3xl">
      {headerTitle && (
        <Flex dir="column" pt="3xl" px={xPadding}>
          <Text
            fontSize={embeddedTable ? "md" : "lg"}
            fontWeight="semibold"
            textColor="primary"
          >
            {headerTitle}
          </Text>
          {headerSubtitle && (
            <Text fontSize="sm" fontWeight="regular" textColor="tertiary">
              {headerSubtitle}
            </Text>
          )}
        </Flex>
      )}

      {primaryFilterOptions && (
        <Flex dir="row">
          <Flex grow={1} pt="xl">
            <Filters
              dynamicFilters={dynamicFilters}
              filter={primaryFilter}
              filterCounts={countLoad.value}
              filters={primaryFilterOptions}
              putTabsInHeader={putTabsInHeader}
              setFilter={setPrimaryFilter}
            />
          </Flex>
        </Flex>
      )}

      <Flex align="center" dir="row" pt="3xl" px={xPadding}>
        {searchEnabled && (
          <>
            <RedoTextInput
              IconLeading={SearchIcon}
              IconTrailing={() => (
                <Flex
                  className={table2Css.closeIcon}
                  flexDirection="column"
                  onClick={() => setSearch("")}
                >
                  {" "}
                  <XCloseIconSvg />{" "}
                </Flex>
              )}
              placeholder="Search"
              setValue={setSearch}
              value={search}
            />
            {searchButton}
          </>
        )}
        {shouldShowFilterButton && (
          <>
            <RedoButton
              hierarchy={RedoButtonHierarchy.SECONDARY}
              IconLeading={FilterLinesIcon}
              ref={setFiltersDropdownButtonRef}
            />
            <RedoMultiSelectDropdown
              dropdownButtonRef={filtersDropdownButtonRef}
              options={filterComponents.map((component) => ({
                value: component.name,
              }))}
              selectedOptions={selectedFilters.map((filter) => ({
                value: filter,
              }))}
              setSelectedOptions={(items) =>
                setSelectedFilters(items.map((item) => item.value))
              }
            >
              {(item) => (
                <Text fontSize="sm">
                  {variableToSemanticDisplay(item.value)}
                </Text>
              )}
            </RedoMultiSelectDropdown>
          </>
        )}

        {shouldShowAdvancedFilterButton && (
          <>
            <RedoButton
              hierarchy={RedoButtonHierarchy.SECONDARY}
              IconLeading={FilterLinesIcon}
              onClick={() => setAdvancedFiltersDropdownOpen(true)}
              ref={setAdvancedFiltersDropdownButtonRef}
            />
            {advancedFiltersDropdownOpen && (
              <ClickAwayListener
                onClickAway={() => setAdvancedFiltersDropdownOpen(false)}
              >
                <Dropdown
                  anchor={advancedFiltersDropdownButtonRef}
                  className={table2Css.filterDropdown}
                  fitToAnchor={false}
                  open={advancedFiltersDropdownOpen}
                >
                  <IterableMap
                    items={advancedFilters}
                    keyFn={(filter) => filter.data.name}
                  >
                    {(filter) => {
                      function onClick() {
                        const newFilter = advancedFilters.find(
                          (f) => f.data.name === filter.data.name,
                        );
                        if (!newFilter) {
                          return;
                        }
                        setDisplayedAdvancedFilters([
                          ...displayedAdvancedFilters,
                          newFilter,
                        ]);
                        setAdvancedFilterParams(
                          encodeAdvancedFilters([
                            ...displayedAdvancedFilters,
                            newFilter,
                          ]),
                        );
                        setAdvancedFiltersDropdownOpen(false);
                      }
                      return (
                        <Flex
                          className={table2Css.filterItem}
                          onClick={onClick}
                        >
                          <filter.Icon />
                          <Text fontSize="sm">
                            {variableToSemanticDisplay(filter.data.name)}
                          </Text>
                        </Flex>
                      );
                    }}
                  </IterableMap>
                </Dropdown>
              </ClickAwayListener>
            )}
          </>
        )}

        <Flex grow={1} justify="flex-end">
          {csv && !hideExportButton && (
            <ExportCsv
              appliedAdvancedFilters={decodeAdvancedFilters(
                advancedFilterParams,
                advancedFilters,
              )}
              csv={csv}
              downloadTriggerRef={downloadTriggerRef}
              fetcher={fetcher}
              filename={filename || "Redo"}
              filters={filters}
              onDownloadStatusChange={onDownloadStatusChange}
              passThroughValues={passThroughValues}
              primaryFilter={primaryFilter}
              search={search}
              sort={sort}
            />
          )}
          {columnSelection && (
            <Flex>
              <RedoButton
                hierarchy={RedoButtonHierarchy.SECONDARY}
                IconLeading={ColumnsIcon}
                ref={setColumnSelectionDropdownButtonRef}
              />
              <ColumnSelector
                columns={columns}
                displayedColumns={displayedColumns}
                dropdownButtonRef={columnSelectionDropdownButtonRef}
                setDisplayedColumns={setDisplayedColumns}
              />
            </Flex>
          )}
          {actionButton}
        </Flex>
      </Flex>
      {(shouldShowFilterRow || saveView) && (
        <Flex
          dir="row"
          gap="lg"
          grow={1}
          justify="space-between"
          pt="2xl"
          px={xPadding}
        >
          <Flex wrap="wrap">
            {shouldShowFilterRow && shouldShowFilterButton && (
              <>
                <IterableMap
                  items={filterComponents.filter((component) =>
                    selectedFilters.includes(component.name),
                  )}
                  keyFn={(component) => component.name}
                >
                  {(component) => {
                    function setValue(value: string) {
                      setFilters({ ...filters, [component.name]: value });
                    }
                    return component.render({
                      value: filters[component.name],
                      setValue,
                    });
                  }}
                </IterableMap>
                <RedoButton
                  hierarchy={RedoButtonHierarchy.SECONDARY}
                  IconLeading={() => <XCloseIconSvg />}
                  onClick={() => {
                    setSelectedFilters([]);
                    setFilters({
                      ...Object.fromEntries(
                        filterComponents.map((component) => [
                          component.name,
                          "",
                        ]),
                      ),
                    });
                  }}
                  size={RedoButtonSize.SMALL}
                  text="Clear all filters"
                />
              </>
            )}
            {shouldShowFilterRow && shouldShowAdvancedFilterButton && (
              <>
                <IterableMap
                  items={displayedAdvancedFilters}
                  keyFn={(filter, index) => filter.data.name + index}
                >
                  {(filter, index) => {
                    function setValue(newFilter: AdvancedTableFilter) {
                      const newFilters = displayedAdvancedFilters.map(
                        (f, i) => {
                          if (index === i) {
                            return newFilter;
                          }
                          return f;
                        },
                      );
                      setAdvancedFilterParams(
                        encodeAdvancedFilters(newFilters),
                      );
                    }

                    function removeFilter() {
                      const newFilters = displayedAdvancedFilters.filter(
                        (_, i) => i !== index,
                      );
                      setAdvancedFilterParams(
                        encodeAdvancedFilters(newFilters),
                      );
                    }
                    return (
                      <AdvancedFilterComponent
                        filter={filter}
                        filters={displayedAdvancedFilters}
                        isViewFilter
                        removeFilter={removeFilter}
                        setValue={setValue}
                      />
                    );
                  }}
                </IterableMap>
                {!allFiltersMatch && (
                  <RedoButton
                    hierarchy={RedoButtonHierarchy.TERTIARY}
                    onClick={() => {
                      setAdvancedFilterParams(
                        getAdvancedFilterArray(activeView, advancedFilters),
                      );
                    }}
                    text="Reset filters"
                  />
                )}
              </>
            )}
          </Flex>
          <Flex justify="flex-end">
            {showViewActionButtons && (
              <>
                {showSaveNewViewButton && (
                  <RedoButton
                    hierarchy={RedoButtonHierarchy.SECONDARY}
                    onClick={() => setSaveViewModalOpen(true)}
                    text="Save as new view"
                  />
                )}
                {showUpdateViewButton && (
                  <RedoButtonDropdown
                    dropdownOpen={editViewDropdownOpen}
                    onClick={() => {
                      saveView({
                        name: activeView.name,
                        filters: displayedAdvancedFilters.map(
                          (filter) => filter.data,
                        ),
                        columns: getColumnsFromDisplayedColumns(
                          columns,
                          displayedColumns,
                        ).map((col) => ({
                          key: col.key,
                          width: columnWidths?.[col.key] ?? col.width,
                          hidden: col?.hidden ?? false,
                        })),
                        sort,
                        pageSize,
                      });
                    }}
                    pending={saveViewLoad?.pending}
                    ref={setEditViewDropdownRef}
                    setDropdownOpen={setEditViewDropdownOpen}
                    size={RedoButtonSize.SMALL}
                    text="Update view"
                  >
                    <RedoCommandMenu
                      anchor={editViewDropdownRef}
                      items={[editViewItem, deleteViewItem]}
                      open={editViewDropdownOpen}
                      setOpen={setEditViewDropdownOpen}
                    />
                  </RedoButtonDropdown>
                )}

                {showEditViewButton && (
                  <RedoButtonDropdown
                    dropdownOpen={editViewDropdownOpen}
                    onClick={() => setViewBeingEdited?.(true)}
                    ref={setEditViewDropdownRef}
                    setDropdownOpen={setEditViewDropdownOpen}
                    size={RedoButtonSize.SMALL}
                    text="Edit view"
                  >
                    <RedoCommandMenu
                      anchor={editViewDropdownRef}
                      items={[deleteViewItem]}
                      open={editViewDropdownOpen}
                      setOpen={setEditViewDropdownOpen}
                    />
                  </RedoButtonDropdown>
                )}
                {deleteViewModalOpen && (
                  <DeleteTableViewModal
                    onDelete={() => {
                      onDeleteView(activeView);
                      setDeleteViewModalOpen(false);
                    }}
                    open={deleteViewModalOpen}
                    setOpen={(open) => {
                      setDeleteViewModalOpen(open);
                    }}
                    view={activeView}
                  />
                )}
                {saveViewModalOpen && (
                  <SaveTableViewModal
                    cancel={() => setSaveViewModalOpen(false)}
                    reservedViewNames={reservedViewNames || new Set()}
                    save={async (name) => {
                      saveView({
                        name: name,
                        filters: appliedAdvancedFilters.map(
                          (filter) => filter.data,
                        ),
                        columns: getColumnsFromDisplayedColumns(
                          columns,
                          displayedColumns,
                        ).map((col) => ({
                          key: col.key,
                          width: columnWidths?.[col.key] ?? col.width,
                          hidden: col?.hidden ?? false,
                        })),
                        sort: sort,
                        pageSize,
                      });
                      setSaveViewModalOpen(false);
                    }}
                  />
                )}
              </>
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
});

function getDisplayedColumns<T>({
  activeView,
  columnOptions,
}: {
  activeView: viewTypes[keyof viewTypes] | undefined;
  columnOptions: Column2<T>[];
}): DisplayedColumn[] {
  if (!activeView?.columns) {
    return columnOptions.map((column) => ({
      key: column.key,
      displayed: !column.hidden,
    }));
  }

  const displayedCols: DisplayedColumn[] = [];
  for (const viewColumn of activeView.columns) {
    displayedCols.push({ key: viewColumn.key, displayed: !viewColumn.hidden });
  }

  for (const col of columnOptions) {
    if (!displayedCols.find((displayedCol) => displayedCol.key === col.key)) {
      displayedCols.push({ key: col.key, displayed: false });
    }
  }

  return displayedCols;
}

function getColumnWidths<T>({
  activeView,
  columnOptions,
}: {
  activeView: viewTypes[keyof viewTypes] | undefined;
  columnOptions: Column2<T>[];
}): Record<string, number> {
  return columnOptions.reduce((acc: Record<string, number>, col) => {
    const viewCol = activeView?.columns?.find(
      (viewCol) => viewCol.key === col.key,
    );
    if (viewCol) {
      acc[col.key] = viewCol.width;
    } else {
      acc[col.key] = col.width;
    }
    return acc;
  }, {});
}

function getViewSort(
  activeView: viewTypes[keyof viewTypes] | undefined,
): TableSort | null {
  return activeView?.sort
    ? { key: activeView.sort.key, direction: activeView.sort.direction }
    : null;
}

export const Table2 = genericMemo(
  forwardRef(TableComponent) as <T>(
    props: TableProps2<T> & { ref?: React.ForwardedRef<TableRef<T>> },
  ) => ReturnType<typeof TableComponent>,
);

function TableComponent<T>(
  props: TableProps2<T>,
  ref: ForwardedRef<TableRef<T>>,
) {
  const {
    adjustableColumnWidths,
    onRowClick,
    onRowHovered,
    isRowSelected,
    primaryFilterDefault,
    sortDefault,
    fetcher,
    filterComponents = [],
    columns,
    searchEnabled = false,
    searchButton,
    passThroughValues = null,
    paramPrefix,
    alwaysLoadTable = false,
    onQuickDownload,
    pageNumber,
    setPageNumber,
    title,
    subtitle,
    scrollable = false,
    theme = TableTheme.COMPACT,
    scrollAreaRef,
    header,
    externalSetSort,
    externalSort,
    pageSize,
    setPageSize,
    EmptyContentMessage,
    LoadingContentMessage,
    onContextMenu,
    size,
    stickyPageControl,
    advancedFilters,
    refreshSymbol,
    viewBeingEdited,
    setViewBeingEdited,
    activeView,
    saveView,
    reservedViewNames,
    onDeleteView,
    saveViewLoad,
    tableContentClassName,
    hideHeader,
    putTabsInHeader,
    useVirtualTable,
  } = props;
  const [filters, setFilters] = useParam<{ [name: string]: string }>(
    new ObjectParam(
      Object.fromEntries(
        filterComponents.map((component) => [component.name, component.param]),
      ),
    ),
    objectEqual(
      Object.fromEntries(
        filterComponents.map((component) => [component.name, stringEqual]),
      ),
    ),
  );
  const [primaryFilter, setPrimaryFilter] = useParam(
    Filter.param(primaryFilterDefault || ""),
  );
  const [search, setSearch] = useParam(Search.param());
  const [internalSort, internalSetSort] = useParam(
    sortParam(getViewSort(activeView) ?? sortDefault, paramPrefix),
    sortEqual,
  );

  useEffect(() => {
    const newSort = getViewSort(activeView) ?? sortDefault;
    if (!sortEqual(internalSort, newSort)) {
      internalSetSort(newSort);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeView]);

  const [advancedFilterParams, setAdvancedFilterParams] = useParam(
    new StringParam(
      "advancedFilters",
      getAdvancedFilterArray(activeView, advancedFilters),
    ),
  );

  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return; // Keeps filters on refresh
    }
    setAdvancedFilterParams(
      getAdvancedFilterArray(activeView, advancedFilters),
    );
  }, [
    activeView?.name,
    advancedFilters,
    activeView?.filters,
    activeView,
    setAdvancedFilterParams,
  ]);

  const sort = useMemo(
    () => externalSort || internalSort,
    [externalSort, internalSort],
  );
  const setSort = useMemo(
    () => externalSetSort || internalSetSort,
    [externalSetSort, internalSetSort],
  );

  const columnOptions = useRef(columns);

  const [displayedColumns, setDisplayedColumns] = useState<DisplayedColumn[]>(
    getDisplayedColumns({ activeView, columnOptions: columnOptions.current }),
  );

  useEffect(() => {
    setDisplayedColumns(
      getDisplayedColumns({ activeView, columnOptions: columnOptions.current }),
    );
  }, [activeView]);

  const [columnWidths, setColumnWidths] = useState<Record<string, number>>(
    getColumnWidths({ activeView, columnOptions: columnOptions.current }),
  );
  useEffect(() => {
    setColumnWidths(
      getColumnWidths({ activeView, columnOptions: columnOptions.current }),
    );
  }, [activeView]);

  const debouncedSearch = useDebounce(search, 200);

  const appliedAdvancedFilters = useMemo(
    () => decodeAdvancedFilters(advancedFilterParams, advancedFilters || []),
    [advancedFilterParams, advancedFilters],
  );

  const countLoad = useLoad(
    async (signal) => {
      const counts = await fetcher.counts(
        filters,
        debouncedSearch || undefined,
        signal,
        appliedAdvancedFilters,
      );

      const numPages = counts.total
        ? Math.ceil(counts.total / (pageSize || DEFAULT_PAGE_SIZE))
        : 0;

      if (
        setPageNumber &&
        pageNumber !== undefined &&
        pageNumber > numPages - 1
      ) {
        setPageNumber(0);
      }
      return counts;
    },
    [fetcher, debouncedSearch, filters, appliedAdvancedFilters],
  );

  const headerPortal = useContext(HeaderOverrideContext);

  useEffect(() => {
    if (viewBeingEdited && activeView && saveView && reservedViewNames) {
      headerPortal(
        <TableFilterEditorHeader
          activeView={activeView}
          cancel={() => setViewBeingEdited?.(false)}
          reservedViewNames={reservedViewNames}
          save={async (body) => {
            saveView({
              name: body.name,
              filters: appliedAdvancedFilters.map((filter) => filter.data),
              columns: getColumnsFromDisplayedColumns(
                columns,
                displayedColumns,
              ).map((col) => ({
                key: col.key,
                width: columnWidths?.[col.key] ?? col.width,
                hidden: col?.hidden ?? false,
              })),
              sort: sort,
              pageSize,
            });
          }}
          savePending={saveViewLoad?.pending ?? false}
        />,
      );
    } else {
      headerPortal(null);
    }
    return () => headerPortal(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnWidths,
    headerPortal,
    viewBeingEdited,
    filters,
    saveView,
    activeView,
    reservedViewNames,
    appliedAdvancedFilters,
    displayedColumns,
    sort,
    setViewBeingEdited,
    saveViewLoad?.pending,
    pageSize,
  ]);

  const footerRef = useRef<HTMLDivElement>(null);

  const columnsToRender = useMemo(
    () =>
      getColumnsFromDisplayedColumns(columns, displayedColumns).filter(
        (col) => !col.hidden,
      ),
    [columns, displayedColumns],
  );

  return (
    <section className={tableCss.tableContainer}>
      {!hideHeader && (
        <Table2Header
          {...props}
          activeView={activeView}
          advancedFilterParams={advancedFilterParams}
          advancedFilters={advancedFilters || []}
          appliedAdvancedFilters={appliedAdvancedFilters}
          columnWidths={columnWidths}
          countLoad={countLoad}
          displayedColumns={displayedColumns}
          filterComponents={filterComponents}
          filters={filters}
          onDeleteView={onDeleteView}
          primaryFilter={primaryFilter}
          putTabsInHeader={putTabsInHeader}
          reservedViewNames={reservedViewNames}
          search={search}
          searchButton={searchButton}
          searchEnabled={searchEnabled}
          setAdvancedFilterParams={setAdvancedFilterParams}
          setDisplayedColumns={setDisplayedColumns}
          setFilters={setFilters}
          setPrimaryFilter={setPrimaryFilter}
          setSearch={setSearch}
          setSort={setSort}
          setViewBeingEdited={setViewBeingEdited}
          sort={sort}
          viewBeingEdited={viewBeingEdited}
        />
      )}
      <div>
        {header && <div className={tableCss.textHeader}>{header}</div>}
        {title && typeof title === "string" && (
          <h3 className={tableCss.title}>{title}</h3>
        )}
        {subtitle && typeof subtitle === "string" && (
          <Text mb="2xl">{subtitle}</Text>
        )}
        <TableContent
          adjustableColumnWidths={adjustableColumnWidths}
          alwaysLoadTable={alwaysLoadTable}
          appliedAdvancedFilters={appliedAdvancedFilters}
          className={tableContentClassName}
          columns={columnsToRender}
          columnWidths={columnWidths}
          EmptyContentMessage={EmptyContentMessage}
          fetcher={fetcher}
          filters={filters}
          footerRef={footerRef}
          header={header}
          isRowSelected={isRowSelected}
          LoadingContentMessage={LoadingContentMessage}
          onContextMenu={onContextMenu}
          onQuickDownload={onQuickDownload}
          onRowClick={onRowClick}
          onRowHovered={onRowHovered}
          pageNumber={pageNumber !== undefined ? pageNumber : undefined}
          pageSize={pageSize}
          passThroughValues={passThroughValues}
          primaryFilter={primaryFilter}
          ref={ref}
          refreshSymbol={refreshSymbol}
          scrollable={scrollable}
          scrollAreaRef={scrollAreaRef}
          search={debouncedSearch}
          setColumnWidths={setColumnWidths}
          setSort={setSort}
          size={size}
          sort={sort}
          theme={theme}
          useVirtualTable={useVirtualTable}
        />
        {pageNumber !== undefined && setPageNumber && (
          <PageControl
            hasNext={
              countLoad.value
                ? countLoad.value.total >
                  (pageNumber + 1) * (pageSize || DEFAULT_PAGE_SIZE)
                : false
            }
            numPages={
              countLoad.value
                ? Math.ceil(
                    countLoad.value.total / (pageSize || DEFAULT_PAGE_SIZE),
                  )
                : 0
            }
            pageNumber={pageNumber}
            pageSize={pageSize}
            ref={footerRef}
            setPageNumber={setPageNumber}
            setPageSize={setPageSize}
            stickyPageControl={stickyPageControl}
          />
        )}
      </div>
    </section>
  );
}

const PageButton = memo(function PageButton({
  index,
  currentPage,
  setPage,
  isEllipses = false,
}: {
  index: number;
  currentPage: number;
  setPage: (index: number) => void;
  isEllipses?: boolean;
}) {
  return (
    <Button
      disabled={index === currentPage}
      onClick={() => setPage(index)}
      size={ButtonSize.MICRO}
      theme={
        index === currentPage ? ButtonTheme.SOLID_LIGHT : ButtonTheme.GHOST
      }
    >
      {isEllipses ? "..." : index + 1}
    </Button>
  );
});

const PageControl = memo(
  forwardRef(function PageControl(
    {
      pageNumber,
      numPages,
      setPageNumber,
      pageSize,
      setPageSize,
      hasNext,
      stickyPageControl,
    }: {
      pageNumber: number;
      numPages: number;
      setPageNumber: (pageNumber: number) => void;
      pageSize: number | undefined;
      hasNext: boolean;
      setPageSize?: (size: number) => void;
      stickyPageControl?: boolean;
    },
    ref: React.Ref<HTMLDivElement>,
  ) {
    const renderPageNumbers = (
      numPages: number,
      page: number,
      setPage: any,
    ) => {
      const pageNumbers = [];
      for (let i = 0; i < numPages; i++) {
        if (page < 3 && i < 4) {
          pageNumbers.push(
            <PageButton
              currentPage={page}
              index={i}
              key={i}
              setPage={setPage}
            />,
          );
        } else if (page > numPages - 3 && i > numPages - 5) {
          pageNumbers.push(
            <PageButton
              currentPage={page}
              index={i}
              key={i}
              setPage={setPage}
            />,
          );
        }
        // Last page/first page
        else if (i === numPages - 1 || i === 0) {
          pageNumbers.push(
            <PageButton
              currentPage={page}
              index={i}
              key={i}
              setPage={setPage}
            />,
          );
        }
        // Current page/adjacent pages
        else if (i === page - 1 || i === page + 1 || i === page) {
          pageNumbers.push(
            <PageButton
              currentPage={page}
              index={i}
              key={i}
              setPage={setPage}
            />,
          );
        }
      }

      if (numPages > 5) {
        if (page > 2) {
          const middleIndex = Math.floor(page / 2);
          pageNumbers.splice(
            1,
            0,
            <PageButton
              currentPage={page}
              index={middleIndex}
              isEllipses
              key={middleIndex}
              setPage={setPage}
            />,
          );
        }

        if (page < numPages - 3) {
          const offset = page > 2 ? 1 : 3;
          const middleIndex = Math.floor((numPages + page + offset) / 2);
          pageNumbers.splice(
            pageNumbers.length - 1,
            0,
            <PageButton
              currentPage={page}
              index={middleIndex}
              isEllipses
              key={middleIndex}
              setPage={setPage}
            />,
          );
        }
      }
      return pageNumbers;
    };

    const [pageSizeRef, setPageSizeRef] = useState<HTMLButtonElement | null>(
      null,
    );
    const pageSizeOptions: RedoListItem<number>[] = [
      { value: 10 },
      { value: 25 },
      { value: 50 },
      { value: 100 },
      { value: 150 },
      { value: 200 },
      { value: 250 },
      { value: 500 },
    ];

    return (
      <Flex
        className={classnames(
          tableCss.pageControl,
          stickyPageControl ? tableCss.pageControlSticky : "",
        )}
        justify="space-between"
        ref={ref}
      >
        <Flex w="xs">
          <Button
            className={tableCss.pageChangeButton}
            disabled={pageNumber === 0}
            icon={LeftArrowIcon}
            onClick={() => setPageNumber(pageNumber - 1)}
            size={ButtonSize.MICRO}
            theme={ButtonTheme.OUTLINED}
          >
            Previous
          </Button>
        </Flex>
        <Flex className={tableCss.pageNumbers}>
          {renderPageNumbers(numPages, pageNumber, setPageNumber)}
        </Flex>

        <Flex gap="4xl" w={pageSize && setPageSize ? undefined : "xs"}>
          {pageSize && setPageSize && (
            <Flex m="auto">
              <RedoButton
                hierarchy={RedoButtonHierarchy.SECONDARY}
                IconTrailing={ChevronDown}
                ref={setPageSizeRef}
                text={String(pageSize)}
              />
              <RedoSingleSelectDropdown
                dropdownButtonRef={pageSizeRef}
                options={pageSizeOptions}
                optionSelected={(option) => setPageSize(option.value)}
                selectedItem={{ value: pageSize }}
                size={RedoListItemSize.SMALL}
              >
                {(item) => (
                  <Text
                    fontSize="sm"
                    overflow="hidden"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {item.value}
                  </Text>
                )}
              </RedoSingleSelectDropdown>
              <Flex flexDirection="column" justify="center" w="xxs">
                <Text fontSize="xs" textColor="secondary">
                  results per page
                </Text>
              </Flex>
            </Flex>
          )}
          <Button
            className={tableCss.pageChangeButton}
            disabled={!hasNext}
            icon={RightArrowIcon}
            iconAlign="right"
            onClick={() => setPageNumber(pageNumber + 1)}
            size={ButtonSize.MICRO}
            theme={ButtonTheme.OUTLINED}
          >
            Next
          </Button>
        </Flex>
      </Flex>
    );
  }),
);

export type SaveViewBody = {
  name: string;
  filters: AdvancedFilterData[];
  columns: ViewColumn[];
  sort: ViewSort | undefined;
  pageSize: number | undefined;
};

type TableProps2<T> = Omit<TableProps<T>, "columns"> & {
  columns: Column2<T>[];
  isRowSelected?: (item: T) => boolean;
  size?: RedoTableSize;
  embeddedTable?: boolean;
  headerTitle?: string;
  headerSubtitle?: string;
  stickyPageControl?: boolean;
  columnSelection?: boolean;
  saveView?: (body: SaveViewBody) => void;
  saveViewLoad?: LoadState<void>;
  advancedFilters?: AdvancedTableFilter[];
  refreshSymbol?: symbol;
  viewBeingEdited?: boolean;
  setViewBeingEdited?: (viewBeingEdited: boolean) => void;
  activeView?: viewTypes[keyof viewTypes];
  reservedViewNames?: Set<string>;
  onDeleteView?: (view: viewTypes[keyof viewTypes]) => void;
  tableContentClassName?: string;
  setPageSize?: (size: number) => void;
  adjustableColumnWidths?: boolean;
  hideHeader?: boolean;
  putTabsInHeader?: boolean;
  useVirtualTable?: boolean;
};

export const Table = genericMemo(
  forwardRef(TableComponent) as <T>(
    props: TableProps2<T> & { ref?: React.ForwardedRef<TableRef<T>> },
  ) => ReturnType<typeof TableComponent>,
);

export const ExportCsv = genericMemo(function ExportCsv<T>({
  csv,
  filename,
  filters,
  primaryFilter,
  fetcher,
  search,
  sort,
  passThroughValues,
  downloadTriggerRef,
  onDownloadStatusChange,
  appliedAdvancedFilters,
}: {
  csv: CsvColumn<T>[];
  filename: string;
  primaryFilter: string;
  filters: { [name: string]: string };
  fetcher: TableFetcher<T>;
  search: string;
  sort: TableSort;
  passThroughValues: any;
  downloadTriggerRef?: MutableRefObject<() => void>;
  onDownloadStatusChange?: (pending: boolean) => void;
  appliedAdvancedFilters: AdvancedTableFilter[];
}) {
  const [load, trigger] = useTriggerLoad(async (signal) => {
    const result = [csv.map((column) => column.header)];
    const data = fetcher.data(
      primaryFilter,
      filters,
      search || undefined,
      search ? undefined : sort,
      undefined,
      undefined,
      signal,
      passThroughValues,
      appliedAdvancedFilters,
    );
    while (true) {
      const results = await data.next();
      if (results?.value?.items?.length) {
        for (const record of results.value.items) {
          result.push(csv.map((column) => column.cell(record)));
        }
      }
      if (results.done) {
        break;
      }
    }
    const blob = new Blob([stringify(result)], { type: "text/csv" });
    await downloadBlob(blob, `${filename}.csv`);
  });

  const onClick = useHandler(() => trigger());
  if (downloadTriggerRef) {
    downloadTriggerRef.current = trigger;
  }

  useEffect(() => {
    if (onDownloadStatusChange) {
      onDownloadStatusChange(load.pending);
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load.pending]);

  return !downloadTriggerRef ? (
    <RedoButton
      hierarchy={RedoButtonHierarchy.SECONDARY}
      IconLeading={() => <DownloadIconSvg />}
      onClick={onClick}
      pending={load.pending}
      size={RedoButtonSize.SMALL}
      text="Download report"
      theme={RedoButtonTheme.NORMAL}
    />
  ) : null;
});

const Filters = memo(function Filters({
  filter,
  filterCounts,
  setFilter,
  filters,
  dynamicFilters = false,
  putTabsInHeader = false,
}: {
  filter: string;
  filterCounts?: { [key: string]: number };
  setFilter(key: string): void;
  filters: Filter[];
  dynamicFilters?: boolean;
  putTabsInHeader?: boolean;
}) {
  if (dynamicFilters && filterCounts) {
    filters = filters.filter((f) => filterCounts[f.key] > 0);
  }

  const tabs = (
    <RedoHorizontalTabs
      drawBottomLine={false}
      mode={RedoHorizontalTabMode.REGULAR}
      pl="none"
      selectedTab={filter}
      setSelectedTab={setFilter}
      size={RedoHorizontalTabSize.SMALL}
      tabs={filters.map((f) => ({
        label: f.name,
        count: filterCounts?.[f.key],
        key: f.key,
      }))}
    />
  );

  if (putTabsInHeader) {
    return <TabsPortal tabCount={filters.length}>{tabs}</TabsPortal>;
  }

  return tabs;
});

export const DefaultLoadingContentMessage = memo(
  function DefaultLoadingContentMessage({
    borderless,
  }: {
    borderless?: boolean;
  }) {
    return (
      <Flex
        align="center"
        className={
          borderless
            ? table2Css.borderlessSpinnerContainer
            : table2Css.spinnerContainer
        }
        grow={1}
        justify="center"
        py="6xl"
      >
        <Flex>
          <Flex align="center" gap="md">
            <Text fontSize="xs" textColor="quaternary">
              Loading...
            </Text>
            <Flex className={table2Css.spinner} mr="2xl">
              <Spinner />
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  },
);

export const DefaultEmptyContentMessage = memo(
  function DefaultEmptyContentMessage({
    borderless,
  }: {
    borderless?: boolean;
  }) {
    return (
      <Flex
        align="center"
        className={
          borderless
            ? table2Css.borderlessSpinnerContainer
            : table2Css.spinnerContainer
        }
        grow={1}
        justify="center"
        py="6xl"
      >
        <Flex>
          <Flex align="center" gap="md">
            <Text fontWeight="medium" textColor="quaternary">
              No matching records
            </Text>
          </Flex>
        </Flex>
      </Flex>
    );
  },
);

type TableContentProps2<T> = Omit<TableContentProps<T>, "columns"> & {
  columns: Column2<T>[];
  size?: RedoTableSize;
  LoadingContentMessage?: ReactElement;
  appliedAdvancedFilters?: AdvancedTableFilter[];
  activeView?: viewTypes;
  className?: string;
  isRowSelected?: (item: T) => boolean;
  adjustableColumnWidths?: boolean;
  columnWidths: Record<string, number>;
  setColumnWidths: (columnWidths: Record<string, number>) => void;
  footerRef?: RefObject<HTMLDivElement>;
  useVirtualTable?: boolean;
};

const getNewColumnWidth = ({
  adjustedWidth,
  originalWidth,
  widthDif,
}: {
  adjustedWidth: number | undefined;
  originalWidth: number;
  widthDif: number;
}): number => {
  const newWidth = (adjustedWidth ?? originalWidth) + widthDif;
  if (newWidth < MINIMUM_COLUMN_WIDTH) {
    return MINIMUM_COLUMN_WIDTH;
  }
  return newWidth;
};

const AdjustableBorder = ({
  onAdjustColumnWidth,
  held,
  setHeld,
}: {
  onAdjustColumnWidth: ({ widthDif }: { widthDif: number }) => void;
  held: boolean;
  setHeld: (held: boolean) => void;
}) => {
  const handler = (mouseDownEvent: any) => {
    let startX = mouseDownEvent.pageX;
    setHeld(true);

    function onMouseMove(mouseMoveEvent: any) {
      onAdjustColumnWidth({ widthDif: mouseMoveEvent.pageX - startX });
    }
    function onMouseUp(mouseMoveEvent: any) {
      setHeld(false);
      document.body.removeEventListener("mousemove", onMouseMove);
      startX = mouseMoveEvent.pageX;
    }

    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  };

  return (
    <div
      className={classnames(
        redoTableHeaderCellsCss.adjustableWidthBorder,
        held ? redoTableHeaderCellsCss.adjustableWidthBorderHeld : undefined,
      )}
      onMouseDown={handler}
    />
  );
};

const NonAdjustableBorder = ({ held }: { held: boolean }) => {
  return (
    <div
      className={classnames(
        redoTableHeaderCellsCss.nonAdjustableWidthBorder,
        held ? redoTableHeaderCellsCss.nonAdjustableWidthBorderHeld : undefined,
      )}
    />
  );
};

export type YieldType<T> = {
  items: T[];
  refresh?: ((signal: AbortSignal) => Promise<T[]>) | undefined;
  aborted?: boolean;
};
function TableContentComponent<T>(
  {
    columns,
    columnWidths,
    setColumnWidths,
    fetcher,
    primaryFilter,
    filters,
    onRowClick,
    onRowHovered,
    sort,
    search,
    pageNumber,
    setSort,
    passThroughValues,
    alwaysLoadTable = false,
    onQuickDownload,
    scrollable = false,
    theme = TableTheme.COMPACT,
    scrollAreaRef,
    header,
    pageSize,
    EmptyContentMessage = <DefaultEmptyContentMessage />,
    onContextMenu,
    size: size = RedoTableSize.MEDIUM,
    LoadingContentMessage = <DefaultLoadingContentMessage />,
    refreshSymbol,
    appliedAdvancedFilters,
    isRowSelected,
    activeView,
    className,
    adjustableColumnWidths,
    footerRef,
    useVirtualTable,
  }: TableContentProps2<T>,
  ref: ForwardedRef<TableRef<T>>,
) {
  const scrolled = useScrolled(scrollAreaRef.current);
  const [items, setItems] = useState<T[]>([]);
  const [iterator, setIterator] = useState<AsyncIterator<YieldType<T>>>();
  const [started, setStarted] = useState(false);
  const [pending, setPending] = useState(false);
  const [finished, setFinished] = useState(false);
  const [refresh, setRefresh] =
    useState<(signal: AbortSignal) => Promise<T[]>>();

  const abortControllerRef = useRef<AbortController>(new AbortController());
  const [heldBorderKey, setHeldBorderKey] = useState<string | null>(null);

  const processedColumns = useMemo(
    () =>
      columns.map((column) => ({
        ...column,
        renderNormalCell:
          adjustableColumnWidths &&
          (!column.widthMode ||
            column?.widthMode?.type === RedoTableColumnWidthType.FILL)
            ? (props: RedoTableCellProps<T>) => {
                return (
                  <>
                    <div className={table2Css.normalCellWithAdjustableBorder}>
                      {column.renderNormalCell(props)}
                    </div>
                    <NonAdjustableBorder held={column.key === heldBorderKey} />
                  </>
                );
              }
            : column.renderNormalCell,
        widthMode: column.widthMode
          ? column.widthMode
          : {
              type: RedoTableColumnWidthType.FILL as RedoTableColumnWidthType.FILL,
              relativeLength: columnWidths?.[column.key] ?? column.width,
            },
        renderHeaderCell: () => {
          function onSort(direction: SortDirection) {
            setSort({ direction, key: column.key });
          }
          return (
            <>
              <ColumnHeader
                defaultDirection={column.sort}
                direction={sort.key === column.key ? sort.direction : null}
                sort={column.sort !== undefined ? onSort : undefined}
                theme={theme}
                width={columnWidths?.[column.key] ?? column.width}
              >
                {column}
              </ColumnHeader>
              {adjustableColumnWidths &&
                (!column.widthMode ||
                  column?.widthMode?.type ===
                    RedoTableColumnWidthType.FILL) && (
                  <AdjustableBorder
                    held={column.key === heldBorderKey}
                    onAdjustColumnWidth={({ widthDif }) => {
                      setColumnWidths({
                        ...columnWidths,
                        [column.key]: getNewColumnWidth({
                          adjustedWidth: columnWidths?.[column.key],
                          originalWidth: column.width,
                          widthDif: widthDif,
                        }),
                      });
                    }}
                    setHeld={(held: boolean) => {
                      if (held) {
                        setHeldBorderKey(column.key);
                      } else {
                        setHeldBorderKey(null);
                      }
                    }}
                  />
                )}
            </>
          );
        },
      })),
    [
      setColumnWidths,
      columnWidths,
      columns,
      sort,
      setSort,
      theme,
      adjustableColumnWidths,
      heldBorderKey,
      setHeldBorderKey,
    ],
  );

  /**
   * This exists to fix the following race condition:
   * 1. Iterator1 is created
   * 2. Iterator1 is aborted before being called for the first time, and Iterator2 is created
   * 3. The second `useEffect` runs with Iterator1, and it returns "done" even though it was
   *    aborted. As a result, `finished` is set to `true`
   * 4. The second `useEffect` runs with Iterator2, which is more up-to-date,
   *    but its execution is skipped because `finished === true`
   *
   * To solve this, check if the iterator is the latest iterator before trying to fetch more
   * data from it.
   *
   * This fix is a bandaid. I believe the root of the problem is calling async code in
   * `useEffect` that sets shared state (a recipe for race conditions). If you know a better
   * fix, have at it.
   */
  const latestIteratorRef = useRef(iterator);

  const debouncedAdvancedFilters = useDebounce(appliedAdvancedFilters, 500);

  useEffect(() => {
    abortControllerRef.current?.abort();
    const newAbort = new AbortController();
    const signal = newAbort.signal;
    const newIterator = fetcher.data(
      primaryFilter,
      filters,
      search || undefined,
      search ? undefined : sort,
      pageSize || DEFAULT_PAGE_SIZE,
      pageNumber,
      signal,
      passThroughValues,
      debouncedAdvancedFilters,
    );
    latestIteratorRef.current = newIterator;
    setIterator(newIterator);
    setPending(false);
    setFinished(false);
    setItems([]);
    abortControllerRef.current = newAbort;
    if (iterator?.return) {
      void iterator.return();
    }
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetcher,
    primaryFilter,
    search,
    sort,
    filters,
    debouncedAdvancedFilters,
    passThroughValues,
    pageNumber,
    refreshSymbol,
    pageSize,
  ]);

  useEffect(() => {
    async function loadMoreIfNecessary() {
      if (
        (scrolled.bottom || alwaysLoadTable || !started) &&
        !pending &&
        !finished &&
        iterator &&
        iterator === latestIteratorRef.current
      ) {
        setStarted(true);
        setPending(true);
        let done: boolean;
        let value: YieldType<T>;
        let aborted: boolean;
        try {
          const results = await iterator.next();
          done = results.done || false;
          value = results.value;
          aborted = value?.aborted || false;
          if (aborted) return; // Pending has already been restored to false
        } catch (e) {
          setPending(false);
          throw e;
        }
        setPending(false);
        if (done) {
          if (iterator.return) {
            await iterator.return();
          }
          if (!aborted) {
            setFinished(true);
          }
          return;
        }
        setRefresh(() => value.refresh);
        setItems((items) => [...items, ...value.items]);
      }
    }
    void loadMoreIfNecessary();
    // FIXME
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iterator, scrolled.bottom, pending, finished, started]);

  const refreshData = async () => {
    if (refresh) {
      const reloadedItems = await refresh(abortControllerRef.current.signal);
      setItems(reloadedItems);
    }
  };

  const removeItem = (
    currentItem: T,
    areEqual: (item1: T, item2: T) => boolean,
  ) => {
    const index = items.findIndex((item) => areEqual(item, currentItem));
    if (index === -1) {
      return;
    }
    setItems((items) => {
      const newItems = [...items];
      newItems.splice(index, 1);
      return newItems;
    });
  };

  const externalRefresh = async (setItemsCallback: (items: T[]) => void) => {
    if (refresh) {
      const reloadedItems = await refresh(abortControllerRef.current.signal);
      setItemsCallback(reloadedItems);
    }
  };

  const externalSetItems = (items: T[]) => {
    setItems(items);
  };

  useImperativeHandle<TableRef<T>, TableRef<T>>(ref, () => ({
    items,
    refresh: refreshData,
    removeItem,
    externalRefresh,
    externalSetItems,
  }));

  const itemsToShow = items || [];

  const shouldShowAnEmptyMessage = !pending && !itemsToShow.length;

  // const width = sumBy(columns, (column) => column.width);
  const rowsSelected =
    isRowSelected && itemsToShow.map((item) => isRowSelected(item));

  const handleRowClick = (row: T, rowIndex: number, e: MouseEvent) => {
    onRowClick?.(row, e as MouseEvent<HTMLTableRowElement>, rowIndex);
  };

  const handleContextMenu = (
    row: T,
    e: MouseEvent<HTMLTableRowElement>,
    rowIndex: number,
  ) => {
    onContextMenu?.(row, e as MouseEvent<HTMLTableRowElement>, rowIndex);
  };

  return (
    <div className={className}>
      {header && <div className={tableCss.headerSpacer} />}
      {useVirtualTable ? (
        <RedoVirtualTable
          emptyStateContent={
            shouldShowAnEmptyMessage ? (
              <Flex grow={1}>{EmptyContentMessage}</Flex>
            ) : null
          }
          footerRef={footerRef}
          layout={{
            columns: processedColumns.map((processedColumn) => {
              return { ...processedColumn, alignment: undefined };
            }),
          }}
          loadingStateContent={pending ? LoadingContentMessage : null}
          onContextMenu={handleContextMenu}
          onRowClick={handleRowClick}
          onRowHovered={onRowHovered}
          rowsOfData={itemsToShow}
          rowsSelected={rowsSelected}
          size={size}
        />
      ) : (
        <>
          <RedoTable
            layout={{
              columns: processedColumns.map((processedColumn) => {
                return { ...processedColumn, alignment: undefined };
              }),
            }}
            onContextMenu={handleContextMenu}
            onRowClick={handleRowClick}
            onRowHovered={onRowHovered}
            rowsOfData={itemsToShow}
            rowsSelected={rowsSelected}
            size={size}
          />
          {pending ? LoadingContentMessage : null}

          {shouldShowAnEmptyMessage && (
            <Flex grow={1}>{EmptyContentMessage}</Flex>
          )}
        </>
      )}
    </div>
  );
}

const TableContent = genericMemo(
  forwardRef(TableContentComponent) as <T>(
    props: TableContentProps2<T> & { ref?: React.ForwardedRef<TableRef<T>> },
  ) => ReturnType<typeof TableContentComponent>,
);

interface ColumnHeaderProps {
  defaultDirection?: SortDirection;
  direction: SortDirection | null;
  children: Column<any>;
  sort?(direction: SortDirection): void;
  width: number;
  theme?: TableTheme;
}

type ColumnHeaderProps2 = Omit<ColumnHeaderProps, "children"> & {
  children: Column2<any>;
};

const ColumnHeader = memo(function ColumnHeader({
  defaultDirection = SortDirection.ASC,
  direction,
  children,
  sort,
  width,
  theme = TableTheme.COMPACT,
}: ColumnHeaderProps2) {
  const className = classnames(
    tableCss.header,
    tableThemeClasses[theme],
    alignClasses.get(children.alignment),
    { [tableCss.active]: direction !== null },
  );
  const sortClassName = classnames(
    tableCss.headerSort,
    alignClasses.get(children.alignment),
    (direction !== undefined ? direction : defaultDirection) ===
      SortDirection.DESC
      ? tableCss.headerSortDesc
      : tableCss.headerSortAsc,
  );

  const onClick = useHandler(() => {
    sort &&
      sort(
        direction == null
          ? defaultDirection
          : direction === SortDirection.ASC
            ? SortDirection.DESC
            : SortDirection.ASC,
      );
  });

  let content: ReactNode;
  if (typeof children.title === "string") {
    let arrowIcon;
    switch (direction) {
      case SortDirection.ASC:
        arrowIcon = StandardRedoTableHeaderCellArrowMode.ASCENDING;
        break;
      case SortDirection.DESC:
        arrowIcon = StandardRedoTableHeaderCellArrowMode.DESCENDING;
        break;
      default:
        arrowIcon = StandardRedoTableHeaderCellArrowMode.NO_ARROW;
    }

    return StandardRedoTableHeaderCell({
      headerText: children.title,
      helpIconTooltip: children.headerTooltip,
      onSortClick: sort ? onClick : undefined,
      arrowIcon,
    });

    const label = (
      <span className={tableCss.headerLabel}>{children.title}</span>
    );
    const ArrowType = theme === TableTheme.WIDE ? ArrowDown : ChevronDown;
    const sort_ = sort && (
      <span className={tableCss.headerSortContainer}>
        <ArrowType className={sortClassName} />
      </span>
    );

    if (children.alignment === ColumnAlignment.RIGHT) {
      content = (
        <>
          {sort_}
          {label}
        </>
      );
    } else {
      content = (
        <>
          {label}
          {sort_}
        </>
      );
    }
  }

  return typeof children.title === "string" ? (
    <th
      className={className}
      style={{ width: `${width}px`, minWidth: `${width}px` }}
    >
      {sort ? (
        <button
          className={classnames(
            tableCss.headerButton,
            tableCss.headerContent,
            tableThemeClasses[theme],
          )}
          onClick={onClick}
        >
          {content}
        </button>
      ) : (
        <div
          className={classnames(
            tableCss.headerContent,
            tableThemeClasses[theme],
          )}
        >
          {content}
        </div>
      )}
    </th>
  ) : (
    <div className={redoTableHeaderCellsCss.headerCell}>{children.title}</div>
  );
});
