// extracted by mini-css-extract-plugin
export var box = "_6o-1";
export var checked = "_6o-5";
export var descriptionSpacer = "_6o-9";
export var disabled = "_6o-4";
export var icon = "_6o-6";
export var input = "_6o-0";
export var labelSpacer = "_6o-8";
export var medium = "_6o-3";
export var small = "_6o-2";
export var text = "_6o-7";