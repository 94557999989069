import * as amplitude from "@amplitude/analytics-browser";
import { Fade } from "@mui/material";
import { useRequiredContext } from "@redotech/react-util/context";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import {
  ReloadTeamContext,
  TeamContext,
} from "@redotech/redo-merchant-app-common/team";
import { allowSupportAiCopilot } from "@redotech/redo-model/team";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoSingleSelectDropdown } from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown";
import Stars01 from "@redotech/redo-web/arbiter-icon/stars-01.svg";
import XIcon from "@redotech/redo-web/arbiter-icon/x-close.svg";
import CircleSpinner from "@redotech/redo-web/circle-spinner.svg";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import {
  getLocalStorageWithExpiry,
  removeItemFromLocalStorage,
  setLocalStorageWithExpiry,
} from "@redotech/redo-web/utils/local-storage-wrapper";
import { isUserOnMac } from "@redotech/util/browser-agent";
import { sinkPromise } from "@redotech/util/promise";
import { assertNever } from "@redotech/util/type";
import {
  KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { getSupportAiResponse } from "../client/support-ai";
import { BANNER_DISMISSED_KEY } from "./ai-trial-banner";
import { hotkeyTooltip } from "./message-input/hotkeys";
import * as styles from "./writing-ai-assistant-menu.module.css";

type AiOperation = "suggest" | "polish";

export enum QuillOperation {
  INSERT = "insert",
  REPLACE = "replace",
}

const SHOWN_AI_ENGAGEMENT_TOOLTIP_KEY = "shown_ai_engagement_tooltip";

interface WritingAiAssistantMenuProps {
  conversationId: string;
  draftId?: string;
  messageRespondingToId: string;
  onAiOperationComplete: (content: string, operation: QuillOperation) => void;
  onLoadingChange?: (isLoading: boolean) => void;
  onHotkeyFunctionReady: (
    hotkeyFunction: (event: KeyboardEvent) => void,
  ) => void;
  aiHasBeenUsedOnConversation: boolean;
}

export const WritingAiAssistantMenu: React.FC<WritingAiAssistantMenuProps> = ({
  conversationId,
  draftId,
  messageRespondingToId,
  onAiOperationComplete,
  onLoadingChange,
  onHotkeyFunctionReady,
  aiHasBeenUsedOnConversation,
}) => {
  const team = useRequiredContext(TeamContext);
  const reloadTeam = useContext(ReloadTeamContext);
  const redoMerchantRpcClient = useRequiredContext(
    RedoMerchantRpcClientContext,
  );
  const client = useRequiredContext(RedoMerchantClientContext);
  const [selectedOperation, setSelectedOperation] =
    useState<AiOperation | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const dropdownButtonRef = useRef<HTMLButtonElement>(null);
  const aiOperations: RedoListItem<AiOperation>[] = [
    { value: "suggest" },
    { value: "polish" },
  ];
  const operationBadgeNumbers: Record<AiOperation, string> = {
    suggest: "1",
    polish: "2",
  };
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isMac = isUserOnMac();

  const canUseAi =
    allowSupportAiCopilot(team) ||
    aiHasBeenUsedOnConversation ||
    (team.settings.support?.ai?.trialMessagesRemaining || 0) > 0;
  const disabled = isLoading || !canUseAi;
  const [showAiEngagementTooltip, setShowAiEngagementTooltip] = useState(false);

  useEffect(() => {
    // wait 2 seconds before showing engagement tooltip
    // show once every 7 days if they haven't tried ai yet
    if (
      !getLocalStorageWithExpiry(SHOWN_AI_ENGAGEMENT_TOOLTIP_KEY) &&
      !team.settings.support?.billing?.ai?.planName &&
      (team.settings.support?.ai?.trialMessagesRemaining || 0) > 0 &&
      !team.settings.support?.ai?.hasTriedAi
    ) {
      setTimeout(() => {
        setShowAiEngagementTooltip(true);
      }, 1000);
    }
  }, []);

  const handleAiOperation = useCallback(
    async (operation: AiOperation) => {
      if (disabled) return;

      setIsLoading(true);
      if (onLoadingChange) {
        onLoadingChange(true);
      }

      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }

      debounceTimeoutRef.current = setTimeout(async () => {
        try {
          let result;
          if (operation === "suggest") {
            result = await getSupportAiResponse(client, {
              type: "conversationReplySuggestion",
              conversationId,
              prevMessageId: messageRespondingToId,
            });
            if (result?.text) {
              amplitude.logEvent("generate-copilotSuggestion", {
                conversationId: conversationId,
                cost: result.cost,
              });
            }
            if (result?.text) {
              onAiOperationComplete(result.text, QuillOperation.INSERT);
            }
          } else if (operation === "polish") {
            if (!draftId) {
              return;
            }
            result = await redoMerchantRpcClient.polishMessage({
              draftId,
              conversationId,
            });
            if (result?.polishedResponse) {
              onAiOperationComplete(
                result.polishedResponse,
                QuillOperation.REPLACE,
              );
            }
          } else {
            assertNever(operation);
          }
        } catch (error) {
          console.error("AI operation failed:", error);
        } finally {
          setIsLoading(false);
          if (onLoadingChange) {
            onLoadingChange(false);
          }
          setSelectedOperation(null);
          if (
            !aiHasBeenUsedOnConversation &&
            team.settings.support?.ai?.trialMessagesRemaining
          ) {
            // Show banner again if we changed the number of remaining free messages, and there's at least one left
            removeItemFromLocalStorage(BANNER_DISMISSED_KEY);
          }
          reloadTeam && reloadTeam();
        }
      }, 300);
    },
    [
      client,
      redoMerchantRpcClient,
      conversationId,
      draftId,
      onAiOperationComplete,
      onLoadingChange,
      isLoading,
    ],
  );

  const optionSelected = useCallback(
    (item: RedoListItem<AiOperation>) => {
      setSelectedOperation(item.value);
      void handleAiOperation(item.value);
    },
    [handleAiOperation],
  );

  const getOperationDisplayText = (value: AiOperation): string => {
    switch (value) {
      case "suggest":
        return "Suggest a response";
      case "polish":
        return "Improve writing";
      default:
        assertNever(value);
    }
  };

  const isToolDisabled = useMemo(() => {
    return (operation: AiOperation): boolean => {
      if (operation === "suggest") {
        return disabled;
      }
      if (operation === "polish") {
        return disabled || !draftId;
      }
      return false;
    };
  }, [disabled, draftId]);

  const maybePerformOptionHotkeyAction = useCallback(
    (event: KeyboardEvent) => {
      if (!event.altKey) {
        return;
      }
      if (
        (event.key === "1" || event.key === "¡") &&
        !isToolDisabled("suggest")
      ) {
        event.preventDefault();
        event.stopPropagation();
        void handleAiOperation("suggest");
      }
      if (
        (event.key === "2" || event.key === "™") &&
        !isToolDisabled("polish")
      ) {
        event.preventDefault();
        event.stopPropagation();
        void handleAiOperation("polish");
      }
    },
    [handleAiOperation, isToolDisabled],
  );

  useEffect(() => {
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    onHotkeyFunctionReady(maybePerformOptionHotkeyAction);
  }, [onHotkeyFunctionReady, maybePerformOptionHotkeyAction]);

  const IconTrailing: React.FC = () => (
    <Flex>{isLoading ? <CircleSpinner /> : <Stars01 />}</Flex>
  );

  if (showAiEngagementTooltip) {
    return (
      <div>
        <Tooltip
          arrow
          onClose={() => {
            // intentionally empty, controlled with x button
          }}
          open={showAiEngagementTooltip}
          placement="top"
          slotProps={{ transition: { timeout: 600 } }}
          slots={{ transition: Fade }}
          subtitle={
            <div className={styles.leftAlign}>
              Did you know that these responses can be automated using our new
              AI Copilot feature?
            </div>
          }
          title={
            <Flex align="center" justify="space-between">
              <div className={styles.leftAlign}>Use AI generated responses</div>
              <RedoButton
                hierarchy={RedoButtonHierarchy.TERTIARY}
                IconLeading={XIcon}
                onClick={() => {
                  setLocalStorageWithExpiry(
                    SHOWN_AI_ENGAGEMENT_TOOLTIP_KEY,
                    "seen",
                    24 * 7,
                  );
                  setShowAiEngagementTooltip(false);
                }}
              />
            </Flex>
          }
        >
          <RedoButton
            disabled={disabled}
            IconTrailing={IconTrailing}
            onClick={() => {
              setShowAiEngagementTooltip(false);
              sinkPromise(handleAiOperation("suggest"));
            }}
            size={RedoButtonSize.REGULAR}
          />
        </Tooltip>
      </div>
    );
  }

  return (
    <Tooltip
      arrow
      placement="top"
      title={
        !canUseAi
          ? "Enable AI features in the billing tab"
          : hotkeyTooltip({
              action: "Show",
              code: "1",
              name: "AI actions",
              isMac,
              modifierKey: "alt",
            })
      }
    >
      <span>
        <RedoButton
          disabled={disabled}
          IconTrailing={IconTrailing}
          ref={dropdownButtonRef}
          size={RedoButtonSize.REGULAR}
        />
        <RedoSingleSelectDropdown
          containerClassName={styles.dropdownContainer}
          dropdownButtonRef={dropdownButtonRef.current}
          gap="none"
          isItemDisabled={(item) => isToolDisabled(item.value)}
          options={aiOperations}
          optionSelected={optionSelected}
          padding="none"
          selectedItem={aiOperations.find(
            (op) => op.value === selectedOperation,
          )}
        >
          {(item) => (
            <Flex className={styles.listItemWrapper}>
              <Flex
                align="center"
                className={styles.listItem}
                justify="space-between"
              >
                <Text className={styles.operationText}>
                  {getOperationDisplayText(item.value)}
                </Text>
                <Flex>
                  <RedoBadge
                    color={RedoBadgeColor.GRAY}
                    size={RedoBadgeSize.MEDIUM}
                    text={operationBadgeNumbers[item.value]}
                  />
                </Flex>
              </Flex>
            </Flex>
          )}
        </RedoSingleSelectDropdown>
      </span>
    </Tooltip>
  );
};
