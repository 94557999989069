import { EstimatedSmsCost } from "@redotech/merchant-sdk/marketing-rpc/schema/billing/get-estimated-sms-cost";
import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { CampaignWithCustomerGroups } from "@redotech/redo-model/campaigns/campaign-definition";
import { MarketingChannel } from "@redotech/redo-model/marketing";
import { TextMessageType } from "@redotech/redo-model/sms";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import {
  RedoInputSize,
  RedoInputState,
} from "@redotech/redo-web/arbiter-components/input/base-redo-text-input";
import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import { RedoBaseModal } from "@redotech/redo-web/arbiter-components/modal/redo-base-modal";
import { RedoModalHeader } from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import {
  HeaderColor,
  RedoTable,
  RedoTableLayout,
} from "@redotech/redo-web/arbiter-components/tables/redo-table";
import { RedoTableTextCell } from "@redotech/redo-web/arbiter-components/tables/redo-table-cells";
import { StandardRedoTableHeaderCell } from "@redotech/redo-web/arbiter-components/tables/redo-table-header-cells";
import CircleSpinner from "@redotech/redo-web/circle-spinner.svg";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useMemo, useState } from "react";
import HelpIcon from "../../../../web/src/arbiter-icon/help-circle.svg";
import { RedoMarketingRpcClientContext } from "../../app/redo-marketing-rpc-client-provider";
import { SmsPhone } from "../sms-builder/common/sms-phone";
import { getSmsBuilderDefault } from "../sms-builder/sms-builder-types";

function useCostStats(campaign?: CampaignWithCustomerGroups | null) {
  const marketingRpcClient = useRequiredContext(RedoMarketingRpcClientContext);

  const recipientCount = useLoad(
    async (signal) => {
      const customerGroups = campaign?.customerGroups || [];

      if (!customerGroups.length) {
        return 0;
      }

      const { count } =
        await marketingRpcClient.getEstimatedCampaignRecipientCount(
          {
            groups: customerGroups.map((group) => group._id.toString()),
            subscriptionType: MarketingChannel.SMS,
          },
          { signal },
        );

      return count;
    },
    [campaign],
  );

  const smsCostEstimate = useLoad<EstimatedSmsCost | null>(async () => {
    const smsTemplate = campaign?.smsTemplate;
    if (!smsTemplate) {
      return {
        messageType: TextMessageType.SMS,
        partCount: 1,
        carrierFeeForMessageTypePart: 0,
        redoCostForMessageTypePart: 0,
      };
    }

    const linksShortened = smsTemplate.autoShortenLinks !== false;
    const cost: EstimatedSmsCost | null =
      await marketingRpcClient.getEstimatedSmsCost({
        linksShortened,
        containsAttachments: !!smsTemplate.imageSrc,
        message: smsTemplate.content,
      });
    return cost;
  }, [campaign]);

  return {
    smsCostEstimate: smsCostEstimate.value || null,
    recipientCount: recipientCount.value ?? 0,
    costLoading: recipientCount.pending || smsCostEstimate.pending,
  };
}

export const SmsSummaryCard = memo(function SmsSummaryCard({
  campaign,
}: {
  campaign?: CampaignWithCustomerGroups | null;
}) {
  const marketingRpcClient = useRequiredContext(RedoMarketingRpcClientContext);

  const [costModalOpen, setCostModalOpen] = useState(false);

  const phoneNumberInfoLoad = useLoad(
    async (signal) => {
      return await marketingRpcClient.getTeamPhoneNumber({}, { signal });
    },
    [marketingRpcClient],
  );

  const { smsCostEstimate, recipientCount, costLoading } =
    useCostStats(campaign);

  const totalCostEstimate: number | null = useMemo(() => {
    if (!smsCostEstimate) {
      return null;
    }

    const costForOneRecipient =
      smsCostEstimate.partCount *
      (smsCostEstimate.redoCostForMessageTypePart +
        smsCostEstimate.carrierFeeForMessageTypePart);

    return recipientCount * costForOneRecipient;
  }, [smsCostEstimate, recipientCount]);

  const recipientCountDisplay = `${recipientCount.toLocaleString()} ${recipientCount === 1 ? "recipient" : "recipients"}`;

  const fromNumber = phoneNumberInfoLoad.value?.number;

  return (
    <>
      <Flex
        bgColor="primary"
        borderColor="primary"
        borderStyle="solid"
        borderWidth="1px"
        dir="column"
        p="3xl"
        radius="xl"
        style={{ minHeight: "max-content" }}
      >
        <Text fontSize="sm" fontWeight="semibold">
          {campaign?.finishedAt ? "SMS content" : "SMS preview"}
        </Text>
        <Flex gap="3xl" grow={1}>
          <Flex py="3xl">
            <SmsPhone
              autoShortenLinks={campaign?.smsTemplate?.autoShortenLinks ?? true}
              content={
                campaign?.smsTemplate?.content || getSmsBuilderDefault().content
              }
              imageSrc={campaign?.smsTemplate?.imageSrc}
            />
          </Flex>
          <Flex basis="0" bgColor="primary" dir="column" grow={1} p="6xl">
            <Flex dir="column" gap="xl" grow={1} justify="center">
              <Flex dir="column" gap="xxs">
                <Text fontSize="sm" fontWeight="semibold">
                  From phone number
                </Text>
                <RedoTextInput
                  setValue={() => {}}
                  size={RedoInputSize.SMALL}
                  state={RedoInputState.READONLY}
                  value={fromNumber || ""}
                />
              </Flex>
              <Flex dir="column" gap="xxs">
                <Text fontSize="sm" fontWeight="semibold">
                  Included segments
                </Text>
                <Flex gap="xs" wrap="wrap">
                  {campaign?.customerGroups?.map((cg) => (
                    <RedoBadge
                      color={RedoBadgeColor.GRAY}
                      key={cg._id}
                      size={RedoBadgeSize.SMALL}
                      text={cg.name}
                    />
                  )) || null}
                </Flex>
              </Flex>
              <Flex dir="column" gap="xxs">
                <Text fontSize="sm" fontWeight="semibold">
                  {campaign?.finishedAt ? "Recipients" : "Audience"}
                </Text>
                <Text fontSize="xs" textColor="primary">
                  {recipientCountDisplay}
                </Text>
                {costLoading && (
                  <CircleSpinner style={{ height: "12px", width: "12px" }} />
                )}
              </Flex>
              {totalCostEstimate !== null && (
                <Flex dir="column" gap="xxs">
                  <Flex align="center">
                    <Text fontSize="sm" fontWeight="semibold">
                      Cost estimate
                    </Text>
                    {!costLoading && (
                      <RedoButton
                        hierarchy={RedoButtonHierarchy.TERTIARY}
                        IconLeading={HelpIcon}
                        onClick={() => setCostModalOpen(true)}
                        size={RedoButtonSize.EXTRA_SMALL}
                      />
                    )}
                  </Flex>
                  <Text fontSize="xs" textColor="primary">
                    {`$${totalCostEstimate.toFixed(2)}`}
                  </Text>
                </Flex>
              )}
              {campaign?.finishedAt && (
                <Flex dir="column" gap="xxs">
                  <Text fontSize="sm" fontWeight="semibold">
                    Sent
                  </Text>
                  <RedoTextInput
                    setValue={() => {}}
                    size={RedoInputSize.SMALL}
                    state={RedoInputState.READONLY}
                    value={campaign.finishedAt
                      .toTemporalInstant()
                      .toLocaleString("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "2-digit",
                        hour12: true,
                      })}
                  />
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <CostEstimateModal
        cost={smsCostEstimate}
        onClose={() => setCostModalOpen(false)}
        open={costModalOpen}
        recipientCount={recipientCount}
      />
    </>
  );
});

interface CostEstimateRow {
  name: string;
  nameDescription: string;
  value: string;
}

const constEstimateTableLayout: RedoTableLayout<CostEstimateRow> = {
  headerColor: HeaderColor.GREY,
  columns: [
    {
      renderHeaderCell: () => <StandardRedoTableHeaderCell headerText="Type" />,
      renderNormalCell: ({ row }) => (
        <RedoTableTextCell subtext={row.nameDescription} text={row.name} />
      ),
    },
    {
      renderHeaderCell: () => <StandardRedoTableHeaderCell headerText="Cost" />,
      renderNormalCell: ({ row }) => <RedoTableTextCell text={row.value} />,
    },
  ],
};

const CostEstimateModal = memo(function CostEstimateModal({
  cost,
  recipientCount,
  open,
  onClose,
}: {
  cost: EstimatedSmsCost | null;
  recipientCount: number;
  open: boolean;
  onClose: () => void;
}) {
  cost = cost || {
    messageType: TextMessageType.SMS,
    partCount: 0,
    carrierFeeForMessageTypePart: 0,
    redoCostForMessageTypePart: 0,
  };

  const rows: CostEstimateRow[] = useMemo(() => {
    const partString = cost.partCount === 1 ? "part" : "parts";
    const messageCostPerRecipientRow: CostEstimateRow = {
      name: "Message cost per recipient",
      nameDescription: `${cost.partCount} ${partString} ($${cost.redoCostForMessageTypePart}/part)`,
      value: `$${cost.partCount * cost.redoCostForMessageTypePart}`,
    };

    const carrierCostRow: CostEstimateRow = {
      name: "Carrier cost per recipient",
      nameDescription: `${cost.partCount} ${partString} ($${cost.carrierFeeForMessageTypePart}/part)`,
      value: `$${cost.partCount * cost.carrierFeeForMessageTypePart}`,
    };

    const audienceRow: CostEstimateRow = {
      name: "Audience",
      nameDescription: `Recipients based on included segments`,
      value: `${recipientCount.toLocaleString()}`,
    };
    return [messageCostPerRecipientRow, carrierCostRow, audienceRow];
  }, [cost, recipientCount]);

  const totalCost = useMemo(() => {
    const pricePerRecipient =
      cost.partCount *
      (cost.redoCostForMessageTypePart + cost.carrierFeeForMessageTypePart);

    return pricePerRecipient * recipientCount;
  }, [cost, recipientCount]);

  return (
    <RedoBaseModal isOpen={open} onModalCloseRequested={onClose}>
      <RedoModalHeader cancelClicked={onClose} title="Cost estimate" />
      <RedoTable layout={constEstimateTableLayout} rowsOfData={rows} />
      <Flex
        borderColor="primary"
        borderStyle="solid"
        borderTopWidth="1px"
        p="2xl"
      >
        <Text
          fontWeight="semibold"
          style={{ flex: "1 1 0px" }}
          textColor="tertiary"
        >
          Total
        </Text>
        <Text
          fontWeight="semibold"
          style={{ flex: "1 1 0px" }}
          textColor="tertiary"
        >{`$${totalCost.toFixed(2)}`}</Text>
      </Flex>
    </RedoBaseModal>
  );
});
