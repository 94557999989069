import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { useLoad } from "@redotech/react-util/load";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { getCurrentFlow } from "@redotech/redo-merchant-app-common/client/team";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { Return, ReturnTypeEnum } from "@redotech/redo-model/return";
import {
  FlowType,
  returnFlowJsonFormat,
} from "@redotech/redo-model/return-flow";
import { alertOnFailure } from "@redotech/redo-web/alert";
import {
  BlockConfig,
  EdgeConfig,
  Selection,
  SelectionMode,
  SelectionTheme,
} from "@redotech/redo-web/flowchart";
import { assertNever } from "@redotech/util/type";
import { useContext, useMemo, useState } from "react";
import { resetReturn } from "../../client/return";
import { modelToSteps } from "../../setting/flow-editor/flow-editor";
import { REJECT } from "../../setting/return-flow/reject";
import * as returnFlowCss from "../../setting/return-flow/return-flow.module.css";
import { Step } from "../../setting/return-flow/step";

export enum Page {
  CONFIRM = "confirm",
  CHANGE_RESET_POINT = "change-reset-point",
}

export enum IconType {
  CHECK_CIRCLE = "check-circle",
  REFRESH_CCW = "refresh-ccw",
}

interface UseResetModalResponse {
  icon: IconType;
  texts: {
    title: string;
    subtitle: string;
    continueButton: string;
    cancelButton: string;
    resetPointLabel: string;
    resetPointText: string;
    resetPointPlaceholder: string;
    resetReasonLabel: string;
    resetReasonPlaceholder: string;
  };
  resetReason: string;
  onResetReasonChange: (resetReason: string) => void;
  onGoToChangeResetPointPage: () => void;
  onContinue: () => void;
  onCancel: () => void;
  continueButtonDisabled: boolean;
  cancelButtonDisabled: boolean;
  page: Page;
  loading: boolean;
  flowchartProps: {
    blocks: BlockConfig[];
    edges: EdgeConfig[];
    selectionMode: SelectionMode;
    onSelect: (s: Selection) => void;
    selected: Selection;
  };
}

export const useResetModal = ({
  return_,
  onSubmitFinished,
}: {
  return_: Return;
  onSubmitFinished: () => void;
}): UseResetModalResponse => {
  const team = useRequiredContext(TeamContext);
  const user = useContext(UserContext);
  const client = useRequiredContext(RedoMerchantClientContext);

  const [page, setPage] = useState<Page>(Page.CONFIRM);
  const [resetReason, setResetReason] = useState<string>("");
  const [resetStepIndex, setResetStepIndex] = useState<number | undefined>(
    undefined,
  );
  const [selected, setSelected] = useState<Selection | undefined>(undefined);

  const doReset = useHandler(async () => {
    if (!selected) {
      return false;
    }

    return await alertOnFailure("Reseting return failed")(async () => {
      await resetReturn(client, {
        step: +selected,
        resetReason,
        returnId: return_.id,
        user: user,
      });
      amplitude.logEvent("reset-return-support", {
        team: team._id,
        returnId: return_._id,
      });

      onSubmitFinished();

      // For some reason the modal stays open for a moment while navigating,
      // so we wait for 3 seconds before returning to guard against the
      // user being able to submit again.
      await new Promise((resolve) => setTimeout(resolve, 3000));

      return true;
    });
  });

  const flowLoad = useLoad(
    async (signal) => {
      let flowType: FlowType;
      const type = return_.type as ReturnTypeEnum;
      switch (type) {
        case ReturnTypeEnum.CLAIM:
        case ReturnTypeEnum.MANAGED_CLAIM:
          flowType = FlowType.CLAIM;
          break;
        case ReturnTypeEnum.WARRANTY:
          flowType = FlowType.WARRANTY;
          break;
        case ReturnTypeEnum.RETURN:
          flowType = FlowType.RETURN;
          break;
        default:
          assertNever(type);
      }
      const { flow } = await getCurrentFlow(client, flowType, { signal });

      const flowSteps = modelToSteps(returnFlowJsonFormat.read(flow), flowType);

      if (resetStepIndex !== undefined) {
        setSelected(resetStepIndex.toString());
      } else {
        for (const [id, step] of flowSteps.entries()) {
          // If the step has no title it means it's a return reason step
          if (step.title() === undefined) {
            setSelected(id);
            setResetStepIndex(parseInt(id));
            break;
          }
        }
      }

      return flowSteps;
    },
    [return_.type],
  );

  const steps: Map<string, Step> = useMemo(() => {
    if (!flowLoad.value) {
      return new Map();
    }
    return flowLoad.value;
  }, [flowLoad.value]);

  const selectionMode: SelectionMode = useMemo(
    () => ({
      enabled: (id) => steps.get(id)!.type !== REJECT,
      theme: SelectionTheme.HIGHLIGHT,
    }),
    [steps],
  );

  const getStepTitle = (step: Step) => {
    if (step.title()) {
      return step.title();
    }
    const returnType = return_.type as ReturnTypeEnum;
    switch (returnType) {
      case ReturnTypeEnum.CLAIM:
      case ReturnTypeEnum.MANAGED_CLAIM:
        return "Claim reason";
      case ReturnTypeEnum.WARRANTY:
        return "Warranty reason";
      case ReturnTypeEnum.RETURN:
        return "Return reason";
      default:
        assertNever(returnType);
    }
  };

  const blocks: BlockConfig[] = [...steps.entries()].map(([id, step]) => ({
    error: !step.valid(FlowType.RETURN),
    layout: step.layout(),
    title: getStepTitle(step),
    description: step.description(),
    icon: <step.Icon className={returnFlowCss.icon} />,
    id,
  }));

  const edges: EdgeConfig[] = [...steps.entries()].flatMap(([id, step]) =>
    step
      .downstream()
      .map((downstream) => ({
        id: `${id}.${downstream.id}`,
        from: id,
        to: downstream.id,
        label: downstream.label,
      })),
  );

  const resetPointText = useMemo(() => {
    if (!selected) {
      return "";
    }
    const step = steps.get(selected);
    const title = step ? getStepTitle(step) : "";

    return `${title}${step?.description() ? `: ${step?.description()}` : ""}`;
  }, [selected, steps]);

  let title: string;
  let subtitle: string;
  let continueButton: string;
  let cancelButton: string;
  let icon: IconType;
  let onCancel: () => void;
  let onContinue: () => void;
  let continueButtonDisabled: boolean;
  let cancelButtonDisabled: boolean;

  switch (page) {
    case Page.CONFIRM:
      icon = IconType.CHECK_CIRCLE;
      title = "Confirm Reset";
      subtitle =
        "This will delete the current return and void the shipping label. The customer will be able to select the items again in the return portal.";
      continueButton = "Reset";
      cancelButton = "Change reset point";
      onCancel = () => setPage(Page.CHANGE_RESET_POINT);
      onContinue = () => doReset();
      continueButtonDisabled = flowLoad.pending || selected === undefined;
      cancelButtonDisabled = flowLoad.pending;
      break;
    case Page.CHANGE_RESET_POINT:
      icon = IconType.REFRESH_CCW;
      title = "Reset return";
      subtitle =
        "Choose the step of the return flow to reset the items to. When returning the items, the customer will start at this step in the return portal.";
      continueButton = "Change reset point";
      cancelButton = "Cancel";
      onCancel = () => {
        setPage(Page.CONFIRM);
        setSelected(resetStepIndex?.toString() ?? undefined);
      };
      onContinue = () => {
        setPage(Page.CONFIRM);
        let index;
        if (selected === "start") {
          index = 0;
          setSelected("0");
        } else if (selected !== undefined) {
          index = parseInt(selected);
        }
        setResetStepIndex(index);
      };
      continueButtonDisabled = selected === undefined;
      cancelButtonDisabled = false;
      break;
    default:
      assertNever(page);
  }

  return {
    icon,
    texts: {
      title,
      subtitle,
      continueButton,
      cancelButton,
      resetPointLabel: "Reset Point",
      resetPointText,
      resetPointPlaceholder: "Select step...",
      resetReasonLabel: "Reset Reason",
      resetReasonPlaceholder: "Enter reason...",
    },
    resetReason,
    onResetReasonChange: setResetReason,
    onGoToChangeResetPointPage: () => {
      setPage(Page.CHANGE_RESET_POINT);
    },
    onContinue,
    onCancel,
    page,
    continueButtonDisabled,
    cancelButtonDisabled,
    loading: flowLoad.pending,
    flowchartProps: {
      blocks,
      edges,
      selectionMode,
      onSelect: (s) => {
        setSelected(s);
      },
      selected: selected,
    },
  };
};
