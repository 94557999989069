// extracted by mini-css-extract-plugin
export var addPackageButtonInner = "_v-f";
export var bold = "_v-3";
export var bottomPadded = "_v-a";
export var buttonBar = "_v-k";
export var buttonContent = "_v-j";
export var buttonIcon = "_v-e";
export var card = "_v-b";
export var container = "_v-0";
export var descriptionDetails = "_v-2";
export var editUnit = "_v-h";
export var editWeight = "_v-g";
export var editWeightContainer = "_v-i";
export var lgText = "_v-6";
export var main = "_v-1";
export var medText = "_v-7";
export var nonBold = "_v-5";
export var packageSelectActionButton = "_v-c";
export var plusIcon = "_v-d";
export var semiBold = "_v-4";
export var smText = "_v-8";
export var xsText = "_v-9";