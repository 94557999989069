// extracted by mini-css-extract-plugin
export var brand = "_2s-9";
export var button = "_2s-0";
export var chevron = "_2s-v";
export var chevronButton = "_2s-w";
export var content = "_2s-n";
export var danger = "_2s-g";
export var dangerOutlined = "_2s-h";
export var dark = "_2s-l";
export var darkTheme = "_2s-c";
export var extraSmall = "_2s-4";
export var fullWidth = "_2s-8";
export var ghost = "_2s-d";
export var gray = "_2s-t";
export var icon = "_2s-p";
export var iconButton = "_2s-s";
export var iconButtonBorder = "_2s-x";
export var iconButtonBorderDark = "_2s-y";
export var iconButtonBorderLight = "_2s-z";
export var iconLeft = "_2s-q";
export var iconRight = "_2s-r";
export var large = "_2s-1";
export var light = "_2s-m";
export var medium = "_2s-2";
export var micro = "_2s-5";
export var nano = "_2s-7";
export var outlined = "_2s-k";
export var pending = "_2s-a";
export var primary = "_2s-i";
export var shadow = "_2s-o";
export var small = "_2s-3";
export var solidLight = "_2s-e";
export var solidLightBrand = "_2s-f";
export var spinner = "_2s-u";
export var transparent = "_2s-j";
export var warning = "_2s-b";
export var wideNano = "_2s-6";