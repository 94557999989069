import { Currency } from "@redotech/money/currencies";
import { Order } from "@redotech/redo-model/order";
import { memo, useMemo } from "react";
import { Card } from "../../card";
import { CURRENCY_FORMAT } from "../../currency";
import { Flex } from "../../flex";
import { Text } from "../../text";
import * as css from "./order.module.css";

export const TotalsCard = memo(function TotalsCard({
  order,
  showHeader = false,
}: {
  order: Order;
  showHeader?: boolean;
}) {
  const orderCurrency = order.shopify.presentment_currency as Currency;

  const formatOrderCurrency = useMemo(() => {
    const formatter = CURRENCY_FORMAT(orderCurrency);
    return (amount: number | string) => formatter.format(Number(amount));
  }, [orderCurrency]);

  const subtotal = parseFloat(order.shopify.total_line_items_price) || 0;

  const redoPrice = (() => {
    if (order.redoPrice) {
      return parseFloat(order.redoPrice);
    }

    const lineItemPrice = order.shopify.line_items.find(
      (item) => item.vendor === "re:do",
    )?.price_set.presentment_money.amount;
    if (lineItemPrice) {
      return parseFloat(lineItemPrice);
    }

    return 0;
  })();
  const taxesIncluded = order.shopify.taxes_included;

  return (
    <Card>
      <Flex dir="column" fontSize="sm" gap="xl">
        {showHeader && (
          <Text fontSize="lg" fontWeight="semibold">
            Summary
          </Text>
        )}
        {[
          ["Subtotal", formatOrderCurrency(subtotal - redoPrice)],
          ...(order.shopify?.current_total_discounts_set?.presentment_money
            ?.amount > 0
            ? [
                [
                  "Discounts",
                  formatOrderCurrency(
                    "-" +
                      order.shopify.current_total_discounts_set
                        .presentment_money.amount,
                  ),
                ],
              ]
            : []),
          ...(taxesIncluded
            ? []
            : [
                [
                  "Taxes",
                  formatOrderCurrency(
                    order.shopify.current_total_tax_set.presentment_money
                      .amount,
                  ),
                ],
              ]),
          [
            "Shipping",
            formatOrderCurrency(
              order.shopify.total_shipping_price_set?.presentment_money?.amount
                ? order.shopify.total_shipping_price_set.presentment_money
                    .amount
                : "Free",
            ),
          ],
          ["Redo coverage", formatOrderCurrency(redoPrice)],
        ].map(([name, value], i) => (
          <Flex align="center" justify="space-between" key={i}>
            <span>{name}</span>
            <span>{value}</span>
          </Flex>
        ))}
        <hr className={css.divider} />
        <Flex align="center" fontWeight="semibold" justify="space-between">
          <span>Total</span>
          <span>
            {formatOrderCurrency(
              order.shopify.current_total_price_set.presentment_money.amount,
            )}
          </span>
        </Flex>
      </Flex>
    </Card>
  );
});
