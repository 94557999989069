// extracted by mini-css-extract-plugin
export var actions = "_2l-9";
export var actionsButton = "_2l-b";
export var authenticateButton = "_2l-s";
export var buttonContent = "_2l-a";
export var buttons = "_2l-t";
export var carrierHeader = "_2l-n";
export var carrierLogo = "_2l-o";
export var carrierParcelDimensions = "_2l-r";
export var carrierParcelName = "_2l-q";
export var carrierParcelsForm = "_2l-p";
export var checkboxContainer = "_2l-m";
export var checkboxSubtext = "_2l-k";
export var checkboxText = "_2l-j";
export var container = "_2l-0";
export var dimensions = "_2l-8";
export var editDimensions = "_2l-f";
export var editUnit = "_2l-h";
export var editWeight = "_2l-g";
export var image = "_2l-5";
export var mainCard = "_2l-1";
export var mainInfo = "_2l-4";
export var modal = "_2l-c";
export var modalActions = "_2l-i";
export var modalButton = "_2l-e";
export var modalForm = "_2l-d";
export var name = "_2l-7";
export var parcel = "_2l-3";
export var parcels = "_2l-2";
export var pillContainer = "_2l-l";
export var text = "_2l-6";