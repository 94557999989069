import { Jsonified } from "@redotech/json/json";
import { parseNumericIdFromShopifyId } from "@redotech/util/shopify-id";
import { NullishPartial } from "@redotech/util/type";
import { z } from "zod";
import { Address } from "./address";
import { zExt } from "./common/zod-util";
import { FormSnapshot } from "./customer-event/customer-event-definition";
import { CustomerTagSource } from "./customer-tags/customer-tag-record";
import { PillTheme } from "./pill-theme";
import { Address as ReturnAddress } from "./return-flow";

export const REDO_CUSTOMER_TAG = "redo-customer";

export interface CustomerDocument {
  _id: string;
  name?: string;
  firstName?: string;
  lastName?: string;
  image?: CustomerProfileImage | null;

  // depprecated ID fields (remove soon)
  team: string;
  origin?: CustomerOrigin;

  // deprecated consent fields (it's per email/phone now)
  supportCommunicationConsent?: CustomerSupportCommunicationConsent | null;
  marketingSubscriptionStatuses?: CustomerMarketingSubscriptionStatuses | null;

  // new fields
  emails?: EmailInfo[];
  phoneNumbers?: PhoneNumberInfo[] | null;
  shopifyAccounts?: ShopifyCustomerInfo[] | null;
  instagramAccounts?: InstagramInfo[] | null;
  facebookAccounts?: FacebookInfo[] | null;
  postscriptSubscriptions?: PostscriptInfo[] | null;
  anonymousIds?: AnonymousIdInfo[] | null;

  tags?: string[];
  tagIds?: [CustomerTagWithId];
  addresses?: CustomerAddress[];
  currentLocation?: CustomerCurrentLocation;
  customerGroupMemberships?: CustomerGroupMembership[];
  createdAt?: Date;
  updatedAt?: Date;
}

export interface CustomerTagWithId {
  tagId: string;
  name: string;
  pillTheme: PillTheme;
  source: CustomerTagSource;
}

export type Customer = Jsonified<CustomerDocument>;

// TODO: Migrate this to match the address schema from @redotech/redo-model/address
type CustomerAddress = Omit<ReturnAddress, "email"> & {
  country_name?: string; // the DB model was out of sync with this model for a while. Keeping this for backwards compatibility.
};

export interface CustomerCurrentLocation extends Partial<Address> {
  countryCode?: string;
  stateCode?: string;
  latitude?: number;
  longitude?: number;
  source: LocationSource;
  updatedAt: Date;
}

export enum LocationSource {
  BILLING_ADDRESS = "billing_address",
  IP_ADDRESS = "ip_address",
}

export enum CustomerOrigin {
  EMAIL = "email",
  REDO_CHAT = "redoChat",
  SHOPIFY = "shopify",
  INSTAGRAM = "instagram",
  FACEBOOK = "facebook",
  INSTAGRAM_COMMENTS = "instagramComments",
  FACEBOOK_COMMENTS = "facebookComments",
  REDO = "redo",
  ATTENTIVE = "attentive",
  POSTSCRIPT = "postscript",
  WARRANTY = "warranty",
  KLAVIYO = "klaviyo",
  UNKNOWN = "unknown",
}

export function getHighestMarketingConsentStatus(
  a: MarketingConsentStatus,
  b: MarketingConsentStatus,
) {
  if (
    a === MarketingConsentStatus.CONFIRMED ||
    b === MarketingConsentStatus.CONFIRMED
  ) {
    return MarketingConsentStatus.CONFIRMED;
  }
  if (
    a === MarketingConsentStatus.SUBSCRIBED ||
    b === MarketingConsentStatus.SUBSCRIBED
  ) {
    return MarketingConsentStatus.SUBSCRIBED;
  }
  return MarketingConsentStatus.NOT_SUBSCRIBED;
}

export enum MarketingConsentStatus {
  NOT_SUBSCRIBED = "not_subscribed",
  SUBSCRIBED = "subscribed",
  CONFIRMED = "confirmed",
}

export interface CustomerProfileImage {
  url: string;
}

export interface EmailInfo {
  email: string;
  marketingSubscription?: BaseMarketingSubscription;
}

export interface CustomerSupportCommunicationConsent {
  textMessages: { optInDate?: Date | null; optOutDate?: Date | null };
}

export interface PhoneNumberInfo {
  phoneNumber: string;
  marketingSubscription?: BaseMarketingSubscription;
  supportCommunicationConsent?: CustomerSupportCommunicationConsent;
}

export interface ShopifyCustomerInfo {
  id: string;
}

export interface InstagramInfo {
  igScopedUserId: string;
  username?: string | null;
  name?: string | null;
  profilePic?: string | null;
  createdAt?: Date | null;
  updatedAt?: Date | null;
}

export interface FacebookInfo {
  pageScopedId: string;
  name?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  profilePic?: string | null;
  createdAt?: Date | null;
  updatedAt?: Date | null;
}

export interface PostscriptInfo {
  subscriberId: string;
}

export interface AnonymousIdInfo {
  id: string;
}

export interface UpdateCustomerInfoData {
  _id: string;
  firstName: string;
  lastName: string;
  phoneNumber?: string;
}

interface CustomerGroupMembership {
  customerGroupId: string;
  addedAt: Date;
}

export function marketingSubscriptionsToMarketingSubscriptionStatuses(
  subscriptions: CustomerMarketingSubscriptions,
): CustomerMarketingSubscriptionStatuses {
  return {
    email: subscriptions.email
      ? baseMarketingSubscriptionToConsentStatus(subscriptions.email)
      : MarketingConsentStatus.NOT_SUBSCRIBED,
    sms: subscriptions.sms
      ? baseMarketingSubscriptionToConsentStatus(subscriptions.sms)
      : MarketingConsentStatus.NOT_SUBSCRIBED,
  };
}

function baseMarketingSubscriptionToConsentStatus(
  subscription: BaseMarketingSubscription,
): MarketingConsentStatus {
  if (subscription.unsubscribedDate) {
    return MarketingConsentStatus.NOT_SUBSCRIBED;
  }
  if (subscription.confirmedDate) {
    return MarketingConsentStatus.CONFIRMED;
  }
  return MarketingConsentStatus.SUBSCRIBED;
}

/**
 * Represents an individual marketing subscription for a customer.
 * Most of the fields below are collected due to guidelines from the T-Mobile code of conduct:
 * https://www.t-mobile.com/support/public-files/attachments/T-Mobile%20Code%20of%20Conduct.pdf
 */
export interface BaseMarketingSubscription {
  entryPoint: string;
  formId?: string;
  /**
   * The snapshot of the form at the time of submission.
   * Only needed if a cell phone carrier requests it.
   */
  formSnapshot: FormSnapshot;
  customerIpAddress?: string;
  /**
   * Currently only applies to SMS subscriptions.
   * But just in case we need to add this to email subscriptions in the future.
   */
  confirmedDate?: Date;
  /**
   * The date the customer unsubscribed from marketing.
   * Takes precedence over confirmedDate.
   *
   * @deprecated in favor of using the customer event system as source of truth
   * Instead the marketingSubscription will be set to null.
   */
  unsubscribedDate?: Date;
}

export type EmailMarketingSubscription = BaseMarketingSubscription & {
  /**
   * Intentionally storing phone number again for redundancy,
   * or in case something goes wrong with the root-level phone number field.
   * This should never be modified.
   */
  email: string;
};

export type SmsMarketingSubscription = BaseMarketingSubscription & {
  phoneNumber: string;
};

export interface CustomerMarketingSubscriptions {
  email?: EmailMarketingSubscription;
  sms?: SmsMarketingSubscription;
}

export interface CustomerMarketingSubscriptionStatuses {
  email?: MarketingConsentStatus;
  sms?: MarketingConsentStatus;
}

// TODO: the customer model should have a string[] field for each identifier,
// uniquely indexed with a collation of strength 2 (case-insensitive)
export interface CustomerIdentifiers {
  redoCustomerId?: string;
  shopifyCustomerId?: string;
  email?: string;
  facebookUserId?: string;
  instagramUserId?: string;
  postscriptSubscriberId?: string;
  anonymousId?: string;
  phoneNumber?: string;
}

export type CustomerIdentifier = keyof CustomerIdentifiers;

export interface CustomerIdentifierInfo {
  /**
   * Which identifiers take precedence when identifying a customer.
   *
   * @remarks
   * When choosing the priority of your identifier, ask the following questions about each of the identifiers you send to Segment:
   * 1. Is it an immutable ID? Give immutable IDs, such as user_id, highest priority.
   * 2. Are they unique IDs? Give Unique IDs such as email higher priority than possibly shared identifiers like android.id or ios.id.
   * 3. Does it temporarily identify a user? Identifiers such as anonymous_id, ios.idfa, and ga_client_id are constantly updated or expired for a user. Generally speaking, rank these lower than identifiers that permanently identify a user.
   *
   * @see {@link https://segment.com/docs/unify/identity-resolution/identity-resolution-onboarding/}
   *
   * Each number should be unique.
   */
  priority: number;
  /**
   * How many times a customer can have a specific identifier.
   */
  multiplicity: Multiplicity;
  /**
   * Whether the identifier is unique to a single customer.
   */
  uniqueness: IdentifierUniqueness;
  /**
   * Where the identifiers array is stored in the customer document.
   */
  arrayPath: keyof CustomerDocument;
  /**
   * The field name of the identifier field on an object in the identifiers array.
   */
  fieldName: string | null;
  /**
   * How to access the identifier from a customer document.
   */
  accessors: DocumentIdentifierAccessors;
}

export const customerIdentifierPriorities: Record<CustomerIdentifier, number> =
  {
    redoCustomerId: 1,
    shopifyCustomerId: 2,
    email: 3,
    facebookUserId: 4,
    instagramUserId: 5,
    postscriptSubscriberId: 6,
    anonymousId: 7,
    phoneNumber: 8,
  };

export enum Multiplicity {
  ZERO_OR_ONE = "zero_or_one",
  EXACTLY_ONE = "exactly_one",
  ZERO_OR_MANY = "zero_or_many",
}

export const multiplicityMaximums: Record<Multiplicity, number> = {
  zero_or_one: 1,
  exactly_one: 1,
  zero_or_many: Infinity,
};

export enum IdentifierUniqueness {
  UNIQUE = "unique",
  POTENTIALLY_SHARED = "potentially_shared",
}

export type PotentiallySharedIdentifiers = Pick<
  CustomerIdentifiers,
  "phoneNumber"
>;

export interface DocumentIdentifierAccessors {
  get: (
    customer:
      | CustomerDocument
      | (Partial<Omit<CustomerDocument, "_id">> & { _id: string }),
  ) => { id: string; otherFields?: { [key: string]: any } }[] | undefined;
  set: (
    customer: CustomerDocument | Partial<CustomerDocument>,
    values: { id: string; otherFields?: { [key: string]: any } }[],
  ) => void;
}

/**
 * An abstraction over all possible identifiers for a customer.
 *
 * It provides various metadata about each identifier, such as:
 * - Priority - Which identifier takes precedence when identifying a customer
 * - Multiplicity - How many times a customer can have a specific identifier
 * - Uniqueness - Whether the identifier is unique to a single customer
 *
 * It also provides a mapping between how identifiers are stored on events vs customers.
 * - Events store a single identifier at root level
 * - Customers store them inside nested arrays
 */
export const customerIdentifierInfo: Record<
  CustomerIdentifier,
  CustomerIdentifierInfo
> = {
  redoCustomerId: {
    priority: 1,
    multiplicity: Multiplicity.EXACTLY_ONE,
    uniqueness: IdentifierUniqueness.UNIQUE,
    arrayPath: "_id",
    fieldName: null,
    accessors: {
      get: (customer) => [{ id: customer._id.toString() }],
      set: (customer, values) => {
        customer._id = values[0].id;
      },
    },
  },
  shopifyCustomerId: {
    priority: 2,
    multiplicity: Multiplicity.ZERO_OR_ONE, // So we can issue store credit to the right account, etc.
    uniqueness: IdentifierUniqueness.UNIQUE,
    arrayPath: "shopifyAccounts",
    fieldName: "id",
    accessors: {
      get: (customer) =>
        customer.shopifyAccounts?.map((account) => ({ id: account.id })),
      set: (customer, values) => {
        if (!customer.shopifyAccounts) {
          customer.shopifyAccounts = [];
        }
        customer.shopifyAccounts = values.map((value) => ({
          ...value.otherFields,
          id: value.id,
        }));
      },
    },
  },
  email: {
    priority: 3,
    multiplicity: Multiplicity.ZERO_OR_ONE,
    uniqueness: IdentifierUniqueness.UNIQUE,
    arrayPath: "emails",
    fieldName: "email",
    accessors: {
      get: (customer) =>
        customer.emails?.map((email) => ({
          id: email.email,
          otherFields: { ...email },
        })),
      set: (customer, values) => {
        if (!customer.emails) {
          customer.emails = [];
        }
        customer.emails = values.map((value) => ({
          ...value.otherFields,
          email: value.id,
        }));
      },
    },
  },
  facebookUserId: {
    priority: 4,
    multiplicity: Multiplicity.ZERO_OR_MANY,
    uniqueness: IdentifierUniqueness.UNIQUE,
    arrayPath: "facebookAccounts",
    fieldName: "pageScopedId",
    accessors: {
      get: (customer) =>
        customer.facebookAccounts?.map((account) => ({
          id: account.pageScopedId,
          otherFields: {
            name: account.name,
            firstName: account.firstName,
            lastName: account.lastName,
            profilePic: account.profilePic,
          },
        })),
      set: (customer, values) => {
        if (!customer.facebookAccounts) {
          customer.facebookAccounts = [];
        }
        customer.facebookAccounts = values.map((value) => ({
          ...value.otherFields,
          pageScopedId: value.id,
        }));
      },
    },
  },
  instagramUserId: {
    priority: 5,
    multiplicity: Multiplicity.ZERO_OR_MANY,
    uniqueness: IdentifierUniqueness.UNIQUE,
    arrayPath: "instagramAccounts",
    fieldName: "igScopedUserId",
    accessors: {
      get: (customer) =>
        customer.instagramAccounts?.map((account) => ({
          id: account.igScopedUserId,
          otherFields: {
            username: account.username,
            name: account.name,
            profilePic: account.profilePic,
          },
        })),
      set: (customer, values) => {
        if (!customer.instagramAccounts) {
          customer.instagramAccounts = [];
        }
        customer.instagramAccounts = values.map((value) => ({
          ...value.otherFields,
          igScopedUserId: value.id,
        }));
      },
    },
  },
  postscriptSubscriberId: {
    priority: 6,
    multiplicity: Multiplicity.ZERO_OR_MANY,
    uniqueness: IdentifierUniqueness.UNIQUE,
    arrayPath: "postscriptSubscriptions",
    fieldName: "subscriberId",
    accessors: {
      get: (customer) =>
        customer.postscriptSubscriptions?.map((subscription) => ({
          id: subscription.subscriberId,
        })),
      set: (customer, values) => {
        if (!customer.postscriptSubscriptions) {
          customer.postscriptSubscriptions = [];
        }
        customer.postscriptSubscriptions = values.map((value) => ({
          ...value.otherFields,
          subscriberId: value.id,
        }));
      },
    },
  },
  anonymousId: {
    priority: 7,
    multiplicity: Multiplicity.ZERO_OR_MANY,
    uniqueness: IdentifierUniqueness.UNIQUE,
    arrayPath: "anonymousIds",
    fieldName: "id",
    accessors: {
      get: (customer) =>
        customer.anonymousIds?.map((anonymousId) => ({ id: anonymousId.id })),
      set: (customer, values) => {
        if (!customer.anonymousIds) {
          customer.anonymousIds = [];
        }
        customer.anonymousIds = values.map((value) => ({ id: value.id }));
      },
    },
  },
  phoneNumber: {
    priority: 8,
    multiplicity: Multiplicity.ZERO_OR_MANY,
    uniqueness: IdentifierUniqueness.POTENTIALLY_SHARED,
    arrayPath: "phoneNumbers",
    fieldName: "phoneNumber",
    accessors: {
      get: (customer) =>
        customer.phoneNumbers?.map((phoneNumber) => ({
          id: phoneNumber.phoneNumber,
          otherFields: { ...phoneNumber },
        })),
      set: (customer, values) => {
        if (!customer.phoneNumbers) {
          customer.phoneNumbers = [];
        }
        customer.phoneNumbers = values.map((value) => ({
          ...value.otherFields,
          phoneNumber: value.id,
        }));
      },
    },
  },
};

interface CustomerLike {
  name: string;
  firstName: string;
  lastName: string;
  instagramAccounts: NullishPartial<{
    username: string;
    name: string;
    profilePic: string;
    igScopedUserId: string;
  }>[];
  facebookAccounts: NullishPartial<{
    name: string;
    profilePic: string;
    firstName: string;
    lastName: string;
    pageScopedId: string;
  }>[];
  phoneNumbers: { phoneNumber: string }[];
  emails: { email: string }[];
  shopifyAccounts: { id: string }[];
  addresses: any[];
  postscriptSubscriptions: { subscriberId: string }[];
}

export function getCustomerDisplayName(
  customer: NullishPartial<CustomerLike> | null | undefined,
): string {
  if (!customer) {
    return "Unknown Customer";
  }
  if (customer.name) {
    return customer.name;
  }
  if (customer.firstName && customer.lastName) {
    return `${customer.firstName} ${customer.lastName}`;
  }
  if (customer.firstName) {
    return customer.firstName;
  }
  const instagramAccount = customer.instagramAccounts?.[0];
  if (instagramAccount) {
    if (instagramAccount.username) {
      return `@${instagramAccount.username}`;
    } else if (instagramAccount.name) {
      return instagramAccount.name;
    }
  }
  const facebookAccount = customer.facebookAccounts?.[0];
  if (facebookAccount) {
    if (facebookAccount.name) {
      return facebookAccount.name;
    }
  }
  const phoneNumber = customer.phoneNumbers?.[0];
  if (phoneNumber) {
    return phoneNumber.phoneNumber;
  }
  const email = customer.emails?.[0];
  if (email) {
    return email.email;
  }
  return "Unknown Customer";
}

export function getPrimaryCustomerPostscriptSubscriberId(
  customer: NullishPartial<CustomerLike> | undefined | null,
): string | undefined {
  return customer?.postscriptSubscriptions?.[0]?.subscriberId;
}

export function getPrimaryCustomerEmail(
  customer: NullishPartial<CustomerLike> | undefined | null,
): string | undefined {
  return customer?.emails?.[0]?.email;
}

export function getPrimaryCustomerPhoneNumber(
  customer: NullishPartial<CustomerLike> | undefined | null,
): string | undefined {
  return customer?.phoneNumbers?.[0]?.phoneNumber;
}

export function getPrimaryCustomerAddress(
  customer: NullishPartial<CustomerLike> | undefined | null,
): NullishPartial<CustomerAddress> | undefined {
  return customer?.addresses?.[0] || undefined;
}

export function getPrimaryCustomerFacebookName(
  customer: NullishPartial<CustomerLike> | undefined | null,
): string | undefined {
  return customer?.facebookAccounts?.[0]?.name || undefined;
}

export function getPrimaryCustomerInstagram(
  customer: NullishPartial<CustomerLike> | undefined | null,
): CustomerLike["instagramAccounts"][number] | undefined {
  return customer?.instagramAccounts?.[0] || undefined;
}

export function getPrimaryCustomerFacebook(
  customer: NullishPartial<CustomerLike> | undefined | null,
): CustomerLike["facebookAccounts"][number] | undefined {
  return customer?.facebookAccounts?.[0] || undefined;
}

export function getPrimaryCustomerInstagramUsername(
  customer: NullishPartial<CustomerLike> | undefined | null,
): string | undefined {
  return customer?.instagramAccounts?.[0]?.username || undefined;
}

export function getPrimaryCustomerShopifyCustomerId(
  customer: NullishPartial<CustomerLike> | undefined | null,
): `${number}` | undefined {
  const idOpt = customer?.shopifyAccounts?.[0]?.id;
  if (idOpt) {
    return parseNumericIdFromShopifyId(idOpt);
  }
  return undefined;
}

export interface SimplifiedCustomer {
  email: string | undefined | null;
  first_name: string | undefined | null;
  last_name: string | undefined | null;
  default_address?: any;
}

// ===== Zod schema =====

const imageSchema = z.object({ url: z.string().optional() });

const instagramSchema = z.object({
  igScopedUserId: z.string(),
  username: z.string().optional(),
  name: z.string().optional(),
  profilePic: z.string().optional(),
});

const facebookSchema = z.object({
  pageScopedId: z.string(),
  name: z.string().optional(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  profilePic: z.string().optional(),
});

const postscriptSchema = z.object({ subscriberId: z.string() });

const marketingSubscriptionSchema = z.object({
  confirmedDate: z.date().optional(),
  unsubscribedDate: z.date().optional(),
});

const emailInfoSchema = z.object({
  email: z.string(),
  marketingSubscription: marketingSubscriptionSchema.optional(),
});

const supportCommunicationConsentSchema = z.object({
  textMessages: z
    .object({
      optInDate: z.date().optional(),
      optOutDate: z.date().optional().nullable(),
    })
    .optional(),
});

const phoneNumberInfoSchema = z.object({
  phoneNumber: z.string(),
  supportCommunicationConsent: supportCommunicationConsentSchema.optional(),
  marketingSubscription: marketingSubscriptionSchema.optional(),
});

const shopifyAccountSchema = z.object({ id: z.string() });

// TODO: Migrate this to match the address schema from @redotech/redo-model/address
const addressSchema = z.object({
  name: z.string().optional(),
  street1: z.string().optional(),
  street2: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zip: z.string().optional(),
  country: z.string().optional(),
  country_name: z.string().optional(),
  phone: z.string().optional(),
});

export const CustomerSchema = z.object({
  _id: zExt.objectId(),
  team: zExt.objectId(),
  firstName: z.string().optional(),
  lastName: z.string().optional(),
  name: z.string().optional(),
  image: imageSchema.optional(),
  emails: z.array(emailInfoSchema).optional(),
  phoneNumbers: z.array(phoneNumberInfoSchema).optional(),
  shopifyAccounts: z.array(shopifyAccountSchema).optional(),
  instagramAccounts: z.array(instagramSchema).optional(),
  facebookAccounts: z.array(facebookSchema).optional(),
  postscriptSubscriptions: z.array(postscriptSchema).optional(),
  anonymousIds: z.array(z.object({ id: z.string() })).optional(),
  tags: z.array(z.string()).optional(),
  origin: z.nativeEnum(CustomerOrigin).optional(),
  addresses: z.array(addressSchema).optional(),
  customerGroupMemberships: z
    .array(z.object({ customerGroupId: zExt.objectId(), addedAt: z.date() }))
    .optional(),
  marketingSubscriptionStatuses: z
    .object({
      email: z.nativeEnum(MarketingConsentStatus).optional(),
      sms: z.nativeEnum(MarketingConsentStatus).optional(),
    })
    .optional(),
  supportCommunicationConsent: supportCommunicationConsentSchema.optional(),
  createdAt: z.date(),
  updatedAt: z.date(),
});
