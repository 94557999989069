import { CarrierAndServiceSchema } from "@redotech/redo-model/fulfillments/fulfillment-carriers-and-services";
import { z } from "zod";

export const BuyRequestSchema = z.object({
  fulfillmentGroupId: z.string(),
  lineItems: z.array(z.object({ id: z.string(), quantity: z.number() })),
  shipmentRate: CarrierAndServiceSchema,
});
export type BuyRequest = z.infer<typeof BuyRequestSchema>;

export const BuyResponseSchema = z.object({
  success: z.boolean(),
  errorMessage: z.string().nullish(),
});
export type BuyResponse = z.infer<typeof BuyResponseSchema>;

export const buyLabels = {
  input: z.object({
    buyRequests: z.array(BuyRequestSchema),
    userId: z.string(),
    print: z.boolean(),
    templateId: z.string().nullish(),
    insured: z.boolean().nullish(),
    fulfillmentDate: z.date().nullish(),
    notifyMarketplace: z.boolean().nullish(),
  }),
  output: z.object({ batchId: z.string(), batchNumber: z.string() }),
};
