import { z } from "zod";
import { RedoShopifyCountryCode } from "./fulfillment-country-code";

export const FulfillmentOrderAddressSchema = z.object({
  address1: z.string().nullish(),
  address2: z.string().nullish(),
  company: z.string().nullish(),
  city: z.string().nullish(),
  countryCode: z.nativeEnum(RedoShopifyCountryCode).nullish(),
  locationId: z.string().nullish(),
  name: z.string(),
  phone: z.string().nullish(),
  email: z.string().nullish(),
  province: z.string().nullish(),
  zip: z.string().nullish(),
  residential: z.boolean().nullish(),
  verified: z.boolean().nullish(),
  messages: z.array(z.string()).nullish(),
});

export type FulfillmentOrderAddress = z.infer<
  typeof FulfillmentOrderAddressSchema
>;

//https://lorefnon.me/2023/11/28/fixing-inferred-types-exceeding-serializable-length/
export interface IFulfillmentOrderAddress
  extends z.ZodType<FulfillmentOrderAddress> {}

export const VerifiedAddressSchema = FulfillmentOrderAddressSchema.extend({
  countryCode: z.string().nullish(),
});

export type VerifiedAddress = z.infer<typeof VerifiedAddressSchema>;

export interface IVerifiedAddress extends z.ZodType<VerifiedAddress> {}

// When comparing addresses, only these keys matter.
const equalityKeys: (keyof FulfillmentOrderAddress)[] = [
  "name",
  "email",
  "phone",
  "company",
  "address1",
  "address2",
  "city",
  "province",
  "zip",
  "countryCode",
  "residential",
];
export const addressesEqual = (
  a: FulfillmentOrderAddress,
  b: FulfillmentOrderAddress,
) => equalityKeys.every((k) => a[k] ?? null === b[k] ?? null);
