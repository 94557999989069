// extracted by mini-css-extract-plugin
export var box = "_2r-0";
export var check = "_2r-1";
export var checkboxButton = "_2r-6";
export var checkboxGroup = "_2r-5";
export var disabled = "_2r-7";
export var ghost = "_2r-9";
export var input = "_2r-2";
export var label = "_2r-4";
export var normal = "_2r-8";
export var uncheck = "_2r-3";