import { z } from "zod";
import { CurrencySchema, WeightSchema } from "../outbound-labels/util";
import { RedoShopifyCountryCode } from "./fulfillment-country-code";
import { RedoShopifyDeliveryMethodType } from "./fulfillment-delivery-method-type";
import { RedoShopifyFulfillmentDisplayStatus } from "./fulfillment-display-status";
import { RedoShopifyFulfillmentHoldReason } from "./fulfillment-hold-reason";
import { RedoShopifyFulfillmentOrderAction } from "./fulfillment-order-action";
import { RedoShopifyFulfillmentOrderRequestStatus } from "./fulfillment-order-request-status";
import { RedoShopifyFulfillmentOrderStatus } from "./fulfillment-order-status";
import { RedoShopifyFulfillmentStatus } from "./fulfillment-status";
import { RedoShopifyOrderDisplayFinancialStatus } from "./order-display-financial-status";

const FulfillmentOrderAssignedLocationSchema = z.object({
  address1: z.string().nullish(),
  address2: z.string().nullish(),
  city: z.string().nullish(),
  countryCode: z.nativeEnum(RedoShopifyCountryCode),
  location: z.object({ id: z.string() }).nullish(),
  name: z.string(),
  phone: z.string().nullish(),
  province: z.string().nullish(),
  zip: z.string().nullish(),
});

const FulfillmentOrderDestinationSchema = z.object({
  address1: z.string().nullish(),
  address2: z.string().nullish(),
  city: z.string().nullish(),
  company: z.string().nullish(),
  countryCode: z.nativeEnum(RedoShopifyCountryCode).nullish(),
  email: z.string().nullish(),
  firstName: z.string().nullish(),
  lastName: z.string().nullish(),
  location: z.object({ id: z.string() }).nullish(),
  phone: z.string().nullish(),
  province: z.string().nullish(),
  zip: z.string().nullish(),
});

const FulfillmentDeliveryMethodSchema = z.object({
  maxDeliveryDateTime: z.coerce.date().nullish(),
  methodType: z.nativeEnum(RedoShopifyDeliveryMethodType),
  minDeliveryDateTime: z.coerce.date().nullish(),
  presentedName: z.string().nullish(),
  serviceCode: z.string().nullish(),
  additionalInformation: z.object({
    instructions: z.string().nullish(),
    phone: z.string().nullish(),
  }),
});

const FulfillmentHoldsSchema = z.object({
  displayReason: z.string(),
  id: z.string(),
  reason: z.nativeEnum(RedoShopifyFulfillmentHoldReason),
  reasonNotes: z.string().nullish(),
});

const FulfillmentOrderSupportedActionSchema = z.object({
  action: z.nativeEnum(RedoShopifyFulfillmentOrderAction),
  externalUrl: z.string().nullish(),
});

const MoneyBagSchema = z.object({
  shopMoney: z.object({ amount: z.string(), currencyCode: CurrencySchema }),
});

export const RiskSchema = z.object({
  assessments: z.array(
    z.object({
      facts: z.array(
        z.object({
          description: z.string().nullish(),
          sentiment: z.string().nullish(),
        }),
      ),
      riskLevel: z.string().nullish(),
    }),
  ),
});

export type OrderRisk = z.infer<typeof RiskSchema>;

const RawFulfillmentOrderLineItemSchema = z.object({
  financialSummaries: z.array(
    z.object({
      approximateDiscountedUnitPriceSet: MoneyBagSchema,
      originalUnitPriceSet: MoneyBagSchema,
      quantity: z.number(),
    }),
  ),
  id: z.string(),
  productTitle: z.string(),
  variantTitle: z.string().nullish(),
  sku: z.string().nullish(),
  image: z.object({ url: z.string() }).nullish(),
  inventoryItemId: z.string().nullish(),
  lineItem: z.object({
    customAttributes: z.array(
      z.object({ key: z.string(), value: z.string().nullish() }),
    ),
    id: z.string(),
    originalUnitPriceSet: MoneyBagSchema,
    quantity: z.number(),
    taxLines: z.array(z.object({ priceSet: MoneyBagSchema })),
    duties: z
      .array(z.object({ harmonizedSystemCode: z.string().nullish() }))
      .nullish(),
  }),
  remainingQuantity: z.number(),
  requiresShipping: z.boolean(),
  totalQuantity: z.number(),
  variant: z
    .object({
      displayName: z.string(),
      id: z.string(),
      bin: z.object({ value: z.string() }).nullish(),
      product: z.object({
        id: z.string(),
        title: z.string(),
        vendor: z.string(),
        bin: z.object({ value: z.string() }).nullish(),
      }),
      inventoryItem: z.object({ harmonizedSystemCode: z.string().nullish() }),
      sku: z.string().nullish(),
      barcode: z.string().nullish(),
      title: z.string(),
    })
    .nullish(),
  warnings: z.array(
    z.object({
      description: z.string().nullish(),
      title: z.string().nullish(),
    }),
  ),
  weight: WeightSchema.nullish(),
});

export type RawFulfillmentOrderLineItem = z.infer<
  typeof RawFulfillmentOrderLineItemSchema
>;

// Should match the requested fields from the fulfillment-fragments.ts
export const RawFulfillmentOrderSchema = z.object({
  id: z.string(),
  assignedLocation: FulfillmentOrderAssignedLocationSchema,
  channelId: z.string().nullish(),
  deliveryMethod: FulfillmentDeliveryMethodSchema.nullish(),
  destination: FulfillmentOrderDestinationSchema.nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  fulfillAt: z.coerce.date().nullish(),
  fulfillBy: z.coerce.date().nullish(),
  fulfillmentHolds: z.array(FulfillmentHoldsSchema),
  fulfillmentLineItems: z.array(RawFulfillmentOrderLineItemSchema),
  orderId: z.string(),
  orderName: z.string(),
  requestStatus: z.nativeEnum(RedoShopifyFulfillmentOrderRequestStatus),
  status: z.nativeEnum(RedoShopifyFulfillmentOrderStatus),
  supportedActions: z.array(FulfillmentOrderSupportedActionSchema),
  order: z.object({
    closed: z.boolean().nullish(),
    closedAt: z.coerce.date().nullish(),
    fullyPaid: z.boolean().nullish(),
    displayFinancialStatus: z
      .nativeEnum(RedoShopifyOrderDisplayFinancialStatus)
      .nullish(),
    note: z.string().nullish(),
    customAttributes: z
      .array(z.object({ key: z.string(), value: z.string().nullish() }))
      .nullish(),
    tags: z.array(z.string()).nullish(),
    risk: RiskSchema.nullish(),
    createdAt: z.coerce.date(),
    updatedAt: z.coerce.date(),
    shippingLine: z.object({ discountedPriceSet: MoneyBagSchema }).nullish(),
    shippingAddress: z
      .object({
        name: z.string().nullish(),
        company: z.string().nullish(),
        address1: z.string().nullish(),
        address2: z.string().nullish(),
        city: z.string().nullish(),
        province: z.string().nullish(),
        zip: z.string().nullish(),
        countryCodeV2: z.nativeEnum(RedoShopifyCountryCode).nullish(),
        phone: z.string().nullish(),
      })
      .nullish(),
    customer: z
      .object({
        id: z.string(),
        email: z.string().nullish(),
        phone: z.string().nullish(),
        numberOfOrders: z.coerce.number(),
        displayName: z.string().nullish(),
        note: z.string().nullish(),
        tags: z.array(z.string()).nullish(),
      })
      .nullish(),
  }),
});

export type RawFulfillmentOrder = z.infer<typeof RawFulfillmentOrderSchema>;

const RawFulfillmentLineItemSchema = z.object({
  id: z.string(),
  quantity: z.number().nullish(),
  lineItem: z.object({
    image: z.object({ url: z.string() }).nullish(),
    customAttributes: z.array(
      z.object({ key: z.string(), value: z.string().nullish() }),
    ),
    id: z.string(),
    discountedUnitPriceSet: MoneyBagSchema,
    originalUnitPriceSet: MoneyBagSchema,
    quantity: z.number(),
    currentQuantity: z.number(),
    variantTitle: z.string().nullish(),
    requiresShipping: z.boolean(),
    variant: z
      .object({
        displayName: z.string(),
        id: z.string(),
        bin: z.object({ value: z.string() }).nullish(),
        barcode: z.string().nullish(),
        product: z.object({
          id: z.string(),
          title: z.string(),
          vendor: z.string(),
          bin: z.object({ value: z.string() }).nullish(),
        }),
        title: z.string(),
      })
      .nullish(),
    sku: z.string().nullish(),
    taxLines: z.array(z.object({ priceSet: MoneyBagSchema })),
  }),
});
export type RawFulfillmentLineItem = z.infer<
  typeof RawFulfillmentLineItemSchema
>;

const FulfillmentOriginAddressSchema = z.object({
  address1: z.string().nullish(),
  address2: z.string().nullish(),
  city: z.string().nullish(),
  countryCode: z.nativeEnum(RedoShopifyCountryCode),
  provinceCode: z.string().nullish(),
  zip: z.string().nullish(),
});

// Should match the requested fields from the fulfillment-fragments.ts
export const RawFulfillmentSchema = z.object({
  id: z.string(),
  name: z.string(),
  originAddress: FulfillmentOriginAddressSchema.nullish(),
  location: z.object({ id: z.string() }).nullish(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  estimatedDeliveryAt: z.coerce.date().nullish(),
  inTransitAt: z.coerce.date().nullish(),
  deliveredAt: z.coerce.date().nullish(),
  status: z.nativeEnum(RedoShopifyFulfillmentStatus),
  displayStatus: z.nativeEnum(RedoShopifyFulfillmentDisplayStatus).nullish(),
  requiresShipping: z.boolean(),
  totalQuantity: z.number(),
  fulfillmentLineItems: z.array(RawFulfillmentLineItemSchema),
  trackingInfo: z.array(
    z.object({
      company: z.string().nullish(),
      url: z.string().nullish(),
      number: z.string().nullish(),
    }),
  ),
  order: z.object({
    id: z.string(),
    name: z.string(),
    note: z.string().nullish(),
    tags: z.array(z.string()).nullish(),
    createdAt: z.coerce.date(),
    shippingAddress: z
      .object({
        address1: z.string().nullish(),
        address2: z.string().nullish(),
        city: z.string().nullish(),
        company: z.string().nullish(),
        countryCodeV2: z.nativeEnum(RedoShopifyCountryCode),
        email: z.string().nullish(),
        firstName: z.string().nullish(),
        lastName: z.string(),
        phone: z.string().nullish(),
        province: z.string().nullish(),
        zip: z.string().nullish(),
      })
      .nullish(),
    customer: z
      .object({
        id: z.string(),
        email: z.string().nullish(),
        phone: z.string().nullish(),
        numberOfOrders: z.coerce.number(),
        displayName: z.string().nullish(),
        note: z.string().nullish(),
        tags: z.array(z.string()).nullish(),
      })
      .nullish(),
  }),
});
export type RawFulfillment = z.infer<typeof RawFulfillmentSchema>;

export const RawFulfillmentOrderSyncDataSchema = z.object({
  id: z.string(),
  updatedAt: z.coerce.date(),
  order: z.object({ updatedAt: z.coerce.date() }),
});

export type RawFulfillmentOrderSyncData = z.infer<
  typeof RawFulfillmentOrderSyncDataSchema
>;
