import {
  genericForwardRefMemo,
  genericMemo,
} from "@redotech/react-util/component";
import * as classNames from "classnames";
import {
  ForwardedRef,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { Flex, FlexProps } from "../../flex";
import { Text, TextProps } from "../../text";
import { RedoBadge, RedoBadgeColor, RedoBadgeSize } from "../badge/redo-badge";
import { RedoCommandMenu } from "../command-menu/redo-command-menu";
import * as horizontalTabsCss from "./redo-horizontal-tabs.module.css";
export enum RedoHorizontalTabSize {
  SMALL = "small",
  X_SMALL = "xsmall",
}

export enum RedoHorizontalTabMode {
  REGULAR = "regular",
  FULL_WIDTH = "full-width",
}

type OverflowableBucketItem<T> = { item: T; mustNotBeBucketed?: boolean };

/**
 *
 * @param availableSpaceRef - The space that the items can be shown in.
 * @param visibleItemsRef - The items that can be shown.
 * @param moreItemsButtonRef - The button that opens the overflow menu.
 */
const useOverflowableBucket = function useOverflowableBucket<T>(
  allItems: OverflowableBucketItem<T>[],
  availableSpaceRef: HTMLDivElement | null,
  visibleItemsRef: HTMLDivElement | null,
  moreItemsButtonRef: HTMLDivElement | null,
  itemsHash: (items: OverflowableBucketItem<T>[]) => string,
) {
  const [availableSpaceWidth, setAvailableSpaceWidth] = useState<number>(0);
  const [itemsInNormalSpot, setItemsInNormalSpot] = useState<T[]>(
    allItems.map((item) => item.item),
  );
  const [itemsInBucket, setItemsInBucket] = useState<T[]>([]);

  const [shouldUpdateItems, setShouldUpdateItems] = useState<boolean>(false);
  const [prevItemHash, setPrevItemHash] = useState<string | number | null>(
    itemsHash(allItems),
  );

  const INITIAL_HORIZONTAL_PADDING = 40;
  const PADDING_BETWEEN_ITEMS = 10;

  const DEFAULT_BUTTON_WIDTH = 10;

  const updateAvailableSpaceWidth = useCallback(() => {
    if (!availableSpaceRef) {
      return;
    }
    if (
      availableSpaceRef.offsetWidth !== availableSpaceWidth ||
      itemsHash(allItems) !== prevItemHash
    ) {
      setAvailableSpaceWidth(availableSpaceRef.offsetWidth);
      setItemsInNormalSpot(allItems.map((item) => item.item));
      setPrevItemHash(itemsHash(allItems));
      setShouldUpdateItems(true);
    }
  }, [
    availableSpaceRef,
    availableSpaceWidth,
    allItems,
    itemsHash,
    prevItemHash,
  ]);

  useLayoutEffect(() => {
    if (!shouldUpdateItems) {
      return;
    }

    if (!availableSpaceRef || !visibleItemsRef) {
      return;
    }
    setShouldUpdateItems(false);

    /** Because of the effects, we're at this point guaranteed to have _all_ the items rendered, so we can calculate their widths. */

    /** Start by filling up normal spot with mandatory items */
    let usedWidth = INITIAL_HORIZONTAL_PADDING;

    type AugmentedItem = OverflowableBucketItem<T> & {
      width: number;
      index: number;
    };

    const allItemsAugmentedWithWidthAndIndex: AugmentedItem[] = allItems.map(
      (item, index) => ({
        ...item,
        width:
          (visibleItemsRef.children[index]?.clientWidth ??
            DEFAULT_BUTTON_WIDTH) + (index > 0 ? PADDING_BETWEEN_ITEMS : 0),
        index,
      }),
    );

    const bucketWidth = moreItemsButtonRef?.clientWidth ?? DEFAULT_BUTTON_WIDTH;

    const mandatoryItems = allItemsAugmentedWithWidthAndIndex.filter(
      (item) => item.mustNotBeBucketed,
    );
    const nonMandatoryItems = allItemsAugmentedWithWidthAndIndex.filter(
      (item) => !item.mustNotBeBucketed,
    );

    const widthRequiredForEverything = [
      ...mandatoryItems,
      ...nonMandatoryItems,
    ].reduce((acc, item) => acc + item.width, INITIAL_HORIZONTAL_PADDING);

    const widthRequiredForMandatoryItems = mandatoryItems.reduce(
      (acc, item) => acc + item.width,
      0,
    );
    usedWidth += widthRequiredForMandatoryItems;

    const shouldBucket = widthRequiredForEverything > availableSpaceWidth;

    if (!shouldBucket) {
      setItemsInNormalSpot(allItems.map((item) => item.item));
      setItemsInBucket([]);
      return;
    }

    usedWidth += bucketWidth;

    const bucketedItems = nonMandatoryItems
      .sort((a, b) => a.index - b.index)
      .reduce((acc, item) => {
        if (usedWidth + item.width > availableSpaceWidth) {
          acc.push(item);
          return acc;
        }
        usedWidth += item.width;
        return acc;
      }, [] as AugmentedItem[]);

    const normalSpotItems = allItemsAugmentedWithWidthAndIndex.filter(
      (item) => !bucketedItems.includes(item),
    );

    setItemsInNormalSpot(
      normalSpotItems
        .sort((a, b) => a.index - b.index)
        .map((item) => item.item),
    );
    setItemsInBucket(
      bucketedItems.sort((a, b) => a.index - b.index).map((item) => item.item),
    );
  }, [
    shouldUpdateItems,
    availableSpaceRef,
    visibleItemsRef,
    moreItemsButtonRef,
    setItemsInNormalSpot,
    setItemsInBucket,
    allItems,
    availableSpaceWidth,
  ]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useLayoutEffect(() => {
    if (!availableSpaceRef || !visibleItemsRef) {
      return;
    }
    const observer = new ResizeObserver(updateAvailableSpaceWidth);
    observer.observe(availableSpaceRef);
    return () => observer.disconnect();
  }, [updateAvailableSpaceWidth, availableSpaceRef, visibleItemsRef]);

  useEffect(() => {
    updateAvailableSpaceWidth();
  }, []);

  return { itemsInNormalSpot, itemsInBucket };
};

export type RedoHorizontalTab<T> = { label: string; key: T; count?: number };

function horizontalTabsHash<T extends string>(
  items: OverflowableBucketItem<RedoHorizontalTab<T>>[],
) {
  return items
    .map(
      (item, index) =>
        `${index}${item.item.key}${item.item.label}${item.item.count ?? 0}${item.mustNotBeBucketed ? "s" : null}`,
    )
    .join(",");
}

interface SingleTabProps<T extends string> {
  tab: RedoHorizontalTab<T>;
  isSelected: boolean;
  setSelectedTab?: (tabKey: T) => void;
  mode: RedoHorizontalTabMode;
  size: RedoHorizontalTabSize;
  ref?: ForwardedRef<HTMLDivElement>;
}

const SingleTab = genericForwardRefMemo(function SingleTab<T extends string>(
  { tab, isSelected, setSelectedTab, mode, size }: SingleTabProps<T>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <Flex
      align="center"
      as="button"
      basis={tabModeToTabBasis[mode]}
      className={classNames(
        horizontalTabsCss.tab,
        horizontalTabsCss[tabModeToStyleName[mode]],
        isSelected && horizontalTabsCss.selected,
      )}
      dir="row"
      justify="center"
      onClick={() => setSelectedTab?.(tab.key)}
      pb="lg"
      px="xs"
      ref={ref}
      shrink={1}
    >
      <Text
        fontSize={tabsSizeToTextSize[size]}
        fontWeight="semibold"
        textColor={tabSelectedToTextColor(isSelected)}
      >
        {tab.label}
      </Text>
      {tab.count ? (
        <RedoBadge
          color={RedoBadgeColor.GRAY}
          size={RedoBadgeSize.SMALL}
          text={tab.count.toString()}
        />
      ) : null}
    </Flex>
  );
});

/**
 * Arbiter horizontal tabs https://www.figma.com/design/iZHj2I36zd9i8nRbWKw4ZK/%E2%9D%96-Arbiter?node-id=1547-265252&t=6E6sxbCjrl7Bw2G1-0
 */
export const RedoHorizontalTabs = genericMemo(function RedoHorizontalTabs<
  T extends string = string,
>({
  size = RedoHorizontalTabSize.SMALL,
  mode = RedoHorizontalTabMode.REGULAR,
  tabs = [],
  selectedTab,
  setSelectedTab,
  pl = "6xl",
  drawBottomLine = true,
  preventMoreTab,
}: {
  size?: RedoHorizontalTabSize;
  mode?: RedoHorizontalTabMode;
  tabs?: RedoHorizontalTab<T>[];
  selectedTab?: T;
  setSelectedTab?: (tabKey: T) => void;
  pl?: FlexProps["pl"];
  drawBottomLine?: boolean;
  preventMoreTab?: boolean;
}) {
  const [bucketMenuOpen, setBucketMenuOpen] = useState(false);

  const [availableSpaceRef, setAvailableSpaceRef] =
    useState<HTMLDivElement | null>(null);
  const [visibleItemsRef, setVisibleItemsRef] = useState<HTMLDivElement | null>(
    null,
  );
  const [moreItemsButtonRef, setMoreItemsButtonRef] =
    useState<HTMLDivElement | null>(null);

  const { itemsInNormalSpot, itemsInBucket } = useOverflowableBucket(
    tabs.map((tab) => ({
      item: tab,
      mustNotBeBucketed: tab.key === selectedTab || preventMoreTab,
    })),
    availableSpaceRef,
    visibleItemsRef,
    moreItemsButtonRef,
    horizontalTabsHash,
  );

  if (tabs.length === 0) {
    return null;
  }

  const moreItemsButton =
    itemsInBucket.length > 0 ? (
      <>
        <SingleTab<T>
          isSelected={false}
          mode={mode}
          ref={setMoreItemsButtonRef}
          setSelectedTab={() => {
            setBucketMenuOpen(true);
          }}
          size={size}
          tab={{ count: itemsInBucket.length, label: "More", key: "more" as T }}
        />
        <RedoCommandMenu
          anchor={moreItemsButtonRef}
          items={itemsInBucket.map((tab) => ({
            text: `${tab.label}${tab.count ? ` (${tab.count})` : ""}`,
            key: tab.key,
            onClick: () => {
              setSelectedTab?.(tab.key);
            },
          }))}
          open={bucketMenuOpen}
          setOpen={setBucketMenuOpen}
        />
      </>
    ) : null;

  /**
   * We're going to build some logic for making a bucket tab that stores overflowed tabs, and has a "..." with a menu that opens.
   **/

  return (
    <Flex
      align="flex-start"
      as="div"
      grow={1}
      ref={setAvailableSpaceRef}
      style={{ overflow: "hidden", minWidth: 0, flexShrink: 0 }}
    >
      <Flex
        align="flex-start"
        as="div"
        basis="100%"
        className={classNames(
          horizontalTabsCss.linedContainer,
          !drawBottomLine && horizontalTabsCss.relyOnExternalBottomBorder,
        )}
        grow={1}
        justify={tabHolderFlexJustify[mode]}
      >
        <Flex
          className={horizontalTabsCss.tabHolder}
          grow={1}
          pl={pl}
          ref={setVisibleItemsRef}
        >
          {itemsInNormalSpot.map((tab, index) => {
            const isSelected = tab.key === selectedTab;
            return (
              <SingleTab<T>
                isSelected={isSelected}
                key={index}
                mode={mode}
                setSelectedTab={setSelectedTab}
                size={size}
                tab={tab}
              />
            );
          })}
          {moreItemsButton}
        </Flex>
      </Flex>
    </Flex>
  );
});

const tabHolderFlexJustify: Record<
  RedoHorizontalTabMode,
  FlexProps["justify"]
> = {
  [RedoHorizontalTabMode.REGULAR]: "flex-start",
  [RedoHorizontalTabMode.FULL_WIDTH]: "center",
};

const tabModeToStyleName: Record<RedoHorizontalTabMode, string> = {
  [RedoHorizontalTabMode.REGULAR]: "regular",
  [RedoHorizontalTabMode.FULL_WIDTH]: "fullWidth",
};

const tabModeToTabBasis: Record<RedoHorizontalTabMode, string> = {
  [RedoHorizontalTabMode.REGULAR]: "auto",
  [RedoHorizontalTabMode.FULL_WIDTH]: "240px",
};

const tabsSizeToTextSize: Record<RedoHorizontalTabSize, TextProps["fontSize"]> =
  {
    [RedoHorizontalTabSize.SMALL]: "sm",
    [RedoHorizontalTabSize.X_SMALL]: "xs",
  };

const tabSelectedToTextColor = (selected: boolean) =>
  selected ? "primary" : "quaternary";
