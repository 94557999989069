import { TimeUnit } from "../time";

export enum ProductRecommendationType {
  PROVIDER_RECOMMENDATIONS = "shopify_recommendations",
  COLLECTION = "collection",
  METAFIELD = "metafield",
  TAG = "tag",
  BEST_SELLERS = "best_sellers",
  MOST_VIEWED = "most_viewed",
  NEWEST_PRODUCTS = "newest_products",
  RANDOM_PRODUCTS = "random_products",
  VIEWED_PRODUCTS = "viewed_products",
  PRODUCTS_ADDED_TO_CART = "products_added_to_cart",
}

export interface BaseRecommendedProductFilter {
  productRecommendationType: ProductRecommendationType;
  name: string;
  _id: string;
  additionalProductFilters?: AdditionalProductFilter[];
}

export function isCollectionRecommendedProductFilter(
  filter: RecommendedProductFilter | undefined,
): filter is CollectionRecommendedProductFilter {
  return (
    filter?.productRecommendationType === ProductRecommendationType.COLLECTION
  );
}

export interface CollectionRecommendedProductFilter
  extends BaseRecommendedProductFilter {
  productRecommendationType: ProductRecommendationType.COLLECTION;
  collectionId: string;
}

export function isMetafieldRecommendedProductFilter(
  filter: RecommendedProductFilter | undefined,
): filter is MetafieldRecommendedProductFilter {
  return (
    filter?.productRecommendationType === ProductRecommendationType.METAFIELD
  );
}

export interface MetafieldRecommendedProductFilter
  extends BaseRecommendedProductFilter {
  productRecommendationType: ProductRecommendationType.METAFIELD;
  metafield: { key: string; namespace: string };
}

export function isViewedProductsRecommendedProductFilter(
  filter: RecommendedProductFilter | undefined,
): filter is ViewedProductsRecommendedProductFilter {
  return (
    filter?.productRecommendationType ===
    ProductRecommendationType.VIEWED_PRODUCTS
  );
}

export interface ViewedProductsRecommendedProductFilter
  extends BaseRecommendedProductFilter {
  productRecommendationType: ProductRecommendationType.VIEWED_PRODUCTS;
  unit: TimeUnit;
  value: number;
}

export function isProductsAddedToCartRecommendedProductFilter(
  filter: RecommendedProductFilter | undefined,
): filter is ProductsAddedToCartRecommendedProductFilter {
  return (
    filter?.productRecommendationType ===
    ProductRecommendationType.PRODUCTS_ADDED_TO_CART
  );
}

export interface ProductsAddedToCartRecommendedProductFilter
  extends BaseRecommendedProductFilter {
  productRecommendationType: ProductRecommendationType.PRODUCTS_ADDED_TO_CART;
  unit: TimeUnit;
  value: number;
}

export function isTagRecommendedProductFilter(
  filter: RecommendedProductFilter | undefined,
): filter is TagRecommendedProductFilter {
  return filter?.productRecommendationType === ProductRecommendationType.TAG;
}

export interface TagRecommendedProductFilter
  extends BaseRecommendedProductFilter {
  productRecommendationType: ProductRecommendationType.TAG;
  tags: string[];
}

export interface BasicRecommendedProductFilter
  extends BaseRecommendedProductFilter {
  productRecommendationType: ProductRecommendationType.PROVIDER_RECOMMENDATIONS;
}

export type RecommendedProductFilter =
  | CollectionRecommendedProductFilter
  | MetafieldRecommendedProductFilter
  | ViewedProductsRecommendedProductFilter
  | ProductsAddedToCartRecommendedProductFilter
  | TagRecommendedProductFilter
  | BasicRecommendedProductFilter;

export enum UniqueProductFilterType {
  COLLECTION = "collection",
  METAFIELD = "metafield",
  TAG = "tag",
  PRICE = "price",
  INVENTORY = "inventory",
}

export enum ComparisonOperator {
  EQUALS = "equals",
  AT_LEAST = "at_least",
  GREATER_THAN = "greater_than",
  LESS_THAN = "less_than",
  AT_MOST = "at_most",
}

export const comparisonOperatorEvaluator: Record<
  ComparisonOperator,
  (a: number, b: number) => boolean
> = {
  [ComparisonOperator.EQUALS]: (a: number, b: number) => a === b,
  [ComparisonOperator.AT_LEAST]: (a: number, b: number) => a >= b,
  [ComparisonOperator.GREATER_THAN]: (a: number, b: number) => a > b,
  [ComparisonOperator.LESS_THAN]: (a: number, b: number) => a < b,
  [ComparisonOperator.AT_MOST]: (a: number, b: number) => a <= b,
};

export type AdditionalProductFilter =
  | UniqueProductFilterCollection
  | UniqueProductFilterMetafield
  | UniqueProductFilterTag
  | UniqueProductFilterPrice
  | UniqueProductFilterInventory;

export interface UniqueProductFilterCollection {
  type: UniqueProductFilterType.COLLECTION;
  collectionIds: string[];
}

export interface UniqueProductFilterMetafield {
  type: UniqueProductFilterType.METAFIELD;
  metafields: { metafieldKey: string; namespace: string }[];
}

export interface UniqueProductFilterTag {
  type: UniqueProductFilterType.TAG;
  tags: string[];
}

export interface UniqueProductFilterPrice {
  type: UniqueProductFilterType.PRICE;
  price: number;
  comparisonOperator: ComparisonOperator;
}

export interface UniqueProductFilterInventory {
  type: UniqueProductFilterType.INVENTORY;
  inventory: number;
  comparisonOperator: ComparisonOperator;
}
