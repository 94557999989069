// extracted by mini-css-extract-plugin
export var actionButton = "_c1-0";
export var bubble = "_c1-2";
export var bubbleContext = "_c1-6";
export var bubbleItems = "_c1-7";
export var customer = "_c1-3";
export var divider = "_c1-8";
export var fileList = "_c1-9";
export var infoAbove = "_c1-a";
export var internal = "_c1-4";
export var lightActionButton = "_c1-1";
export var link = "_c1-b";
export var merchant = "_c1-5";
export var message = "_c1-c";
export var profilePicture = "_c1-e";
export var quillToolbarContainer = "_c1-f";
export var senderDate = "_c1-g";
export var senderDateCustomer = "_c1-h";
export var senderName = "_c1-d";
export var time = "_c1-i";
export var uploadedImage = "_c1-j";
export var username = "_c1-k";
export var xsmall = "_c1-l";