import { zExt } from "@redotech/rpc/ext";
import { z } from "zod";
import { SchemaType } from "./advanced-flow/schemas/schemas";
import { categories } from "./advanced-flow/triggers";
import {
  Alignment,
  ButtonLinkType,
  CartLayoutType,
  EmailBlockType,
  EmailHeaderType,
  FontFamily,
  FontWeight,
  ProductSelectionType,
  Section,
  Size,
  SocialPlatform,
  VerticalAlignment,
} from "./email-builder";
import { UpsellProductSource } from "./team";

export interface EmailTemplateRow extends EmailTemplate {
  _id: any;
}
const paddingSchema = z.object({
  top: z.number(),
  right: z.number(),
  bottom: z.number(),
  left: z.number(),
});

const baseSectionSchema = z.object({
  blockId: zExt.objectId().optional(),
  schemaFieldName: z.string().optional(),
  sectionPadding: paddingSchema,
});

const discountSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.DISCOUNT),
  discountId: zExt.objectId().optional(),
  alignment: z.nativeEnum(Alignment),
  fontFamily: z.nativeEnum(FontFamily),
  fontWeight: z.nativeEnum(FontWeight),
  fontSize: z.number(),
  textColor: z.string(),
  sectionColor: z.string(),
  blockBackgroundColor: z.string(),
});

const columnSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.COLUMN),
  columns: z.array(z.lazy(() => sectionWithoutColumnSchema.nullable())),
  columnCount: z.number(),
  sectionColor: z.string(),
  gap: z.number(),
  stackOnMobile: z.boolean(),
  alignment: z.nativeEnum(VerticalAlignment),
});

const menuItemSchema = z.object({ id: z.string(), label: z.string() });

const menuSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.MENU),
  menuItems: z.array(menuItemSchema),
  linkColor: z.string(),
  sectionColor: z.string(),
  alignment: z.nativeEnum(Alignment),
  fontFamily: z.nativeEnum(FontFamily),
  fontSize: z.number(),
  textColor: z.string(),
});

const socialItemSchema = z.object({
  id: z.string(),
  platform: z.nativeEnum(SocialPlatform),
  url: z.string(),
});

const socialsSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.SOCIALS),
  socialLinks: z.array(socialItemSchema),
  iconColor: z.nativeEnum(Section.SocialIconColors),
  iconPadding: z.number(),
  alignment: z.nativeEnum(Alignment),
  sectionColor: z.string(),
});

const headerSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.HEADER),
  headerType: z.nativeEnum(EmailHeaderType),
  layout: z.nativeEnum(Alignment),
  sectionColor: z.string(),
  imageUrl: z.string(),
  text: z.string(),
  textColor: z.string(),
  fontSize: z.number(),
  fontFamily: z.nativeEnum(FontFamily),
  logoHeight: z.number(),
  imageHeight: z.number(),
  altText: z.string().optional(),
  clickthroughUrl: z.string().optional(),
});

const footerSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.FOOTER),
  horizontalPadding: z.nativeEnum(Size),
  verticalPadding: z.nativeEnum(Size),
  padding: paddingSchema,
  sectionColor: z.string(),
  textColor: z.string(),
  alignment: z.nativeEnum(Alignment),
  fontSize: z.number().optional(),
  fontFamily: z.nativeEnum(FontFamily).optional(),
});

const textSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.TEXT),
  textColor: z.string(),
  fontSize: z.number(),
  fontFamily: z.nativeEnum(FontFamily),
  sectionColor: z.string(),
  linkColor: z.string(),
  text: z.string(),
});

const buttonSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.BUTTON),
  width: z.number(),
  height: z.number(),
  size: z.nativeEnum(Size),
  alignment: z.nativeEnum(Alignment),
  cornerRadius: z.number(),
  buttonText: z.string(),
  horizontalPadding: z.nativeEnum(Size),
  verticalPadding: z.nativeEnum(Size),
  padding: paddingSchema,
  buttonLink: z.string().optional(),
  fillColor: z.string(),
  strokeColor: z.string(),
  textColor: z.string(),
  strokeWeight: z.number(),
  fontFamily: z.nativeEnum(FontFamily),
  fontSize: z.number(),
  sectionColor: z.string(),
  as: z.enum(["button", "a"]).optional(),
  buttonType: z.enum(["button", "submit", "reset"]).optional(),
  fullWidth: z.boolean().optional(),
  linkType: z.nativeEnum(ButtonLinkType).optional(),
});

const reviewRequestSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.REVIEW_REQUEST),
  sectionColor: z.string(),
  sectionHeader: z.string(),
  imageCornerRadius: z.number(),
  imageSize: z.nativeEnum(Size),
  buttonHeight: z.number(),
  buttonWidth: z.number(),
  buttonColor: z.string(),
  buttonTextColor: z.string(),
  buttonSize: z.nativeEnum(Size),
  buttonCornerRadius: z.number(),
  textColor: z.string(),
  fontFamily: z.nativeEnum(FontFamily),
});

const lineSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.LINE),
  color: z.string(),
  padding: paddingSchema,
  horizontalPadding: z.nativeEnum(Size),
  verticalPadding: z.nativeEnum(Size),
  sectionColor: z.string(),
});

const spacerSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.SPACER),
  sectionColor: z.string(),
  height: z.number(),
});

const imageSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.IMAGE),
  imageUrl: z.string(),
  padding: paddingSchema,
  horizontalPadding: z.nativeEnum(Size),
  verticalPadding: z.nativeEnum(Size),
  sectionColor: z.string(),
  showCaption: z.boolean(),
  caption: z.string().optional(),
  altText: z.string().optional(),
  clickthroughUrl: z.string().optional(),
  aspectRatio: z.number().optional(),
});

const trackingInfoSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.TRACKING_INFO),
  sectionColor: z.string(),
  textColor: z.string(),
  buttonVisible: z.boolean(),
  buttonHeight: z.number(),
  buttonWidth: z.number(),
  buttonColor: z.string(),
  buttonTextColor: z.string(),
  buttonSize: z.nativeEnum(Size),
  buttonCornerRadius: z.number(),
  statusIndicatorColor: z.string(),
  statusIndicatorInnerColor: z.string(),
  fontFamily: z.nativeEnum(FontFamily),
});

const trackableSummarySchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.TRACKABLE_SUMMARY),
  showOrderInformation: z.boolean(),
  showCostSummary: z.boolean(),
  showPaymentInformation: z.boolean(),
  showCustomerInformation: z.boolean(),
  sectionColor: z.string(),
  textColor: z.string(),
  fontFamily: z.nativeEnum(FontFamily),
  imageSize: z.nativeEnum(Size),
  imageCornerRadius: z.number(),
});

const manuallySelectedProductSchema = z.object({
  productId: z.string(),
  variantId: z.string(),
});

const metafieldSchema = z.object({
  key: z.string(),
  namespace: z.string(),
  metaobjectKey: z.string().optional(),
});

const interactiveCartSchema = baseSectionSchema.extend({
  type: z.literal(EmailBlockType.INTERACTIVE_CART),
  sectionColor: z.string(),
  textColor: z.string(),
  fontFamily: z.nativeEnum(FontFamily),
  imageCornerRadius: z.number(),
  checkoutButton: buttonSchema.omit({ sectionPadding: true, type: true }),
  lineItemButtons: buttonSchema.omit({ sectionPadding: true, type: true }),
  numberOfProducts: z.number(),
  imageSize: z.nativeEnum(Size),
  productSelectionType: z.nativeEnum(ProductSelectionType),
  source: z.nativeEnum(UpsellProductSource),
  showPrice: z.boolean().optional(),
  showTitle: z.boolean().optional(),
  showImage: z.boolean().optional(),
  showButton: z.boolean().optional(),
  showQuantity: z.boolean().optional(),
  collectionId: z.string().optional(),
  metafield: metafieldSchema.optional(),
  layoutType: z.nativeEnum(CartLayoutType).optional(),
  alignment: z.nativeEnum(Alignment),
  columns: z.number(),
  stackOnMobile: z.boolean(),
  recommendedProductFilterId: zExt.objectId().optional(),

  hoverSecondImage: z.boolean().optional(),
  collectionName: z.string().optional(),
  metafieldKey: z.string().optional(),
  text: z.string().optional(),
  minimumStock: z.number().optional(),
  productTags: z.array(z.string()).optional(),
  productEstimateEnabled: z.boolean().optional(),
  manuallySelectedProducts: z
    .array(manuallySelectedProductSchema.nullable())
    .optional(),
});

const shopInEmailSchema = interactiveCartSchema
  .omit({ type: true })
  .extend({ type: z.literal(EmailBlockType.SHOP_IN_EMAIL) });

const sectionWithoutColumnSchema = z.discriminatedUnion("type", [
  discountSchema,
  menuSchema,
  socialsSchema,
  headerSchema,
  footerSchema,
  textSchema,
  buttonSchema,
  reviewRequestSchema,
  lineSchema,
  spacerSchema,
  imageSchema,
  trackingInfoSchema,
  trackableSummarySchema,
  interactiveCartSchema,
  shopInEmailSchema,
]);

const sectionSchema = z.discriminatedUnion("type", [
  ...sectionWithoutColumnSchema.options,
  columnSchema,
]);

export const emailTemplateSchema = z.object({
  _id: zExt.objectId(),
  name: z.string(),
  subject: z.string(),
  templateType: z.enum(["transactional", "marketing", "default"]),
  category: z.enum(categories),
  schemaType: z.nativeEnum(SchemaType),
  emailPreview: z.string().optional().nullable(),
  emailBackgroundColor: z.string(),
  contentBackgroundColor: z.string(),
  address: z.object({
    businessAddress: z.string().optional().nullable(),
    legalAddress: z.string(),
    cityStateZip: z.string(),
    country: z.string(),
  }),
  sections: z.array(sectionSchema),
  linkColor: z.string().optional().nullable(),
  // optional because we do have some emailTemplates as subdocuments
  // on campaigns that don't have the team field
  team: zExt.objectId().optional(),
});

export type EmailTemplate = z.infer<typeof emailTemplateSchema>;
