import { RedoMerchantClient } from "@redotech/redo-merchant-app-common/client";
import {
  ConversationPlatform,
  MessageVisibility,
} from "@redotech/redo-model/conversation";
import { Team } from "@redotech/redo-model/team";
import { alertOnFailure } from "@redotech/redo-web/alert";
import {
  RedoBadge,
  RedoBadgeColor,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import {
  RedoCheckbox,
  RedoCheckboxSize,
} from "@redotech/redo-web/arbiter-components/checkbox/redo-checkbox";
import { RedoModal } from "@redotech/redo-web/arbiter-components/modal/redo-modal";
import Edit02Svg from "@redotech/redo-web/arbiter-icon/edit-02.svg";
import StickerSquareSvg from "@redotech/redo-web/arbiter-icon/sticker-square.svg";
import { Flex } from "@redotech/redo-web/flex";
import { QuillEditor } from "@redotech/redo-web/quill/quill-editor";
import * as quillEditorCss from "@redotech/redo-web/quill/quill-editor.module.css";
import { Text } from "@redotech/redo-web/text";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import Quill from "quill";
import { Delta as DeltaType, EmitterSource } from "quill/core";
import { memo, ReactElement, Ref, useEffect, useState } from "react";
import { sendMessageDraft } from "../../../client/conversations";
import {
  AutocompleteType,
  AutocompletionAction,
  SupportMessageAutocomplete,
} from "../../support-message-autocomplete";
import { MacroAutomation } from "../macro-automation";
import * as macroModalCss from "../macro-modal.module.css";
import { clearColoringAtCursor, formatWithInserts } from "../quill-macro-utils";
const Delta = Quill.import("delta");

const AddInternalNoteBadge = memo(function AddInternalNoteBadge({
  noteToAddContent,
  onEdit,
}: {
  noteToAddContent: string;
  onEdit?: () => void;
}) {
  return (
    <Tooltip title={noteToAddContent}>
      <div>
        <RedoBadge
          color={RedoBadgeColor.GRAY}
          iconTrailing={
            onEdit
              ? { type: "icon", Icon: Edit02Svg, onClick: onEdit }
              : undefined
          }
          segmentLeading={{ type: "icon", Icon: StickerSquareSvg }}
          text="Add note"
        />
      </div>
    </Tooltip>
  );
});

export const AddInternalNoteAutomation: MacroAutomation<
  {
    client: RedoMerchantClient;
    shouldAddNote: boolean;
    setShouldAddNote: (value: boolean) => void;
    quill: Quill | null;
    setQuill: Ref<Quill> | undefined;
    team: Team;
    noteToAddUsersMentioned: string[];
    setNoteToAddUsersMentioned: (usersMentioned: string[]) => void;
    shouldAllowTogglingAddNote?: boolean;
  },
  { shouldAddNote: boolean; noteToAddContent: string },
  {
    client: RedoMerchantClient;
    team: Team;
    shouldAddNote: boolean;
    noteToAddContent: string;
    setShouldAddNote: (value: boolean) => void;
    setNoteToAddContent: (content: string) => void;
    noteToAddHtmlContent: string;
    setNoteToAddHtmlContent: (content: string) => void;
    noteToAddUsersMentioned: string[];
    setNoteToAddUsersMentioned: (usersMentioned: string[]) => void;
  },
  {
    client: RedoMerchantClient;
    conversationId: string;
    shouldAddNote: boolean;
    redoDraftMessageId: string;
  }
> = {
  EditMode: ({
    client,
    shouldAddNote,
    setShouldAddNote,
    quill,
    setQuill,
    team,
    noteToAddUsersMentioned,
    setNoteToAddUsersMentioned,
    shouldAllowTogglingAddNote = true,
  }): ReactElement => {
    const [autocompleteVisible, setAutocompleteVisible] = useState(false);
    const [triggerOpenAutocompleteMenu, setTriggerOpenAutocompleteMenu] =
      useState<AutocompleteType | undefined>(undefined);
    const [autocompleteActionToPerform, setAutocompleteActionToPerform] =
      useState<AutocompletionAction | null>(null);
    const [usersMentionedInMessage, setUsersMentionedInMessage] = useState<
      { name: string; id: string }[]
    >([
      /**
       * Initially, lookup each user ID given in noteToAddUsersMentioned on the team, and load in their names.
       * We do this since we don't store the names in the database - we only store the user IDs.
       *
       * But, knowing the names lets us delete the IDs if the names get erased.
       * This logic is currently duplicated in message-input.tsx.
       * Only thing preventing consolidation is moving users mentioned entirely inside, which
       * requires shuffling some other parts of the code around (but it's doable)
       */
      ...team.users
        .map((user) => ({
          name: typeof user.user === "string" ? user.user : user.user.name,
          /** This won't work if it's a string since user.user isn't an ID, but it's a benign error (just won't remove someone in this rare case) */
          id: typeof user.user === "string" ? user.user : user.user._id,
        }))
        .filter((user) => noteToAddUsersMentioned.includes(user.id)),
    ]);

    const [formatWithInsertsTrigger, setFormatWithInsertsTrigger] =
      useState(Symbol());

    useEffect(() => {
      if (usersMentionedInMessage.length) {
        setNoteToAddUsersMentioned(
          usersMentionedInMessage.map((user) => user.id),
        );
      }
    }, [usersMentionedInMessage]);

    const handleTextChange = () => {
      // If a mention was removed don't keep it in the list of users mentioned
      const text = quill?.getText();
      for (const user of usersMentionedInMessage) {
        if (!text?.includes(`@${user.name}`)) {
          setUsersMentionedInMessage(
            usersMentionedInMessage.filter((u) => u.id !== user.id),
          );
        }
      }
    };

    /**
     * Clear formatting when it changes (duped from message-input.tsx for now - good candidate for consolidation)
     */
    useEffect(() => {
      if (!quill) {
        return;
      }

      const formatInsertsCallback = (
        delta: DeltaType,
        oldDelta: DeltaType,
        source: EmitterSource,
      ) => {
        if (!delta.ops.some((op) => !!op.insert || !!op.delete)) {
          return;
        }
        formatWithInserts({ quill });
      };

      quill.on(Quill.events.TEXT_CHANGE, formatInsertsCallback);

      return () => {
        quill.off(Quill.events.TEXT_CHANGE, formatInsertsCallback);
      };
    }, [quill]);

    useEffect(() => {
      if (!quill) {
        return;
      }
      formatWithInserts({ quill });
    }, [formatWithInsertsTrigger]);

    return (
      <>
        {shouldAllowTogglingAddNote && (
          <RedoCheckbox
            description="When this template is used this note will be added to the ticket."
            label="Add note when used"
            setValue={(value) =>
              setShouldAddNote(
                value && value !== "indeterminate" ? true : false,
              )
            }
            size={RedoCheckboxSize.SMALL}
            value={shouldAddNote}
          />
        )}
        {shouldAddNote && (
          <Flex dir="column">
            <Flex dir="column" gap="xs">
              <Text fontSize="sm" fontWeight="medium" textColor="secondary">
                Note
              </Text>
              <div className={quillEditorCss.container}>
                <QuillEditor
                  defaultValue={new Delta().insert("")}
                  editorClassName={macroModalCss.quillEditor}
                  onTextChange={handleTextChange}
                  placeholder="Enter a note..."
                  readOnly={false}
                  ref={setQuill}
                />
                <SupportMessageAutocomplete
                  autocompleteActionToPerform={autocompleteActionToPerform}
                  client={client}
                  discountCodesInMessage={[]}
                  messageVisibility={MessageVisibility.INTERNAL}
                  platform={ConversationPlatform.EMAIL}
                  productsInMessage={[]}
                  quill={quill}
                  setAutocompleteActionToPerform={
                    setAutocompleteActionToPerform
                  }
                  setDiscountCodesInMessage={() => {}}
                  setFormatWithInsertsTrigger={setFormatWithInsertsTrigger}
                  setProductsInMessage={() => {}}
                  setShouldOpenFromExternalTrigger={
                    setTriggerOpenAutocompleteMenu
                  }
                  setUsersMentionedInMessage={setUsersMentionedInMessage}
                  setVisible={setAutocompleteVisible}
                  shouldOpenFromExternalTrigger={triggerOpenAutocompleteMenu}
                  team={team}
                  usersMentionedInMessage={usersMentionedInMessage}
                  visible={autocompleteVisible}
                />
              </div>
            </Flex>
          </Flex>
        )}
      </>
    );
  },
  PreviewTag({ shouldAddNote, noteToAddContent }) {
    return (
      <Flex align="flex-start" dir="column">
        {shouldAddNote && (
          <AddInternalNoteBadge noteToAddContent={noteToAddContent} />
        )}
      </Flex>
    );
  },
  PendingOnMessageTag({
    client,
    shouldAddNote,
    setShouldAddNote,
    noteToAddContent,
    noteToAddHtmlContent,
    setNoteToAddContent,
    setNoteToAddHtmlContent,
    noteToAddUsersMentioned,
    setNoteToAddUsersMentioned,
    team,
  }) {
    const [showEditModal, setShowEditModal] = useState(false);
    const [quill, setQuill] = useState<Quill | null>(null);
    useEffect(() => {
      if (quill && noteToAddHtmlContent) {
        quill.clipboard.dangerouslyPasteHTML(noteToAddHtmlContent);
        clearColoringAtCursor(quill, quill.getText().length);
      }
    }, [shouldAddNote, quill, noteToAddHtmlContent]);

    const handleSetNoteToAddContent = () => {
      if (!quill) {
        return;
      }
      setNoteToAddContent(quill?.getText() || "");
      setNoteToAddHtmlContent(quill?.getSemanticHTML() || "");
      setShowEditModal(false);
    };

    const handleSetShouldAddNote = (value: boolean) => {
      setShouldAddNote(value);
      setShowEditModal(false);
    };

    return (
      <>
        {shouldAddNote && (
          <AddInternalNoteBadge
            noteToAddContent={noteToAddContent}
            onEdit={() => setShowEditModal(true)}
          />
        )}
        {showEditModal && (
          <RedoModal
            onModalCloseRequested={() => setShowEditModal(false)}
            primaryButton={{ text: "Save", onClick: handleSetNoteToAddContent }}
            secondaryButton={{
              text: "Remove",
              onClick: () => handleSetShouldAddNote(false),
            }}
            title="Edit note to add"
          >
            <AddInternalNoteAutomation.EditMode
              client={client}
              noteToAddUsersMentioned={noteToAddUsersMentioned}
              quill={quill}
              setNoteToAddUsersMentioned={setNoteToAddUsersMentioned}
              setQuill={setQuill}
              setShouldAddNote={() => {}}
              shouldAddNote={shouldAddNote}
              shouldAllowTogglingAddNote={false}
              team={team}
            />
          </RedoModal>
        )}
      </>
    );
  },
  perform: async ({
    client,
    conversationId,
    shouldAddNote,
    redoDraftMessageId,
  }) => {
    await alertOnFailure("Failed to add internal note")(async () => {
      if (shouldAddNote) {
        await sendMessageDraft(client, {
          conversationId: conversationId,
          redoDraftMessageId: redoDraftMessageId,
        });
      }
    });
  },
};
