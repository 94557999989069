import type { Stripe } from "stripe";
import type { BalanceTransaction as IBalanceTransaction } from "./balance-transaction";
import type { Address, Rate, ShipmentParcel } from "./shipment";

export type OutboundLabelShippingAddress = {
  name?: string;
  address1: string;
  address2?: string;
  city: string;
  country?: string;
  country_code?: string;
  province?: string;
  province_code?: string;
  zip?: string;
  first_name?: string;
  last_name?: string;
  company?: string;
  email?: string;
  phone: string;
};

export type ShipmentRatesOld = {
  shipmentId?: string;
  toAddress: Address;
  fromAddress: Address;
  parcel: ShipmentParcel;
  mode: string;
  rates: Rate[];
  totalWeight: number;
  weightUnit: WeightUnit;
};

export namespace ParcelType {
  export const BOX = "box";
  export const ENVELOPE = "envelope";
  export const SOFT_PACK = "soft_pack";
}
export type ParcelType =
  | typeof ParcelType.BOX
  | typeof ParcelType.ENVELOPE
  | typeof ParcelType.SOFT_PACK;

export const PACKAGE_TYPES: ParcelType[] = [
  ParcelType.BOX,
  ParcelType.ENVELOPE,
  ParcelType.SOFT_PACK,
];

export namespace LengthUnit {
  export const INCH = "in";
  export const CENTIMETER = "cm";
}
export type LengthUnit = typeof LengthUnit.INCH | typeof LengthUnit.CENTIMETER;
export const LENGTH_UNITS: LengthUnit[] = [
  LengthUnit.INCH,
  LengthUnit.CENTIMETER,
];

export namespace WeightUnit {
  export const OUNCE = "oz";
  export const POUND = "lb";
  export const GRAM = "g";
  export const KILOGRAM = "kg";
}
export type WeightUnit =
  | typeof WeightUnit.OUNCE
  | typeof WeightUnit.POUND
  | typeof WeightUnit.GRAM
  | typeof WeightUnit.KILOGRAM;

export const WEIGHT_UNITS: WeightUnit[] = [
  WeightUnit.OUNCE,
  WeightUnit.POUND,
  WeightUnit.GRAM,
  WeightUnit.KILOGRAM,
];

export interface BaseParcel {
  name: string;
}

export interface CustomParcel extends BaseParcel {
  type: ParcelType;
  length: number;
  width: number;
  height: number;
  lengthUnit: LengthUnit;
  weight: number;
  weightUnit: WeightUnit;
}

export interface CarrierParcel extends BaseParcel {
  carrier: string;
  description: string;
  dimensions: string[];
  human_readable: string;
  max_weight: number | null;
}

export type Parcel = CustomParcel | CarrierParcel;

export interface BuyOutboundLabelRequest {
  orderId: string;
  rate: Rate;
}

export interface BuyOutboundLabelResponse {
  orderId: string;
  success: boolean;
  errorMessage?: string;
}

export const GRAMS_TO_OUNCES = 0.035274;
export const KILOGRAMS_TO_OUNCES = 35.274;

export function getPackageWeightInOunces(parcel: CustomParcel): number {
  return getWeightInOunces(parcel.weight, parcel.weightUnit);
}

export function getWeightInOunces(
  weight: number,
  weightUnit: WeightUnit,
): number {
  return weightUnit === WeightUnit.OUNCE
    ? weight
    : weightUnit === WeightUnit.POUND
      ? weight * 16
      : weightUnit === WeightUnit.GRAM
        ? weight * GRAMS_TO_OUNCES
        : weightUnit === WeightUnit.KILOGRAM
          ? weight * KILOGRAMS_TO_OUNCES
          : 1;
}

export function getPackageDimensionInInches(parcel: CustomParcel): {
  height: number;
  width: number;
  length: number;
} {
  return {
    height:
      parcel?.lengthUnit === LengthUnit.INCH
        ? parcel.height
        : parcel.height / 2.54,
    width:
      parcel?.lengthUnit === LengthUnit.INCH
        ? parcel.width
        : parcel.width / 2.54,
    length:
      parcel?.lengthUnit === LengthUnit.INCH
        ? parcel.length
        : parcel.length / 2.54,
  };
}

export function standardizePackageDimensions(parcel: Parcel): Parcel {
  if ("carrier" in parcel && parcel.carrier) {
    return parcel;
  } else {
    const { height, width, length } = getPackageDimensionInInches(
      parcel as CustomParcel,
    );
    return {
      ...parcel,
      weight: getPackageWeightInOunces(parcel as CustomParcel),
      height,
      width,
      length,
      lengthUnit: LengthUnit.INCH,
      weightUnit: WeightUnit.OUNCE,
    };
  }
}

export type AddFundsResult =
  | {
      success: true;
      stripeChargeSuccess: boolean;
      transaction: IBalanceTransaction;
      paymentIntent: Stripe.PaymentIntent;
    }
  | { success: false; stripeChargeSuccess: boolean; error: string };

export enum PageOrientation {
  Portrait = "portrait",
  Landscape = "landscape",
}
export enum PageFormat {
  Letter = "8.5x11",
  Label = "4x6",
}

export const PageFormatDimensions = {
  [PageFormat.Letter]: { width: 8.5, height: 11 },
  [PageFormat.Label]: { width: 4, height: 6 },
};

export enum ItemOrderBy {
  NameAsc = "NameAsc",
  NameDesc = "NameDesc",
  QuantityAsc = "QuantityAsc",
  QuantityDesc = "QuantityDesc",
  SkuAsc = "SkuAsc",
  SkuDesc = "SkuDesc",
  TotalPriceAsc = "TotalPriceAsc",
  TotalPriceDesc = "TotalPriceDesc",
  UnitPriceAsc = "UnitPriceAsc",
  UnitPriceDesc = "UnitPriceDesc",
}
