// extracted by mini-css-extract-plugin
export var createdDate = "_b9-1";
export var divider = "_b9-2";
export var headerDivider = "_b9-3";
export var messagesCard = "_b9-0";
export var messagesContainer = "_b9-7";
export var messagesContainerWrapper = "_b9-4";
export var pointerCapturingButtonBoxes = "_b9-a";
export var showLeftPanelButton = "_b9-9";
export var showMoreButton = "_b9-c";
export var showMoreButtonContainer = "_b9-b";
export var summaryTitle = "_b9-6";
export var systemMessageCardWrapper = "_b9-d";
export var topButtons = "_b9-8";
export var withPadding = "_b9-5";