import { useRequiredContext } from "@redotech/react-util/context";
import { getSmsPrefix, SMS_SUFFIX } from "@redotech/redo-model/marketing";
import { TeamContext } from "@redotech/redo-web/context/team-context";
import { Flex } from "@redotech/redo-web/flex";
import { isDef } from "@redotech/util/checks";
import { memo, useEffect, useRef } from "react";
import PhoneImage from "./Phone.png?url";
import * as smsPhoneCss from "./sms-phone.module.css";

export const SmsPhone = memo(function SmsPhone({
  content,
  imageSrc,
  autoShortenLinks,
}: {
  content: string;
  imageSrc?: string | undefined;
  autoShortenLinks: boolean;
}) {
  const textareaRef = useRef<HTMLDivElement>(null);

  const team = useRequiredContext(TeamContext);

  // Update height when content changes and sanitize content
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [content]);

  if (!team) {
    return null;
  }

  return (
    <Flex className={smsPhoneCss.container}>
      <Flex className={smsPhoneCss.content} dir="column" gap="md">
        {isDef(imageSrc) && imageSrc && (
          <img alt="Card" className={smsPhoneCss.smsImage} src={imageSrc} />
        )}
        <div
          className={smsPhoneCss.textarea}
          onChange={() => {}}
          ref={textareaRef}
        >
          {getSmsPrefix(team)}
          {sanitizeSmsBuilderContent(content, autoShortenLinks)}
          {SMS_SUFFIX}
        </div>
      </Flex>

      <img alt="Card" className={smsPhoneCss.phoneCard} src={PhoneImage} />
    </Flex>
  );
});

// this is required so that we can underline the links in the content.
// can be removed if we don't care to underline in the phone preview.
function sanitizeSmsBuilderContent(content: string, autoShortenLinks: boolean) {
  const segments: React.ReactNode[] = [];
  let lastIndex = 0;
  const markdownLinkRegex = /\[(.*?)\]\((.*?)\)/g;

  content.trim().replace(markdownLinkRegex, (match, text, url, offset) => {
    // Add text before the link
    if (offset > lastIndex) {
      segments.push(
        <span key={lastIndex}>{content.slice(lastIndex, offset)}</span>,
      );
    }

    // Add the link
    segments.push(
      <span key={offset} style={{ textDecoration: "underline" }}>
        {autoShortenLinks ? "https://redo.link/A1B2C3D4" : url}
      </span>,
    );

    lastIndex = offset + match.length;
    return match; // Required for replace
  });

  // Add remaining text
  if (lastIndex < content.length) {
    segments.push(<span key={lastIndex}>{content.slice(lastIndex)}</span>);
  }

  return <>{segments}</>;
}
