import { useRequiredContext } from "@redotech/react-util/context";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import {
  ConversationPlatform,
  ExpandedConversation,
} from "@redotech/redo-model/conversation";
import { getCustomerDisplayName } from "@redotech/redo-model/customer";
import { Permission, permitted } from "@redotech/redo-model/user";
import {
  RedoButton,
  RedoButtonHierarchy,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import AlignLeft01Svg from "@redotech/redo-web/arbiter-icon/align-left-01_filled.svg";
import AlignRight01Svg from "@redotech/redo-web/arbiter-icon/align-right-01_filled.svg";
import ChevronDownSVG from "@redotech/redo-web/arbiter-icon/chevron-down_filled.svg";
import ChevronUpSVG from "@redotech/redo-web/arbiter-icon/chevron-up_filled.svg";
import XCloseSVG from "@redotech/redo-web/arbiter-icon/x-close.svg";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { Flex } from "@redotech/redo-web/flex";
import { InputSize } from "@redotech/redo-web/input";
import { Text } from "@redotech/redo-web/text";
import { TextInput } from "@redotech/redo-web/text-input";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { isUserOnMac } from "@redotech/util/browser-agent";
import { sinkPromise } from "@redotech/util/promise";
import * as classNames from "classnames";
import {
  memo,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { updateConversation } from "../client/conversations";
import { ActiveUsers } from "./active-users";
import * as conversationHeaderCss from "./conversation-header.module.css";
import { ActiveViewContext } from "./conversations-table-filters/active-view-context";
import { ActiveViewConversationCountsContext } from "./conversations-table-filters/conversation-counts-context";
import { hotkeyTooltip } from "./message-input/hotkeys";
import { TeamConversationActivityContext } from "./support";

export const ConversationHeader = memo(function ConversationHeader({
  conversation,
  leftPanelOpen,
  handleSetLeftPanelOpen,
  rightPanelOpen,
  handleSetRightPanelOpen,
  actionButtons,
  setActiveConversation,
  onConversationsViewed,
  nextConversationInList,
  pendingNextConversation,
  prevConversationInList,
}: {
  conversation: ExpandedConversation;
  leftPanelOpen: boolean;
  handleSetLeftPanelOpen: (value: boolean) => void;
  rightPanelOpen: boolean;
  handleSetRightPanelOpen: (value: boolean) => void;
  actionButtons: ReactNode;
  setActiveConversation: (
    conversation: ExpandedConversation | undefined,
  ) => void;
  onConversationsViewed: (
    conversation: ExpandedConversation,
  ) => ExpandedConversation | undefined;
  nextConversationInList?: ExpandedConversation;
  pendingNextConversation?: boolean;
  prevConversationInList?: ExpandedConversation;
}) {
  const navigate = useNavigate();
  const handleBackClick = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const ACTIVE_CONVERSATION_QUERY_PARAMETER = "activeConversationId";
    searchParams.delete(ACTIVE_CONVERSATION_QUERY_PARAMETER);
    navigate(`${location.pathname}?${searchParams}`);
    setActiveConversation(undefined);
  };

  useEffect(() => {
    setConversationSubject(conversation.subject);
  }, [conversation]);

  const client = useRequiredContext(RedoMerchantClientContext);
  const user = useRequiredContext(UserContext);
  const canEditSubject =
    !!user && permitted(user.permissions, Permission.CREATE_REPLY);

  const [conversationSubject, setConversationSubject] = useState(
    conversation.subject,
  );

  async function handleSetSubject() {
    if (!conversationSubject) {
      setConversationSubject(conversation.subject);
    } else {
      await updateConversation(client, conversation, {
        subject: conversationSubject,
      });
    }
  }

  const isMac = isUserOnMac();

  const teamConversationActivity = useContext(TeamConversationActivityContext);

  const shouldShowSubheaderBecauseThereAreOtherUsersViewing =
    teamConversationActivity?.[conversation._id]?.viewing?.length > 1;

  const conversationCounts = useContext(ActiveViewConversationCountsContext);
  const activeView = useContext(ActiveViewContext);

  const filterStatus = activeView.filters.status || "all";

  const totalConversations = useMemo(() => {
    return conversationCounts
      ? (conversationCounts[`${activeView.name}-${filterStatus}`] ?? 0)
      : 0;
  }, [conversationCounts, activeView, filterStatus]);

  const shouldShowSubheaderBecauseWeHaveProximateConversations =
    !!nextConversationInList || !!prevConversationInList;

  const shouldDisableButtonsBecauseLoading = pendingNextConversation;

  const shouldDisableNavigateUp =
    !prevConversationInList || shouldDisableButtonsBecauseLoading;

  const shouldDisableNavigateDown =
    !nextConversationInList || shouldDisableButtonsBecauseLoading;

  const shouldShowSubheader =
    shouldShowSubheaderBecauseThereAreOtherUsersViewing ||
    (shouldShowSubheaderBecauseWeHaveProximateConversations &&
      totalConversations > 1);

  const handleNavigateUp = () => {
    if (shouldDisableNavigateUp) {
      return;
    }
    onConversationsViewed(prevConversationInList);
    setActiveConversation(prevConversationInList);
  };

  const handleNavigateDown = () => {
    if (shouldDisableNavigateDown) {
      return;
    }
    onConversationsViewed(nextConversationInList);
    setActiveConversation(nextConversationInList);
  };

  useEffect(
    function handleHotkeysForNavigation() {
      const onKeyDown = (e: KeyboardEvent) => {
        if (e.code === "KeyJ" && e.altKey) {
          e.preventDefault();
          e.stopPropagation();
          handleNavigateUp();
        } else if (e.code === "KeyK" && e.altKey) {
          e.preventDefault();
          e.stopPropagation();
          handleNavigateDown();
        }
      };

      window.addEventListener("keydown", onKeyDown);

      return () => window.removeEventListener("keydown", onKeyDown);
    },
    [
      nextConversationInList,
      prevConversationInList,
      shouldDisableNavigateDown,
      shouldDisableNavigateUp,
    ],
  );

  return (
    <Flex
      className={classNames(
        conversationHeaderCss.headerBar,
        ...(!shouldShowSubheader ? [conversationHeaderCss.collapsed] : []),
      )}
      dir="column"
      gap="xs"
      pb="sm"
    >
      <Flex
        align="center"
        gap="3xl"
        justify="space-between"
        pl="2xl"
        pr="2xl"
        pt="md"
      >
        <Flex
          align="center"
          className={conversationHeaderCss.leftHeaderContent}
          gap="sm"
        >
          <Button
            className={conversationHeaderCss.headerButtons}
            onClick={() => handleSetLeftPanelOpen(!leftPanelOpen)}
            size={ButtonSize.NANO}
            theme={ButtonTheme.OUTLINED}
          >
            <Flex
              align="center"
              className={conversationHeaderCss.buttonIconWrapper}
              justify="center"
            >
              {leftPanelOpen ? <AlignLeft01Svg /> : <AlignRight01Svg />}
            </Flex>
          </Button>

          <Button
            className={classNames(
              conversationHeaderCss.headerButtons,
              conversationHeaderCss.justIconButtons,
            )}
            onClick={handleBackClick}
            size={ButtonSize.NANO}
            theme={ButtonTheme.SOLID_LIGHT}
          >
            <Flex
              align="center"
              className={classNames(conversationHeaderCss.buttonIconWrapper)}
              justify="center"
            >
              <XCloseSVG />
            </Flex>
          </Button>
          <Text fontSize="sm" fontWeight="bold" textColor="primary">
            {getCustomerDisplayName(conversation.customer)}
          </Text>

          {conversation.platform === "email" && (
            <>
              <Text
                color="var(--redo-colors-text-text-placeholder_subtle)"
                fontSize="sm"
                fontWeight="regular"
              >
                |
              </Text>
              <Flex className={conversationHeaderCss.subjectContainer}>
                <TextInput
                  color="tertiary"
                  ellipsis
                  fullwidth
                  hideFocus
                  onChange={setConversationSubject}
                  onFocus={(focused) => {
                    if (!focused) {
                      sinkPromise(handleSetSubject());
                    }
                  }}
                  readonly={!canEditSubject}
                  showBorder={false}
                  size={InputSize.SMALL}
                  value={conversationSubject}
                />
              </Flex>
            </>
          )}
          {(conversation.platform === ConversationPlatform.FACEBOOK_COMMENTS ||
            conversation.platform === ConversationPlatform.FACEBOOK ||
            conversation.platform === ConversationPlatform.INSTAGRAM ||
            conversation.platform ===
              ConversationPlatform.INSTAGRAM_COMMENTS) &&
            conversation?.metaPageName && (
              <Text fontSize="sm" fontWeight="regular">
                | {conversation?.metaPageName}
              </Text>
            )}
        </Flex>
        <Flex align="center" gap="md" justify="flex-end">
          <Text fontSize="sm" textColor="secondary">
            Ticket #{conversation.ticketNumber}
          </Text>
          {actionButtons}

          <Button
            className={conversationHeaderCss.headerButtons}
            onClick={() => {
              handleSetRightPanelOpen(!rightPanelOpen);
            }}
            size={ButtonSize.NANO}
            theme={ButtonTheme.OUTLINED}
          >
            <Flex
              align="center"
              className={conversationHeaderCss.buttonIconWrapper}
              justify="center"
            >
              {rightPanelOpen ? <AlignRight01Svg /> : <AlignLeft01Svg />}
            </Flex>
          </Button>
        </Flex>
      </Flex>
      {shouldShowSubheader && (
        <Flex grow={1} justify="space-between" pl="2xl" pr="2xl">
          <Flex>
            {shouldShowSubheaderBecauseThereAreOtherUsersViewing && (
              <ActiveUsers conversation={conversation} maxAvatars={4} />
            )}
          </Flex>

          <Flex align="center" gap="md" justify="flex-end">
            {shouldShowSubheaderBecauseWeHaveProximateConversations && (
              <>
                {totalConversations > 1 && (
                  <Flex align="center" gap="none">
                    <Text fontSize="xs" fontWeight="bold">
                      {`${totalConversations}`}
                    </Text>
                    <Text fontSize="xs">
                      {` conversation${totalConversations === 1 ? "" : "s"}`}
                    </Text>
                  </Flex>
                )}

                <Tooltip
                  title={hotkeyTooltip({
                    action: "Navigate",
                    code: "J",
                    name: "up",
                    isMac,
                    modifierKey: "alt",
                  })}
                >
                  <span>
                    <RedoButton
                      disabled={shouldDisableNavigateUp}
                      hierarchy={RedoButtonHierarchy.SECONDARY}
                      IconLeading={() => <ChevronUpSVG />}
                      onClick={handleNavigateUp}
                    />
                  </span>
                </Tooltip>
                <Tooltip
                  title={hotkeyTooltip({
                    action: "Navigate",
                    code: "K",
                    name: "down",
                    isMac,
                    modifierKey: "alt",
                  })}
                >
                  <span>
                    <RedoButton
                      disabled={shouldDisableNavigateDown}
                      hierarchy={RedoButtonHierarchy.SECONDARY}
                      IconLeading={() => <ChevronDownSVG />}
                      onClick={handleNavigateDown}
                    />
                  </span>
                </Tooltip>
              </>
            )}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
});
