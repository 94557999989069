import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import { ExpandedConversation } from "@redotech/redo-model/conversation";
import { GetUser as User } from "@redotech/redo-model/user";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { Modal, ModalSize } from "@redotech/redo-web/modal";
import { ProgressBar } from "@redotech/redo-web/progress-bar";
import { memo, useEffect, useState } from "react";
import {
  bulkAssignConversations,
  updateConversation,
} from "../../client/conversations";
import { AssigneePicker } from "./../conversation-detail-panel/assignee-picker";
import { getNextBatch } from "./../utils";
import * as assignTicketModalCss from "./assign-ticket-modal.module.css";

export const AssignTicketModal = memo(function AssignTicketModal({
  open,
  setOpen,
  conversations,
  shouldAssignToMeImmediately,
  setShouldAssignToMeImmediately,
  cleanupFn,
  selectAllInfo,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  conversations: ExpandedConversation[];
  shouldAssignToMeImmediately: boolean;
  setShouldAssignToMeImmediately: (val: boolean) => void;
  cleanupFn?: (clearSelected?: boolean) => void;
  selectAllInfo?: {
    count: number;
    deselectedConversations: ExpandedConversation[];
    bulkActionIterator: AsyncIterator<{
      conversations: ExpandedConversation[];
    }>;
    setBlockRefresh: (val: boolean) => void;
  };
}) {
  const user = useRequiredContext(UserContext);
  const client = useRequiredContext(RedoMerchantClientContext);
  const team = useRequiredContext(TeamContext);
  const [assigningSelectAll, setAssigningSelectAll] = useState(false);
  const [numDone, setNumDone] = useState(0);
  const defaultAssignee = shouldAssignToMeImmediately
    ? user
    : conversations.length === 1 ||
        (conversations.length > 1 &&
          conversations.every(
            (conversation) =>
              conversation.assignee?._id === conversations[0].assignee?._id,
          ))
      ? conversations[0].assignee
      : undefined;
  const [assignee, setAssignee] = useState<User | null>(
    defaultAssignee ?? null,
  );

  const handleAssignTickets = async () => {
    if (conversations.length === 1) {
      await updateConversation(client, conversations[0], {
        assignee: assignee?._id || null,
      });
      amplitude.logEvent("update-conversationAssignee", {
        mode: "single",
        conversationIds: [conversations[0]._id],
        channels: [conversations[0].platform],
        assignee: assignee?._id,
      });
    } else if (conversations.length > 1) {
      const conversationIds = conversations
        .filter(
          (conversation) =>
            (conversation.assignee?._id || null) !== (assignee?._id || null),
        )
        .map((conversation) => conversation._id);
      if (conversationIds.length > 0) {
        await bulkAssignConversations(client, {
          conversationIds,
          assignee: assignee?._id || null,
        });
        const channels = [
          ...new Set(
            conversations.map((conversation) => conversation.platform),
          ),
        ];
        amplitude.logEvent("update-conversationAssignee", {
          mode: "multiple",
          conversationIds,
          channels,
          assignee: assignee?._id,
        });
      }
    }
    cleanupFn && cleanupFn(true);
    setOpen(false);
  };

  const assignBatch = async (
    assignee: User | null,
  ): Promise<{ batchSize: number; done: boolean }> => {
    if (!selectAllInfo) return { batchSize: 0, done: true };
    const { done, value } = await getNextBatch(
      selectAllInfo.bulkActionIterator,
    );
    if (done) {
      return { batchSize: 0, done };
    }
    const conversationIds = value.conversations
      .filter(
        (conversation) =>
          (conversation.assignee?._id || null) !== (assignee?._id || null) && // TODO
          !selectAllInfo.deselectedConversations.some(
            (deselectedConversation) =>
              deselectedConversation._id === conversation._id,
          ),
      )
      .map((conversation) => conversation._id);
    if (conversationIds.length > 0) {
      await bulkAssignConversations(client, {
        conversationIds,
        assignee: assignee?._id || null,
      });
    }
    return { batchSize: value.conversations.length, done: false };
  };

  // This function synchronously assigns each page of conversations.
  // If speed becomes an issue, we could asynchronously assign each page.
  const handleAssignSelectAll = async () => {
    if (!selectAllInfo) return;
    setAssigningSelectAll(true);
    selectAllInfo.setBlockRefresh(true);
    let done = false;
    do {
      const result = await assignBatch(assignee);
      setNumDone((prevNumDone) => prevNumDone + result.batchSize);
      done = result.done;
    } while (!done);
    amplitude.logEvent("update-conversationAssignee", { mode: "all" });
    setAssigningSelectAll(false);
    selectAllInfo.setBlockRefresh(false);
    cleanupFn && cleanupFn(true);
    setOpen(false);
  };

  useEffect(() => {
    if (shouldAssignToMeImmediately) {
      (async () => {
        selectAllInfo
          ? await handleAssignSelectAll()
          : await handleAssignTickets();
        setShouldAssignToMeImmediately(false);
      })();
    }
  }, [shouldAssignToMeImmediately]);

  const plural = conversations.length > 1 || selectAllInfo;

  const footer = (
    <div className={assignTicketModalCss.actionButtonsContainer}>
      <Button onClick={() => setOpen(false)} theme={ButtonTheme.OUTLINED}>
        Cancel
      </Button>
      <Button
        disabled={(assignee?._id || null) === defaultAssignee?._id}
        onClick={async () => {
          selectAllInfo
            ? await handleAssignSelectAll()
            : await handleAssignTickets();
        }}
        pending={shouldAssignToMeImmediately}
        theme={ButtonTheme.PRIMARY}
      >
        {assignee === null ? "Unassign" : "Assign"}
      </Button>
    </div>
  );

  return (
    <Modal
      footer={assigningSelectAll ? undefined : footer}
      onClose={() => {
        cleanupFn && cleanupFn();
        setOpen(false);
      }}
      open={open}
      showHeaderBorder={false}
      size={ModalSize.SMALL}
      title={
        assigningSelectAll
          ? "Assigning tickets"
          : `Assign ${
              selectAllInfo
                ? "all selected "
                : plural
                  ? conversations.length + " "
                  : ""
            }ticket${plural ? "s" : ""}?`
      }
    >
      {assigningSelectAll ? (
        <ProgressBar
          value={(numDone / (selectAllInfo?.count || numDone)) * 100}
        />
      ) : (
        <LabeledInput label="Select assignee">
          <AssigneePicker
            assignee={assignee}
            onChange={setAssignee}
            users={team.users}
          />
        </LabeledInput>
      )}
    </Modal>
  );
});
