const oneLbsInGrams = 453.59237;
const oneKgInGrams = 1000;
const oneOunceInGrams = 28.3495;

export const ouncesToGrams = (ounces: number): number => {
  return ounces / 0.035274;
};

export const poundsToGrams = (pounds: number): number => {
  return pounds / 0.0022046;
};

export const kilogramsToGrams = (kilograms: number): number => {
  return kilograms * 1000;
};

export function stringToWeightUnit(weightUnit: string): WeightUnit {
  return WeightUnit[weightUnit as keyof typeof WeightUnit];
}

/**
 * This is not exactly precise since it converts to grams first and then to the desired unit. Use at your own risk.

 */
export function convertWeight(
  weight: number,
  from: WeightUnit,
  to: WeightUnit,
): number {
  if (from === to) {
    return weight;
  }

  let weightInGrams = weight;
  //First convert the weight to grams
  switch (from) {
    case WeightUnit.GRAMS:
      break;
    case WeightUnit.KILOGRAMS:
      weightInGrams *= oneKgInGrams;
      break;
    case WeightUnit.OUNCES:
      weightInGrams *= oneOunceInGrams;
      break;
    case WeightUnit.POUNDS:
      weightInGrams *= oneLbsInGrams;
      break;
    default:
      break;
  }

  switch (to) {
    case WeightUnit.GRAMS:
      return weightInGrams;
    case WeightUnit.KILOGRAMS:
      return weightInGrams / oneKgInGrams;
    case WeightUnit.OUNCES:
      return weightInGrams / oneOunceInGrams;
    case WeightUnit.POUNDS:
      return weightInGrams / oneLbsInGrams;
    default:
      return weightInGrams;
  }
}
export const convertShopifyWeightToGrams = (
  weight: number,
  weightUnit: string,
): number => {
  switch (weightUnit) {
    case "GRAMS":
      return weight;
    case "KILOGRAMS":
      return kilogramsToGrams(weight);
    case "OUNCES":
      return ouncesToGrams(weight);
    case "POUNDS":
      return poundsToGrams(weight);
    default:
      return weight;
  }
};

export namespace WeightUnit {
  export const GRAMS = "g";
  export const KILOGRAMS = "kg";
  export const OUNCES = "oz";
  export const POUNDS = "lb";
}

export type WeightUnit =
  | typeof WeightUnit.GRAMS
  | typeof WeightUnit.KILOGRAMS
  | typeof WeightUnit.OUNCES
  | typeof WeightUnit.POUNDS;
