import { useLoad } from "@redotech/react-util/load";
import { StringFilterOperator } from "@redotech/redo-model/views/advanced-filters/string-filter";
import { memo, useEffect, useState } from "react";
import {
  RedoFilterDropdownAnchor,
  RedoFilterGroup,
} from "../../arbiter-components/filter-group/redo-filter-group";
import { RedoInputState } from "../../arbiter-components/input/base-redo-text-input";
import { RedoTextInput } from "../../arbiter-components/input/redo-text-input";
import { RedoListItem } from "../../arbiter-components/list/redo-list";
import { RedoListItemSize } from "../../arbiter-components/list/redo-list-item";
import { RedoSingleSelectDropdown } from "../../arbiter-components/select-dropdown/redo-single-select-dropdown";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { variableToSemanticDisplay } from "../../utils/display-code-variables";
import { AdvancedTableFilter, StringTableFilter } from "../advanced-filter";
import * as styles from "./filter.module.css";

export const StringFilterGroup = memo(function StringFilterGroup({
  filter,
  setFilter,
  removeFilter,
  openOnRender,
  filters,
}: {
  filter: StringTableFilter;
  setFilter(filter: StringTableFilter): void;
  removeFilter(): void;
  openOnRender: boolean;
  filters: AdvancedTableFilter[];
}) {
  const [operatorRef, setOperatorRef] = useState<HTMLButtonElement | null>(
    null,
  );
  const [valueRef, setValueRef] = useState<HTMLButtonElement | null>(null);

  const { name, value, operator } = filter.data;

  function getStringValue(item: any): string {
    return filter.stringify && item != null
      ? filter.stringify(item)
      : item != null
        ? item.toString()
        : "";
  }

  function getDisplayString(item: any): string {
    return filter.display && item
      ? filter.display(item)
      : item
        ? item.toString()
        : "";
  }

  const valueOptions = useLoad(
    async (signal) => {
      if (filter.valueFetcher) {
        return await filter.valueFetcher(
          {
            filters: filters.filter(
              (otherFilter) => filter.data.name !== otherFilter.data.name,
            ),
          },
          signal,
        );
      }
      return filter.values ?? [];
    },
    [filter],
  );

  const options = valueOptions.value || [];

  useEffect(() => {
    if (openOnRender && valueRef) {
      valueRef.click();
    }
  }, [openOnRender, valueRef]);

  const operatorAnchor = (
    <RedoFilterDropdownAnchor
      color="secondary"
      ref={setOperatorRef}
      text={stringTypeToText[operator]}
    />
  );

  const operatorDropdown = (
    <RedoSingleSelectDropdown
      dropdownButtonRef={operatorRef}
      options={
        filter.operators && filter.operators.length > 0
          ? stringDropdownItems.filter((item) =>
              filter?.operators?.includes(item.value),
            )
          : stringDropdownItems
      }
      optionSelected={(operator: RedoListItem<StringFilterOperator>) => {
        setFilter({
          ...filter,
          data: { ...filter.data, operator: operator.value },
        });
      }}
      selectedItem={stringDropdownItems.find((item) => item.value === operator)}
      size={RedoListItemSize.SMALL}
    >
      {(item) => (
        <Text
          fontSize="sm"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {stringTypeToText[item.value]}
        </Text>
      )}
    </RedoSingleSelectDropdown>
  );

  const valueText = value == null ? "..." : getDisplayString(value);

  const valueInput = (
    <RedoTextInput
      className={styles.stringInput}
      fullWidth={false}
      placeholder={valueText}
      setValue={(value) => {
        setFilter({
          ...filter,
          data: { ...filter.data, value: getStringValue(value) },
        });
      }}
      state={RedoInputState.DEFAULT}
      value={getStringValue(value)}
    />
  );

  const valueDropdownAnchor = (
    <RedoFilterDropdownAnchor
      color="secondary"
      ref={setValueRef}
      text={valueText}
      tooltip={
        value && filter.ItemRenderer ? (
          filter.ItemRenderer({ item: value })
        ) : value ? (
          <Flex dir="column">
            <Text>{getDisplayString(value)}</Text>
          </Flex>
        ) : undefined
      }
    />
  );

  const itemListOptions: RedoListItem<any>[] = (options || []).map((option) => {
    return { value: getStringValue(option) };
  });

  const valueDropdown = (
    <RedoSingleSelectDropdown
      dropdownButtonRef={valueRef}
      options={itemListOptions}
      optionSelected={(option) =>
        setFilter({
          ...filter,
          data: { ...filter.data, value: getStringValue(option.value) },
        })
      }
      selectedItem={value !== null ? { value } : undefined}
      size={RedoListItemSize.SMALL}
    >
      {(item) =>
        filter.ItemRenderer ? (
          filter.ItemRenderer({ item: item.value ?? null })
        ) : (
          <Text
            fontSize="sm"
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {item.value ? getDisplayString(item.value) : "..."}
          </Text>
        )
      }
    </RedoSingleSelectDropdown>
  );

  return (
    <>
      {operatorDropdown}
      {inputOperators.includes(operator) ? undefined : valueDropdown}
      <RedoFilterGroup
        Icon={filter.Icon}
        propertyName={variableToSemanticDisplay(name) ?? name}
        query={operatorAnchor}
        removeFilter={removeFilter}
        value={
          inputOperators.includes(operator) ? valueInput : valueDropdownAnchor
        }
      />
    </>
  );
});

const stringTypeToText: Record<StringFilterOperator, string> = {
  [StringFilterOperator.EQUALS]: "is",
  [StringFilterOperator.NOT_EQUALS]: "is not",
  [StringFilterOperator.CONTAINS]: "contains",
};

const stringDropdownItems: RedoListItem<StringFilterOperator>[] = [
  { value: StringFilterOperator.EQUALS },
  { value: StringFilterOperator.NOT_EQUALS },
  { value: StringFilterOperator.CONTAINS },
];

const inputOperators = [StringFilterOperator.CONTAINS];
