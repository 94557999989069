import { genericMemo } from "@redotech/react-util/component";
import { useHandler } from "@redotech/react-util/hook";
import { Dispatch, ReactNode, SetStateAction, useMemo } from "react";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { RedoList, RedoListItem as RedoListItemType } from "./redo-list";
import {
  RedoListItem,
  RedoListItemSize,
  RedoListItemVariant,
} from "./redo-list-item";

export const RedoMultiselectList = genericMemo(function RedoMultiselectList<T>({
  refToListenTo,
  options,
  setSelectedOptions,
  children,
  selectedOptions,
  keyFn,
  size,
  selectionVariant,
  loading,
  focusedIndex,
  setFocusedIndex,
}: {
  refToListenTo: HTMLElement | null;
  focusedIndex: number | undefined;
  setFocusedIndex: Dispatch<SetStateAction<number | undefined>>;
  options: RedoListItemType<T>[];
  setSelectedOptions(value: RedoListItemType<T>[]): void;
  children(item: RedoListItemType<T>): ReactNode;
  selectedOptions: RedoListItemType<T>[];
  keyFn?: (item: RedoListItemType<T>) => string | number;
  size?: RedoListItemSize;
  selectionVariant?: RedoListItemVariant;
  loading?: boolean;
}) {
  function itemsEqual(item1: RedoListItemType<T>, item2: RedoListItemType<T>) {
    if (keyFn) {
      return keyFn(item1) === keyFn(item2);
    }
    return item1.value === item2.value;
  }

  const isOptionSelected = useHandler((item: RedoListItemType<T>) => {
    return selectedOptions.some((selectedItem) =>
      itemsEqual(item, selectedItem),
    );
  });

  function handleItemToggled(item: RedoListItemType<T>) {
    const newSelectedOptions = isOptionSelected(item)
      ? selectedOptions.filter(
          (selectedItem) => !itemsEqual(selectedItem, item),
        )
      : [...selectedOptions, item];
    setSelectedOptions(newSelectedOptions);
  }

  const selectAllState = useMemo(() => {
    if (options.every((option) => isOptionSelected(option))) {
      return true;
    } else if (options.some((option) => isOptionSelected(option))) {
      return "indeterminate";
    }
    return false;
  }, [options, isOptionSelected]);

  const handleToggleAll = useHandler(() => {
    if (selectAllState === false) {
      setSelectedOptions([...options]);
      return;
    }
    setSelectedOptions([]);
  });

  return (
    <Flex
      dir="column"
      gap="none"
      key={`${selectionVariant}-${options}-${size}`}
    >
      {selectionVariant === RedoListItemVariant.CHECKBOX && (
        <Flex
          borderBottomWidth="1px"
          borderColor="secondary"
          borderStyle="solid"
          px="md"
          py="sm"
        >
          <RedoListItem
            focusable={false}
            focused={false}
            itemClicked={handleToggleAll}
            menu={undefined}
            onItemHovered={() => {}}
            selected={selectAllState}
            selectionVariant={selectionVariant}
            size={size || RedoListItemSize.SMALL}
          >
            <Text>Select all</Text>
          </RedoListItem>
        </Flex>
      )}
      <RedoList
        focusedIndex={focusedIndex}
        gap="sm"
        isItemSelected={isOptionSelected}
        items={options}
        itemSelected={handleItemToggled}
        itemsLoading={loading}
        keyFn={keyFn}
        px="md"
        py="sm"
        refToListenTo={refToListenTo}
        selectionVariant={selectionVariant}
        setFocusedIndex={setFocusedIndex}
        size={size}
      >
        {(item) => children(item)}
      </RedoList>
    </Flex>
  );
});
