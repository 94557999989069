import { AuthContext } from "@redotech/redo-merchant-app-common/auth";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import {
  Breadcrumb,
  BreadcrumbOverriders,
  BreadcrumbOverridersContext,
  useBreadcrumbs,
} from "@redotech/redo-web/breadcrumb";
import {
  createContext,
  Dispatch,
  memo,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { useDocumentTitle } from "usehooks-ts";
import { FailedAlert } from "./failed-alert";
import { GlobalSearchProvider } from "./global-search/global-search-context";
import { SearchModelProvider } from "./global-search/search-model-context";
import { SettingNavigationProvider } from "./global-search/setting-search/setting-navigator-context";
import { Onboarding } from "./onboarding";
import { OrderSyncProgress } from "./order-sync-progress";
import { getAllMerchantAppSettings } from "./setting/settings";

type DocumentTitleOverriders = Map<
  string,
  (breadcrumb: Breadcrumb[]) => string
>;
export const DocumentTitleOverridersContext = createContext<
  [DocumentTitleOverriders, Dispatch<SetStateAction<DocumentTitleOverriders>>]
>([new Map(), () => {}]);

const RouterRootInner = memo(function RouterRootInner() {
  const intercom = useIntercom();
  const navigate = useNavigate();
  const { teamId } = useParams();
  const url = useLocation();
  const user = useContext(UserContext);
  const team = useContext(TeamContext);

  const auth = useContext(AuthContext);
  if (auth && auth.teamId && !teamId) {
    const prefix = url.pathname.startsWith("/")
      ? `/stores/${auth.teamId}`
      : `/stores/${auth.teamId}/`;
    navigate(prefix + url.pathname, { replace: true });
  }
  const breadcrumbs = useBreadcrumbs();

  const [documentTitleOverriders] = useContext(DocumentTitleOverridersContext);
  const title = useMemo(() => {
    if (documentTitleOverriders && documentTitleOverriders.size > 0) {
      const firstOverrider = Array.from(documentTitleOverriders.values())[0];
      return firstOverrider(breadcrumbs || []);
    }
    return breadcrumbs
      ? `${breadcrumbs.map((breadcrumb) => breadcrumb.name).join(" ")} — Redo`
      : "Redo";
  }, [breadcrumbs, documentTitleOverriders]);

  useDocumentTitle(title);

  useEffect(() => {
    // TODO(paul): more robust way to exclude Redo users
    if (!user || !team || user.email.endsWith("@getredo.com")) {
      return;
    }
    intercom.update({
      company: {
        companyId: team._id,
        name: team.name,
        createdAt: team.createdAt,
        website: `https://${team.storeUrl}/`,
      },
      createdAt: user.createdAt,
      email: user.email,
      name: user.name,
      userId: user._id,
    });
  }, [user, team]);

  return (
    <>
      <Onboarding />
      <OrderSyncProgress />
      <FailedAlert />
      <SearchModelProvider>
        <GlobalSearchProvider settings={getAllMerchantAppSettings()}>
          <SettingNavigationProvider settings={getAllMerchantAppSettings()}>
            <Outlet />
          </SettingNavigationProvider>
        </GlobalSearchProvider>
      </SearchModelProvider>
    </>
  );
});

export const RouterRoot = memo(function RouterRoot() {
  const [breadcrumbOverriders, setBreadcrumbOverriders] =
    useState<BreadcrumbOverriders>(new Map());
  const [documentTitleOverriders, setDocumentTitleOverriders] =
    useState<DocumentTitleOverriders>(new Map());
  return (
    <BreadcrumbOverridersContext.Provider
      value={[breadcrumbOverriders, setBreadcrumbOverriders]}
    >
      <DocumentTitleOverridersContext.Provider
        value={[documentTitleOverriders, setDocumentTitleOverriders]}
      >
        <RouterRootInner />
      </DocumentTitleOverridersContext.Provider>
    </BreadcrumbOverridersContext.Provider>
  );
});
