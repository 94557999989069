import { assertNever } from "@redotech/util/type";
import * as classnames from "classnames";
import { memo } from "react";
import { Card } from "../../card";
import { Flex } from "../../flex";
import { Text } from "../../text";
import { RedoRadioButton } from "../radio/redo-radio-button";
import { RedoCheckbox, RedoCheckboxSize } from "./redo-checkbox";
import * as css from "./redo-checkbox-card.module.css";

export const checkboxCardInputTypes = ["radio", "checkbox"] as const;
export type CheckboxCardInputType = (typeof checkboxCardInputTypes)[number];

export enum Layout {
  SHORT = "short",
  TALL = "tall",
}

type InputSize = "xs" | "sm" | "md";

export interface RedoCheckboxCardProps {
  title: React.ReactNode;
  children?: React.ReactNode;
  icon?: React.ReactNode;
  checked: boolean;
  onClick: () => void;
  borderColor?: string;
  inputType?: CheckboxCardInputType;
  inputSize?: InputSize;
  layout?: Layout;
}

export const RedoCheckboxCard = memo(function RedoCheckboxCardProps({
  title,
  icon,
  checked,
  onClick,
  inputType,
  inputSize = "md",
  layout = Layout.SHORT,
  children,
}: RedoCheckboxCardProps) {
  const titleText =
    typeof title === "string" ? (
      <Text fontSize="md" fontWeight="bold">
        {title}
      </Text>
    ) : (
      title
    );
  const childrenText =
    typeof children === "string" ? (
      <Text color="secondary" fontSize="sm">
        {children}
      </Text>
    ) : (
      children
    );

  return (
    <Card
      className={classnames(css.checkboxCard, {
        [css.checkboxCardSelected]: checked,
      })}
      onClick={onClick}
    >
      <Flex
        flexDirection={layout === Layout.SHORT ? "row" : "column"}
        gap={layout === Layout.SHORT ? "lg" : "3xl"}
      >
        <Flex align="flex-start" justify="space-between">
          {icon && <div className={css.cardIcon}>{icon}</div>}
          {layout === Layout.TALL && (
            <CardInput
              checked={checked}
              inputSize={inputSize}
              inputType={inputType}
            />
          )}
        </Flex>
        <Flex align="flex-start" dir="column" flex={1} gap="xs">
          {titleText}
          {childrenText}
        </Flex>

        {layout === Layout.SHORT && (
          <CardInput
            checked={checked}
            inputSize={inputSize}
            inputType={inputType}
          />
        )}
      </Flex>
    </Card>
  );
});

const CardInput = memo(function CardInput({
  checked,
  inputType,
  inputSize,
}: {
  checked: boolean;
  inputType: CheckboxCardInputType | undefined;
  inputSize: InputSize;
}) {
  if (!inputType) {
    return null;
  }
  if (inputType === "checkbox") {
    return (
      <span style={{ pointerEvents: "none" }}>
        <RedoCheckbox
          setValue={() => {}}
          size={inputSizeToCheckboxSize[inputSize]}
          value={checked}
        />
      </span>
    );
  }
  if (inputType === "radio") {
    return (
      <span style={{ pointerEvents: "none" }}>
        <RedoRadioButton
          onSelect={() => {}}
          selected={checked}
          size={inputSize}
        />
      </span>
    );
  }
  assertNever(inputType);
});

const inputSizeToCheckboxSize: Record<InputSize, RedoCheckboxSize> = {
  xs: RedoCheckboxSize.EXTRA_SMALL,
  md: RedoCheckboxSize.MEDIUM,
  sm: RedoCheckboxSize.SMALL,
};
