import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { RedoMerchantRpcClientContext } from "@redotech/redo-merchant-app-common/rpc-client";
import {
  ConversationTagWithId,
  ExpandedConversation,
  getConversationStatus,
} from "@redotech/redo-model/conversation";
import { UpdateConversationBody } from "@redotech/redo-model/updateconversationbody";
import { GetUser as User } from "@redotech/redo-model/user";
import { alertOnFailure } from "@redotech/redo-web/alert";
import {
  RedoBadge,
  RedoBadgeColor,
  RedoBadgeSize,
} from "@redotech/redo-web/arbiter-components/badge/redo-badge";
import ArrowUpRightSvg from "@redotech/redo-web/arbiter-icon/arrow-up-right_filled.svg";
import { FiveStarRating } from "@redotech/redo-web/five-star-rating";
import { Flex } from "@redotech/redo-web/flex";
import { ImageLightbox } from "@redotech/redo-web/image-lightbox";
import { ExternalLink } from "@redotech/redo-web/link";
import { RedoSupportChannelBadge } from "@redotech/redo-web/support/redo-support-channel-badge";
import { RedoSupportStatusBadge } from "@redotech/redo-web/support/redo-support-status-badge";
import { Text } from "@redotech/redo-web/text";
import { memo, useEffect, useState } from "react";
import { updateConversation } from "../../client/conversations";
import { ConversationTagInput } from "../conversation-tags/conversation-tag-input";
import { GenerateAiSummaryButton } from "../generate-ai-summary-button";
import { AssigneeDetails } from "./assignee-details";
import * as conversationDetailsPanelCss from "./conversation-details-panel.module.css";

export const ConversationDetailsOverview = memo(
  function ConversationDetailsOverview({
    conversation,
    setConversationAssignee,
  }: {
    conversation: ExpandedConversation;
    setConversationAssignee: (assignee: any) => void;
  }) {
    const client = useRequiredContext(RedoMerchantClientContext);

    const [imageLightboxOpen, setImageLightboxOpen] = useState(false);
    const [conversationTagsToShow, setConversationTagsToShow] = useState<
      ConversationTagWithId[]
    >([]);

    useEffect(() => {
      setConversationTagsToShow(conversation.tagIds || []);
    }, [conversation]);

    async function updateConversationTags(
      updatedTags: ConversationTagWithId[],
    ) {
      setConversationTagsToShow(updatedTags);

      const body: UpdateConversationBody = { tags: updatedTags };

      await updateConversation(client, conversation, body);
    }

    const handleUpdateConversationDetails = async ({
      assignee,
    }: {
      assignee: User | null;
    }) => {
      amplitude.logEvent("update-conversationAssignee", {
        mode: "single",
        conversationIds: [conversation._id],
        channels: [conversation.platform],
        assignee: assignee?._id,
      });
      await alertOnFailure("Unable to change assignee")(async () => {
        await updateConversation(client, conversation, {
          assignee: assignee ? assignee._id : null,
        });
        setConversationAssignee(assignee);
      });
    };

    return (
      <Flex dir="column">
        <Flex dir="column" gap="xl">
          <GenerateAiSummaryButton conversation={conversation} />
          <CsatScore conversation={conversation} />
          <Flex gap="md" wrap="wrap">
            <RedoSupportChannelBadge
              platform={conversation.platform}
              size={RedoBadgeSize.X_SMALL}
            />
            <RedoSupportStatusBadge
              size={RedoBadgeSize.X_SMALL}
              snoozedUntil={conversation.snoozedUntil}
              status={getConversationStatus(conversation)}
            />
          </Flex>
          {conversation.instagramCommentThread && (
            <Flex>
              <Flex>
                {conversation.instagramCommentThread?.mediaPreviewUrl && (
                  <>
                    <div
                      className={conversationDetailsPanelCss.postPreview}
                      onClick={() => setImageLightboxOpen(true)}
                    >
                      <img
                        alt="Instagram post"
                        src={
                          conversation.instagramCommentThread.mediaPreviewUrl
                        }
                      />
                    </div>
                    <ImageLightbox
                      imageSrc={
                        conversation.instagramCommentThread.mediaPreviewUrl
                      }
                      onClose={() => setImageLightboxOpen(false)}
                      open={imageLightboxOpen}
                    />
                  </>
                )}
              </Flex>
              <Flex
                className={conversationDetailsPanelCss.wrappingText}
                dir="column"
              >
                <ExternalLink
                  newTab
                  showIcon={false}
                  url={
                    conversation.instagramCommentThread?.permalink ?? undefined
                  }
                >
                  <RedoBadge
                    color={RedoBadgeColor.GRAY}
                    iconTrailing={{ type: "icon", Icon: ArrowUpRightSvg }}
                    size={RedoBadgeSize.X_SMALL}
                    text="See post"
                  />
                </ExternalLink>
              </Flex>
            </Flex>
          )}
          {conversation.facebookCommentThread && (
            <ExternalLink
              newTab
              showIcon={false}
              url={conversation.facebookCommentThread?.permalink ?? undefined}
            >
              <RedoBadge
                color={RedoBadgeColor.GRAY}
                iconTrailing={{ type: "icon", Icon: ArrowUpRightSvg }}
                size={RedoBadgeSize.X_SMALL}
                text="See post"
              />
            </ExternalLink>
          )}
          <Flex gap="md" wrap="wrap">
            <ConversationTagInput
              currentTags={conversationTagsToShow}
              setCurrentTags={(tags) => updateConversationTags(tags)}
              showAddButtonToEnterInput
              showBorder={false}
            />
          </Flex>
        </Flex>

        <Flex dir="column">
          <AssigneeDetails
            conversation={conversation}
            updateConversationDetails={handleUpdateConversationDetails}
          />
        </Flex>
      </Flex>
    );
  },
);

const CsatScore = memo(function CsatScore({
  conversation,
}: {
  conversation: ExpandedConversation;
}) {
  const redoMerchantRpcClient = useRequiredContext(
    RedoMerchantRpcClientContext,
  );

  const csatScore = useLoad(async () => {
    const result = await redoMerchantRpcClient.getCsatResponsesForConversation({
      conversationId: conversation._id,
    });
    if (result.length === 0) {
      return undefined;
    }
    const scores = result.map((response) => response.rating);
    const averageScore = scores.reduce((a, b) => a + b, 0) / scores.length;
    return averageScore;
  }, [conversation._id]);

  if (csatScore.value !== undefined) {
    return (
      <Flex dir="column" gap="xs">
        <Text fontSize="xs" fontWeight="medium" textColor="secondary">
          CSAT
        </Text>
        <FiveStarRating
          className={conversationDetailsPanelCss.csatScore}
          rating={csatScore.value}
          uniqueId={`${conversation._id}-details`}
        />
      </Flex>
    );
  } else {
    return null;
  }
});
