import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {
  useLazyContext,
  useRequiredContext,
} from "@redotech/react-util/context";
import { useInput } from "@redotech/react-util/form";
import { useLoad, useTriggerLoad } from "@redotech/react-util/load";
import { uploadFile } from "@redotech/redo-api-client/conversations";
import { getCustomerSearch } from "@redotech/redo-merchant-app-common/client/shopify";
import {
  Attachment,
  CreateConversationBody,
} from "@redotech/redo-model/create-conversation-body";
import {
  Macro,
  MacroAutomationsData,
  isAtLeastOneMacroAutomationActive,
} from "@redotech/redo-model/macro";
import { Autocomplete } from "@redotech/redo-web/autocomplete";
import { Button, ButtonTheme } from "@redotech/redo-web/button";
import PaperclipIcon from "@redotech/redo-web/icon-old/paperclip.svg";
import { LabeledInput } from "@redotech/redo-web/labeled-input";
import { Modal, ModalSize, PaddingAmount } from "@redotech/redo-web/modal";
import { QuillEditor } from "@redotech/redo-web/quill/quill-editor";
import { FormSelectDropdown } from "@redotech/redo-web/select-dropdown";
import { FormTextInput, TextInput } from "@redotech/redo-web/text-input";
import { ValidationError, groupInput, input } from "@redotech/ui/form";
import Quill from "quill";
import { memo, useContext, useEffect, useRef, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { MacroModal } from "./macros/macro-modal";
import * as newMessageModalCss from "./new-message-modal.module.css";
const Delta = Quill.import("delta");
// Styles for quill editor
import * as amplitude from "@amplitude/analytics-browser";
import { emailIsValid } from "@redotech/email";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import {
  ConversationPlatform,
  ExpandedConversation,
  MessageVisibility,
} from "@redotech/redo-model/conversation";
import {
  getPrimaryCustomerEmail,
  getPrimaryCustomerPhoneNumber,
} from "@redotech/redo-model/customer";
import { ReplyNoDraftBody } from "@redotech/redo-model/reply-no-draft-body";
import { conversationFileUploadErrorMessages } from "@redotech/redo-model/support/conversations/conversation-file-upload-error";
import { EmailReplyType } from "@redotech/redo-model/support/conversations/email-info";
import { alertOnFailure, toast } from "@redotech/redo-web/alert";
import { CollapseSubsection } from "@redotech/redo-web/card";
import { ChipDelimiter, ChipInput } from "@redotech/redo-web/chip-input";
import { RedoClientContext } from "@redotech/redo-web/client";
import { Divider } from "@redotech/redo-web/divider";
import { Flex } from "@redotech/redo-web/flex";
import { QuillAttachmentCarousel } from "@redotech/redo-web/quill/quill-attachment-carousel";
import { QuillToolbarOptions } from "@redotech/redo-web/quill/quill-toolbar-options";
import { QuillToolbarUploadFile } from "@redotech/redo-web/quill/quill-toolbar-upload-file";
import { Text } from "@redotech/redo-web/text";
import { unique } from "@redotech/util/array";
import "quill/dist/quill.snow.css";
import { createConversation, replyNoDraft } from "../client/conversations";
import { getCustomer } from "../client/customer";
import { EmailIntegrationsContext } from "../services/support/email-integrations";
import { MacrosContext } from "../services/support/macros-service";
import { materializeMacroIntoPendingAutomations } from "./macros/macro-automation-utils";
import { MacroAutomationsList } from "./macros/macro-automations-list";
import { performMacroAutomationsAfterSendingMessage } from "./macros/perform-macro-automations";
import { clearFormattingFromMacroAutomationsText } from "./macros/quill-macro-utils";
import { doFileDrop } from "./utils";

const NAME_EMAIL_DELIMITER = " - ";

function getEmails(emails: string): string[] {
  return emails.split(",").filter((email) => email.length > 0);
}

const conversationForm = groupInput({
  channel: input<ConversationPlatform>(),
  email: input<string | null>(),
  ccEmails: input<string>({
    validator: (value: string) => {
      const errors: ValidationError[] = [];
      const emails = value ? getEmails(value) : [];
      for (const email of emails) {
        if (!emailIsValid(email)) {
          errors.push("Invalid email");
        }
      }
      if (emails.length > 48) {
        errors.push("Too many emails, max 48");
      }
      return errors;
    },
  }),
  subject: input<string>(),
  message: input<string>(),
  firstName: input<string>(),
  lastName: input<string>(),
  emailSendingAs: input<string>(),
});

export interface ShopifyCustomer {
  email: string;
  firstName: string;
  lastName: string;
}

export const NewMessageModal = memo(function NewMessageModal({
  open,
  setOpen,
  customerInfo,
  handleNavigate,
  showStayOption = false,
  existingConversation,
}: {
  open: boolean;
  setOpen(val: boolean): void;
  customerInfo?: { _id?: string; email?: string };
  handleNavigate?: ({
    conversationId,
    stayOnPage,
  }: {
    conversationId: string;
    stayOnPage?: boolean;
  }) => Promise<void>;
  showStayOption?: boolean;
  existingConversation?: ExpandedConversation;
}) {
  const cursorIndexRef = useRef<number | undefined>(undefined);

  const defaults: {
    channel: ConversationPlatform;
    email: string;
    ccEmails: string;
    subject: string;
    message: string;
    firstName: string;
    lastName: string;
    files: Attachment[];
    emailSendingAs: string;
  } = {
    channel: ConversationPlatform.EMAIL,
    email: "",
    ccEmails: "",
    subject: "",
    message: "",
    firstName: "",
    lastName: "",
    files: [],
    emailSendingAs: "",
  };
  const [customers, setCustomers] = useState<Record<string, ShopifyCustomer>>(
    {},
  );
  const [macroModalOpen, setMacroModalOpen] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const input = useInput(conversationForm, defaults);
  const {
    channel,
    email,
    ccEmails,
    subject,
    message,
    firstName,
    lastName,
    emailSendingAs,
  } = input.inputs;
  const team = useContext(TeamContext);
  const apiClient = useRequiredContext(RedoClientContext);
  const client = useRequiredContext(RedoMerchantClientContext);
  const user = useContext(UserContext);
  const [searchString, setSearchString] = useState<string | undefined>();
  const debouncedSearch = useDebounce(searchString, 500);
  const [customersLoading, setCustomersLoading] = useState(false);
  const [files, setFiles] = useState<Attachment[]>([]);
  const [sendPending, setSendPending] = useState<{ stay: boolean } | undefined>(
    undefined,
  );

  const [macroAutomations, setMacroAutomations] =
    useState<MacroAutomationsData>({});

  const [quill, setQuill] = useState<Quill | null>(null);

  const [ccEmailsInput, setCcEmailsInput] = useState<string[]>(
    getEmails(ccEmails.value),
  );
  const [availableEmails, setAvailableEmails] = useState<string[]>([]);

  const [customersLoad, doCustomersLoad] = useTriggerLoad(async (signal) => {
    if (customerInfo?._id) {
      return;
    }

    // since the search result gets the customer name prepended, split off the name before searching.
    const searchTermPieces = (debouncedSearch || "").split(
      NAME_EMAIL_DELIMITER,
    );
    const searchTerm = searchTermPieces[searchTermPieces.length - 1];
    setCustomersLoading(true);
    const customers = await getCustomerSearch(client, {
      search: searchTerm || "",
      signal,
    });
    setCustomersLoading(false);
    return customers;
  });

  const specifiedCustomerLoad = useLoad(
    async (signal) => {
      if (!customerInfo?._id) {
        return;
      }

      const customer = await getCustomer(client, {
        id: customerInfo._id,
        signal,
      });
      const primaryEmail = getPrimaryCustomerEmail(customer);
      if (!primaryEmail) {
        throw new Error("Customer email not found");
      }
      setCustomers({
        [primaryEmail]: {
          email: primaryEmail,
          firstName: customer.firstName ?? "missing name",
          lastName: customer.lastName ?? "",
        },
      });
      email.setValue(primaryEmail);
      return customer;
    },
    [customerInfo?._id],
  );

  useEffect(() => {
    if (searchString !== undefined) {
      doCustomersLoad();
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (open && customerInfo?.email) {
      setSearchString(customerInfo.email);
    }
  }, [open]);

  const [emailIntegrationsLoad] = useLazyContext(EmailIntegrationsContext);

  useEffect(() => {
    if (
      quill &&
      channel.value === ConversationPlatform.EMAIL &&
      team?.settings.support?.emailSignature
    ) {
      // Add email signature to the end, and create space for new message.
      quill.clipboard.dangerouslyPasteHTML(
        "<br><br><br>" + team.settings.support.emailSignature,
      );
    }
  }, [quill, team, channel.value]);

  useEffect(() => {
    if (!emailIntegrationsLoad.value) {
      return;
    }
    appendEmails(
      [
        ...(emailIntegrationsLoad.value.customIntegrations || []),
        ...(emailIntegrationsLoad.value.gmailIntegrations || []),
        ...(emailIntegrationsLoad.value.outlookIntegrations || []),
      ].map((integration) => integration.email),
    );
    emailSendingAs.setValue(team?.settings.support?.defaultEmail || "");
  }, [emailIntegrationsLoad.value]);

  function pickDefaultEmail(availableEmails: string[]): string {
    // First, check if there's a team default email and it's available
    if (team?.settings?.support?.defaultEmail) {
      const defaultEmail = team.settings.support.defaultEmail;
      if (availableEmails.includes(defaultEmail)) {
        return defaultEmail;
      }
    }

    // Fall back to user's email if available
    const userEmail = user!.email.toLowerCase();
    const emailThatMatchesUsersEmail = availableEmails.find(
      (email) => email.toLowerCase() === userEmail,
    );
    if (emailThatMatchesUsersEmail) {
      return emailThatMatchesUsersEmail;
    }

    // Finally, fall back to the last configured email
    const firstEmailThatWasSetup = availableEmails.at(-1);
    if (firstEmailThatWasSetup) {
      return firstEmailThatWasSetup;
    }
    return "";
  }

  const appendEmails = (emails: string[]) => {
    setAvailableEmails((oldAvailableEmails: string[]) => {
      const updatedAvailableEmails = oldAvailableEmails;
      (emails || []).forEach((email: string) => {
        if (!updatedAvailableEmails.includes(email)) {
          updatedAvailableEmails.push(email);
        }
      });
      return [...updatedAvailableEmails];
    });
    emailSendingAs.setValue(pickDefaultEmail(availableEmails));
  };

  const onCustomerInputChange = (event: any, value: string) => {
    setSearchString(value);
  };

  const [macrosLoad, refreshMacros] = useLazyContext(MacrosContext);

  function addAttachments(attachments: Attachment[]) {
    setFiles((oldFiles) => {
      const uniqueAttachments = unique(
        [...attachments, ...oldFiles],
        (attachment) => attachment.url,
      );
      return uniqueAttachments;
    });
  }

  const handleUpload = async ({
    event,
    file,
  }: {
    event?: any;
    file?: File;
  }) => {
    const fileToUpload = file || event.target?.files?.[0];
    if (!fileToUpload) {
      return;
    }

    const form = new FormData();
    form.append("file", fileToUpload);
    form.append("fileName", fileToUpload.name);
    const response = await uploadFile(apiClient, form);
    if (response.success) {
      const body = response.body;
      addAttachments([body]);
    } else {
      toast(conversationFileUploadErrorMessages[response.error], {
        variant: "error",
      });
    }
  };

  const handleCreateConversation = async ({
    stayOnPage,
  }: {
    stayOnPage: boolean;
  }) => {
    setSendPending({ stay: !!stayOnPage });
    if (!quill) {
      return;
    }

    const body: CreateConversationBody = {
      channel: channel.value,
      email: email.value!,
      ccEmails: getEmails(ccEmails.value),
      subject: subject.value,
      message: quill.getText(),
      htmlBody: quill.getSemanticHTML(),
      firstName: customers[email.value!]?.firstName || firstName.value,
      lastName: customers[email.value!]?.lastName || lastName.value,
      files,
      emailSendingAs: emailSendingAs.value,
    };

    await alertOnFailure("Failed to create ticket")(async () => {
      if (!team) {
        throw new Error("Team not found");
      }
      const createdConversation = await createConversation(client, body);

      await performMacroAutomationsAfterSendingMessage({
        client,
        automations: macroAutomations,
        conversation: createdConversation,
        team,
        shouldSetSubjectOnConversation: false,
        messageToForwardThatsAlreadySent: createdConversation.messages[0],
      });

      if (handleNavigate) {
        await handleNavigate({
          conversationId: createdConversation._id,
          stayOnPage,
        });
      }
      amplitude.logEvent("create-conversation");
      handleClose();
    }).finally(() => setSendPending(undefined));
  };

  const handleNewMessageInConversation = async () => {
    if (!quill || !existingConversation) {
      return;
    }
    setSendPending({ stay: true });
    const primaryPhoneNumber = getPrimaryCustomerPhoneNumber(
      existingConversation.customer,
    );
    const body: ReplyNoDraftBody = {
      subject: subject.value,
      message: quill.getText(),
      email: email.value!,
      htmlBody: quill.getSemanticHTML(),
      emailSendingAs: emailSendingAs.value,
      conversationId: existingConversation._id,
      visibility: MessageVisibility.PUBLIC,
      replyType: EmailReplyType.REPLY,
      attachments: files,
      usersMentioned: [],
      phoneNumber: primaryPhoneNumber,
    };
    await alertOnFailure("Failed to send message")(async () => {
      if (!team) {
        throw new Error("Team not found");
      }
      const updatedConversation = await replyNoDraft(client, body);

      await performMacroAutomationsAfterSendingMessage({
        client,
        automations: macroAutomations,
        conversation: updatedConversation,
        team,
        shouldSetSubjectOnConversation: false,
        messageToForwardThatsAlreadySent:
          updatedConversation.messages[updatedConversation.messages.length - 1],
      });

      amplitude.logEvent("new-message-in-modal");
      handleClose();
    }).finally(() => setSendPending(undefined));
  };

  const handleSubmit = async ({ stayOnPage }: { stayOnPage: boolean }) => {
    if (existingConversation) {
      await handleNewMessageInConversation();
    } else {
      await handleCreateConversation({ stayOnPage: stayOnPage });
    }
  };

  const setMacro = async (macro: Macro) => {
    if (!team) {
      return;
    }
    const [contentWithVariablesReplaced, htmlContentWithVariablesReplaced] =
      await materializeMacroIntoPendingAutomations({
        client,
        team,
        existingPendingAutomations: macroAutomations,
        newMacroToInclude: macro,
        email: email.value || "",
        firstName: customers[email.value || ""]?.firstName || firstName.value,
        lastName: customers[email.value || ""]?.lastName || lastName.value,
        fullName: "",
        agentFirstName: user?.firstName || "",
        agentLastName: user?.lastName || "",
        agentFullName: user?.name || "",
        addAttachments,
        setErrorMessage,
        setShowErrorMessage,
        setMacroAutomations,
        conversationPlatform: channel.value,
        visibilityOfMessage: MessageVisibility.PUBLIC,
      });

    if (macro.emailSubjectToSet) {
      subject.setValue(macro.emailSubjectToSet);
    }
    if (quill) {
      const indexToInsert = cursorIndexRef.current ?? 0;
      if (
        channel.value === ConversationPlatform.EMAIL &&
        htmlContentWithVariablesReplaced
      ) {
        quill.clipboard.dangerouslyPasteHTML(
          indexToInsert,
          htmlContentWithVariablesReplaced,
        );
      } else if (contentWithVariablesReplaced) {
        quill.insertText(indexToInsert, contentWithVariablesReplaced);
      }

      /* Macro variables have a unique purple-on-purple color scheme. This resets the color and background color of the macro variables to the default. */
      clearFormattingFromMacroAutomationsText(quill);
    }
  };

  const handleClose = () => {
    setMacroModalOpen(false);
    setFiles([]);
    setCcEmailsInput([]);
    channel.setValue(ConversationPlatform.EMAIL);
    email.setValue("");
    ccEmails.setValue("");
    subject.setValue("");
    message.setValue("");
    firstName.setValue("");
    lastName.setValue("");
    setOpen(false);
  };

  useEffect(() => {
    if (customersLoad.value?.customers) {
      setCustomers(
        customersLoad.value.customers.reduce(
          (accumulator: Record<string, ShopifyCustomer>, customer) => {
            accumulator[customer.email!] = {
              email: customer.email!,
              firstName: customer.first_name!,
              lastName: customer.last_name!,
            };
            return accumulator;
          },
          {},
        ),
      );

      if (
        !email.value &&
        customerInfo?.email &&
        searchString === customerInfo.email
      ) {
        email.setValue(customerInfo.email);
      }
    }
  }, [customersLoad.value]);

  const getCustomerDisplayNameForEmail = (email?: string) => {
    if (!email) {
      return "";
    }
    if (email.includes(NAME_EMAIL_DELIMITER)) {
      return email;
    }
    if (!customers[email]) {
      return `New customer${NAME_EMAIL_DELIMITER}${email}`;
    }
    if (
      !customers[email].firstName &&
      !customers[email].lastName &&
      !customers[email].email
    ) {
      return "";
    }
    if (!customers[email]?.firstName && !customers[email]?.lastName) {
      return `Missing name${NAME_EMAIL_DELIMITER}${customers[email]?.email || ""}`;
    }
    return `${customers[email]?.firstName || ""} ${
      customers[email]?.lastName || ""
    }${NAME_EMAIL_DELIMITER}${customers[email]?.email || ""}`;
  };

  const prependCustom = (customerLabels: string[]) => {
    if (searchString?.includes("@")) {
      return [searchString, ...customerLabels];
    } else {
      return customerLabels;
    }
  };

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const removeFile = (url: string) => {
    setFiles((oldFiles) => {
      return oldFiles.filter((file) => file.url !== url);
    });
  };

  if (!user) {
    return null;
  }

  const showMacroAutomationsSection =
    isAtLeastOneMacroAutomationActive(macroAutomations);

  const footer = (
    <div className={newMessageModalCss.footer}>
      <Button
        className={newMessageModalCss.macroButton}
        disabled={macrosLoad.pending || !!sendPending}
        onClick={() => {
          setMacroModalOpen(true);
        }}
        theme={ButtonTheme.GHOST}
      >
        Use template
      </Button>
      {showStayOption ? (
        <>
          <Button
            disabled={
              !email.value ||
              !subject.value ||
              !emailSendingAs.value ||
              submitDisabled ||
              (!!sendPending && sendPending.stay)
            }
            onClick={() => handleSubmit({ stayOnPage: false })}
            pending={!!sendPending && !sendPending.stay}
            theme={ButtonTheme.OUTLINED}
          >
            Send and go to ticket
          </Button>
          <Button
            disabled={
              !email.value ||
              !subject.value ||
              !emailSendingAs.value ||
              submitDisabled ||
              (!!sendPending && !sendPending.stay)
            }
            onClick={() => handleSubmit({ stayOnPage: false })}
            pending={!!sendPending && sendPending.stay}
            theme={ButtonTheme.PRIMARY}
          >
            Send and stay here
          </Button>
        </>
      ) : (
        <Button
          disabled={
            !email.value ||
            !subject.value ||
            !emailSendingAs.value ||
            submitDisabled
          }
          onClick={() => handleSubmit({ stayOnPage: false })}
          pending={!!sendPending}
          theme={ButtonTheme.PRIMARY}
        >
          Send
        </Button>
      )}
    </div>
  );

  return (
    <>
      <Modal
        footer={footer}
        onClose={handleClose}
        open={open}
        paddingAmount={PaddingAmount.NONE}
        showFooterBorder
        size={ModalSize.MEDIUM}
        title={existingConversation ? "New message" : "Create ticket"}
      >
        <div className={newMessageModalCss.modalContent}>
          <FormSelectDropdown
            input={channel}
            label="Channel"
            options={[ConversationPlatform.EMAIL]}
          >
            {(channel) => {
              return {
                [ConversationPlatform.EMAIL]: "Email",
                [ConversationPlatform.REDO_CHAT]: "Redo Chat",
                // Not available yet - added to make TypeScript happy
                [ConversationPlatform.FACEBOOK]: undefined,
                [ConversationPlatform.INSTAGRAM]: undefined,
                [ConversationPlatform.INSTAGRAM_COMMENTS]: undefined,
                [ConversationPlatform.FACEBOOK_COMMENTS]: undefined,
                [ConversationPlatform.SMS]: undefined,
                [ConversationPlatform.ATTENTIVE]: undefined,
                [ConversationPlatform.POSTSCRIPT]: undefined,
                [ConversationPlatform.VOICE]: undefined,
                [""]: undefined,
              }[channel];
            }}
          </FormSelectDropdown>
          {channel.value === "email" && (
            <FormSelectDropdown
              input={emailSendingAs}
              label="Email to send as"
              options={availableEmails}
            >
              {(email) => email}
            </FormSelectDropdown>
          )}
          {customerInfo?._id ? (
            <LabeledInput label="Customer">
              <TextInput
                disabled
                value={
                  specifiedCustomerLoad.value
                    ? getCustomerDisplayNameForEmail(
                        getPrimaryCustomerEmail(specifiedCustomerLoad.value),
                      )
                    : ""
                }
              />
            </LabeledInput>
          ) : (
            <LabeledInput
              description="Type to search customers"
              label="Customer"
            >
              <Autocomplete
                filterOptions={(x) => x}
                getLabel={(customer): string => {
                  return getCustomerDisplayNameForEmail(customer);
                }}
                keyFn={(customer, index): string => {
                  return `${getCustomerDisplayNameForEmail(customer)}-${index}`;
                }}
                noOptionsText={
                  customersLoading
                    ? "Loading..."
                    : "No options found, search for customer by email, name, or order number"
                }
                onInputChange={onCustomerInputChange}
                options={
                  !customersLoading
                    ? prependCustom(Object.keys(customers || {}))
                    : []
                }
                value={email.value}
                valueChange={email.setValue}
              >
                {(customer) => getCustomerDisplayNameForEmail(customer)}
              </Autocomplete>
            </LabeledInput>
          )}
          {email.value && !customers[email.value] && (
            <>
              <FormTextInput input={firstName} label="First name" />
              <FormTextInput input={lastName} label="Last name" />
            </>
          )}
          {channel.value === ConversationPlatform.EMAIL && (
            <div>
              <CollapseSubsection
                inheritColor
                title={
                  <Text
                    fontSize="sm"
                    fontWeight="regular"
                    textColor="secondary"
                  >
                    Cc
                  </Text>
                }
              >
                <LabeledInput errors={[...ccEmails.allErrors]} label="">
                  <ChipInput
                    delimiter={ChipDelimiter.NEWLINE}
                    showPlaceholderWithoutFocus
                    value={ccEmailsInput}
                    valueChange={(value) => {
                      setCcEmailsInput([...value]);
                      ccEmails.setValue(value.join(","));
                    }}
                  />
                </LabeledInput>
              </CollapseSubsection>
            </div>
          )}
          <Divider />
          {channel.value === ConversationPlatform.EMAIL && (
            <FormTextInput input={subject} label="Email subject" />
          )}
          <Text fontSize="sm" textColor="secondary">
            Message
          </Text>
          <div onDrop={(e) => doFileDrop(e, handleUpload)}>
            <QuillEditor
              cursorIndexRef={cursorIndexRef}
              defaultValue={new Delta().insert("")}
              editorClassName={newMessageModalCss.quillEditor}
              onQuillEditorEmptyChange={setSubmitDisabled}
              placeholder="Start typing..."
              readOnly={false}
              ref={setQuill}
              toolbar="create-toolbar"
            />
          </div>
          <QuillToolbarOptions
            additionalToolbarItems={[
              {
                icon: PaperclipIcon,
                buttonTitle: "Attach file",
                identifier: "attach-file",
                element: (
                  <QuillToolbarUploadFile
                    clearInput={(event: any) => (event.target.value = "")}
                    handleUpload={handleUpload}
                  />
                ),
              },
            ]}
            emailMode={channel.value === ConversationPlatform.EMAIL}
            toolbarId="create-toolbar"
          />
          <QuillAttachmentCarousel
            attachments={files}
            removeFileFromDrafts={removeFile}
          />
          {showMacroAutomationsSection && (
            <Flex dir="column">
              <Text fontSize="sm" textColor="secondary">
                Automations
              </Text>
              <MacroAutomationsList
                macroAutomations={macroAutomations}
                platform={channel.value}
                setMacroAutomations={setMacroAutomations}
              />
            </Flex>
          )}
        </div>
      </Modal>
      <MacroModal
        key={macrosLoad?.value?.length}
        macros={macrosLoad.value}
        open={macroModalOpen}
        refreshMacros={refreshMacros}
        setMacro={setMacro}
        setOpen={setMacroModalOpen}
      />
      {showErrorMessage && (
        <Snackbar
          autoHideDuration={10000}
          onClose={() => setShowErrorMessage(false)}
          open={showErrorMessage}
        >
          <Alert onClose={() => setShowErrorMessage(false)} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
});
