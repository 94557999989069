import { Json } from "@redotech/json/json";
import { Flow, FlowModel } from "@redotech/redo-model/advanced-flow/flow";
import {
  AggregatedMetrics,
  MetricType,
} from "@redotech/redo-model/aggregated-metrics/index";
import {
  ConversationFiltersV3,
  CONVERSATIONS_FILTERS_VERSION,
} from "@redotech/redo-model/conversation-filters/conversation-filters";
import { Coverage, PaymentModel } from "@redotech/redo-model/coverage";
import {
  GetConversationCountsBody,
  serializeGetConversationCountsBody,
} from "@redotech/redo-model/get-conversation-counts-body";
import { PillTheme } from "@redotech/redo-model/pill-theme";
import { FlowType } from "@redotech/redo-model/return-flow";
import { ShopifyIntegration } from "@redotech/redo-model/shopify";
import { BillingSettings, GetTeam, Team } from "@redotech/redo-model/team";
import { GetUser } from "@redotech/redo-model/user";
import { isDef } from "@redotech/util/checks";
import { RedoMerchantClient } from ".";

export interface TeamBillingInfo {
  lastBill: { amount: number; date: string; status: string } | null;
  nextBill: { amount: number; date: string };
}

/**
 * POST /team/upload
 */
export async function createUpload(
  client: RedoMerchantClient,
  {
    file,
    maxFileSizeKb,
    signal,
  }: { file: File; maxFileSizeKb?: number | undefined; signal?: AbortSignal },
): Promise<{ url: string }> {
  const data = new FormData();
  data.append("image", file);
  if (isDef(maxFileSizeKb)) {
    data.append("maxFileSizeKb", maxFileSizeKb.toString());
  }
  const response = await client.client.postForm("team/upload", data, {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

/**
 * GET /team
 */
export async function getCurrentTeam(
  client: RedoMerchantClient,
  { signal }: { signal?: AbortSignal },
): Promise<GetTeam> {
  const response = await client.client.get("team", {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

/**
 * GET /team/:teamId/url
 */
export async function getTeam(
  client: RedoMerchantClient,
  { teamId, signal }: { teamId: string; signal?: AbortSignal },
): Promise<string> {
  const response = await client.client.get(`team/${teamId}/url`, { signal });
  return response.data;
}

/**
 * GET /team_urls
 */
export async function getTeamUrls(
  client: RedoMerchantClient,
  { ids, signal }: { ids: string[]; signal?: AbortSignal },
): Promise<{ id: string; url: string; logoUrl: string; name: string }[]> {
  const params = ids.map((id) => `ids=${id}`).join("&");
  const response = await client.client.get(`team/urls?${params}`, { signal });
  return response.data;
}

/**
 * GET /team/shopify-store
 */
export async function getShopifyStore(
  client: RedoMerchantClient,
  { signal }: { signal?: AbortSignal },
  teamID: string,
): Promise<string> {
  const response = await client.client.get(
    `team/shopify-store/${encodeURIComponent(teamID)}`,
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * GET /teams/:teamId/billing-date
 */
export async function getBillingInfo(
  client: RedoMerchantClient,
  {
    signal,
    teamId,
    settings,
  }: { signal?: AbortSignal; teamId: string; settings: BillingSettings },
): Promise<TeamBillingInfo> {
  const response = await client.client.post(
    `team/${encodeURIComponent(teamId)}/billing-info`,
    { settings },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * POST /team
 */
export async function updateCurrentTeam(
  client: RedoMerchantClient,
  {
    shopify,
    team,
    signal,
  }: { shopify?: ShopifyIntegration; team: Team; signal?: AbortSignal },
) {
  const response = await client.client.post(
    "team",
    { shopify, team },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * POST /team//email-exclusions
 */
export async function updateEmailExclusions(
  client: RedoMerchantClient,
  { exclusionList, signal }: { exclusionList: string[]; signal?: AbortSignal },
) {
  const response = await client.client.post(
    "team/email-exclusions",
    { exclusionList },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * POST /team/coverage
 */
export async function updateCoverage(
  client: RedoMerchantClient,
  {
    coverageEnabled,
    coverageExcludeCollections,
    coverageExcludeTags,
    coverageExcludeProductTags,
    coverageExcludeSources,
    coverage,
    paidModel,
    signal,
  }: {
    coverageExcludeCollections: readonly string[];
    coverageExcludeTags: readonly string[];
    coverageExcludeProductTags: readonly string[];
    coverageExcludeSources: readonly string[];
    coverageEnabled: boolean;
    coverage: Coverage;
    paidModel: PaymentModel;
    signal?: AbortSignal;
  },
) {
  const response = await client.client.post(
    "team/coverage",
    {
      coverageEnabled,
      coverageExcludeCollections,
      coverageExcludeTags,
      coverageExcludeProductTags,
      coverageExcludeSources,
      coverage,
      paidModel,
    },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * DELETE /team/user/:user_id
 */
export async function deleteTeamUser(
  client: RedoMerchantClient,
  { user, signal }: { user: GetUser; signal?: AbortSignal },
) {
  const response = await client.client.delete(`team/user/${user._id}`, {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

/**
 * POST /team/user
 */
export async function createTeamUser(
  client: RedoMerchantClient,
  {
    email,
    firstName,
    lastName,
    roles,
    sendEmail,
    customNote,
    signal,
  }: {
    email: string;
    firstName: string;
    lastName: string;
    roles: string[];
    sendEmail: boolean;
    customNote: string | undefined;
    signal?: AbortSignal;
  },
) {
  const response = await client.client.post(
    "team/user",
    { email, firstName, lastName, roles, sendEmail, customNote },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * POST /team/tags
 */
export async function addTags(
  client: RedoMerchantClient,
  { tags, signal }: { tags: { name: string }[]; signal?: AbortSignal },
) {
  const response = await client.client.post(
    "team/tags",
    { tags },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * PUT /team/tags
 */
export async function updateTags(
  client: RedoMerchantClient,
  {
    updates,
    signal,
  }: {
    updates: { oldName: string; newName: string; pillTheme: PillTheme }[];
    signal?: AbortSignal;
  },
) {
  const response = await client.client.put(
    "team/tags",
    { updates },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * GET /team/returns
 */
export async function getTeamReturns(
  client: RedoMerchantClient,
  { email, signal }: { email: string; signal?: AbortSignal },
) {
  const response = await client.client.get(
    `team/returns?email=${encodeURIComponent(email)}`,
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * GET /team/orders
 */
export async function getTeamOrders(
  client: RedoMerchantClient,
  { email, signal }: { email: string; signal?: AbortSignal },
) {
  const response = await client.client.get(
    `team/orders?email=${encodeURIComponent(email)}`,
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * POST /team/email-flow
 */
export async function addEmailFlow(
  client: RedoMerchantClient,
  {
    flow,
    signal,
  }: { flow: Omit<Flow, "versionGroupId">; signal?: AbortSignal },
) {
  const response = await client.client.post(
    "team/email-flow",
    { flow },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * DELETE /team/email-flow/:id
 */
export async function deleteEmailFlow(
  client: RedoMerchantClient,
  { id, signal }: { id: string; signal?: AbortSignal },
) {
  const response = await client.client.delete(`team/email-flow/${id}`, {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

/**
 * GET /team/advanced-flow/
 */
export async function getAdvancedFlows(
  client: RedoMerchantClient,
  {
    includeMetrics,
    signal,
  }: { includeMetrics?: boolean; signal?: AbortSignal },
) {
  const response = await client.client.get(
    `team/advanced-flow?includeMetrics=${includeMetrics ?? false}`,
    { headers: client.authorization(), signal },
  );
  if (includeMetrics) {
    return response.data as (FlowModel & {
      metrics: AggregatedMetrics<MetricType.Outreach>["metrics"];
    })[];
  }
  return response.data as FlowModel[];
}

/**
 * GET /team/advanced-flow/:id
 */
export async function getAdvancedFlow(
  client: RedoMerchantClient,
  { id, signal }: { id: string; signal?: AbortSignal },
) {
  const response = await client.client.get(`team/advanced-flow/${id}`, {
    headers: client.authorization(),
    signal,
  });
  return response.data as FlowModel;
}

/**
 * PUT /team/advanced-flow/:id
 */
export async function saveAdvancedFlow(
  client: RedoMerchantClient,
  steps: Flow["steps"],
  flowId: string,
  name: string,
  { signal }: { signal?: AbortSignal },
): Promise<{ flow: Flow }> {
  const response = await client.client.put(
    `team/advanced-flow/${flowId}`,
    { steps, name },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * GET /team/flows/:flowType
 */
export async function getCurrentFlow(
  client: RedoMerchantClient,
  flowType: FlowType,
  { signal }: { signal?: AbortSignal },
): Promise<{ flow: Json }> {
  const response = await client.client.get(`team/flows/${flowType}`, {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

/**
 * GET /team/flow-versions/:flowType
 */
export async function getFlowVersions(
  client: RedoMerchantClient,
  flowType: FlowType,
  { signal }: { signal?: AbortSignal },
): Promise<{ flows: Json[] }> {
  const response = await client.client.get(`team/flows-versions/${flowType}`, {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

/**
 * POST /team/flows/:flowType
 */
export async function saveFlow(
  client: RedoMerchantClient,
  flowType: FlowType,
  flow: Json,
  { signal }: { signal?: AbortSignal },
): Promise<{ flow: Json }> {
  const response = await client.client.post(`team/flows/${flowType}`, flow, {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}

/**
 * POST /team/flows/:flowType/publish
 */
export async function publishFlow(
  client: RedoMerchantClient,
  flowType: FlowType,
  name: string,
  { signal }: { signal?: AbortSignal },
): Promise<{ flow: Json }> {
  const response = await client.client.post(
    `team/flows/${flowType}/publish`,
    { name },
    { headers: client.authorization(), signal },
  );
  return response.data;
}

/**
 * GET team/conversations/counts
 */
export async function getConversationsCounts(
  client: RedoMerchantClient,
  {
    views,
    signal,
    presetViewFilters,
  }: {
    views?: { name: string; filters: ConversationFiltersV3 }[];
    signal?: AbortSignal;
    presetViewFilters?: Record<string, ConversationFiltersV3>;
  },
): Promise<Record<string, number>> {
  const v2Views = (views || []).map((view) => {
    return { viewName: view.name, filters: view.filters };
  });

  const presetV2Filters =
    Object.entries(presetViewFilters || {}).map(([viewName, filters]) => {
      return { viewName, filters };
    }) || [];

  const body: GetConversationCountsBody = {
    views: [...presetV2Filters, ...v2Views],
  };
  const serializedBody = serializeGetConversationCountsBody(body);

  const url = `team/conversations/counts`;

  const v3Header = { "x-api-version": CONVERSATIONS_FILTERS_VERSION };

  const response = await client.client.post(url, serializedBody, {
    headers: { ...client.authorization(), ...v3Header },
    signal,
  });

  return response.data;
}

/**
 * GET team/multipass-availability
 */
export async function getMultipassAvailability(
  client: RedoMerchantClient,
  { signal }: { signal?: AbortSignal },
): Promise<{ available: boolean }> {
  const response = await client.client.get("team/multipass-availability", {
    headers: client.authorization(),
    signal,
  });
  return response.data;
}
