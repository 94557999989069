import * as amplitude from "@amplitude/analytics-browser";
import { useRequiredContext } from "@redotech/react-util/context";
import { useHandler } from "@redotech/react-util/hook";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import { UserContext } from "@redotech/redo-merchant-app-common/user";
import {
  ConversationPlatform,
  ConversationStatus,
  ExpandedConversation,
} from "@redotech/redo-model/conversation";
import {
  FilterGroupFilterOption,
  FiltersStatus,
} from "@redotech/redo-model/conversation-filters/conversation-filters";
import { getPrimaryCustomerEmail } from "@redotech/redo-model/customer";
import { SortDirection, TableSort } from "@redotech/redo-model/tables/table";
import {
  Permission,
  GetUser as User,
  permitted,
} from "@redotech/redo-model/user";
import { alertOnFailure, toast } from "@redotech/redo-web/alert";
import {
  RedoButton,
  RedoButtonHierarchy,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import AnnotationCheck from "@redotech/redo-web/arbiter-icon/annotation-check.svg";
import CheckSvg from "@redotech/redo-web/arbiter-icon/check_filled.svg";
import DotsHorizontalSvg from "@redotech/redo-web/arbiter-icon/dots-horizontal.svg";
import Edit04Icon from "@redotech/redo-web/arbiter-icon/edit-04.svg";
import RefreshCCW01Svg from "@redotech/redo-web/arbiter-icon/refresh-ccw-01_filled.svg";
import StarsSvg from "@redotech/redo-web/arbiter-icon/stars-01_filled.svg";
import StatusSvg from "@redotech/redo-web/arbiter-icon/status.svg";
import { Button, ButtonSize, ButtonTheme } from "@redotech/redo-web/button";
import { ButtonDropdown } from "@redotech/redo-web/button-dropdown";
import { CardClickHandler, CardList } from "@redotech/redo-web/card-list";
import { DropdownOption } from "@redotech/redo-web/dropdown";
import { Flex } from "@redotech/redo-web/flex";
import ArchiveIcon from "@redotech/redo-web/icon-old/archive.svg";
import EnvelopeIcon from "@redotech/redo-web/icon-old/mail.svg";
import MergeIcon from "@redotech/redo-web/icon-old/merge.svg";
import SnoozeClockIcon from "@redotech/redo-web/icon-old/snooze-clock.svg";
import SpamIcon from "@redotech/redo-web/icon-old/spam.svg";
import UndoArrow from "@redotech/redo-web/icon-old/undo-arrow.svg";
import { Text } from "@redotech/redo-web/text";
import { Tooltip } from "@redotech/redo-web/tooltip/tooltip";
import { getLocalStorageWithExpiry } from "@redotech/redo-web/utils/local-storage-wrapper";
import { isUserOnMac } from "@redotech/util/browser-agent";
import { sinkPromise } from "@redotech/util/promise";
import * as classNames from "classnames";
import {
  KeyboardEvent,
  memo,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { getConversations, updateConversation } from "../client/conversations";
import { ConversationContext } from "./action-panel/conversation-context";
import { AiTrialBanner, BANNER_DISMISSED_KEY } from "./ai-trial-banner";
import { ActiveConversationContext } from "./context/active-conversation-context";
import { UpdateConversationStateContext } from "./context/update-conversations-context";
import { ArchiveTicketModal } from "./conversation-actions/archive-ticket-modal";
import { ChangeSubjectModal } from "./conversation-actions/change-subject-modal";
import {
  CloseTicketModal,
  handleCloseTickets,
} from "./conversation-actions/close-ticket-modal";
import { MarkSpamModal } from "./conversation-actions/mark-spam-modal";
import { MergeModal } from "./conversation-actions/merge-modal";
import { ReopenTicketModal } from "./conversation-actions/reopen-ticket-modal";
import { SnoozeModal } from "./conversation-actions/snooze-modal";
import { UnmarkSpamModal } from "./conversation-actions/unmark-spam-modal";
import { ConversationContent } from "./conversation-content";
import { ConversationFetcher } from "./conversation-fetcher";
import * as conversationHeaderCss from "./conversation-header.module.css";
import { ConversationInfoCardRender } from "./conversation-info-card";
import { ConversationSendStateContextProvider } from "./conversation-send-states";
import {
  AdvancedFiltersContext,
  FinalizedFiltersContext,
  SetUniqueFiltersContext,
  UniqueFiltersContext,
} from "./conversations-table-filters/filters-context";
import {
  markConversationAsRead,
  markConversationAsUnread,
} from "./conversations/mark-conversation-read-manager";
import { MergeSuggestionFlow } from "./merge-suggestion/merge-suggestion-flow";
import { getMergeGroupForConversation } from "./merge-suggestion/utils";
import * as singleConversationViewCss from "./single-conversation-view.module.css";

export const SingleConversationView = memo(function SingleConversationView({
  activeConversation,
  setActiveConversation,
  fetcher,
  blockRefresh,
}: {
  activeConversation: ExpandedConversation;
  setActiveConversation: (
    conversation: ExpandedConversation | undefined,
  ) => void;
  fetcher: ConversationFetcher;
  blockRefresh: boolean;
}) {
  const navigate = useNavigate();
  const client = useRequiredContext(RedoMerchantClientContext);
  const user = useRequiredContext(UserContext);
  const team = useRequiredContext(TeamContext);
  const filters = useContext(UniqueFiltersContext);
  const advancedFilters = useContext(AdvancedFiltersContext);
  const setFilters = useContext(SetUniqueFiltersContext);
  const finalizedFilters = useContext(FinalizedFiltersContext);
  const { removeConversation } = useRequiredContext(
    UpdateConversationStateContext,
  );

  const { cardListRef, waitForRefresh } = useRequiredContext(
    UpdateConversationStateContext,
  );

  const [mergeModalOpen, setMergeModalOpen] = useState(false);
  const [closeModalOpen, setCloseModalOpen] = useState(false);
  const [reopenModalOpen, setReopenModalOpen] = useState(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [snoozeModalOpen, setSnoozeModalOpen] = useState(false);
  const [markSpamModalOpen, setMarkSpamModalOpen] = useState(false);
  const [unmarkSpamModalOpen, setUnmarkSpamModalOpen] = useState(false);
  const [changeSubjectModalOpen, setChangeSubjectModalOpen] = useState(false);
  const [mergeSuggestionFlowState, setMergeSuggestionFlowState] = useState<
    "dialog" | "summaries" | "merge" | undefined
  >();
  const modalOpen =
    mergeModalOpen ||
    closeModalOpen ||
    reopenModalOpen ||
    archiveModalOpen ||
    snoozeModalOpen ||
    markSpamModalOpen ||
    unmarkSpamModalOpen;
  const [leftPanelOpen, setLeftPanelOpen] = useState(() => {
    const savedLeftPanelOpen = localStorage.getItem(
      "redo.support.conversation-detail.left-panel-open",
    );
    // Default to having the panel open when the localstorage hasn't previously been set.
    return savedLeftPanelOpen ? savedLeftPanelOpen === "true" : true;
  });

  const handleSetLeftPanelOpen = (value: boolean) => {
    setLeftPanelOpen(value);
    localStorage.setItem(
      "redo.support.conversation-detail.left-panel-open",
      value ? "true" : "false",
    );
  };

  const [rightPanelOpen, setRightPanelOpen] = useState(() => {
    const savedRightPanelOpen = localStorage.getItem(
      "redo.support.conversation-detail.right-panel-open",
    );
    return savedRightPanelOpen ? savedRightPanelOpen === "true" : true;
  });

  const handleSetRightPanelOpen = (value: boolean) => {
    setRightPanelOpen(value);
    localStorage.setItem(
      "redo.support.conversation-detail.right-panel-open",
      value ? "true" : "false",
    );
  };

  const [conversationClosing, setConversationClosing] = useState(false);
  const cardListScrollAreaRef = useRef<HTMLDivElement>(null);
  const conversationDetailRef = useRef<HTMLDivElement>(null);

  const canCloseTicket =
    !!user && permitted(user.permissions, Permission.CLOSE_CONVERSATION);
  const canCreateReply =
    !!user && permitted(user.permissions, Permission.CREATE_REPLY);
  const canArchiveTicket =
    !!user && permitted(user.permissions, Permission.ARCHIVE_CONVERSATION);

  const [mergeSuggestionDismissed, setMergeSuggestionDismissed] =
    useState<boolean>(true);
  const [suggestedConversationsToMerge, setSuggestedConversationsToMerge] =
    useState<ExpandedConversation[] | undefined>();

  const [pendingNextConversation, setPendingNextConversation] =
    useState<boolean>(false);

  const [conversationsInProximity, setConversationsInProximity] = useState<
    ExpandedConversation[]
  >([]);

  const [nextConversationInList, setNextConversationInList] = useState<
    ExpandedConversation | undefined
  >();
  const [prevConversationInList, setPrevConversationInList] = useState<
    ExpandedConversation | undefined
  >();

  // Pass in the new active conversation to prevent race conditions in the setting of state.
  const reloadNearbyConversations = async (
    newActiveConversation: ExpandedConversation | undefined,
  ) => {
    if (!newActiveConversation) {
      return;
    }
    setPendingNextConversation(true);
    const currentActiveConversation = newActiveConversation;
    const nextConversations = await getConversations(client, {
      pageContinue: currentActiveConversation.lastResponseAt,
      pageStop: undefined,
      pageSize: 25,
      filters: { ...finalizedFilters },
    });
    const reversedSortFilter = {
      ...finalizedFilters,
      sort: {
        direction:
          finalizedFilters.sort?.direction === SortDirection.ASC
            ? SortDirection.DESC
            : SortDirection.ASC,
        key: finalizedFilters.sort?.key ?? "lastResponse",
      },
    };
    const prevConversations = await getConversations(client, {
      pageContinue: currentActiveConversation.lastResponseAt,
      pageStop: undefined,
      pageSize: 25,
      filters: reversedSortFilter,
    });
    setPrevConversationInList(prevConversations.data[0]);
    setNextConversationInList(nextConversations.data[0]);
    // Reverse prevConversations to order the whole group
    const reversePrev = prevConversations.data.reverse();
    setConversationsInProximity([
      ...reversePrev,
      currentActiveConversation,
      ...nextConversations.data,
    ]);
    setPendingNextConversation(false);
  };

  const removeConversationFromProximity = (
    conversationToExclude: ExpandedConversation,
  ) => {
    const conversationsInProximityWithoutRemoved =
      conversationsInProximity?.filter(
        (conv) => conv._id !== conversationToExclude!._id,
      );
    setConversationsInProximity(conversationsInProximityWithoutRemoved);
  };

  useEffect(() => {
    // Card list is not always guaranteed to have next and previous loaded so we will fetch them individually.
    if (!activeConversation) {
      return;
    }
    if (!conversationsInProximity?.length) {
      sinkPromise(reloadNearbyConversations(activeConversation));
    } else {
      const indexOfCurrentConversation = conversationsInProximity.findIndex(
        (conversation) => conversation._id === activeConversation._id,
      );
      if (indexOfCurrentConversation !== -1) {
        const newPrevConversationInList =
          conversationsInProximity[indexOfCurrentConversation - 1];
        const newNextConversationInList =
          conversationsInProximity[indexOfCurrentConversation + 1];
        if (newPrevConversationInList && newNextConversationInList) {
          setPrevConversationInList(newPrevConversationInList);
          setNextConversationInList(newNextConversationInList);
        } else {
          sinkPromise(reloadNearbyConversations(activeConversation));
        }
        return;
      }
    }
  }, [activeConversation?._id, conversationsInProximity?.length]);

  function resetMergeSuggestionFlow() {
    setMergeSuggestionFlowState(undefined);
    setSuggestedConversationsToMerge(undefined);
    setMergeSuggestionDismissed(true);
  }

  /** When a new conversation is loaded or conversation status changes, fetch merge suggestions */
  useEffect(() => {
    const primaryEmail = getPrimaryCustomerEmail(activeConversation.customer);
    if (
      activeConversation.status === ConversationStatus.CLOSED.toLowerCase() ||
      !primaryEmail
    ) {
      return;
    }
    const abortController = new AbortController();
    void alertOnFailure("Failed to fetch merge suggestions")(async () => {
      let mergeGroup;
      try {
        mergeGroup = (
          await getMergeGroupForConversation({
            client,
            conversationId: activeConversation._id,
            signal: abortController.signal,
          })
        ).data;
      } catch (e: any) {
        if (e.name === "CanceledError") {
          return;
        }
        throw e;
      }
      setMergeSuggestionDismissed(mergeGroup.dismissed);
      const suggestedConversations = mergeGroup.conversations.filter(
        (suggestedConversation) =>
          suggestedConversation._id !== activeConversation._id,
      );
      if (suggestedConversations.length > 0) {
        setSuggestedConversationsToMerge(suggestedConversations);
        if (!mergeGroup.dismissed) {
          setMergeSuggestionFlowState("dialog");
        }
      } else {
        setSuggestedConversationsToMerge(undefined);
      }
    });
    return () => abortController.abort();
  }, [activeConversation?._id, activeConversation?.status]);

  const onConversationsViewed = (conversation: ExpandedConversation) => {
    if (!team) {
      return;
    }
    void markConversationAsRead(client, conversation, team, user._id);
    resetMergeSuggestionFlow();
    return conversation;
  };

  const handleCardClick = useHandler<CardClickHandler<ExpandedConversation>>(
    // To keep conversation switching fast, don't await any network requests in this function.
    (record) => {
      const newRecord = onConversationsViewed(record);
      amplitude.logEvent("view-conversationFromCardList", {
        mode: "single",
        conversationId: newRecord?._id,
        teamId: team._id,
        channels: [newRecord?.platform],
      });
      setActiveConversation(newRecord);
    },
  );

  const isConversationActive = (conversation: ExpandedConversation) => {
    return activeConversation?._id === conversation._id;
  };

  const handleMarkedUnread = async (
    conversationToMark: ExpandedConversation | undefined,
  ) => {
    if (conversationToMark && team) {
      await markConversationAsUnread(
        client,
        conversationToMark,
        team,
        user._id,
      );
      const searchParams = new URLSearchParams(window.location.search);
      const ACTIVE_CONVERSATION_QUERY_PARAMETER = "activeConversationId";
      searchParams.delete(ACTIVE_CONVERSATION_QUERY_PARAMETER);
      navigate(`${location.pathname}?${searchParams}`);
      setActiveConversation(undefined);
    }
  };

  const handleMarkInProgress = async (conversation: ExpandedConversation) => {
    const updatedConversation = await updateConversation(client, conversation, {
      status: "in_progress",
    });
    setActiveConversation(updatedConversation.data);
  };

  const onKeyDown = async (e: KeyboardEvent) => {
    if (modalOpen || conversationClosing || pendingNextConversation) return;
    if (e.altKey) {
      switch (e.code) {
        case "KeyC": // Alt+C to close ticket
          e.preventDefault();
          await actions.doCloseTicketAction();
          break;

        case "KeyR": // Alt+R to reopen ticket
          e.preventDefault();
          actions.doReopenTicketAction();
          break;

        case "KeyS": // Alt+S to snooze ticket
          e.preventDefault();
          actions.doSnoozeTicketAction();
          break;

        case "KeyP": // Alt+P to mark in progress
          e.preventDefault();
          await actions.doMarkInProgressAction();
          break;

        case "ArrowUp": // Alt+Up to go to previous conversation
          e.preventDefault();
          prevConversationInList &&
            setActiveConversation(prevConversationInList);
          break;

        case "ArrowDown": // Alt+Down to go to next conversation
          nextConversationInList &&
            setActiveConversation(nextConversationInList);
          e.preventDefault();
          break;
      }
    }
  };

  const actionCleanupFn = () => {
    if (reopenModalOpen) {
      setFilters({ ...filters, status: FiltersStatus.OPEN });
    }
  };

  const actions = {
    doCloseTicketAction: async () => {
      if (
        !canCloseTicket ||
        activeConversation?.status ===
          ConversationStatus.CLOSED.toLowerCase() ||
        !team
      )
        return;
      if (
        localStorage.getItem("redo.doNotShowCloseTicketModal") === "true" ||
        activeConversation?.platform !== ConversationPlatform.REDO_CHAT
      ) {
        setConversationClosing(true);

        removeConversationFromProximity(activeConversation!);
        await handleCloseTickets({
          client,
          conversations: [activeConversation!],
          setActiveConversation,
          team,
          inDetailView: true,
          removeConversation,
          nextConversationInList,
          prevConversationInList,
        });
        setConversationClosing(false);
      } else {
        amplitude.logEvent("view-closeConversationModal", {
          mode: "single",
          conversationIds: [activeConversation?._id],
          channels: [activeConversation?.platform],
        });
        setCloseModalOpen(true);
      }
    },
    doReopenTicketAction: () => {
      if (
        !canCloseTicket ||
        activeConversation?.status === ConversationStatus.OPEN.toLowerCase()
      )
        return;
      amplitude.logEvent("view-reopenConversationModal", {
        mode: "single",
        conversationIds: [activeConversation?._id],
        channels: [activeConversation?.platform],
      });
      setReopenModalOpen(true);
    },
    doSnoozeTicketAction: () => {
      if (!canCreateReply) return;
      amplitude.logEvent("view-snoozeConversationModal", {
        mode: "single",
        conversationIds: [activeConversation?._id],
        channels: [activeConversation?.platform],
      });
      setSnoozeModalOpen(true);
    },
    doMarkInProgressAction: async () => {
      if (!canCloseTicket) return;
      amplitude.logEvent("markInProgress", {
        mode: "single",
        conversationIds: [activeConversation?._id],
        channels: [activeConversation?.platform],
      });
      setConversationsInProximity((prev: ExpandedConversation[]) => [
        ...prev.filter((conv) => activeConversation._id !== conv._id),
      ]);
      await handleMarkInProgress(activeConversation);
      setFilters({ ...filters, status: FiltersStatus.IN_PROGRESS });
    },
    doArchiveTicketAction: () => {
      if (!canArchiveTicket) return;
      amplitude.logEvent("view-archiveConversationModal", {
        mode: "single",
        conversationIds: [activeConversation?._id],
        channels: [activeConversation?.platform],
      });
      setArchiveModalOpen(true);
    },
    doMarkUnreadTicketAction: async () => {
      if (
        !activeConversation?.messages.some(
          (message) => message.type === "customer",
        )
      )
        return;
      if (
        advancedFilters.find(
          (advancedFilter) =>
            advancedFilter.type === FilterGroupFilterOption.READ,
        )
      ) {
        removeConversationFromProximity(activeConversation!);
      }
      await handleMarkedUnread(activeConversation);
    },
    doMergeTicketAction: () => {
      if (!canCloseTicket) return;
      amplitude.logEvent("view-mergeConversationModal", {
        mode: "single",
        conversationIds: [activeConversation?._id],
        channels: [activeConversation?.platform],
      });
      setMergeModalOpen(true);
    },
    doMarkSpamTicketAction: () => {
      if (
        activeConversation?.platform !== ConversationPlatform.EMAIL ||
        !canCloseTicket
      )
        return;
      amplitude.logEvent("view-markSpamModal", {
        mode: "single",
        conversationId: activeConversation?._id,
      });
      setMarkSpamModalOpen(true);
    },
    doUnmarkSpamTicketAction: () => {
      if (
        activeConversation?.platform !== ConversationPlatform.EMAIL ||
        !canCloseTicket
      )
        return;
      amplitude.logEvent("view-unmarkSpamModal", {
        mode: "single",
        conversationId: activeConversation?._id,
      });
      setUnmarkSpamModalOpen(true);
    },
    doChangeSubjectAction: () => {
      if (
        activeConversation?.platform !== ConversationPlatform.EMAIL ||
        !canCreateReply
      )
        return;
      amplitude.logEvent("view-changeSubjectModal", {
        mode: "single",
        conversationId: activeConversation?._id,
      });
      setChangeSubjectModalOpen(true);
    },
  };

  const isMac = isUserOnMac();

  const actionButtons = [
    {
      show: canCloseTicket,
      buttonComponent:
        activeConversation?.status !==
        ConversationStatus.CLOSED.toLowerCase() ? (
          <Tooltip arrow key="close" title={isMac ? "Option+C" : "Alt+C"}>
            <span>
              <Button
                className={conversationHeaderCss.headerButtons}
                disabled={pendingNextConversation}
                onClick={actions.doCloseTicketAction}
                size={ButtonSize.NANO}
                theme={ButtonTheme.PRIMARY}
              >
                <Flex align="center" gap="xs" justify="center" wrap="nowrap">
                  <Flex
                    align="center"
                    className={classNames(
                      conversationHeaderCss.buttonIconWrapper,
                      conversationHeaderCss.closeButton,
                    )}
                    justify="center"
                  >
                    <CheckSvg />
                  </Flex>
                  <Text fontSize="xs" fontWeight="medium">
                    Close
                  </Text>
                </Flex>
              </Button>
            </span>
          </Tooltip>
        ) : (
          <Tooltip arrow key="reopen" title={isMac ? "Option+R" : "Alt+R"}>
            <span>
              <Button
                className={conversationHeaderCss.headerButtons}
                onClick={actions.doReopenTicketAction}
                size={ButtonSize.NANO}
                theme={ButtonTheme.PRIMARY}
              >
                <div className={singleConversationViewCss.buttonContent}>
                  <UndoArrow className={singleConversationViewCss.icon} />
                  <Text fontSize="xs" fontWeight="medium">
                    Reopen
                  </Text>
                </div>
              </Button>
            </span>
          </Tooltip>
        ),
    },
    {
      show:
        canCloseTicket &&
        activeConversation?.status ===
          ConversationStatus.IN_PROGRESS.toLowerCase(),
      dropdownComponent: (
        <DropdownOption
          action={actions.doReopenTicketAction}
          key="reopen"
          tooltip={isMac ? "Option+R" : "Alt+R"}
        >
          <div className={singleConversationViewCss.buttonContent}>
            <UndoArrow className={singleConversationViewCss.icon} />
            Mark as open
          </div>
        </DropdownOption>
      ),
    },
    {
      show: canCreateReply,
      dropdownComponent: (
        <DropdownOption
          action={actions.doSnoozeTicketAction}
          key="snooze"
          tooltip={isMac ? "Option+S" : "Alt+S"}
        >
          <div className={singleConversationViewCss.buttonContent}>
            <SnoozeClockIcon className={singleConversationViewCss.icon} />
            Snooze
          </div>
        </DropdownOption>
      ),
      buttonComponent: (
        <Tooltip arrow title={isMac ? "Option+S" : "Alt+S"}>
          <span>
            <Button
              onClick={() => {
                amplitude.logEvent("view-snoozeConversationModal", {
                  mode: "single",
                  conversationIds: [activeConversation?._id],
                  channels: [activeConversation?.platform],
                });
                setSnoozeModalOpen(true);
              }}
              size={ButtonSize.SMALL}
              theme={ButtonTheme.OUTLINED}
            >
              <div className={singleConversationViewCss.buttonContent}>
                <SnoozeClockIcon className={singleConversationViewCss.icon} />
                Snooze
              </div>
            </Button>
          </span>
        </Tooltip>
      ),
    },
    {
      show:
        canCloseTicket &&
        team.settings.support?.useInProgressStatus &&
        activeConversation?.status !==
          ConversationStatus.IN_PROGRESS.toLowerCase(),
      dropdownComponent: (
        <DropdownOption
          action={actions.doMarkInProgressAction}
          key="in_progress"
          tooltip={isMac ? "Option+P" : "Alt+P"}
        >
          <div className={singleConversationViewCss.buttonContent}>
            <StatusSvg className={singleConversationViewCss.icon} />
            Mark in progress
          </div>
        </DropdownOption>
      ),
      buttonComponent: (
        <DropdownOption
          action={actions.doMarkInProgressAction}
          key="in_progress"
        >
          <div className={singleConversationViewCss.buttonContent}>
            <StatusSvg className={singleConversationViewCss.icon} />
            Mark in progress
          </div>
        </DropdownOption>
      ),
    },
    {
      show: canArchiveTicket,
      dropdownComponent: (
        <DropdownOption action={actions.doArchiveTicketAction} key="archive">
          <div className={singleConversationViewCss.buttonContent}>
            <ArchiveIcon className={singleConversationViewCss.icon} />
            Delete
          </div>
        </DropdownOption>
      ),
      buttonComponent: (
        <Button
          onClick={actions.doArchiveTicketAction}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          <div className={singleConversationViewCss.buttonContent}>
            <ArchiveIcon className={singleConversationViewCss.icon} />
            Delete
          </div>
        </Button>
      ),
    },
    {
      show: activeConversation?.messages.some(
        (message) => message.type === "customer",
      ),
      dropdownComponent: (
        <DropdownOption
          action={actions.doMarkUnreadTicketAction}
          key="markUnread"
        >
          <div className={singleConversationViewCss.buttonContent}>
            <EnvelopeIcon className={singleConversationViewCss.icon} />
            Mark unread
          </div>
        </DropdownOption>
      ),
      buttonComponent: (
        <Button
          onClick={actions.doMarkUnreadTicketAction}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          <div className={singleConversationViewCss.buttonContent}>
            <EnvelopeIcon className={singleConversationViewCss.icon} />
            Mark unread
          </div>
        </Button>
      ),
    },
    {
      show:
        canCloseTicket &&
        activeConversation?.platform &&
        ![
          ConversationPlatform.INSTAGRAM_COMMENTS,
          ConversationPlatform.FACEBOOK_COMMENTS,
        ].includes(activeConversation.platform as ConversationPlatform),
      dropdownComponent: (
        <DropdownOption action={actions.doMergeTicketAction} key="merge">
          <div className={singleConversationViewCss.buttonContent}>
            <MergeIcon className={singleConversationViewCss.icon} />
            Merge
          </div>
        </DropdownOption>
      ),
      buttonComponent: (
        <Button
          onClick={actions.doMergeTicketAction}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          <div className={singleConversationViewCss.buttonContent}>
            <MergeIcon className={singleConversationViewCss.icon} />
            Merge
          </div>
        </Button>
      ),
    },
    {
      show:
        activeConversation?.platform === ConversationPlatform.EMAIL &&
        activeConversation.tagIds?.every((tag) => tag.name !== "spam") &&
        canCloseTicket,
      dropdownComponent: (
        <DropdownOption action={actions.doMarkSpamTicketAction} key="markSpam">
          <div className={singleConversationViewCss.buttonContent}>
            <SpamIcon className={singleConversationViewCss.icon} />
            Mark spam
          </div>
        </DropdownOption>
      ),
      buttonComponent: (
        <Button
          onClick={actions.doMarkSpamTicketAction}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          <div className={singleConversationViewCss.buttonContent}>
            <SpamIcon className={singleConversationViewCss.icon} />
            Mark spam
          </div>
        </Button>
      ),
    },
    {
      show:
        activeConversation?.platform === ConversationPlatform.EMAIL &&
        activeConversation.tagIds?.some((tag) => tag.name === "spam") &&
        canCloseTicket,
      dropdownComponent: (
        <DropdownOption
          action={actions.doUnmarkSpamTicketAction}
          key="unmarkSpam"
        >
          <div className={singleConversationViewCss.buttonContent}>
            <AnnotationCheck className={singleConversationViewCss.icon} />
            Unmark spam
          </div>
        </DropdownOption>
      ),
      buttonComponent: (
        <Button
          onClick={actions.doUnmarkSpamTicketAction}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          <div className={singleConversationViewCss.buttonContent}>
            <AnnotationCheck className={singleConversationViewCss.icon} />
            Unmark spam
          </div>
        </Button>
      ),
    },
    {
      show:
        activeConversation?.platform === ConversationPlatform.EMAIL &&
        canCreateReply,
      dropdownComponent: (
        <DropdownOption
          action={actions.doChangeSubjectAction}
          key="changeSubject"
        >
          <div className={singleConversationViewCss.buttonContent}>
            <Edit04Icon className={singleConversationViewCss.icon} />
            Change subject
          </div>
        </DropdownOption>
      ),
      buttonComponent: (
        <Button
          onClick={actions.doChangeSubjectAction}
          size={ButtonSize.SMALL}
          theme={ButtonTheme.OUTLINED}
        >
          <div className={singleConversationViewCss.buttonContent}>
            <Edit04Icon className={singleConversationViewCss.icon} />
            Change subject
          </div>
        </Button>
      ),
    },
    {
      show:
        !!suggestedConversationsToMerge &&
        suggestedConversationsToMerge.length > 0 &&
        canCloseTicket &&
        activeConversation?.platform !==
          ConversationPlatform.INSTAGRAM_COMMENTS,
      dropdownComponent: (
        <DropdownOption
          action={() => setMergeSuggestionFlowState("summaries")}
          key="view-merge-suggestion"
        >
          <div className={singleConversationViewCss.buttonContent}>
            <StarsSvg className={singleConversationViewCss.icon} />
            View merge suggestion
          </div>
        </DropdownOption>
      ),
    },
  ];

  const enabledActionButtons = actionButtons.filter(
    (actionButton) => actionButton.show,
  );

  const actionsDropdown = (
    <>
      <div className={singleConversationViewCss.dropdownTitle}>
        Other actions
      </div>
      {enabledActionButtons
        .slice(1)
        .map((actionButton) => actionButton.dropdownComponent)}
    </>
  );

  const [conversationJustMerged, setConversationJustMerged] = useState(false);

  const conversationActionButtons = (
    <div className={singleConversationViewCss.portalButtonsWrapper}>
      {enabledActionButtons.length > 1 && (
        <div className={conversationHeaderCss.threeDotsButton}>
          <ButtonDropdown
            disabled={pendingNextConversation}
            dropdown={actionsDropdown}
            restrictDropdownSizeToParent={false}
            size={ButtonSize.NANO}
            theme={ButtonTheme.OUTLINED}
          >
            <Flex
              align="center"
              className={conversationHeaderCss.buttonIconWrapper}
              justify="center"
            >
              <DotsHorizontalSvg />
            </Flex>
          </ButtonDropdown>
        </div>
      )}
      {enabledActionButtons
        .slice(0, 1)
        .map((actionButton) => actionButton.buttonComponent)}
      {mergeModalOpen && (
        <MergeModal
          cleanupFn={() => {
            setConversationJustMerged(true);
            actionCleanupFn();
          }}
          conversations={[activeConversation]}
          open={mergeModalOpen}
          setActiveConversation={setActiveConversation}
          setConversationsInProximity={setConversationsInProximity}
          setOpen={setMergeModalOpen}
        />
      )}
      {closeModalOpen && (
        <CloseTicketModal
          cleanupFn={actionCleanupFn}
          conversations={[activeConversation]}
          inDetailView
          nextConversationInList={nextConversationInList}
          open={closeModalOpen}
          prevConversationInList={prevConversationInList}
          setActiveConversation={setActiveConversation}
          setOpen={setCloseModalOpen}
        />
      )}
      {reopenModalOpen && (
        <ReopenTicketModal
          cleanupFn={() => {
            actionCleanupFn();
          }}
          conversations={[activeConversation]}
          inDetailView
          open={reopenModalOpen}
          setActiveConversation={setActiveConversation}
          setOpen={setReopenModalOpen}
        />
      )}
      {archiveModalOpen && (
        <ArchiveTicketModal
          cleanupFn={actionCleanupFn}
          conversations={[activeConversation]}
          inDetailView
          nextConversationInList={nextConversationInList}
          open={archiveModalOpen}
          prevConversationInList={prevConversationInList}
          setActiveConversation={setActiveConversation}
          setConversationsInProximity={setConversationsInProximity}
          setOpen={setArchiveModalOpen}
        />
      )}
      {snoozeModalOpen && (
        <SnoozeModal
          cleanupFn={actionCleanupFn}
          conversations={[activeConversation]}
          inDetailView
          nextConversationInList={nextConversationInList}
          open={snoozeModalOpen}
          prevConversationInList={prevConversationInList}
          setActiveConversation={setActiveConversation}
          setConversationsInProximity={setConversationsInProximity}
          setOpen={setSnoozeModalOpen}
        />
      )}
      {markSpamModalOpen && (
        <MarkSpamModal
          cleanupFn={actionCleanupFn}
          conversations={[activeConversation]}
          inDetailView
          nextConversationInList={nextConversationInList}
          open={markSpamModalOpen}
          prevConversationInList={prevConversationInList}
          setActiveConversation={setActiveConversation}
          setConversationsInProximity={setConversationsInProximity}
          setOpen={setMarkSpamModalOpen}
        />
      )}
      {unmarkSpamModalOpen && (
        <UnmarkSpamModal
          cleanupFn={actionCleanupFn}
          conversations={[activeConversation]}
          inDetailView
          nextConversationInList={nextConversationInList}
          open={unmarkSpamModalOpen}
          prevConversationInList={prevConversationInList}
          setActiveConversation={setActiveConversation}
          setConversationsInProximity={setConversationsInProximity}
          setOpen={setUnmarkSpamModalOpen}
        />
      )}
      {changeSubjectModalOpen && (
        <ChangeSubjectModal
          conversation={activeConversation}
          resolve={() => setChangeSubjectModalOpen(false)}
        />
      )}
    </div>
  );

  const sortDefault: TableSort = {
    direction: SortDirection.DESC,
    key: "lastResponse",
  };

  const [refreshTableButtonPending, setRefreshTableButtonPending] =
    useState(false);

  function handleRefreshTableButton() {
    sinkPromise(
      (async () => {
        if (refreshTableButtonPending) {
          return;
        }

        const failToast = () => {
          toast("Failed to refresh conversations", { variant: "error" });
        };
        setRefreshTableButtonPending(true);

        await waitForRefresh().catch(failToast);
        setRefreshTableButtonPending(false);
      })(),
    );
  }

  const shouldAllowClickingCardListRefresh = !blockRefresh;

  const [bannerShown, setBannerShown] = useState(false);

  useEffect(() => {
    const bannerDismissed = getLocalStorageWithExpiry(BANNER_DISMISSED_KEY);
    const aiSettings = team.settings.support?.ai;
    if (
      !bannerDismissed &&
      aiSettings?.hasTriedAi &&
      !team.settings.support?.billing?.ai?.planName
    ) {
      setBannerShown(true);
    }
  }, [team.settings.support?.ai?.trialMessagesRemaining]);

  return (
    <ActiveConversationContext.Provider
      value={{
        div: conversationDetailRef,
        ticketActions: actions,
        conversationClosing,
        setConversationClosing,
        setActiveConversation,
        activeConversation,
      }}
    >
      <div
        className={classNames(singleConversationViewCss.wide)}
        onKeyDown={onKeyDown}
        ref={conversationDetailRef}
        tabIndex={0}
      >
        <Flex dir="column" gap="none" w="full">
          {bannerShown && <AiTrialBanner setBannerShown={setBannerShown} />}
          <Flex gap="none" grow={1}>
            <div
              className={singleConversationViewCss.panel}
              style={!leftPanelOpen ? { display: "none" } : undefined}
            >
              <div
                className={classNames(
                  singleConversationViewCss.tableSummaryContainer,
                  singleConversationViewCss.fullHeight,
                  bannerShown && singleConversationViewCss.bannerShown,
                )}
              >
                <div className={singleConversationViewCss.tableSummaryHeader}>
                  <Flex
                    align="center"
                    grow={1}
                    justify="space-between"
                    pl="xl"
                    pr="xl"
                  >
                    <Flex>
                      <Text
                        fontSize="sm"
                        fontWeight="semibold"
                        textColor="primary"
                      >
                        Tickets
                      </Text>
                    </Flex>
                    <Flex>
                      <RedoButton
                        disabled={!shouldAllowClickingCardListRefresh}
                        hierarchy={RedoButtonHierarchy.SECONDARY}
                        IconLeading={() => <RefreshCCW01Svg />}
                        onClick={handleRefreshTableButton}
                        pending={refreshTableButtonPending}
                      />
                    </Flex>
                  </Flex>
                </div>
                <div
                  className={singleConversationViewCss.cardList}
                  ref={cardListScrollAreaRef}
                >
                  <CardList
                    card={ConversationInfoCardRender}
                    fetcher={fetcher}
                    inactive={!leftPanelOpen}
                    isCardActive={isConversationActive}
                    onCardClick={handleCardClick}
                    passThroughValues={filters}
                    ref={cardListRef}
                    scrollAreaRef={cardListScrollAreaRef}
                    sortDefault={sortDefault}
                  />
                </div>
              </div>
            </div>
            <div className={singleConversationViewCss.centerContent}>
              <div
                className={classNames(
                  singleConversationViewCss.conversationDetailContainer,
                  singleConversationViewCss.fullHeight,
                  bannerShown && singleConversationViewCss.bannerShown,
                )}
              >
                <ConversationSendStateContextProvider>
                  <ConversationContent
                    actionButtons={conversationActionButtons}
                    conversation={activeConversation}
                    conversationJustMerged={conversationJustMerged}
                    handleSetLeftPanelOpen={handleSetLeftPanelOpen}
                    handleSetRightPanelOpen={handleSetRightPanelOpen}
                    leftPanelOpen={leftPanelOpen}
                    nextConversationInList={nextConversationInList}
                    onConversationsViewed={onConversationsViewed}
                    pendingNextConversation={pendingNextConversation}
                    prevConversationInList={prevConversationInList}
                    removeConversationFromProximity={
                      removeConversationFromProximity
                    }
                    rightPanelOpen={rightPanelOpen}
                    setActiveConversation={setActiveConversation}
                  />
                </ConversationSendStateContextProvider>
              </div>
            </div>
            <div
              className={
                rightPanelOpen
                  ? classNames(
                      singleConversationViewCss.panel,
                      singleConversationViewCss.right,
                    )
                  : singleConversationViewCss.hidden
              }
            >
              <div
                className={classNames(
                  singleConversationViewCss.conversationContextContainer,
                  singleConversationViewCss.fullHeight,
                  bannerShown && singleConversationViewCss.bannerShown,
                )}
              >
                <ConversationContext
                  conversation={activeConversation}
                  setActiveConversation={setActiveConversation}
                  setConversationAssignee={(user: User | null) => {
                    setActiveConversation({
                      ...activeConversation,
                      assignee: user,
                    });
                  }}
                />
              </div>
            </div>
          </Flex>
        </Flex>

        {mergeSuggestionFlowState && suggestedConversationsToMerge && (
          <MergeSuggestionFlow
            activeConversation={activeConversation}
            alreadyDismissed={mergeSuggestionDismissed}
            initialStep={mergeSuggestionFlowState}
            onClose={() => setMergeSuggestionFlowState(undefined)}
            suggestedConversations={suggestedConversationsToMerge}
          />
        )}
      </div>
    </ActiveConversationContext.Provider>
  );
});
