import { useRequiredContext } from "@redotech/react-util/context";
import { useLoad } from "@redotech/react-util/load";
import { RedoMerchantClientContext } from "@redotech/redo-merchant-app-common/client/context";
import { getCustomerTags } from "@redotech/redo-merchant-app-common/client/shopify";
import {
  CustomerTagsFilterType,
  FilterGroupFilterOption,
  CustomerTagsFilter as ModelCustomerTagsFilter,
  PendingFilter,
} from "@redotech/redo-model/conversation-filters/conversation-filters";
import {
  RedoFilterDropdownAnchor,
  RedoFilterGroup,
} from "@redotech/redo-web/arbiter-components/filter-group/redo-filter-group";
import { RedoListItem } from "@redotech/redo-web/arbiter-components/list/redo-list";
import { RedoListItemSize } from "@redotech/redo-web/arbiter-components/list/redo-list-item";
import { RedoMultiSelectDropdown } from "@redotech/redo-web/arbiter-components/select-dropdown/redo-multi-select-dropdown";
import { RedoSingleSelectDropdown } from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown";
import {
  ConversationFilterOptionToIcon,
  ConversationFilterOptionToName,
} from "@redotech/redo-web/conversation-filters/conversation-filter-icons";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { memo, useEffect, useMemo, useState } from "react";

export const CustomerTagsFilter = memo(function CustomerTagsFilter({
  filter,
  removeFilter,
  setFilter,
  openOnRender,
}: {
  filter: ModelCustomerTagsFilter | PendingFilter<ModelCustomerTagsFilter>;
  removeFilter(): void;
  setFilter(
    filter: ModelCustomerTagsFilter | PendingFilter<ModelCustomerTagsFilter>,
  ): void;
  openOnRender: boolean;
}) {
  const client = useRequiredContext(RedoMerchantClientContext);

  const customerTagFilterType = filter.query;
  const selectedTags = filter.value;

  const availableCustomerTagsLoad = useLoad(
    (signal) => getCustomerTags(client, { signal }),
    [client],
  );

  const [queryRef, setQueryRef] = useState<HTMLButtonElement | null>(null);
  const [valueRef, setValueRef] = useState<HTMLButtonElement | null>(null);
  const query = (
    <RedoFilterDropdownAnchor
      ref={setQueryRef}
      text={tagFilterTypeToText[customerTagFilterType]}
    />
  );

  useEffect(() => {
    if (openOnRender && valueRef) {
      valueRef.click();
    }
  }, [openOnRender, valueRef]);

  const queryDropdown = (
    <RedoSingleSelectDropdown
      dropdownButtonRef={queryRef}
      options={tagQueryOptions}
      optionSelected={(query) => setFilter({ ...filter, query: query.value })}
      selectedItem={tagQueryOptions.find(
        (item) => item.value === customerTagFilterType,
      )}
      size={RedoListItemSize.SMALL}
    >
      {(item) => (
        <Text
          fontSize="sm"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {tagFilterTypeToText[item.value]}
        </Text>
      )}
    </RedoSingleSelectDropdown>
  );

  const valueText = selectedTags
    ? selectedTags.length === 1
      ? "1 Tag"
      : `${selectedTags.length} Tags`
    : "...";

  const value = (
    <RedoFilterDropdownAnchor
      ref={setValueRef}
      text={valueText}
      tooltip={
        selectedTags && (
          <Flex dir="column">
            {selectedTags.map((tag) => (
              <Text key={tag}>{tag}</Text>
            ))}
          </Flex>
        )
      }
      weight="semibold"
    />
  );

  const allTagItems = useMemo<RedoListItem<{ name: string }>[]>(
    () =>
      (availableCustomerTagsLoad.value || []).map((tag) => ({ value: tag })),
    [availableCustomerTagsLoad.value],
  );

  const selectedTagItems = useMemo<RedoListItem<{ name: string }>[]>(
    () => (selectedTags || []).map((tag) => ({ value: { name: tag } })),
    [selectedTags],
  );

  const valueDropdown = (
    <RedoMultiSelectDropdown
      dropdownButtonRef={valueRef}
      keyFn={(item) => item.value.name}
      options={allTagItems}
      selectedOptions={selectedTagItems}
      setSelectedOptions={(items) => {
        if (items.length === 0) {
          setFilter({ ...filter, value: null });
        } else {
          setFilter({ ...filter, value: items.map((item) => item.value.name) });
        }
      }}
      size={RedoListItemSize.SMALL}
    >
      {(item) => (
        <Text
          fontSize="sm"
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
        >
          {item.value.name}
        </Text>
      )}
    </RedoMultiSelectDropdown>
  );

  return (
    <>
      {valueDropdown}
      {queryDropdown}
      <RedoFilterGroup
        Icon={
          ConversationFilterOptionToIcon[FilterGroupFilterOption.CUSTOMER_TAGS]
        }
        propertyName={
          ConversationFilterOptionToName[FilterGroupFilterOption.CUSTOMER_TAGS]
        }
        query={query}
        removeFilter={removeFilter}
        value={value}
      />
    </>
  );
});

const tagFilterTypeToText: Record<CustomerTagsFilterType, string> = {
  [CustomerTagsFilterType.ALL_OF]: "have all of",
  [CustomerTagsFilterType.ANY_OF]: "include any of",
  [CustomerTagsFilterType.NONE_OF]: "include none of",
};

const tagQueryOptions: RedoListItem<CustomerTagsFilterType>[] = [
  { value: CustomerTagsFilterType.ALL_OF },
  { value: CustomerTagsFilterType.ANY_OF },
  { value: CustomerTagsFilterType.NONE_OF },
];
