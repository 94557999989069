// extracted by mini-css-extract-plugin
export var addButton = "_7p-0";
export var border = "_7p-8";
export var check = "_7p-h";
export var danger = "_7p-6";
export var dropdownContainer = "_7p-1";
export var editModalBody = "_7p-j";
export var footerContainer = "_7p-i";
export var fullWidth = "_7p-a";
export var hide = "_7p-9";
export var icon = "_7p-3";
export var input = "_7p-7";
export var inputFlex = "_7p-b";
export var inputWrapper = "_7p-c";
export var manualFocus = "_7p-5";
export var optionButton = "_7p-4";
export var pillWrapper = "_7p-e";
export var pointer = "_7p-2";
export var showOverflow = "_7p-f";
export var tagsFlex = "_7p-d";
export var xButton = "_7p-g";