import { ExtendedAddress } from "@redotech/redo-model/address";
import { z } from "zod";
import { zExt } from "../common/zod-util";
import { Provider } from "../order";
import { PermissionEntrySchema } from "../order-schema";
import { ItemOrderBy } from "../outbound-labels";
import { LearnedPresetSchema } from "../outbound-labels/learned-preset";
import {
  OutboundRate,
  ShipmentRates,
  ShipmentRatesSchema,
} from "../outbound-labels/outbound-labels";
import {
  getParcelWeightGrams,
  Parcel,
  ParcelSchema,
} from "../outbound-labels/parcel";
import {
  addWeight,
  convertWeight,
  MoneySchema,
  Weight,
  WeightSchema,
  WeightUnit,
} from "../outbound-labels/util";
import { OrderItemTableColumnData } from "../packing-slips/packing-slip-builder";
import { TagSchema } from "../tag";
import {
  CarrierAndService,
  CarrierAndServiceSchema,
  carrierToLabel,
  getServiceLabel,
} from "./fulfillment-carriers-and-services";
import { RedoShopifyDeliveryMethodType } from "./fulfillment-delivery-method-type";
import { FulfillmentGroupStatus } from "./fulfillment-group-status";
import { FulfillmentLineItem } from "./fulfillment-line-item";
import {
  FulfillmentOrderAddressSchema,
  IFulfillmentOrderAddress,
  IVerifiedAddress,
  VerifiedAddressSchema,
} from "./fulfillment-order-address";
import {
  FulfillmentOrderLineItem,
  FulfillmentOrderLineItemSchema,
  IFulfillmentOrderLineItem,
} from "./fulfillment-order-line-item";
import { RedoShopifyFulfillmentOrderStatus } from "./fulfillment-order-status";
import { PrintStatusSchema } from "./fullfilment-print-status";
import { RedoShopifyOrderDisplayFinancialStatus } from "./order-display-financial-status";
import {
  IRedoFulfillment,
  RedoFulfillmentSchema,
  ShipmentPackageSchema,
} from "./outbound-shipment";
import { RiskSchema } from "./shopify-fulfillment-schemas";

export const FulfillmentOrderCustomerSchema = z.object({
  id: z.string(),
  name: z.string(),
  tags: z.array(z.string()).nullish(),
  numOrders: z.number(),
  email: z.string().nullish(),
  phone: z.string().nullish(),
  note: z.string().nullish(),
});

export type FulfillmentOrderCustomer = z.infer<
  typeof FulfillmentOrderCustomerSchema
>;

//https://lorefnon.me/2023/11/28/fixing-inferred-types-exceeding-serializable-length/
export interface IFulfillmentOrderCustomer
  extends z.ZodType<FulfillmentOrderCustomer> {}

export const FulfillmentOrderSchema = z.object({
  provider: z.nativeEnum(Provider),
  externalId: z.string(),
  originAddress: FulfillmentOrderAddressSchema as IFulfillmentOrderAddress,
  verifiedOriginAddress: (VerifiedAddressSchema as IVerifiedAddress).nullish(),
  deliveryMethod: z.object({
    name: z.string().nullish(),
    code: z.string().nullish(),
    kind: z.nativeEnum(RedoShopifyDeliveryMethodType),
    instructions: z.string().nullish(),
    phone: z.string().nullish(),
  }),
  destinationAddress: FulfillmentOrderAddressSchema as IFulfillmentOrderAddress,
  verifiedDestinationAddress: (
    VerifiedAddressSchema as IVerifiedAddress
  ).nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
  fulfillAt: z.date().nullish(),
  fulfillBy: z.date().nullish(),
  lineItems: z.array(FulfillmentOrderLineItemSchema),
  order: z
    .object({
      id: z.string(),
      redoOrderId: z.string().nullish(),
      fullyPaid: z.boolean().nullish(),
      displayFinancialStatus: z
        .nativeEnum(RedoShopifyOrderDisplayFinancialStatus)
        .nullish(),
      name: z.string(),
      risk: RiskSchema.nullish(),
      tags: z.array(z.string()).nullish(),
      note: z.string(),
      customAttributes: z
        .array(z.object({ key: z.string(), value: z.string().nullish() }))
        .nullish(),
      createdAt: z.date(),
      shippingCost: MoneySchema,
      closed: z.boolean().nullish(),
      closedAt: z.date().nullish(),
    })
    .nullish(),
  customer: (
    FulfillmentOrderCustomerSchema as IFulfillmentOrderCustomer
  ).nullish(),
  status: z.nativeEnum(RedoShopifyFulfillmentOrderStatus),
  raw: z.any(),
});

export type FulfillmentOrder = z.infer<typeof FulfillmentOrderSchema>;
export interface IFulfillmentOrder extends z.ZodType<FulfillmentOrder> {}

const ShippingCostBreakdownSchema = z
  .object({
    fulfillmentOrdersBreakdown: z
      .array(
        z.object({
          labelCost: z.string(),
          markup: z
            .object({
              percentage: z.string().optional(),
              amount: z.string().optional(),
            })
            .optional(),
        }),
      )
      .optional(),
  })
  .merge(MoneySchema);
export type ShippingCostBreakdown = z.infer<typeof ShippingCostBreakdownSchema>;

export const FulfillmentGroupAddressesSchema = z.object({
  address: FulfillmentOrderAddressSchema,
  verifiedAddress: VerifiedAddressSchema.nullish(),
});
export type FulfillmentGroupAddresses = z.infer<
  typeof FulfillmentGroupAddressesSchema
>;
export interface IFulfillmentGroupAddresses
  extends z.ZodType<FulfillmentGroupAddresses> {}

export const FulfillmentGroupSummary = z.object({
  totalItemQuantity: z.number(),
  totalSkuCount: z.number(),
  totalWeightGrams: z.number(),
  totalWeightWithParcelGrams: z.number().nullish(),
  totalPrice: MoneySchema,
  totalPriceInt: z.number().nullish(),
  totalTax: MoneySchema,
  totalTaxInt: z.number().nullish(),
  totalShippingCost: ShippingCostBreakdownSchema,
  totalShippingCostInt: z.number().nullish(),
  providerCreatedAt: z.date(),
  providerUpdatedAt: z.date(),
  updatedAt: z.date().nullish(),
  originAddress: FulfillmentOrderAddressSchema as IFulfillmentOrderAddress,
  verifiedOriginAddress: (VerifiedAddressSchema as IVerifiedAddress).nullish(),
  destinationAddress: FulfillmentOrderAddressSchema as IFulfillmentOrderAddress,
  verifiedDestinationAddress: (
    VerifiedAddressSchema as IVerifiedAddress
  ).nullish(),
  customer: (
    FulfillmentOrderCustomerSchema as IFulfillmentOrderCustomer
  ).nullish(),
});

export type FulfillmentGroupSummary = z.infer<typeof FulfillmentGroupSummary>;
export interface IFulfillmentGroupSummary
  extends z.ZodType<FulfillmentGroupSummary> {}

export const FulfillmentGroupSchema = z.object({
  _id: zExt.objectId(),
  team: zExt.objectId(),
  summary: FulfillmentGroupSummary,
  status: z.nativeEnum(FulfillmentGroupStatus).nullish(),
  fulfillmentOrders: z.array(FulfillmentOrderSchema as IFulfillmentOrder),
  shipmentRates: ShipmentRatesSchema.nullish(),
  overrideWeight: WeightSchema.nullish(),
  displayWeightGrams: z.number().nullish(),
  selectedParcel: ParcelSchema.nullish(),
  selectedShippingRate: CarrierAndServiceSchema.nullish(),
  createdAt: z.date(),
  updatedAt: z.date(),
  tags: z.array(TagSchema).nullish(),
  noteToBuyer: z.string().nullish(),
  noteFromBuyer: z.string().nullish(),
  internalNote: z.string().nullish(),
  assignedUserId: zExt.objectId().nullish(),
  shipment: ShipmentPackageSchema.nullish(),
  fulfillments: z.array(RedoFulfillmentSchema as IRedoFulfillment).nullish(),
  printStatus: PrintStatusSchema.nullish(),
  learnedPresets: z.array(LearnedPresetSchema).nullish(),
  ratesPendingSince: z.date().nullish(),
  signatureRequired: z.boolean().nullish(),
  adultSignatureRequired: z.boolean().nullish(),
  overrideDestinationAddresses: (
    FulfillmentGroupAddressesSchema as IFulfillmentGroupAddresses
  ).nullish(),
  mergedTo: zExt.objectId().nullish(),
  ratesErrorMessage: z.string().nullish(),
});

export type FulfillmentGroup = z.infer<typeof FulfillmentGroupSchema>;
export interface IFulfillmentGroup extends z.ZodType<FulfillmentGroup> {}

export const OMSUserSchema = z.object({
  roles: z.array(z.string()),
  _id: zExt.objectId(),
  email: z.string(),
  createdAt: z.coerce.date(),
  firstName: z.string(),
  lastName: z.string(),
  name: z.string(),
  updatedAt: z.coerce.date(),
  team: zExt.objectId(),
  permissions: z.array(PermissionEntrySchema).nullish(),
});
export type OMSUser = z.infer<typeof OMSUserSchema>;

export const FulfillmentOrderDataSchema = FulfillmentGroupSummary.extend({
  _id: z.string(),
  tags: z.array(TagSchema).nullish(),
  orders: z.array(
    z.object({
      redoOrderId: z.string().nullish(),
      name: z.string(),
      tags: z.array(z.string()),
      id: z.string(),
    }),
  ),
  shippingMethodNames: z.array(z.string()),
  items: z.array(FulfillmentOrderLineItemSchema as IFulfillmentOrderLineItem),
  originAddress: FulfillmentOrderAddressSchema,
  verifiedOriginAddress: VerifiedAddressSchema.nullish(),
  destinationAddress: FulfillmentOrderAddressSchema,
  verifiedDestinationAddress: VerifiedAddressSchema.nullish(),
  status: z.nativeEnum(FulfillmentGroupStatus).nullish(),
  selectedParcel: ParcelSchema.nullish(),
  availableShipmentRates: ShipmentRatesSchema.nullish(),
  selectedShippingRate: CarrierAndServiceSchema.nullish(),
  overrideWeight: WeightSchema.nullish(),
  displayWeightGrams: z.number().nullish(),
  assignedUser: OMSUserSchema.nullish(),
  fulfillments: z.array(RedoFulfillmentSchema as IRedoFulfillment).nullish(),
  shipment: ShipmentPackageSchema.nullish(),
  noteToBuyer: z.string().nullish(),
  noteFromBuyer: z.string().nullish(),
  internalNote: z.string().nullish(),
  customAttributes: z.array(
    z.object({ key: z.string(), value: z.string().nullish() }),
  ),
  customerName: z.string().nullish(),
  customerEmail: z.string().nullish(),
  printStatus: PrintStatusSchema.nullish(),
  orderSources: z.array(z.nativeEnum(Provider)),
  risk: RiskSchema.nullish(),
  ratesPendingSince: z.date().nullish(),
  signatureRequired: z.boolean().nullish(),
  adultSignatureRequired: z.boolean().nullish(),
  ratesErrorMessage: z.string().nullish(),
});

export type FulfillmentOrderData = z.infer<typeof FulfillmentOrderDataSchema>;
export interface IFulfillmentOrderData
  extends z.ZodType<FulfillmentOrderData> {}

export function sortFulfillmentOrderLineItems(
  orderBy: ItemOrderBy,
  items: FulfillmentOrderLineItem[],
): FulfillmentOrderLineItem[] {
  switch (orderBy) {
    case ItemOrderBy.QuantityDesc:
      return items.sort((a, b) => b.quantity - a.quantity);
    case ItemOrderBy.QuantityAsc:
      return items.sort((a, b) => a.quantity - b.quantity);
    case ItemOrderBy.NameAsc:
      return items.sort((a, b) => a.title.localeCompare(b.title));
    case ItemOrderBy.NameDesc:
      return items.sort((a, b) => b.title.localeCompare(a.title));
    case ItemOrderBy.SkuAsc:
      return items.sort((a, b) => (a.sku || "").localeCompare(b.sku || ""));
    case ItemOrderBy.SkuDesc:
      return items.sort((a, b) => (b.sku || "").localeCompare(a.sku || ""));
    case ItemOrderBy.TotalPriceAsc:
      return items.sort(
        (a, b) => Number(a.totalPrice.amount) - Number(b.totalPrice.amount),
      );
    case ItemOrderBy.TotalPriceDesc:
      return items.sort(
        (a, b) => Number(b.totalPrice.amount) - Number(a.totalPrice.amount),
      );
    case ItemOrderBy.UnitPriceAsc:
      return items.sort(
        (a, b) => Number(a.unitPrice.amount) - Number(b.unitPrice.amount),
      );
    case ItemOrderBy.UnitPriceDesc:
      return items.sort(
        (a, b) => Number(b.unitPrice.amount) - Number(a.unitPrice.amount),
      );
  }
}

export function sortFulfillmentLineItems(
  orderBy: ItemOrderBy,
  items: FulfillmentLineItem[],
): FulfillmentLineItem[] {
  switch (orderBy) {
    case ItemOrderBy.QuantityDesc:
      return items.sort((a, b) => b.quantity - a.quantity);
    case ItemOrderBy.QuantityAsc:
      return items.sort((a, b) => a.quantity - b.quantity);
    case ItemOrderBy.NameAsc:
      return items.sort((a, b) => a.title.localeCompare(b.title));
    case ItemOrderBy.NameDesc:
      return items.sort((a, b) => b.title.localeCompare(a.title));
    case ItemOrderBy.SkuAsc:
      return items.sort((a, b) => (a.sku || "").localeCompare(b.sku || ""));
    case ItemOrderBy.SkuDesc:
      return items.sort((a, b) => (b.sku || "").localeCompare(a.sku || ""));
    case ItemOrderBy.TotalPriceAsc:
      return items.sort(
        (a, b) => Number(a.totalPrice.amount) - Number(b.totalPrice.amount),
      );
    case ItemOrderBy.TotalPriceDesc:
      return items.sort(
        (a, b) => Number(b.totalPrice.amount) - Number(a.totalPrice.amount),
      );
    case ItemOrderBy.UnitPriceAsc:
      return items.sort(
        (a, b) =>
          Number(a.originalUnitPrice.amount) -
          Number(b.originalUnitPrice.amount),
      );
    case ItemOrderBy.UnitPriceDesc:
      return items.sort(
        (a, b) =>
          Number(b.originalUnitPrice.amount) -
          Number(a.originalUnitPrice.amount),
      );
  }
}

export function getWeightWithParcelGrams(
  totalWeightGrams: number,
  parcel: Parcel | null | undefined,
): Weight {
  const orderWeight: Weight = {
    unit: WeightUnit.GRAM,
    value: totalWeightGrams,
  };

  if (!parcel) {
    return orderWeight;
  }

  const parcelWeight = getParcelWeightGrams(parcel);

  const total = parcelWeight
    ? addWeight(parcelWeight, orderWeight)
    : orderWeight;

  return convertWeight(total, WeightUnit.GRAM);
}

export function getDisplayWeightGrams(
  overrideWeight: Weight | null | undefined,
  totalWeightWithParcelGrams: number,
): number {
  return overrideWeight
    ? convertWeight(overrideWeight, WeightUnit.GRAM).value
    : totalWeightWithParcelGrams;
}

export type FulfillmentGroupForPackingSlip = {
  fulfillmentGroupId: string;
  recipientFirstName: string;
  recipientFullName: string;
  orderItems: OrderItemTableColumnData[];
  destinationAddress: ExtendedAddress;
  originAddress: ExtendedAddress;
  //billingAddress: Address;
  trackingNumber: string | null;
  recipientPhone: string;
  recipientEmail: string;
  orderNumber: string;
  orderDate: string;
  packageWeight: { weight: number; unit: string };
  packageInformation: { name: string; dimensions: string[] };
  productsPrice: string;
  shippingPrice: string;
  tax: string;
  orderTotal: string;
  shippingCarrier: string;
  shippingService: string;
  requestedService: string;
  noteToCustomer: string;
  noteFromCustomer: string;
};

export function getSelectedRate({
  serviceCarrierRate,
  availableRates,
}: {
  serviceCarrierRate: CarrierAndService | null | undefined;
  availableRates: ShipmentRates | null | undefined;
}): OutboundRate | undefined {
  if (!serviceCarrierRate || !availableRates) {
    return undefined;
  }

  return (availableRates?.rates || []).find(
    (rate) =>
      carrierToLabel[rate.carrier] ===
        carrierToLabel[serviceCarrierRate.carrier] &&
      getServiceLabel(rate.service) ===
        getServiceLabel(serviceCarrierRate.service),
  );
}

export function getFulfillmentOrdersByOrder(
  groups: FulfillmentGroup[],
): Record<string, FulfillmentOrder[]> {
  const byOrder = groups
    .flatMap((group) => group.fulfillmentOrders)
    .reduce(
      (acc, fulfillmentOrder) => {
        if (fulfillmentOrder.order) {
          const existing = acc[fulfillmentOrder.order.id] ?? [];
          existing.push(fulfillmentOrder);
          acc[fulfillmentOrder.order.id] = existing;
        }
        return acc;
      },
      {} as Record<string, FulfillmentOrder[]>,
    );
  return byOrder;
}
