// extracted by mini-css-extract-plugin
export var baseCustomerActivityCardArrowRight = "_aa-f";
export var baseCustomerActivityCardArrowRightContainer = "_aa-e";
export var baseCustomerActivityCardContainer = "_aa-c";
export var baseCustomerActivityCardContainerHovered = "_aa-d";
export var conversationActivityDataConversationIconContainer = "_aa-5";
export var customerActivityDataClaimIcon = "_aa-8";
export var customerActivityDataClaimIconContainer = "_aa-7";
export var customerActivityDataConversationIcon = "_aa-6";
export var customerActivityDataConversationIconContainer = "_aa-4";
export var customerActivityDataOrderIcon = "_aa-1";
export var customerActivityDataOrderIconContainer = "_aa-0";
export var customerActivityDataReturnIcon = "_aa-3";
export var customerActivityDataReturnIconContainer = "_aa-2";
export var customerActivityShoppingIconContainer = "_aa-9";
export var messageBubble = "_aa-a";
export var messageBubbleText = "_aa-b";