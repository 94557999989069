import MuiModal from "@mui/material/Modal";
import { applyDynamicVariableSyntax } from "@redotech/redo-model/advanced-flow/schemas/schemas";
import {
  RedoButton,
  RedoButtonHierarchy,
  RedoButtonSize,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import {
  FeaturedIconSize,
  RedoFeaturedIcon,
} from "@redotech/redo-web/arbiter-components/featured-icon/redo-featured-icon";
import { RedoTextInput } from "@redotech/redo-web/arbiter-components/input/redo-text-input";
import * as redoModalCss from "@redotech/redo-web/arbiter-components/modal/redo-modal.module.css";
import {
  ButtonSize,
  RedoButtonCloseX,
} from "@redotech/redo-web/arbiter-components/redo-button-close-x";
import {
  RedoDropdownInputSize,
  RedoSingleSelectDropdownInput,
} from "@redotech/redo-web/arbiter-components/select-dropdown/redo-single-select-dropdown-input";
import VariableIcon from "@redotech/redo-web/arbiter-icon/brackets-ellipses_filled.svg";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { camelToTitleCase } from "@redotech/string/transform";
import * as classNames from "classnames";
import { memo, useCallback, useEffect, useState } from "react";
import * as pickerCss from "./dynamic-variable-picker.module.css";
export type DynamicVariablePickerCloseCause =
  | "clickOutside"
  | "clickX"
  | "clickCancel"
  | "select"
  | "escape";

export const DynamicVariablePicker = memo(function DynamicVariablePicker({
  dynamicVariables,
  handleDynamicVariableSelected,
  onClose,
  open,
  closeOnSelect = true,
}: {
  dynamicVariables: readonly string[];
  handleDynamicVariableSelected: (formattedVariable: string) => void;
  onClose: (cause: DynamicVariablePickerCloseCause) => void;
  open: boolean;
  closeOnSelect?: boolean;
}) {
  const [selectedVariable, setSelectedVariable] = useState<string>();
  const [defaultForVariable, setDefaultForVariable] = useState<string>();

  const onSubmit = useCallback(() => {
    if (!selectedVariable) return;
    handleDynamicVariableSelected(
      applyDynamicVariableSyntax({
        key: selectedVariable,
        default: defaultForVariable,
      }),
    );
    if (closeOnSelect) {
      onClose("select");
    }
  }, [
    selectedVariable,
    defaultForVariable,
    closeOnSelect,
    onClose,
    handleDynamicVariableSelected,
  ]);

  // Attach escape key listener
  useEffect(() => {
    if (!open) return;
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose("escape");
      }
    };
    window.addEventListener("keydown", onKeyDown);
    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  }, [open, onClose]);

  const options = dynamicVariables.map((variableName: string) => ({
    value: variableName,
  }));

  const muiModalCloseCallback = useCallback(
    (event: {}, muiReason?: "backdropClick" | "escapeKeyDown") => {
      let reason: DynamicVariablePickerCloseCause;
      if (muiReason === "backdropClick") {
        reason = "clickOutside";
      } else if (muiReason === "escapeKeyDown") {
        reason = "escape";
      } else {
        reason = "clickX";
      }
      onClose(reason);
    },
    [onClose],
  );

  return (
    <MuiModal onClose={muiModalCloseCallback} open={open}>
      <article
        className={classNames(redoModalCss.modal, pickerCss.pickerModal)}
      >
        <Flex dir="column" p="3xl">
          {/* Header */}
          <Flex dir="column" gap="xl">
            <RedoFeaturedIcon
              icon={<VariableIcon />}
              size={FeaturedIconSize.LARGE}
            />
            <Flex dir="column" gap="xs">
              <Text fontSize="lg" fontWeight="semibold">
                Insert a variable
              </Text>
              <Text fontSize="sm" textColor="tertiary">
                This will be dynamic per subscriber. Set a default for anyone we
                don't have the dynamic content for.
              </Text>
            </Flex>

            <RedoSingleSelectDropdownInput
              label="Variable"
              options={options}
              optionSelected={(item) => setSelectedVariable(item.value)}
              placeholder="Select variable"
              selectedItem={
                selectedVariable ? { value: selectedVariable } : undefined
              }
              size={RedoDropdownInputSize.SMALL}
            >
              {(item) => {
                if (!item.value) return undefined;
                const titleCase = camelToTitleCase(item.value);
                return titleCase[0] + titleCase.slice(1).toLowerCase();
              }}
            </RedoSingleSelectDropdownInput>

            <RedoTextInput
              infoTooltip="This text will appear anytime we don't have the variable information for a subscriber"
              label="Default value"
              placeholder="Set a default"
              setValue={setDefaultForVariable}
              value={defaultForVariable ?? ""}
            />
          </Flex>
          {/* Header */}

          {/* Footer */}
          <Flex alignSelf="flex-end" pt="3xl">
            <RedoButton
              hierarchy={RedoButtonHierarchy.SECONDARY}
              onClick={() => onClose("clickCancel")}
              size={RedoButtonSize.REGULAR}
              text="Cancel"
            />
            <RedoButton
              disabled={!selectedVariable}
              hierarchy={RedoButtonHierarchy.PRIMARY}
              onClick={onSubmit}
              size={RedoButtonSize.REGULAR}
              text="Insert"
            />
          </Flex>
          {/* Footer */}

          {/* X button */}
          <Flex className={pickerCss.xButton}>
            <RedoButtonCloseX
              onclick={() => onClose("clickX")}
              onDarkBackground={false}
              size={ButtonSize.LARGE}
            />
          </Flex>
          {/* X button */}
        </Flex>
      </article>
    </MuiModal>
  );
});
