import { useRequiredContext } from "@redotech/react-util/context";
import { TeamContext } from "@redotech/redo-merchant-app-common/team";
import {
  RedoButton,
  RedoButtonHierarchy,
} from "@redotech/redo-web/arbiter-components/buttons/redo-button";
import { Flex } from "@redotech/redo-web/flex";
import { Text } from "@redotech/redo-web/text";
import { setLocalStorageWithExpiry } from "@redotech/redo-web/utils/local-storage-wrapper";
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import * as aiTrialBannerCss from "./ai-trial-banner.module.css";

const MAX_FREE_MESSAGES = 10;
export const BANNER_DISMISSED_KEY = "aiTrialBannerDismissed";

const calculateHoursUntilNextMonth = () => {
  const now = new Date();
  const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
  const diffInMilliseconds = nextMonth.getTime() - now.getTime();
  const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
  return diffInHours;
};

export const AiTrialBanner = memo(function AiTrialBanner({
  setBannerShown,
}: {
  setBannerShown: (shown: boolean) => void;
}) {
  const team = useRequiredContext(TeamContext);
  const navigate = useNavigate();

  const trialMessagesRemaining =
    team.settings.support?.ai?.trialMessagesRemaining;

  return (
    <div className={aiTrialBannerCss.banner}>
      <Flex align="center" height="full" justify="space-between">
        <Text fontSize="sm" fontWeight="regular" textColor="primary-on-brand">
          {trialMessagesRemaining === MAX_FREE_MESSAGES
            ? "Hey, we've given you 10 more free AI Copilot messages to try out this month!"
            : `You have ${trialMessagesRemaining} free AI Copilot messages remaining this month.`}
        </Text>
        <Flex align="center" gap="3xl">
          <Text
            className={aiTrialBannerCss.buttonText}
            fontSize="xs"
            onClick={() => {
              const hoursUntilNextMonth = calculateHoursUntilNextMonth();
              setLocalStorageWithExpiry(
                BANNER_DISMISSED_KEY,
                "dismissed",
                hoursUntilNextMonth,
              );
              setBannerShown(false);
            }}
            textColor="placeholder"
          >
            Dismiss
          </Text>
          <RedoButton
            hierarchy={RedoButtonHierarchy.SECONDARY}
            onClick={() => {
              navigate("../../settings/billing?currentTab=ai-support");
            }}
            text="Opt in"
          />
        </Flex>
      </Flex>
    </div>
  );
});
