import { breadcrumbSlot } from "@redotech/redo-web/breadcrumb";
import { Navigate, RouteObject } from "react-router-dom";
import { getOrder } from "../client/order";
import { urlIsOnlyOnMainTable } from "../return/route";
import { Order } from "./order";

const fullscreen = (url: string) => {
  return (
    url.includes("/packing-slips/") &&
    url
      .split("/")
      .pop()
      ?.split("?")[0]
      ?.match(/^[0-9a-fA-F]{24}$/) !== null
  );
};

const orderBreadcrumb = breadcrumbSlot();

export const orderRoutes: RouteObject[] = [
  {
    handle: { breadcrumb: "Orders" },
    async lazy() {
      const { Page } = await import("../app/page");
      return {
        element: (
          <Page
            hideHeader={fullscreen}
            hideNavbar={fullscreen}
            hidePadding={(url) =>
              urlIsOnlyOnMainTable(url, [
                "orders",
                "batch-history",
                "eod-manifest",
              ]) || url.includes("outbound-labels")
            }
          />
        ),
      };
    },
    children: [
      {
        index: true,
        async lazy() {
          const { Orders } = await import("./orders");
          return {
            element: (
              <Orders
                filterParams={(filters) => ({
                  dateRange: filters.dateRange,
                  shipmentStatus: filters.shipmentStatus,
                })}
              />
            ),
          };
        },
      },
      {
        path: ":orderId",
        handle: { breadcrumb: orderBreadcrumb },
        async lazy() {
          const { Order } = await import("./order");
          return { element: <Order breadcrumb={orderBreadcrumb} /> };
        },
      },
      {
        path: "outbound-labels",
        handle: { breadcrumb: "Outbound Labels" },
        children: [
          {
            path: "scan",
            handle: { breadcrumb: "Scan" },
            children: [
              {
                index: true,
                async lazy() {
                  const { ScanStartPage } = await import(
                    "@redotech/redo-merchant-app-fulfillment/scan/start-page"
                  );
                  return { Component: ScanStartPage };
                },
              },
              {
                path: ":fulfillmentGroupId",
                async lazy() {
                  const { ScanFulfillmentPage: ScanFulfillemntPage } =
                    await import(
                      "@redotech/redo-merchant-app-fulfillment/scan/fulfillment-page"
                    );
                  return { Component: ScanFulfillemntPage };
                },
              },
            ],
          },
          {
            path: "orders/:orderId",
            handle: { breadcrumb: orderBreadcrumb },
            async lazy() {
              const { OutboundOrder } = await import(
                "@redotech/redo-merchant-app-fulfillment/outbound-order"
              );
              return {
                element: (
                  <OutboundOrder
                    breadcrumb={orderBreadcrumb}
                    getOrder={getOrder}
                  >
                    {(order) => (
                      <Order
                        breadcrumb={orderBreadcrumb}
                        hideActionPortal
                        order={order}
                      />
                    )}
                  </OutboundOrder>
                ),
              };
            },
          },
          { path: "", element: <Navigate replace to="awaiting-shipment" /> },
          {
            handle: { breadcrumb: "Order view" },
            path: "*",
            async lazy() {
              const { FulfillmentGroupPage } = await import(
                "@redotech/redo-merchant-app-fulfillment/fulfillment-groups/fulfillment-group-page"
              );
              return { element: <FulfillmentGroupPage /> };
            },
          },
        ],
      },
      {
        path: "batch-history",
        handle: { breadcrumb: "Batch history" },
        async lazy() {
          const { BatchHistoryPage } = await import(
            "@redotech/redo-merchant-app-fulfillment/batch-history"
          );
          return { element: <BatchHistoryPage /> };
        },
      },
      {
        path: "eod-manifest",
        handle: { breadcrumb: "EOD Manifest" },
        async lazy() {
          const { EodManifestPage } = await import(
            "@redotech/redo-merchant-app-fulfillment/end-of-day-manifest/eod-manifest-page"
          );
          return { element: <EodManifestPage /> };
        },
      },
      {
        path: "packing-slips",
        handle: { breadcrumb: "Packing slips" },
        children: [
          {
            index: true,
            async lazy() {
              const { PackingSlipsPage } = await import(
                "@redotech/redo-merchant-app-fulfillment/packing-slips/packing-slips-page"
              );
              return { element: <PackingSlipsPage /> };
            },
          },
          {
            path: ":packingSlipId",
            handle: { breadcrumb: "Packing slip builder" },
            async lazy() {
              const { PackingSlipBuilder } = await import(
                "@redotech/redo-merchant-app-fulfillment/packing-slips/packing-slip-builder"
              );
              return { Component: PackingSlipBuilder };
            },
          },
        ],
      },
    ],
  },
];
